import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkGetStatusRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkGetStatusBase } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkStatusForm from '../forms/BulkStatusForm'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
}

class BulkStatusPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkStatus = this.sendBulkStatus.bind(this)
  }

  sendBulkStatus(x: BulkGetStatusBase) {
    const productionOrderIdList = x.productionOrderIds
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkGetStatusRequest({
        productionOrderIds: productionOrderIdList
      })
    )
  }

  renderForm() {
    return <BulkStatusForm input=
      {{
        productionOrderIds: ''
      }}
      onSubmit={this.sendBulkStatus}></BulkStatusForm>
  }

  renderResponse() {
  console.log('Bulk Status Response:', this.props.bulkGetStatusResponse);
  const { productionOrderStatusResponses } = this.props.bulkGetStatusResponse;

  const tenMinutesAgo = new Date(new Date().getTime() - 10 * 60000);

  const recentlyChanged = productionOrderStatusResponses?.filter(res => 
    res.timestamp && new Date(res.timestamp) >= tenMinutesAgo && !res.error
  ) || [];

  const notRecentlyChanged = productionOrderStatusResponses?.filter(res => 
    res.timestamp && new Date(res.timestamp) < tenMinutesAgo && !res.error
  ) || [];

  const errors = productionOrderStatusResponses?.filter(x => x.error) || [];

  return (
    <Container fluid className="p-0">
      <Row>
        <Col md="6">
          <h1>Bulk Status Result</h1>
        </Col>
        <Col md="6" className="back-button">
          <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
        </Col>
      </Row>
      <div className="response-container">
        {recentlyChanged && recentlyChanged.length > 0 && (
          <>
            <Row>
              <Col md="12" className='status-success status-heading'>
                Status Changed Last 10 Minutes
            </Col>
            </Row>
            <Row>
              <Col md="4" className="column-highlight">
                Production Order Ids
            </Col>
              <Col md="4" className="column-highlight">
                Status
            </Col>
              <Col md="4" className="column-highlight">
                Timestamp
            </Col>
            </Row>
            {recentlyChanged.map((x, index) => (
              <Row key={index}>
                <Col md="4" className="success-column">
                  {x.productionOrderId}
                </Col>
                <Col md="4" className="success-column">
                  {x.status}
                </Col>
                <Col md="4" className="success-column">
                  {x.timestamp ? new Date(x.timestamp).toDateString() : 'No Timestamp'}
                </Col>
              </Row>
            ))}
          </>
        )}
        {notRecentlyChanged && notRecentlyChanged.length > 0 && (
          <>
            <Row>
              <Col md="12" className='status-error status-heading'>
                Status NOT Changed Last 10 Minutes
            </Col>
            </Row>
            <Row>
              <Col md="4" className="column-highlight">
                Production Order Ids
            </Col>
              <Col md="4" className="column-highlight">
                Status
            </Col>
              <Col md="4" className="column-highlight">
                Timestamp
            </Col>
            </Row>
            {notRecentlyChanged.map((x, index) => (
              <Row key={index}>
                <Col md="4" className="success-column">
                  {x.productionOrderId}
                </Col>
                <Col md="4" className="success-column">
                  {x.status}
                </Col>
                <Col md="4" className="success-column">
                  {x.timestamp ? new Date(x.timestamp).toDateString() : 'No Timestamp'}
                </Col>
              </Row>
            ))}
          </>
        )}
        {errors && errors.length > 0 && (
          <>
            <Row>
              <Col md="12" className='status-error status-heading'>
                Errors
            </Col>
            </Row>
            <Row>
              <Col md="5" className="column-highlight">
                Production Order Ids
            </Col>
              <Col md="5" className="column-highlight">
                Error
            </Col>
            </Row>
            {errors.map((x, index) => (
              <Row key={index}>
                <Col md="5" className="failed-column">
                  {x.productionOrderId}
                </Col>
                <Col md="5" className="failed-column">
                  {x.error}
                </Col>
              </Row>
            ))}
          </>
        )}
      </div>
    </Container>
  )
}
  render() {
    return this.props.bulkGetStatusResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkStatusPanel
