import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import * as commentActions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import * as orderDetailsActions from '../actions/orderDetailsActions'
import { GlobalConfiguration, NoteRequest, OrderDetails, RefundCalculationType } from '../api'
import AmountRefundForm, { FormData } from '../components/forms/AmountRefundForm'
import PercentageRefundForm from '../components/forms/PercentageRefundForm'
import OrderRefundForm from '../components/forms/OrderRefundForm'
import Select from '../components/forms/Select'
import ShippingCostsRefundForm from '../components/forms/ShippingCostsRefundForm'
import NavigationLinks from '../components/generic/NavigationLinks'
import OrderHeader from '../components/generic/OrderHeader'
import OrderPrices from '../components/lists/OrderPrices'
import { CommonProps, GlobalPanels, StaticPanelsCollection, TogglePanel } from '../models'
import { AppStore } from '../reducers/states'
import './RefundsView.scss'
import { OperationType } from '../services/OrderManagementService'
import * as refundsActions from '../actions/refundsActions'

interface Props extends CommonProps {
  order?: OrderDetails
  configuration: GlobalConfiguration
  staticPanels: StaticPanelsCollection
  isNotesInEditMode: boolean
  notesFilter?: string
  showNotesCreatedNotification: boolean
}

interface State {
  reason?: string
  calculationType?: string
}

const calculationOptions = _.values(RefundCalculationType).map((value) => ({ value }))

const refundReasons = [
  '100% satisfaction guarantee partial refund compensation',
  'Compensation wavy pages',
  'Customer applies for 100% satisfaction guarantee, full refund',
  'Customer did not use promotion code',
  'Double payment',
  'Extra Compensation (agreed with TM)',
  'Commercial-bulk order',
  'Gift cards not usable in a combination',
  'Order cancelled',
  'Order partially cancelled',
  'Pick up at plant (shipping costs)',
  'Photo book title is missing / not readable',
  'Received a better promotion code offer',
  'Reupload did not work',
  'VAT refund - B2B customer'
]

const reasonOptions = refundReasons.map((value) => ({ value }))

class RefundsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { calculationType: RefundCalculationType.Amount }
    this.onSubmitAmountRefund = this.onSubmitAmountRefund.bind(this)
    this.onChangeCalculationType = this.onChangeCalculationType.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.sendNote = this.sendNote.bind(this)
    this.toggleNotes = this.toggleNotes.bind(this)
    this.toggleNotesMode = this.toggleNotesMode.bind(this)
  }

  render() {
    return this.props.order ? this.renderForm(this.props.order) : <div />
  }

  componentDidMount() {
    if (!this.props.order || this.props.order.id !== this.props.match.params.orderId) {
      this.props.dispatch(orderDetailsActions.requestOrderDetails(this.props.match.params.orderId))
    } else if (document.body.scrollTop !== 0) {
      this.props.dispatch(global.showSpinnerForMoment())
    }
  }

  private renderForm(order: OrderDetails) {
    const notesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderNotes.accessKey],
      toggle: this.toggleNotes,
    }

    return (
      <div className="refunds">
        <NavigationLinks orderCode={order.id} source={order.source} configuration={this.props.configuration} />
        <OrderHeader
          order={order}
          prefix="Refund:"
          notesPanelDetails={{
            notesPanel: notesPanel,
            isNotesInEditMode: this.props.isNotesInEditMode,
            notesFilter: this.props.notesFilter,
            sendNote: this.sendNote,
            showNotesCreatedNotification: this.props.showNotesCreatedNotification,
            toggleNotes: this.toggleNotes,
            toggleNotesMode: this.toggleNotesMode,
          }}
        />
        <div className="content">
          <div className="refund-form">
            <FormGroup>
              <Label>Refund reason</Label>
              <Select
                onChange={this.onChangeReason}
                value={this.state.reason}
                emptyText="-- select a refund reason --"
                options={reasonOptions}
                id="refund-reason-selector"
              />
            </FormGroup>
            <FormGroup>
              <Label>Refund calculation</Label>
              <Select
                onChange={this.onChangeCalculationType}
                value={this.state.calculationType}
                emptyText="-- select a refund calculation --"
                options={calculationOptions}
                id="refund-calculation-selector"
              />
            </FormGroup>
            {this.state.calculationType === RefundCalculationType.Amount && (
              <AmountRefundForm onSubmit={this.onSubmitAmountRefund} valid={!!this.state.reason} />
            )}
            {this.state.calculationType === RefundCalculationType.Percentage && (
              <PercentageRefundForm orderDetails={order} onSubmit={this.onSubmitAmountRefund} valid={!!this.state.reason} />
            )}
            {this.state.calculationType === RefundCalculationType.Order && (
              <OrderRefundForm orderDetails={order} onSubmit={this.onSubmitAmountRefund} valid={!!this.state.reason} />
            )}
            {this.state.calculationType === RefundCalculationType.ShippingCosts && (
              <ShippingCostsRefundForm orderDetails={order} onSubmit={this.onSubmitAmountRefund} valid={!!this.state.reason} />
            )}
          </div>
          <OrderPrices order={order} />
        </div>
      </div>
    )
  }

  private sendNote(request: NoteRequest) {
    this.props.dispatch(commentActions.sendComment(request))
  }

  private toggleNotesMode(isEditMode?: boolean) {
    this.props.dispatch(orderDetailsActions.toggleNotesPanelMode(isEditMode))
  }

  private toggleNotes() {
    this.props.dispatch(orderDetailsActions.togglePanel(GlobalPanels.orderNotes.accessKey))
  }

  private onChangeCalculationType(event: ChangeEvent<{ value: string }>) {
    this.setState({ calculationType: event.target.value })
  }

  private onChangeReason(event: ChangeEvent<{ value: string }>) {
    this.setState({ reason: event.target.value })
  }

  private onSubmitAmountRefund(form: FormData) {
    if (form.amount !== undefined && this.state.reason) {
      this.props.dispatch(
        refundsActions.submitAmountRefund({
          reason: this.state.reason,
          amount: Math.round((form.amount + Number.EPSILON) * 100) / 100,
          orderCode: this.props.order!.id,
          refundType: OperationType[form.operationType!] || OperationType[OperationType.RefundAmount]
        })
      )
    }
  }
}

function mapStateToProps(state: AppStore) {
  return {
    staticPanels: state.orderDetailsView.staticPanels,
    isNotesInEditMode: state.orderDetailsView.isNotesInEditMode,
    notesFilter: state.orderDetailsView.notesFilter,
    showNotesCreatedNotification: state.orderDetailsView.showNotesCreatedNotification,
    order: state.orderDetails.data,
    configuration: state.global.configuration,
  }
}

export default connect(mapStateToProps)(RefundsView)
