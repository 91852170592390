import _ from 'lodash'
import React from 'react'
import { Note, NoteRequestBase } from '../../api'
import { MixupNoteForm } from '../forms/MixupNoteForm'
import LazyContainer from '../generic/LazyContainer'
import NoteItem from '../generic/NoteItem'
import SidePanel, { SidePanelSettings } from '../panels/SidePanel'

export interface MixupNotesPanelProps extends SidePanelSettings {
  parentId: string
  notes: Note[] | undefined
  onNoteSubmit(x: NoteRequestBase): void
  isLoading: boolean
}

export default class MixupNotesPanel extends React.Component<MixupNotesPanelProps, any> {
  render() {
    const notes = _.sortBy(this.props.notes || [], (x) => -new Date(x.timestamp || 0))
    return (
      <SidePanel {...this.props}>
        <LazyContainer isLoading={this.props.isLoading}>
          <div className="mb-4">
            <MixupNoteForm form={`${this.props.parentId}_notes`} onSubmit={this.props.onNoteSubmit} />
          </div>
          {notes.map((note, i) => (
            <NoteItem note={note} key={i} lookupSecondaryKeyDisplayName={(_key) => undefined} />
          ))}
        </LazyContainer>
      </SidePanel>
    )
  }
}
