import _ from 'lodash'
import React from 'react'
import { Product } from '../../api'
import { FulfillmentProduct } from '../../api'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import SidePanel, { SidePanelSettings } from './SidePanel'

export interface ProductOptionsPanelProps extends SidePanelSettings {
  product?: Product
  fulfillmentProduct?: FulfillmentProduct
}

export default class FulfilmentProductOptionsPanel extends React.Component<ProductOptionsPanelProps> {
  render() {
    const fulfillmentProduct = this.props.fulfillmentProduct
    const fulfillmentProductOptions = Service.getFulfillmentProductOptions(fulfillmentProduct)
    const fulfillmentProductOptionsSorted = _.sortBy(fulfillmentProductOptions, ['name'])
    const product = this.props.product
    const productOptions = Service.getProductOptions(product)
    const productOptionsSorted = _.sortBy(productOptions, ['name'])

    return (
      <SidePanel {...this.props}>
        {product ? (
          <div>
            <h3>
              {Service.resolveProductDescriptionWithFallback(
                fulfillmentProduct && fulfillmentProduct.productId,
                product.productId
              )}
            </h3>
            <table className="bo-grid-vertical wide">
              <tbody>
                {fulfillmentProductOptions &&
                  fulfillmentProductOptionsSorted.map((o) => (
                    <tr key={o.name}>
                      <th>{o.name}</th>
                      <td>{o.value ? o.value : <i>Unknown</i>}</td>
                    </tr>
                  ))}
                {!fulfillmentProductOptions &&
                  productOptions &&
                  productOptionsSorted.map((o) => (
                    <tr key={o.name}>
                      <th>{o.name}</th>
                      <td>{o.value ? o.value : <i>Unknown</i>}</td>
                    </tr>
                  ))}
                {fulfillmentProduct && fulfillmentProduct.barcodes && fulfillmentProduct.barcodes.length > 0 && (
                  <React.Fragment>
                    <tr className="delimiter">
                      <th colSpan={2}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <th>Barcodes</th>
                      <td>{_.join(fulfillmentProduct.barcodes, ', ')}</td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <h3>
            <i>Options are not available</i>
          </h3>
        )}
      </SidePanel>
    )
  }
}
