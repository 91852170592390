import _ from 'lodash'
import React from 'react'
import { CommentType, Note, NoteRequest, NoteRequestBase } from '../../api'
import * as OrderTypes from '../../api/order'
import confirm from '../../assets/img/confirm.svg'
import { TogglePanel } from '../../models'
import { OperationType, ProductWithValue, ProductionOrderDetails } from '../../services/OrderManagementService'
import { OrderNoteForm } from '../forms/OrderNoteForm'
import OrderStructure from '../forms/OrderStructure'
import LazyContainer from '../generic/LazyContainer'
import NoteItem from '../generic/NoteItem'
import SidePanel, { SidePanelSettings } from './SidePanel'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'

export interface OrderNotesPanelProps extends SidePanelSettings, TogglePanel {
  editMode: boolean
  filter: string | undefined
  orderData: OrderTypes.OrderDetails
  onNoteSubmit(x: NoteRequest): void
  toggleEditMode(): void
  lookupProductKeyDisplayName(key: string | undefined): string
  isLoading: boolean
  showCreatedNotification: boolean
}

interface OrderNotesPanelState {
  products: ProductWithValue[]
  productionOrders: ProductionOrderDetails[]
}

export default class OrderNotesPanel extends React.Component<OrderNotesPanelProps, OrderNotesPanelState> {
  constructor(props: OrderNotesPanelProps) {
    super(props)

    this.state = {
      products: [],
      productionOrders: []
    }

    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.submitNote = this.submitNote.bind(this)
  }

  handleProductsChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>) {
    this.setState({
      products: products,
      productionOrders: productionOrders
    })
  }

  submitNote(x: NoteRequestBase) {
    const allProductsAffected = (this.state.productionOrders.length === 0 && this.state.products.length > 0 && this.state.products.every((p) => p.value === p.quantity))
      || (this.state.products.length === 0 && this.state.productionOrders.length > 0 && this.state.productionOrders.every((po) => po.products.every((p) => p.value === p.quantity)))
      || (this.state.products.length > 0 && this.state.products.every((p) => p.value === p.quantity)
        && this.state.productionOrders.length > 0 && this.state.productionOrders.every((po) => po.products.every((p) => p.value === p.quantity)))

    var affectedIds: string[] = []

    if (!allProductsAffected) {
      if (this.state.products !== undefined) {
        affectedIds = this.state.products.filter((p) => p.value > 0).map((p) => p.id)
      }
      if (this.state.productionOrders !== undefined) {
        const affectedProducts = this.state.productionOrders.flatMap((po) => po.products.filter((p) => p.value > 0).map((p) => p.id))
        const distinctAffectedProducts = affectedProducts.filter(function (v, i, self) {
          return i == self.indexOf(v);
        });

        affectedIds = affectedIds.concat(distinctAffectedProducts)
      }
    }

    const secondaryKey = affectedIds && affectedIds.length > 0 ? affectedIds.join(',') : undefined

    const payload: NoteRequest = {
      body: x.body,
      parentId: this.props.orderData.id,
      type: CommentType.OrderNote,
      secondaryKey: (allProductsAffected) ? undefined : secondaryKey,
    }

    this.props.onNoteSubmit(payload)
    this.props.toggleEditMode()
  }

  // TODO: introduce log service
  filterPredicate(note: Note, filter: string | undefined): boolean {
    if (!filter || !note.secondaryKey) {
      return true
    }

    return note.secondaryKey.includes(filter)
  }

  render() {
    let notes = this.props.orderData.notes || []

    notes = _.sortBy(notes, (x) => -((x.timestamp && new Date(x.timestamp)) || new Date(0)))
    return (
      <SidePanel {...this.props}>
        <LazyContainer isLoading={this.props.isLoading}>
          {this.props.editMode ? (
            <div className="mb-4">
              <OrderStructure
                preselectedProductId={this.props.orderData.preselectedProductId}
                data={this.props.orderData}
                handleChange={this.handleProductsChange}
                operation={OperationType.Note}
              />
              <OrderNoteForm
                form={`${this.props.orderData.id}_notes`}
                onSubmit={this.submitNote}
                onCancel={this.props.toggleEditMode}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <span className={`${this.props.showCreatedNotification ? 'visible fade-out' : 'invisible'}`}>
                <img src={confirm} width="32" height="32" className="mr-2" alt="confirmed" />
                <b>Note added</b>
              </span>
              <button
                className="medium"
                onClick={(e) => {
                  e.preventDefault()
                  this.props.toggleEditMode()
                }}>
                Add note
              </button>
            </div>
          )}
          <hr />
          {notes
            .filter((note) => this.filterPredicate(note, this.props.filter))
            .map((note, i) => (
              <NoteItem note={note} key={i} lookupSecondaryKeyDisplayName={this.props.lookupProductKeyDisplayName} />
            ))}
        </LazyContainer>
      </SidePanel>
    )
  }
}
