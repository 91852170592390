import React, { MouseEvent, ReactNode } from 'react'

const newlineRegex = /(\r\n|\r|\n)/g

export function highlightText(input: string | undefined, highlight = true): React.ReactNode {
  if (input && highlight) {
    return <mark>{input}</mark>
  }

  return input
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function highlight(input: string | undefined, search: string | undefined): React.ReactNode {
  if (!input) {
    return ''
  }

  if (!search) {
    return input || ''
  }

  if (search.toUpperCase() === input.toUpperCase()) {
    return <mark>{input}</mark>
  }

  const regexp = new RegExp(escapeRegExp(search), 'gi')
  const parts = input.split(regexp)
  const items = parts.map((item, index) => (
    <React.Fragment key={index}>
      {item}
      {index !== parts.length - 1 && <mark>{search}</mark>}
    </React.Fragment>
  ))

  return <React.Fragment>{items}</React.Fragment>
}

export function nl2br(
  str: string | number | null | undefined,
  func?: (line: string | undefined, index: number) => ReactNode
): ReactNode {
  return regexpReplacer(str, newlineRegex, function(line, index) {
    if (!line) {
      return ''
    }
    if (line.match(newlineRegex)) {
      return <br key={index} />
    }
    return func === undefined ? line : func(line, index)
  })
}

export function regexpReplacer(
  str: string | number | null | undefined,
  regex: RegExp,
  func: (line: string | undefined, index: number) => string | ReactNode
): ReactNode {
  if (!str) {
    return ''
  } else if (typeof str === 'number') {
    return str
  } else if (typeof str !== 'string') {
    return ''
  }

  return str.split(regex).map(function(line, index) {
    return func(line, index)
  })
}

export function selectOnClick(e: MouseEvent) {
  e.stopPropagation()
  selectText(e.target)
}

export function selectText(node: any) {
  const body: any = document.body
  if (body.createTextRange) {
    const range = body.createTextRange()
    range.moveToElementText(node)
    range.select()
  } else if (window.getSelection) {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  } else {
    console.warn('Could not select text in node: Unsupported browser.')
  }
}
