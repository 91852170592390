import React, { ReactNode } from 'react'
import closed from '../../assets/img/closed.svg'
import open from '../../assets/img/open.svg'
import ActionLink from './ActionLink'

interface CollapsableContainerWithLinkProps {
  title?: string
  link?: string
  index: number
  folderCards: boolean[]
  onToggleCard(index: number): void
  foldAll: boolean
  children?: ReactNode,
  toolTip?: string
}

interface CollapsableContainerWithLinkState {
  folded: boolean
}

export default class CollapsableContainerWithLink extends React.Component<
  CollapsableContainerWithLinkProps,
  CollapsableContainerWithLinkState
> {
  constructor(props: CollapsableContainerWithLinkProps) {
    super(props)

  }

  isFolded(index: number) {
    if (this.props.folderCards && this.props.folderCards[index] !== undefined) {
      return !!this.props.folderCards[index]
    }

    return !!this.props.foldAll
  }

  render() {
    return (
      <React.Fragment>
        <span className="d-flex" onClick={() => this.props.onToggleCard(this.props.index) }>
          <img src={this.isFolded(this.props.index) ? closed : open} width="20" height="20" alt="folded" />
          {this.props.title && !this.props.toolTip && <h3>{this.props.title}</h3>}
          {this.props.title && this.props.toolTip &&<h3 title={this.props.toolTip}>{this.props.title}</h3>}
          {this.props.link && (
            <ActionLink
              text="Open in Fulfillment Management UI"
              href={this.props.link}
              onClick={(e) => e.stopPropagation()}
              className="ml-auto mb-2"
            />
          )}
        </span>
        {!this.isFolded(this.props.index) && this.props.children}
      </React.Fragment>
    )
  }
}
