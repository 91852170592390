import React, { ChangeEventHandler } from 'react'
import { Input } from 'reactstrap'

interface Props {
  onChange: ChangeEventHandler<{ value: string }>
  value?: string
  options: {
    value: string | number
    text?: string
  }[]
  emptyText?: string
  disabled?: boolean
  invalid?: boolean
  id?: string
  alwaysShowEmptyText?: boolean
}

export default function Select(props: Props) {
  return (
    <div>
      <Input
        onChange={props.onChange}
        value={props.value || ''}
        type="select"
        disabled={props.disabled}
        invalid={props.invalid}
        id={props.id}>
        {props.emptyText && (
          <option value="" disabled={!props.alwaysShowEmptyText} style={!props.alwaysShowEmptyText ? { display: 'none' } : {}}>
            {` ${props.emptyText} `}
          </option>
        )}
        {props.options.map((x, i) => (
          <option value={x.value} key={i}>
            {x.text === undefined ? x.value : x.text}
          </option>
        ))}
      </Input>
    </div>
  )
}
