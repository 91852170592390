import { discountsSlice } from '../slices/discounts'

export * from './customerDetailsReducer'
export * from './customerDetailsViewReducer'
export * from './globalReducer'
export * from './mixupDetailsReducer'
export * from './mixupDetailsViewReducer'
export * from './mixupReducer'
export * from './orderDetailsReducer'
export * from './orderDetailsViewReducer'
export * from './productViewReducer'
export * from './sbsReducer'
export * from './searchReducer'
export * from './projectReducer'
export * from './bulkOperationsViewReducer'
export * from './accountReducer'
export * from './redoReducer'

export const discounts = discountsSlice.reducer
