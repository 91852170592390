import React from 'react'
import { Input as BootstrapInput } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import ValidationWrapper, { CustomComponent } from './ValidationWrapper'

export default function ReadOnlyInput(props: WrappedFieldProps & CustomComponent) {
  return (
    <ValidationWrapper {...props}>
      <BootstrapInput type="text" {...props.input} readOnly={true} />
    </ValidationWrapper>
  )
}
