import { adalGetToken, AuthenticationContext, runWithAdal, UserInfo } from 'react-adal'
import { LocalStorageInstance } from './LocalStorageService'

export class AuthService {
  authContext: AuthenticationContext

  constructor() {
    // TODO: configure me externally (enc variables, api call etc)
    this.authContext = new AuthenticationContext({
      clientId: 'cba38adc-67c6-440f-87e2-ba708a723c0b',
      tenant: '5ac5a0c0-3ee7-4848-be5e-226c80860f44',
      cacheLocation: 'localStorage'
    })
  }

  getToken(): Promise<string | null> {
    return adalGetToken(this.authContext, this.authContext.config.clientId)
      .then((token: any) => token)
      .catch((reason: any) => {
        console.error(reason)
        throw reason
      })
  }

  logOut(): void {
    LocalStorageInstance.clearAll()
    this.authContext.logOut()
  }

  getUser(): UserInfo {
    return this.authContext.getCachedUser()
  }

  runWithAdal(app: any): void {
    const DO_NOT_LOGIN = false
    runWithAdal(this.authContext, app, DO_NOT_LOGIN)
  }
}

export const AuthInstance = new AuthService()
