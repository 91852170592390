import React from 'react'
import { Discount } from '../../api/order'
import { DiscountView } from './DiscountView'
import LazyContainer from './LazyContainer'
import QuestionmarkWithTooltip from './QuestionmarkWithTooltip'

interface DiscountViewWithTooltipProps {
  discountCode: string
  loading: boolean
  error?: string
  data?: Discount
  showTooltip: boolean
  onLoad: () => void
}

export default class DiscountViewWithTooltip extends React.Component<DiscountViewWithTooltipProps> {
  render() {
    return (
      <span>
        <span id="discount_code">{this.props.discountCode}</span>
        {this.props.showTooltip === true && (
          <QuestionmarkWithTooltip
            tooltipId="discount_tooltip"
            placement="right-end"
            boundariesElement="viewport"
            size={20}
            onOpen={() => (this.props.data ? () => {} : this.props.onLoad())}>
            <LazyContainer isLoading={this.props.loading} className={this.props.loading ? 'p-3' : 'p-0'}>
              <DiscountView error={this.props.error} data={this.props.data} discountCode={this.props.discountCode} />
            </LazyContainer>
          </QuestionmarkWithTooltip>
        )}
      </span>
    )
  }
}
