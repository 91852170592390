import { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import * as commentActions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import * as actions from '../actions/productViewActions'
import {
  CommentType,
  FileInfoResponse,
  ProductContentLog,
  ProductContentView,
  ProductsRequest,
  RestartFinalizerRequest,
  RestoreFromColdStorageRequest,
} from '../api'
import { AppStore } from '../reducers/states'
import routes from '../route/routes'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* fetchProductContent(action: PayloadAction<ProductsRequest>) {
  try {
    yield put(global.showSpinner())
    yield fetchProductContentInternal(action.payload.contentId)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
    if (e.response && e.response.status === 404) {
      yield put(push(routes.NOT_FOUND()))
    } else {
      yield put(push(routes.ERROR()))
    }
  } finally {
    yield put(global.hideSpinner())
  }
}

function* fetchPartialProductContent(action: PayloadAction<string>) {
  try {
    yield fetchProductContentInternal(action.payload)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* fetchProductContentInternal(contentId: string) {
  const details: ProductContentView = yield call(ApiServiceInstance.get, `product/${contentId}`)

  if (!details) {
    yield put(push(routes.NOT_FOUND()))
    return
  }

  yield put(actions.receivedProductView(details))

  yield fetchProductContentLog(contentId)
}

function* fetchProductContentLog(contentId: string) {
  try {
    const logs: ProductContentLog[] = yield call(ApiServiceInstance.get, `product/${contentId}/logs`)

    yield put(actions.receivedProductLogs(logs))
  } catch (e) {
    // 404 for Vistaprint orders
    console.warn(e)
  }
}

function* restartFinalizer(action: PayloadAction<RestartFinalizerRequest>) {
  try {
    yield call(ApiServiceInstance.post, `product/refinalize`, action.payload)
    yield fetchProductContentInternal(action.payload.contentId)
    if (action.payload.orderCode) {
      yield put(commentActions.requestBulk({ parentId: action.payload.orderCode, type: CommentType.OrderNote }))
    }
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* requestRestoreFromColdStorage(action: PayloadAction<RestoreFromColdStorageRequest>) {
  try {
    const restoreDetails = yield call(ApiServiceInstance.post, `product/restore`, action.payload)
    yield put(global.showWarning('The file is being retrieved from cold storage, it can take up to 5 minutes'))
    yield put(actions.requestRestoringFiles(restoreDetails.data))
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* requestRestoringFiles(action: PayloadAction) {
  try {
    const restoringFiles = yield select((store: AppStore) => store.productView.restoringFiles)
    if (!restoringFiles || !restoringFiles.length) {
      return
    }

    const response = yield call(
      ApiServiceInstance.get,
      `product/files?${queryString.stringify({ storageIds: restoringFiles }, { arrayFormat: 'index' })}`
    )

    for (let i = 0; i < restoringFiles.length; i++) {
      const storageId = restoringFiles[i]
      const file = response[storageId] as FileInfoResponse
      if (file && file.coldStorage === false) {
        yield put(actions.receivedRestoringFile(file))
        yield put(global.hideWarning())
        yield put(global.showInfo('The file is available!'))
      }
    }

    yield delay(10000) // wait 10 seconds

    yield put(action) // self trigger
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

export default function* root() {
  yield all([
    yield takeLatest(actions.requestProductView, fetchProductContent),
    yield takeLatest(actions.requestRestartFinalizer, restartFinalizer),
    yield takeLatest(actions.requestProductViewPartial, fetchPartialProductContent),
    yield takeLatest(actions.requestRestoreFromColdStorage, requestRestoreFromColdStorage),
    yield takeLatest(actions.requestRestoringFiles, requestRestoringFiles),
  ])
}
