import { Note } from './note'

export interface MixupDetails {
  form: MixupRecord
  notes: Note[]
}

export interface MixupRecord {
  id: string
  orderRef: string
  ticketRef?: string
  type?: MixupType
  category?: MixupCategory
  barcodeMatchState: MixupBarcodeMatchState
  pdfChecked?: boolean
  returnState: MixupReturnState
  description?: string
}

export enum MixupType {
  Unknown = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4
}

export enum MixupCategory {
  Unknown = 0,
  First = 1,
  Second = 2,
  Third = 3
}

export const MixupTypeNames: { [type: number]: string } = {
  [MixupType.First]: 'Package mislabeled',
  [MixupType.Second]: 'Products bundled incorrectly',
  [MixupType.Third]: 'Barcode stuck to wrong product',
  [MixupType.Fourth]: 'Production error'
}

export const MixupTypeHints: { [type: number]: string } = {
  [MixupType.First]: 'Complete mix-up, the address sticker was put on the wrong package.',
  [MixupType.Second]:
    'Order contains multiple products, being incorrectly bundled in production. This causes customer A to receive (one of) his/her own product(s) + product(s) from another customer.',
  [MixupType.Third]: 'All barcodes belong to customer A, but not all products belong to customer A.',
  [MixupType.Fourth]:
    'During producing the product, images are mixed up. Customer A received a book/calendar/prints/cards with his/her own images + some (not all!) from another customer. Also applies to photo prints and cards.'
}

export const MixupCategoryNumber: { [type: number]: string } = {
  [MixupCategory.Unknown]: 'Unknown',
  [MixupCategory.First]: 'Category 1',
  [MixupCategory.Second]: 'Category 2',
  [MixupCategory.Third]: 'Category 3',
}

export const MixupCategoryHints: { [type: number]: string } = {
  [MixupCategory.Unknown]: 'Unknown',
  [MixupCategory.First]: 'No significant risk.',
  [MixupCategory.Second]: 'Possible/some risk.',
  [MixupCategory.Third]: 'High risk.'
}

export enum MixupBarcodeMatchState {
  Unknown = 0,
  Matched = 1,
  NotMatched = 2
}

export const MixupBarcodeMatchStateNames: { [type: number]: string } = {
  [MixupBarcodeMatchState.Unknown]: 'Unknown',
  [MixupBarcodeMatchState.Matched]: 'Matched',
  [MixupBarcodeMatchState.NotMatched]: 'Not matched'
}

export enum MixupReturnState {
  NotReturned = 0,
  ReturnedOrDestroyed = 1,
  PartiallyReturnedOrDestroyed = 2,
  DestroyedByCustomer = 3
}

export const MixupReturnStateNames: { [type: number]: string } = {
  [MixupReturnState.NotReturned]: 'Not returned',
  [MixupReturnState.ReturnedOrDestroyed]: 'Returned/Destroyed',
  [MixupReturnState.PartiallyReturnedOrDestroyed]: 'Partially returned/destroyed',
  [MixupReturnState.DestroyedByCustomer]: 'Destroyed by customer'
}
