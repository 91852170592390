import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as comments from '../actions/commentActions'
import * as actions from '../actions/mixupDetailsActions'
import { isCommentRelatedToMixUp, MixupBarcodeMatchState, MixupDetails, MixupReturnState, NoteResponse } from '../api'
import { MixupDetailsStore } from './states'

const initialState: MixupDetailsStore = defaultState()

function defaultState(orderRef?: string | undefined): MixupDetailsStore {
  return {
    form: {
      id: '',
      orderRef: orderRef || '',
      returnState: MixupReturnState.NotReturned,
      barcodeMatchState: MixupBarcodeMatchState.Unknown,
      pdfChecked: false
    },
    notes: [],
  }
}

export const mixupDetails = createReducer<MixupDetailsStore>(initialState, {
  [actions.reset.type]: (_state, action: PayloadAction<string | undefined>) => defaultState(action.payload),
  [actions.receivedMixup.type]: (_state, action: PayloadAction<MixupDetails>) => {
    return action.payload
  },
  [comments.sentComment.type]: (state, action: PayloadAction<NoteResponse>) => {
    if (isCommentRelatedToMixUp(state.form.id, action)) {
      state.notes = state.notes.concat([action.payload.response])
    }
  },
})
