import React from 'react'
import { Col, Container, Form, FormGroup, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { OrderDeliveryAddress } from '../../api'
import { nameof, validators } from '../../utils'
import Input from './Input'
import ReadOnlyInput from './ReadOnlyInput'

interface ChangeAddressFormProps {
  onSubmit(newAddress: OrderDeliveryAddress): void
  disableSubmit: boolean
  showExtendedAddress: boolean
  showCounty: boolean
  showComplement: boolean
}

function ChangeAddressFormInternal({
  handleSubmit,
  onSubmit,
  submitting,
  invalid,
  disableSubmit,
  showExtendedAddress,
  showCounty,
  showComplement,
}: ChangeAddressFormProps & InjectedFormProps<OrderDeliveryAddress, ChangeAddressFormProps>) {
  return (
    <Container className="pl-0">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {renderRow(nameof<OrderDeliveryAddress>('customerTitle'), 'Customer Title', Input, false, 0, 100)}
        {renderRow(nameof<OrderDeliveryAddress>('customerFirstName'), 'Customer First Name', Input, true, 0, 24)}
        {renderRow(nameof<OrderDeliveryAddress>('customerPrefix'), 'Customer Prefix', Input, false, 0, 24)}
        {renderRow(nameof<OrderDeliveryAddress>('customerLastName'), 'Customer Last Name', Input, true, 0, 24)}
        {renderRow(nameof<OrderDeliveryAddress>('companyName'), 'Company', Input, false, 2, 25)}
        {renderRow(nameof<OrderDeliveryAddress>('street'), 'Street Name', Input, true, 0, 200, showExtendedAddress)}
        {renderRow(nameof<OrderDeliveryAddress>('houseNumber'), 'House Number', Input, true, 0, 9, showExtendedAddress)}
        {renderRow(
          nameof<OrderDeliveryAddress>('houseNumberExtension'),
          'House Number Extension',
          Input,
          false,
          0,
          10,
          showExtendedAddress
        )}
        {renderRow(
          nameof<OrderDeliveryAddress>('addressLine1'),
          showComplement ? 'Address' : 'Address Line 1',
          Input,
          true,
          0,
          200,
          !showExtendedAddress
        )}
        {renderRow(
          nameof<OrderDeliveryAddress>('addressLine2'),
          showComplement ? 'Complément et Lieu-dit' : 'Address Line 2',
          Input,
          false,
          0,
          200,
          !showExtendedAddress
        )}
        {renderRow(nameof<OrderDeliveryAddress>('zipCode'), 'Zip', Input, true, 0, 30)}
        {renderRow(nameof<OrderDeliveryAddress>('city'), 'City', Input, true, 0, 50)}
        {renderRow(nameof<OrderDeliveryAddress>('county'), 'County', Input, false, 0, 100, showCounty)}
        {renderRow(nameof<OrderDeliveryAddress>('country'), 'Country', ReadOnlyInput, true, 0, 20)}
        {renderRow(nameof<OrderDeliveryAddress>('customerPhone'), 'Phone', Input, false, 0, 100)}
        <FormGroup className="mt-3">
          <button type="submit" disabled={submitting || invalid || disableSubmit}>
            Submit
          </button>
        </FormGroup>
      </Form>
    </Container>
  )
}

function renderRow(
  name: keyof OrderDeliveryAddress,
  label: string,
  component: any,
  isRequired: boolean,
  minlength?: number,
  maxLength?: number,
  show: boolean = true
) {
  if (!show) {
    return
  }

  const validatorsArray = []
  if (isRequired) {
    validatorsArray.push(validators.required)
  }
  switch (maxLength) {
    case undefined:
      break
    case 9:
      validatorsArray.push(validators.maxLength9)
      break
    case 10:
      validatorsArray.push(validators.maxLength10)
      break
    case 20:
      validatorsArray.push(validators.maxLength20)
      break
    case 24:
      validatorsArray.push(validators.maxLength24)
      break
    case 25:
      validatorsArray.push(validators.maxLength25)
      break
    case 30:
      validatorsArray.push(validators.maxLength30)
      break
    case 50:
      validatorsArray.push(validators.maxLength50)
      break
    case 100:
      validatorsArray.push(validators.maxLength100)
      break
    case 200:
      validatorsArray.push(validators.maxLength200)
      break
    default:
      validatorsArray.push(validators.maxLengthUnsupported)
      break
  }
  switch (minlength) {
    case undefined:
    case 0:
      break
    case 2:
      validatorsArray.push(validators.minLength2)
      break
    default:
      validatorsArray.push(validators.minLengthUnsupported)
      break
  }

  return (
    <Row>
      <Col sm="12" className="pr-0">
        <Field name={name} component={component} label={label} validate={validatorsArray} />
      </Col>
    </Row>
  )
}

export const ChangeAddressForm = reduxForm<OrderDeliveryAddress, ChangeAddressFormProps>({ enableReinitialize: true })(
  ChangeAddressFormInternal
)
