import { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as global from '../actions/globalActions'
import * as actions from '../actions/mixupActions'
import * as detailsActions from '../actions/mixupDetailsActions'
import { AppStore, MixupSearchStore } from '../reducers/states'
import routes from '../route/routes'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* fetchMixups() {
  yield put(actions.loadStarted())
  const mixups: MixupSearchStore = yield select((store: AppStore) => store.mixups)

  try {
    const query = {
      ...mixups.filter,
      limit: mixups.data.limit,
      paginationToken: mixups.data.paginationToken,
    }
    const response = yield call(ApiServiceInstance.get, `mix-up?${queryString.stringify(query)}`)

    yield put(actions.receivedMixups(response))
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* newOpen(action: PayloadAction<any>) {
  yield put(detailsActions.reset(action.payload))
  const location = yield select((store: AppStore) => store.router.location)
  yield put(push(`${routes.MIXUPS_NEW()}${location.search}`))
}

function* editOpen(action: PayloadAction<string>) {
  yield put(global.showSpinner())
  yield put(detailsActions.reset())

  try {
    const response = yield call(ApiServiceInstance.get, `mix-up/${action.payload}`)

    yield put(detailsActions.receivedMixup(response))
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put(push(routes.NOT_FOUND()))
      return
    }
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* sendCreateOrUpdate(action: PayloadAction<any>) {
  yield put(global.showSpinner())

  try {
    if (!action.payload.id) {
      const query = {
        byOrder: action.payload.orderRef,
        limit: 1,
        paginationToken: null,
      }

      const response = yield call(ApiServiceInstance.get, `mix-up?${queryString.stringify(query)}`)

      if (response && response.items && response.items.length > 0) {
        yield put(push(routes.MIXUPS_DETAILS({ id: response.items[0].id })))
        yield put(global.showWarning('Mix-up with following order number already exist'))
        return
      }
    }

    yield call(ApiServiceInstance.post, 'mix-up', action.payload)

    yield put(push(routes.MIXUPS()))
    yield put(actions.filterChanged())
    yield put(actions.requestMixups())
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(actions.requestMixups, fetchMixups)
  yield takeLatest(detailsActions.newMixupOpen, newOpen)
  yield takeLatest(detailsActions.editMixupOpen, editOpen)
  yield takeLatest(detailsActions.mixupSend, sendCreateOrUpdate)
}
