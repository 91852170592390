import { ResetReason } from '.';

export enum InputTypes {
  Orderlines = 0,
  ProductNumbers = 1
}

export interface BulkReprocessBase {
  inputType: InputTypes
  productCodes: string | ''
  message: string | ''
  resetReasons: ResetReason[]
  reasonCode: string | '';
}

export interface BulkReprocessRequest {
  productNumbers?: string[]
  orderLineIds?: string[]
  reasonCode: string
  comment: string
}

export interface BulkReprocessResponse {
  productsResetSuccess?: ProductResetSuccess[]
  productsResetFailed?: ProductResetFailed[]
  productsNotFound?: ProductNotFound[]
  orderLinesNotFound?: OrderLineNotFound[]
  valid: boolean
}

export interface ProductResetSuccess {
  productNumber: string
  orderLineIds?: string[]
}

export interface ProductResetFailed {
  productNumber: string
  orderLineIds?: string[]
  error: string
}

export interface ProductNotFound {
  productNumber: string
  reason: string
}

export interface OrderLineNotFound {
  orderLineId: string
  reason: string
}