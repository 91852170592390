import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/projectActions'
import { ProjectSearchResponse, SearchProjectBy } from '../api'
import { notEmpty } from '../utils'
import { ProjectsSearchFilter, ProjectsSearchStore } from './states'

const initialState: ProjectsSearchStore = {
  filter: {
    type: SearchProjectBy.User,
    query: '',
  },
  items: undefined,
  isLoading: false,
}

export const projects = createReducer<ProjectsSearchStore>(initialState, {
  [actions.search.type]: (state, action: PayloadAction<ProjectsSearchFilter>) => {
    state.isLoading = true
    state.filter = action.payload
    state.items = undefined
    state.error = undefined
  },
  [actions.receivedProjects.type]: (state, action: PayloadAction<ProjectSearchResponse[]>) => {
    state.isLoading = false
    state.items = action.payload
      .filter((it) => it.items)
      .map((it) => it.items)
      .flat()
      .filter(notEmpty)
      .sort((a, b) => b.modificationDate.localeCompare(a.modificationDate))
  },
  [actions.searchErrorProjects.type]: (state, action: PayloadAction<string>) => {
    state.isLoading = false
    state.error = action.payload
  },
})
