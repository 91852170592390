import React from 'react'
import { OrderDetails } from '../../api'
import OrderPrices from '../lists/OrderPrices'
import SidePanel, { SidePanelSettings } from './SidePanel'

interface Props extends SidePanelSettings {
  data: OrderDetails
  showRefundButton?: boolean
}

export default class OrderPricePanel extends React.Component<Props> {
  render() {
    return (
      <SidePanel {...this.props}>
        <OrderPrices order={this.props.data} showRefundButton={this.props.showRefundButton} />
      </SidePanel>
    )
  }
}
