import React, { ReactNode } from 'react'
import closed from '../../assets/img/closed.svg'
import open from '../../assets/img/open.svg'

interface CollapsableContainerProps {
  folded?: boolean
  title?: string
  children?: ReactNode
}

interface CollapsableContainerState {
  folded: boolean
}

export default class CollapsableContainer extends React.Component<CollapsableContainerProps, CollapsableContainerState> {
  constructor(props: CollapsableContainerProps) {
    super(props)

    this.state = {
      folded: props.folded || false,
    }
  }

  render() {
    return (
      <React.Fragment>
        <span className="d-flex" onClick={() => this.setState({ folded: !this.state.folded })}>
          <img src={this.state.folded ? closed : open} width="20" height="20" alt="folded" />
          {this.props.title && <h3>{this.props.title}</h3>}
        </span>
        {!this.state.folded && this.props.children}
      </React.Fragment>
    )
  }
}
