import { GetDeliveryOptionsResponse } from '../api'

export const resolveEstimatedDeliveryDate = (
  deliveryMethod: string,
  deliveryType: string,
  deliveryOptions?: GetDeliveryOptionsResponse
) => {
  const deliveryOption = deliveryOptions?.shipments
    ?.map((s) => s.deliveryOptions)
    .flat()
    .map((deliveryOption) => ({
      estimatedDeliveryDate: deliveryOption?.estimatedDeliveryDate!,
      deliveryMethod: deliveryOption?.shippingDetails.deliveryMethod!,
      deliveryType: deliveryOption?.shippingDetails.deliveryType!,
    }))
    .filter((deliveryOption) => deliveryOption.deliveryMethod === deliveryMethod && deliveryOption.deliveryType === deliveryType)

  return deliveryOption !== undefined && deliveryOption.length > 0
    ? deliveryOption?.reduce((max, x) => (max.estimatedDeliveryDate > x.estimatedDeliveryDate ? max : x)).estimatedDeliveryDate
    : undefined
}
