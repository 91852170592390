import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'
import * as orderDetailsActions from '../actions/orderDetailsActions'
import * as actions from '../actions/widgetActions'
import { getWidgetPanelKey } from '../actions/widgetActions'
import { WidgetRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* handleError(error: AxiosError, productCode: string, message: string) {
  yield put(orderDetailsActions.togglePanel(getWidgetPanelKey(productCode)))
  yield put(Errors.toErrorAction(error, message))
}

function* createRequest(action: PayloadAction<WidgetRequest>) {
  try {
    yield call(ApiServiceInstance.post, `widget/create`, action.payload)
    yield put(orderDetailsActions.requestOrderDetails(action.payload.orderCode))
  } catch (e) {
    yield handleError(e, action.payload.productCode, 'Failed to create widget.')
  }
}

function* recreateRequest(action: PayloadAction<WidgetRequest>) {
  try {
    yield call(ApiServiceInstance.post, `widget/recreate`, action.payload)
    yield put(
      actions.showNotification({
        title: 'Widget is created again',
        message: 'An e-mail with the widget link has been sent to to customer.',
        productCode: action.payload.productCode,
      })
    )
  } catch (e) {
    yield handleError(e, action.payload.productCode, 'Failed to recreate widget.')
  }
}

function* deleteRequest(action: PayloadAction<WidgetRequest>) {
  try {
    yield call(ApiServiceInstance.del, `widget/delete`, action.payload)
    yield put(orderDetailsActions.requestOrderDetails(action.payload.orderCode))
  } catch (e) {
    yield handleError(e, action.payload.productCode, 'Failed to delete widget.')
  }
}

export default function* root() {
  yield takeLatest(actions.recreateRequest, recreateRequest)
  yield takeLatest(actions.createRequest, createRequest)
  yield takeLatest(actions.deleteRequest, deleteRequest)
}
