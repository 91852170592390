import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import * as actions from '../actions/globalActions'
import { ErrorPayload } from '../actions/globalActions'
import { GlobalConfiguration } from '../api'
import { GlobalStore } from './states'

const initialState: GlobalStore = {
  error: '',
  warning: '',
  info: '',
  isNewVersionAvailable: false,
  configuration: {} as GlobalConfiguration,
  loading: true,
}

function getErrorMessage(payload?: ErrorPayload): ReactNode {
  const unhandledError = 'Unhandled error'
  if (!payload) {
    return unhandledError
  }

  if (!payload.httpResponse) {
    return payload.defaultMessage || unhandledError
  }

  switch (payload.httpResponse.status) {
    case 403:
      return 'You do not have permission for this operation. Please contact #care-tech-support slack channel or Team manager.'
  }

  const response = payload.httpResponse
  const backupError = payload.defaultMessage || 'Unhandled error'
  const errorMessage = (response.data && response.data.message) || backupError

  // 4xx
  if (payload.httpResponse.status >= 400 && payload.httpResponse.status < 500) {
    return `HTTP ERROR ${response.status}: ${errorMessage}`
  }

  return backupError
}

export const global = createReducer<GlobalStore>(initialState, {
  [actions.showError.type]: (state, action: PayloadAction<ErrorPayload>) => {
    state.error = getErrorMessage(action.payload)
  },
  [actions.hideError.type]: (state) => {
    state.error = ''
  },
  [actions.showWarning.type]: (state, action: PayloadAction<string>) => {
    state.warning = action.payload
  },
  [actions.hideWarning.type]: (state) => {
    state.warning = ''
  },
  [actions.showInfo.type]: (state, action: PayloadAction<string>) => {
    state.info = action.payload
  },
  [actions.hideInfo.type]: (state) => {
    state.info = ''
  },
  [actions.newVersionAvailable.type]: (state) => {
    state.isNewVersionAvailable = true
  },
  [actions.receivedConfiguration.type]: (state, action: PayloadAction<GlobalConfiguration>) => {
    state.configuration = action.payload
  },
  [actions.showSpinner.type]: (state) => {
    state.loading = true
  },
  [actions.hideSpinner.type]: (state) => {
    state.loading = false
  },
})
