import { createAction } from '@reduxjs/toolkit'
import { WidgetRequest } from '../api'
import { WidgetNotification } from '../models'

export const createRequest = createAction<WidgetRequest>('WIDGET_CREATE')
export const recreateRequest = createAction<WidgetRequest>('WIDGET_RECREATE')
export const deleteRequest = createAction<WidgetRequest>('WIDGET_DELETE')
export const showNotification = createAction<WidgetNotification>('WIDGET_SHOW_NOTIFICATION')

export function getWidgetPanelKey(productCode: string) {
  return `${productCode}_widget`
}
