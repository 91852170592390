import React from 'react'
import { Note } from '../../api'
import { nl2br, toDateTime } from '../../utils'

export interface NoteItemProps extends React.Props<any> {
  note: Note
  lookupSecondaryKeyDisplayName(key: string | undefined): string | undefined
}

export default function NoteItem({ note, lookupSecondaryKeyDisplayName }: NoteItemProps) {
  return (
    <div>
      {note.secondaryKey ? (
        note.secondaryKey
          .split(',')
          .map((sk) => lookupSecondaryKeyDisplayName(sk))
          .map((sk, skIndex) => (
            <div key={skIndex}>
              <b>{sk}</b>
            </div>
          ))
      ) : (
        <div>
          <b>{lookupSecondaryKeyDisplayName(undefined)}</b>
        </div>
      )}
      <div className="secondary-font mt-1 mb-1">
        <b>
          <span className="date-time">{note.timestamp ? toDateTime(note.timestamp) : <i>Old backoffice note</i>}</span>
          {note.username && <React.Fragment>&nbsp;|&nbsp;{note.username}</React.Fragment>}
        </b>
      </div>
      <div className="big-font">{nl2br(note.body)}</div>
      <hr />
    </div>
  )
}
