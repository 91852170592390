import React from 'react'
import { Input as BootstrapInput } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import ValidationWrapper, { CustomComponent } from './ValidationWrapper'

export interface SelectOption {
  key: string | number
  value: string
}

interface SelectCustomProps extends CustomComponent {
  emptyText?: string
  options: SelectOption[]
}

export default function ReduxSelect(props: WrappedFieldProps & SelectCustomProps) {
  const hasError = !!props.meta.error && !!props.meta.touched
  return (
    <ValidationWrapper {...props}>
      <BootstrapInput type="select" {...props.input} invalid={hasError} disabled={props.disabled}>
        {props.emptyText && (
          <option value="" disabled style={{ display: 'none' }}>
            {props.emptyText}
          </option>
        )}
        {props.options.map((kv) => (
          <option value={kv.key} key={kv.key}>
            {kv.value}
          </option>
        ))}
      </BootstrapInput>
    </ValidationWrapper>
  )
}
