import { AxiosError } from 'axios'
import { codes, LogMessage } from '../api'
import createHttpClient from './HttpService'
import { LogApiInstance } from './LogApiService'

declare global {
  interface Window {
    ALBELLI_BACKEND_URL: string
    ALBELLI_FRONTEND_URL: string
  }
}

const createInstance = () => createHttpClient(window.ALBELLI_BACKEND_URL)

async function handleError(error: AxiosError) {
  const message = new LogMessage(error, codes.httpCallError)
  if (error.response && error.response.status >= 400 && error.response.status < 500) {
    await LogApiInstance.warn(message)
  } else {
    await LogApiInstance.error(message)
  }

  throw error
}

export class ApiService {
  async get(path: string) {
    const instance = await createInstance()

    return instance({ url: path, method: 'get' })
      .then((response) => {
        return response.data
      })
      .catch(handleError)
  }

  async post(path: string, body: any = null) {
    const instance = await createInstance()

    return instance({ url: path, method: 'post', data: body })
      .then((response) => {
        return response
      })
      .catch(handleError)
  }

  async put(path: string, body: any = null) {
    const instance = await createInstance()

    return instance({ url: path, method: 'put', data: body })
      .then((response) => {
        return response
      })
      .catch(handleError)
  }

  async del(path: string, body: any = null) {
    const instance = await createInstance()

    return instance({ url: path, method: 'delete', data: body })
      .then((response) => {
        return response
      })
      .catch(handleError)
  }
}

export const ApiServiceInstance: ApiService = new ApiService()
