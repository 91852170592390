import React from 'react'
import { MixupReturnState, MixupTypeNames, MixupCategoryNumber } from '../../api/mixup'
import { MixupSearchFilter } from '../../api/search'
import routes from '../../route/routes'
import { highlight, toDateTime } from '../../utils'
import ActionLink from '../generic/ActionLink'
import Grid, { GridColumn } from '../generic/Grid'

interface Props {
  items?: any[]
  filter: MixupSearchFilter
}

export default function MixupList({ items, filter }: Props) {
  if (!items || items.length === 0) {
    return <b>0 result</b>
  }

  const columns: GridColumn[] = [
    {
      title: 'Date',
      value: mixup => <ActionLink text={toDateTime(mixup.modifiedAt) || ''} route={routes.MIXUPS_DETAILS({ id: mixup.id })} />
    },
    {
      title: 'Order number',
      value: mixup => highlight(mixup.orderRef, filter.byOrder)
    },
    {
      title: 'Ticket(s)',
      value: mixup => highlight(mixup.ticketRef, filter.byTicket)
    },
    { title: 'Type', value: mixup => MixupTypeNames[mixup.type] },
    {
      title: 'Destroyed',
      value: mixup => (mixup.returnState === MixupReturnState.ReturnedOrDestroyed ? 'destroyed' : '')
    },
    {
      title: 'Category', value: mixup => MixupCategoryNumber[mixup.category]
    },
    {
      title: 'File Checked', value: mixup => (mixup.pdfChecked ? 'Yes' : 'No')
    }
  ]

  return <Grid columns={columns} className="mt-3" data={items} autoScroll={true} />
}
