export interface AccountSearchResponse {
  items?: Account[]
  hasMore: boolean
  offset: number
  limit: number
}

export interface AccountNote {
  date: Date
  title: string
  detail: string
  payload: object
}

export interface Account {
  email: string
  accountId: string
  identityId: string
  idp: string
  createdAt: Date
  lastUpdatedAt: Date
  deletionPolicyNextCheck: Date
  deletionPolicyStatus: DeletionPolicyStatus
  deletionPolicyReason: string
  status: string
  notes: AccountNote[]
}

export interface AccountDeletionRequest {
  email: string
  accountId: string
  identityId: string
  idp: string
  reason: string
}

export enum SearchAccountBy {
  Id = 1,
  Email = 2,
}

export enum DeletionPolicyStatus {
  NeverChecked,
  Completed,
  InProgress,
  AlreadyDeleted,
  WhiteListed,
}
