import { createAction } from '@reduxjs/toolkit'
import { Customer, SearchResponse } from '../api'

export const requestDetails = createAction<string>('CUSTOMER_DETAILS_REQUEST')

export const receivedDetails = createAction<Customer>('CUSTOMER_DETAILS_RECEIVED')

export const requestAlbelliOrders = createAction('CUSTOMER_ALBELLI_HISTORY_REQUEST')

export const receivedAlbelliHistory = createAction<SearchResponse>('CUSTOMER_ALBELLI_HISTORY_RECEIVED')

export const toggleOrderDetails = createAction<number>('CUSTOMER_DETAILS_TOGGLE_ORDER_DETAILS')

export const toggleAllDetails = createAction('CUSTOMER_DETAILS_TOGGLE_ALL_DETAILS')
