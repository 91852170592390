import * as React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import { CustomComponent } from './ValidationWrapper'

export default function CheckboxWithLabel(props: WrappedFieldProps & CustomComponent) {
  return (
    <FormGroup>
      <Input type="checkbox" id={props.input.name} checked={props.input.value} {...props.input} />
      <Label className="form-check-label" for={props.input.name}>{props.label}</Label>
    </FormGroup>
  )
}
