import { NumericDictionary } from 'lodash'

interface BaseSbsRequest {
  appVersion: string
  orderCode: string
  vendorId: string
}

export interface SbsError {
  error?: string
}

export interface SbsPostRequest extends BaseSbsRequest, ShowByScanForm {
  products: string[]
}

export interface SbsForProductionOrderPostRequest extends BaseSbsRequest, ShowByScanForm {
  productionOrderId: string
}

export interface SbsDeleteRequest extends BaseSbsRequest {
  products: string[]
}

export interface ShowByScanForm {
  type?: ShowByScanType
  text?: string
}

export interface ShowByScanRecord {
  productId: string
  productionOrderId: string
  type: ShowByScanType
  text: string
  createdAt: Date
  username: string
}

export enum ShowByScanType {
  Courier = 1,
  ExtraQuality = 2,
  ChangeAddress = 3,
  PickUpAtPlant = 4,
  Other = 5
}

export const ShowByScanNames: NumericDictionary<string> = {
  [ShowByScanType.ChangeAddress]: 'Red (Change Address)',
  [ShowByScanType.ExtraQuality]: 'Green (Extra QC)',
  [ShowByScanType.Courier]: 'Blue (Courier)',
  [ShowByScanType.PickUpAtPlant]: 'Yellow (Pick-up at Plant)',
  [ShowByScanType.Other]: 'Black (Other)'
}
