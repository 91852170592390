import React from 'react'
import ReactJson from 'react-json-view'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Col, FormFeedback, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap'
import * as accountActions from '../actions/accountActions'
import { Account, AccountDeletionRequest, SearchAccountBy } from '../api'
import notesIcon from '../assets/img/flags/notes.svg'
import Confirmation from '../components/forms/Confirmation'
import FilterBar, { FilterBarValue } from '../components/forms/FilterBar'
import ActionLink from '../components/generic/ActionLink'
import Flag from '../components/generic/Flag'
import Grid, { GridColumn } from '../components/generic/Grid'
import LazyContainer from '../components/generic/LazyContainer'
import SidePanel from '../components/panels/SidePanel'
import { CommonProps } from '../models'
import { AccountsSearchStore, AppStore, AccountsPasswordResetLinkRequest } from '../reducers/states'
import routes from '../route/routes'
import { AuthInstance } from '../services/AuthService'
import { toDateTime } from '../utils'

interface Props extends CommonProps, AccountsSearchStore { }

interface State {
  searchAccountBy: SearchAccountBy
  filter: string
  showDeleteConfirmation: boolean
  showPasswordResetLinkConfirmation: boolean
  showNotes: boolean
  deletionRequest: AccountDeletionRequest
  passwordResetLinkRequest: AccountsPasswordResetLinkRequest
  selectedAccount: Account
  hasReasonError: boolean
}

const AccountsSearchResultView = (props: {
  items: Account[]
  onRequestWhitelistAccount: (id: string, operation: string) => void
  onRequestGetPasswordResetLink: (account: Account) => void
  onRequestDeleteAccount: (account: Account) => void
  onRequestNotes: (account: Account) => void
}) => {
  const columns: GridColumn<Account>[] = [
    {
      title: 'Id',
      value: (account) => {
        return <span title={`Idp: ${account.idp} Identity: ${account.identityId}`}>{account.accountId}</span>
      },
    },
    {
      title: 'Email',
      value: (account) => <Link to={routes.CUSTOMERS_VIEW({ email: account.email })}>{account.email}</Link>,
    },
    {
      title: 'Created Date',
      value: (account) => toDateTime(account.createdAt),
    },
    {
      title: 'Last Updated Date',
      value: (account) => toDateTime(account.lastUpdatedAt),
    },
    {
      title: 'Status',
      value: (account) => {
        return (
          <div className={`badge badge-pill ${getAccountStatusClass(account.status)}`}>
            <div className="text">{account.status}</div>
          </div>
        )
      },
    },
    {
      title: 'Actions',
      value: (account) => {
        const whitelistOperation = getWhitelistOperation(account)
        return (
          <>
            {(account.status === 'Active' || account.status === 'Whitelisted') && (
              <ActionLink
                text={whitelistOperation}
                key="whitelist"
                onClick={() => props.onRequestWhitelistAccount(account.accountId, whitelistOperation)}
              />
            )}
            {account.status === 'Active' && <span> | </span>}
            {account.status === 'Active' && (
              <ActionLink key="delete" text="Delete" onClick={() => props.onRequestDeleteAccount(account)} />
            )}
            {(account.status === 'Active' || account.status === 'Whitelisted') && <span> | </span>}
            {(account.status === 'Active' || account.status === 'Whitelisted') && (
              <ActionLink key="resetPasswordLink" text="Get password-reset link" onClick={() => props.onRequestGetPasswordResetLink(account)} />
            )}
          </>
        )
      },
    },
    {
      title: '',
      value: (account) => (
        <div className="float-right mr-3">
          <button
            className="orange medium d-flex align-items-center"
            id="header-notes-button"
            onClick={() => props.onRequestNotes(account)}>
            <Flag icon={notesIcon} title="Notes" size={24} /> Notes ({account.notes.length})
          </button>
        </div>
      ),
    },
  ]
  return (
    <ListGroup flush>
      <ListGroupItem>
        <Grid gridId={'account-list'} columns={columns} data={props.items} />
      </ListGroupItem>
    </ListGroup>
  )
}

function getWhitelistOperation(account: Account) {
  return account.status === 'Whitelisted' ? 'Unwhitelist' : 'Whitelist'
}

const getAccountStatusClass = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success'
    case 'Deleted':
      return 'danger'
    default:
      return 'other'
  }
}

class SearchAccountView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      searchAccountBy: 1,
      filter: '',
      showDeleteConfirmation: false,
      showPasswordResetLinkConfirmation: false,
      showNotes: false,
      deletionRequest: {} as AccountDeletionRequest,
      passwordResetLinkRequest: {} as AccountsPasswordResetLinkRequest,
      selectedAccount: {} as Account,
      hasReasonError: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
      filter,
    } = this.props
    if (params.accountId && filter.query !== params.accountId) {
      this.setState({ searchAccountBy: 1, filter: params.accountId })
      this.props.dispatch(accountActions.search({ type: this.state.searchAccountBy, query: this.state.filter }))
    }
  }

  private renderDeleteConfirmation() {
    return (
      <Confirmation
        isOpen={this.state.showDeleteConfirmation}
        cancelText="No, Cancel"
        submitText="Yes"
        title="Delete Account"
        toggle={this.onToggleDeleteConfirmation}
        onSubmit={this.onConfirmDeleteAccount}>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Are you sure you want to delete this account?
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col>
            {this.state.deletionRequest.accountId}
            <br />
            {this.state.deletionRequest.email}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Reason
          </Label>
          <Col>
            <textarea rows={3} cols={65} maxLength={50} onChange={this.onChangeDeleteReason} required></textarea>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Deleted by
          </Label>
          <Col>{AuthInstance.getUser().userName}</Col>
        </FormGroup>
        {this.state.hasReasonError && <label className="field-help-text">Reason is required</label>}
      </Confirmation>
    )
  }

  private renderGetPasswordResetLinkConfirmation() {
    return (
      <Confirmation
        isOpen={this.state.showPasswordResetLinkConfirmation}
        cancelText="No, Cancel"
        submitText="Yes"
        title="Get password-reset link"
        toggle={this.onTogglePasswordResetLinkConfirmation}
        onSubmit={this.onConfirmGetPasswordResetLink}>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Are you sure you want to request a password-reset link for this account?
            <br />
            Note: Link will be valid for 24h.
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col>
            {this.state.passwordResetLinkRequest.accountId}
            <br />
            {this.state.passwordResetLinkRequest.email}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Theme/Site
          </Label>
          <Col>
            <select onChange={this.onChangePasswordResetLinkTheme}>
              <option value="albelli.nl">albelli.nl</option>
              <option value="albelli.be">albelli.be</option>
              <option value="bonusprint.co.uk">bonusprint.co.uk</option>
              <option value="fotoknudsen.no">fotoknudsen.no</option>
              <option value="monalbumphoto.fr">monalbumphoto.fr</option>
              <option value="onskefoto.se">onskefoto.se</option>
              <option value="posterxxl.de">posterxxl.de</option>
              <option value="posterxxl.at">posterxxl.at</option>
              <option value="b2b.posterxxl.de">b2b.posterxxl.de</option>
              <option value="photobox.co.uk">photobox.co.uk</option>
              <option value="photobox.fr">photobox.fr</option>
              <option value="photobox.dk">photobox.dk</option>
              <option value="photobox.ie">photobox.ie</option>
              <option value="photobox.it">photobox.it</option>
              <option value="hofmann.es">photobox.es</option>
              <option value="hofmann.pt">photobox.pt</option>
            </select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={10} className="col-form-label">
            Requested by
          </Label>
          <Col>{AuthInstance.getUser().userName}</Col>
        </FormGroup>
      </Confirmation>
    )
  }

  render() {
    return (
      <div className="search-container">
        <div className="panel">
          <FilterBar
            inputId="main-search-input"
            formId="main-search-form"
            initialValue={{
              id: this.state.searchAccountBy,
              query: this.state.filter,
            }}
            items={[
              { id: SearchAccountBy.Id, navLinkId: 'account-search-by-id', title: 'Id' },
              { id: SearchAccountBy.Email, navLinkId: 'account-search-by-email', title: 'Email' },
            ]}
            onSubmit={this.onSearch}
          />
        </div>
        <LazyContainer isLoading={this.props.isLoading}>
          {this.props.error && (
            <Alert color="danger" className="w-100 mb-0">
              {this.props.error}
            </Alert>
          )}
          {this.props.items && (
            <AccountsSearchResultView
              items={this.props.items}
              onRequestWhitelistAccount={this.onRequestWhitelistAccount}
              onRequestGetPasswordResetLink={this.onRequestGetPasswordResetLink}
              onRequestDeleteAccount={this.onRequestDeleteAccount}
              onRequestNotes={this.onRequestNotes}
            />
          )}
          {this.renderDeleteConfirmation()}
          {this.renderGetPasswordResetLinkConfirmation()}
        </LazyContainer>
        <SidePanel title="Notes" isOpen={this.state.showNotes} accessKey="" isLoading={false} toggle={this.onToggleNotesPanel}>
          {this.state?.selectedAccount?.notes?.map((note) => {
            return (
              <div>
                <div className="font-weight-bold">
                  <span>{note.title}</span>
                </div>
                <div className="secondary-font mt-1 mb-1">
                  <span className="date-time">{toDateTime(note.date)}</span>
                </div>
                <div className="big-font">{note.detail}</div>
                {!!note.payload && (
                  <div className="big-font mt-2 mb-2">
                    <ReactJson
                      src={note.payload}
                      collapsed={true}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      name="Audit"
                    />
                  </div>
                )}
                <hr />
              </div>
            )
          })}
        </SidePanel>
      </div>
    )
  }

  onSearch = (value: FilterBarValue) => {
    if (value && value.query) {
      this.props.dispatch(accountActions.search({ type: value.id, query: value.query.trim() }))
    }
  }

  onToggleNotesPanel = () => {
    this.setState({ showNotes: !this.state.showNotes })
  }

  onRequestWhitelistAccount = (accountId: string, operation: string) => {
    this.props.dispatch(
      accountActions.requestWhitelistAccount({
        accountId: accountId,
        operation: operation,
        filter: { type: this.props.filter.type, query: this.props.filter.query },
      })
    )
  }

  onRequestGetPasswordResetLink = (account: Account) => {
    this.setState({
      showPasswordResetLinkConfirmation: !this.state.showPasswordResetLinkConfirmation,
      passwordResetLinkRequest: {
        accountId: account.accountId,
        email: account.email,
        themeName: "albelli.nl"
      },
    })
  }

  onRequestDeleteAccount = (account: Account) => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
      deletionRequest: {
        email: account.email,
        accountId: account.accountId,
        identityId: account.identityId,
        idp: account.idp,
        reason: '',
      },
    })
  }

  onChangeDeleteReason = (e: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({
      deletionRequest: { ...this.state.deletionRequest, reason: e.currentTarget.value },
      hasReasonError: false,
    })
  }

  onChangePasswordResetLinkTheme = (e: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      passwordResetLinkRequest: { ...this.state.passwordResetLinkRequest, themeName: e.currentTarget.value },
      hasReasonError: false,
    })
  }

  onToggleDeleteConfirmation = () => {
    this.setState({ showDeleteConfirmation: !this.state.showDeleteConfirmation })
  }

  onTogglePasswordResetLinkConfirmation = () => {
    this.setState({ showPasswordResetLinkConfirmation: !this.state.showPasswordResetLinkConfirmation })
  }

  onConfirmDeleteAccount = () => {
    const { deletionRequest } = this.state
    if (deletionRequest.reason) {
      this.onToggleDeleteConfirmation()
      this.props.dispatch(accountActions.requestDeleteAccount(deletionRequest))
    } else {
      this.setState({ hasReasonError: true })
    }
  }

  onConfirmGetPasswordResetLink = () => {
    const { passwordResetLinkRequest } = this.state
    this.onTogglePasswordResetLinkConfirmation()
    this.props.dispatch(
      accountActions.requestGetPasswordResetLink(passwordResetLinkRequest)
    )
  }

  onRequestNotes = (account: Account) => {
    this.setState({ showNotes: !this.state.showNotes, selectedAccount: account })
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.accounts,
  } as Props
}
export default connect(mapStateToProps)(SearchAccountView)
