import { createAction } from '@reduxjs/toolkit'
import { MixupSearchFilter, MixupSearchResponse } from '../api/search'

export const loadStarted = createAction('MIXUPS_LOAD_STARTED')

export const requestMixups = createAction('MIXUP_LIST_REQUEST')

export const receivedMixups = createAction<MixupSearchResponse>('MIXUP_LIST_RECEIVED')

export const filterChanged = createAction<MixupSearchFilter | undefined>('MIXUP_FILTER_CHANGED')
