import React from 'react'
import { FaAngleDoubleDown } from 'react-icons/fa'
import { Container, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'

const ReleaseNotesView = () => (
  <Container>
    {renderHeader()}
    <h3>2024.05.06</h3>
    <ListGroup>
      <ListGroupItem color="success">
        BEAM-5362 Support backoffice changes for account migration
      </ListGroupItem>
    </ListGroup>
    <h3>2024.01.16</h3>
    <ListGroup>
      <ListGroupItem color="info">
        PCT-10783 ChangeAddress will now also change it in MyAccount and Shipping confirmation email
      </ListGroupItem>
    </ListGroup>
    <h3>2023.11.27</h3>
    <ListGroup>
      <ListGroupItem color="warning">
        PCT-10480 Some orders don't show either FF or PO sections, despite non-empty Products{' '}
      </ListGroupItem>
      <ListGroupItem color="info">PCT-10490 Specific order not searchable in CBO</ListGroupItem>
    </ListGroup>
    <h3>2023.11.22</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-10429 Search doesn`t work in CBO</ListGroupItem>
    </ListGroup>
    <h3>2023.11.17</h3>
    <ListGroup>
      <ListGroupItem color="info">
        PCT-10418 No shipping information present after re-routing a production order to Fulfillment
      </ListGroupItem>
    </ListGroup>
    <h3>2023.11.15</h3>
    <ListGroup>
      <ListGroupItem color="warning">PCT-9890 Showing FF orders if there is a PO for the same product</ListGroupItem>
    </ListGroup>
    <h3>2023.11.08</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-9840 An error occurs when opening an order in CBO</ListGroupItem>
    </ListGroup>
    <h3>2023.06.29</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-8493 Support Prints (Image Archive)</ListGroupItem>
    </ListGroup>
    <h3>2023.03.02</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-7627 Hide split production orders</ListGroupItem>
    </ListGroup>
    <h3>2022.01.12</h3>
    <ListGroup>
      <ListGroupItem color="warning">
        BEAM-4219 Fix selection for get reset password link is set default to fotoknudsen.no behind the scenes
      </ListGroupItem>
    </ListGroup>
    <h3>2022.08.26</h3>
    <ListGroup>
      <ListGroupItem color="info">BEAM-3949 Update Backoffice if SMS failed</ListGroupItem>
    </ListGroup>
    <h3>2022.08.03</h3>
    <ListGroup>
      <ListGroupItem color="info">BEAM-3905 Update Backoffice if email is invalid</ListGroupItem>
    </ListGroup>
    <h3>2022.07.15</h3>
    <ListGroup>
      <ListGroupItem color="info">BEAM-3878 Update Backoffice if email was bounced and there was no phone number</ListGroupItem>
    </ListGroup>
    <h3>2022.07.12</h3>
    <ListGroup>
      <ListGroupItem color="warning">PCT-5915 Fix PdfRetrieval status sorting</ListGroupItem>
    </ListGroup>
    <h3>2022.07.07</h3>
    <ListGroup>
      <ListGroupItem color="warning">BEAM-3861 Fix copy on non-instantly deleted accounts</ListGroupItem>
    </ListGroup>
    <h3>2022.07.05</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-3861 Improve copy for PO instant account deletion</ListGroupItem>
    </ListGroup>
    <h3>2022.06.30</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-3838 Add send sms state to the Backoffice</ListGroupItem>
    </ListGroup>
    <h3>2022.06.24</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-3838 Add Photo Organiser Stats to Backoffice</ListGroupItem>
    </ListGroup>
    <h3>2022.01.28</h3>
    <ListGroup>
      <ListGroupItem color="success">STUDENT-8 implement cancel order for studenten in Backoffice</ListGroupItem>
    </ListGroup>
    <h3>2021.11.30</h3>
    <ListGroup>
      <ListGroupItem color="warning">PCT-4505 Adding PDF Retrieval Events to Product Content View</ListGroupItem>
    </ListGroup>
    <h3>2021.11.17</h3>
    <ListGroup>
      <ListGroupItem color="warning">
        BEAM-3261 Fixed Backoffice PhotoOrganizer Reminders not showing when customer changes email
      </ListGroupItem>
    </ListGroup>
    <h3>2021.10.01</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-3691 Show an icon for refunded orders</ListGroupItem>
    </ListGroup>
    <h3>2021.09.24</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-4022 Configurable per plant features</ListGroupItem>
    </ListGroup>
    <h3>2021.09.23</h3>
    <ListGroup>
      <ListGroupItem color="info">PCT-4017 Disable partial cancellation for ELA products</ListGroupItem>
      <ListGroupItem color="info">PCT-4019 Make SBS not available for ELA products</ListGroupItem>
      <ListGroupItem color="info">PCT-4020 Make Reset available for ELA</ListGroupItem>
      <ListGroupItem color="info">PCT-4021 Enable Address Change for ELA</ListGroupItem>
    </ListGroup>
    <h3>2021.08.10</h3>
    <ListGroup>
      <ListGroupItem color="success">
        PCT-3744 Security Fixes and Adding a new Delivering State for orders in the process of delivery to our customers
      </ListGroupItem>
    </ListGroup>
    <h3>2021.07.15</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-2796 Add account tab with list accounts by id and email</ListGroupItem>
      <ListGroupItem color="success">BEAM-2796 Add account whitelist functionality</ListGroupItem>
    </ListGroup>
    <h3>2021.05.12</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-1889 Show bounced state on inactivity warnings</ListGroupItem>
    </ListGroup>
    <h3>2021.04.28</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-576 Show PhotoOrganizer inactivity warnings</ListGroupItem>
    </ListGroup>
    <h3>2020.12.22</h3>
    <ListGroup>
      <ListGroupItem color="success">CLA-2394 Updating Invoice Endpoint</ListGroupItem>
    </ListGroup>
    <h3>2020.12.10</h3>
    <ListGroup>
      <ListGroupItem color="success">CLA-1920 Support adding notes in bulk</ListGroupItem>
    </ListGroup>
    <h3>2020.12.10</h3>
    <ListGroup>
      <ListGroupItem color="info">CLA-2118 Reuploaded products should not be reset</ListGroupItem>
    </ListGroup>
    <h3>2020.11.18</h3>
    <ListGroup>
      <ListGroupItem color="info">CLA-2196 Improve Change Address feedback</ListGroupItem>
    </ListGroup>
    <h3>2020.11.18</h3>
    <ListGroup>
      <ListGroupItem color="info">CLA-1895 Enable Change Address for YPB and NLH plants</ListGroupItem>
      <ListGroupItem color="success">CLA-1922 A separate icon for EasyFlow WD editor</ListGroupItem>
    </ListGroup>
    <h3>2020.10.27</h3>
    <ListGroup>
      <ListGroupItem color="info">PI-1835 Enable Reset and Refinalization for RRD products</ListGroupItem>
    </ListGroup>
    <h3>2020.10.22</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-780 update prints name from 10x13 to 10x14</ListGroupItem>
      <ListGroupItem color="info">CBO-752 Change reset text</ListGroupItem>
    </ListGroup>
    <h3>2020.10.14</h3>
    <ListGroup>
      <ListGroupItem color="success">Added Projects page with search by customer email or product title</ListGroupItem>
    </ListGroup>
    <h3>2020.10.08</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-776 Discount search page</ListGroupItem>
      <ListGroupItem color="info">I'm Lucky search to look at comments</ListGroupItem>
      <ListGroupItem color="info">I'm Lucky search redirects to order page if only one order found</ListGroupItem>
      <ListGroupItem color="info">Remove customer title from shipping address</ListGroupItem>
    </ListGroup>
    <h3>2020.10.06</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-767 Disable enhancement for RRD products</ListGroupItem>
      <ListGroupItem color="info">CBO-760 Change address for RRD</ListGroupItem>
    </ListGroup>
    <h3>2020.10.02</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-778 adjust reupload action for RRD products</ListGroupItem>
    </ListGroup>
    <h3>2020.10.01</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-305 Search by Discount Code (I'm Lucky)</ListGroupItem>
      <ListGroupItem color="info">CBO-769 Disabled partial cancellation for RRD products</ListGroupItem>
      <ListGroupItem color="info">CBO-766 Make SBS not available for RRD products</ListGroupItem>
      <ListGroupItem color="info">CBO-768 Mark Reset temporarily unavailable for RRD</ListGroupItem>
    </ListGroup>
    <h3>2020.09.28</h3>
    <ListGroup>
      <ListGroupItem color="danger">Hide Experimental Timeline Prediction</ListGroupItem>
    </ListGroup>
    <h3>2020.09.24</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-265 Added discount lookup</ListGroupItem>
    </ListGroup>
    <h3>2020.09.23</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-140 Experimental Timeline Prediction</ListGroupItem>
    </ListGroup>
    <h3>2020.09.04</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-321 Make Delivered status available for Timeline</ListGroupItem>
      <ListGroupItem color="success">Added BQ ISO country code: Bonaire, Sint Eustatius and Saba</ListGroupItem>
      <ListGroupItem color="warning">Minor bug fix</ListGroupItem>
    </ListGroup>
    <h3>2020.08.18</h3>
    <ListGroup>
      <ListGroupItem color="info">BEAM-1153 Restore old PDF on click</ListGroupItem>
      <ListGroupItem color="info">Elasticsearch 7.7</ListGroupItem>
      <ListGroupItem color="info">Mix-up procedure wiki link replace with help centre</ListGroupItem>
      <ListGroupItem color="info">Updated widget URLs</ListGroupItem>
    </ListGroup>
    <h3>2020.08.13</h3>
    <ListGroup>
      <ListGroupItem color="danger">Removed Resnap support</ListGroupItem>
    </ListGroup>
    <h3>2020.07.06</h3>
    <ListGroup>
      <ListGroupItem color="success">Add price total to customer order history table</ListGroupItem>
      <ListGroupItem color="danger">Removed links to old CBO</ListGroupItem>
      <ListGroupItem color="info">(BACKEND) Changed post reupload email notification contract</ListGroupItem>
    </ListGroup>
    <h3>2020.06.09</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-449 Change reset reasons LOGISTICS</ListGroupItem>
      <ListGroupItem color="info">CBO-365 Show amount of reset/cancelled orderlines in notes</ListGroupItem>
      <ListGroupItem color="info">CBO-480 Enlarge note field</ListGroupItem>
      <ListGroupItem color="info">CBO-618 Only generate payment links for orders that haven't lost their uploads</ListGroupItem>
      <ListGroupItem color="info">CBO-456 Copy of only part of the name: Last Name vs First Name</ListGroupItem>
      <ListGroupItem color="warning">CBO-279 Not found mix-up leads to Internal server error</ListGroupItem>
    </ListGroup>
    <h3>2020.05.11</h3>
    <ListGroup>
      <ListGroupItem color="success">
        CBO-519 Links between reupload orders and separate icons for different reupload states
      </ListGroupItem>
      <ListGroupItem color="success">CBO-390 Separate icon for X-Sell editor</ListGroupItem>
      <ListGroupItem color="info">New look and location for notification icons on Product Details</ListGroupItem>
    </ListGroup>
    <h3>2020.05.08</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-751 Align forex names between CBO and Zendesk</ListGroupItem>
    </ListGroup>
    <h3>2020.05.04</h3>
    <ListGroup>
      <ListGroupItem color="info">BEAM-617 Added PDF restore action if file is stored in cold storage (Glacier)</ListGroupItem>
    </ListGroup>
    <h3>2020.04.24</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-736 Added comments to timeline</ListGroupItem>
    </ListGroup>
    <h3>2020.04.21</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-742 New Refund reasons</ListGroupItem>
      <ListGroupItem color="info">CBO-744 New Reupload reasons</ListGroupItem>
      <ListGroupItem color="success">
        More timeline events: PDF Created, FF request created, Moved to another plant etc
      </ListGroupItem>
    </ListGroup>
    <h3>2020.03.25</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-626 Show Product Options for products that are not in Fulfillment</ListGroupItem>
      <ListGroupItem color="info">Some product options renamed</ListGroupItem>
    </ListGroup>
    <h3>2020.03.18</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-718 Cannot adjust image enhancement for wall arts</ListGroupItem>
      <ListGroupItem color="info">BEAM-596 - Add link to studenten slack support channel</ListGroupItem>
      <ListGroupItem color="info">BEAM-597 - Add message about default preview images for studenten</ListGroupItem>
    </ListGroup>
    <h3>2020.03.12</h3>
    <ListGroup>
      <ListGroupItem color="success">BEAM-419 added Studenten orders details</ListGroupItem>
      <ListGroupItem color="success">CBO-703 Order timeline</ListGroupItem>
      <ListGroupItem color="info">CBO-554 Better indication of price vs quantity in price details</ListGroupItem>
      <ListGroupItem color="info">CBO-433 Error handling for refunds larger than total order value</ListGroupItem>
      <ListGroupItem color="info">CBO-575 Old production tickets added to search</ListGroupItem>
      <ListGroupItem color="info">I'm Lucky search option available for all roles</ListGroupItem>
      <ListGroupItem color="warning">CBO-629 Search by barcode/ticket is case sensitive</ListGroupItem>
    </ListGroup>
    <h3>2020.02.28</h3>
    <ListGroup>
      <ListGroupItem color="success">Studenten orders appears in customer history</ListGroupItem>
      <ListGroupItem color="success">CBO-623 New magic search option - I'm Lucky</ListGroupItem>
    </ListGroup>
    <h3>2020.02.25</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-556 Show shipment info for vouchers</ListGroupItem>
      <ListGroupItem color="success">CBO-692 Direct link from CBO to Fulfillment Management UI</ListGroupItem>
      <ListGroupItem color="warning">CBO-693 Reupload of the shipped product takes 20 seconds</ListGroupItem>
    </ListGroup>
    <h3>2020.02.14</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-611 Search by Name is case sensitive</ListGroupItem>
    </ListGroup>
    <h3>2020.02.11</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-678 Fixed order date and time (time zone issue)</ListGroupItem>
      <ListGroupItem color="info">Improved search by order code UX</ListGroupItem>
    </ListGroup>
    <h3>2020.01.31</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-627 Orders placed before 2012 are crashing CBO</ListGroupItem>
      <ListGroupItem color="success">CBO-336 Possibility to copy the address on the Order Details page</ListGroupItem>
    </ListGroup>
    <h3>2020.01.27</h3>
    <ListGroup>
      <ListGroupItem color="warning">
        CBO-670 Not all orders are shown for customers that have accounts both in FK and Albelli
      </ListGroupItem>
      <ListGroupItem color="info">Reduce waiting time after cancellation for non-fulfillment orders</ListGroupItem>
      <ListGroupItem color="info">CBO-685 Added PAP number to Product Options panel</ListGroupItem>
    </ListGroup>
    <h3>2020.01.24</h3>
    <ListGroup>
      <ListGroupItem color="danger">Removed 'Delayed' status</ListGroupItem>
      <ListGroupItem color="success">CBO-619 added delayed flag per product and delivery info</ListGroupItem>
      <ListGroupItem color="info">CBO-601 Improve product statuses</ListGroupItem>
      <ListGroupItem color="info">Reupload reason added to automatic note</ListGroupItem>
    </ListGroup>
    <h3>2020.01.20</h3>
    <ListGroup>
      <ListGroupItem color="warning">Fixed SBS actions</ListGroupItem>
    </ListGroup>
    <h3>2020.01.16</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-345 Reset shouldn't be possible if product file is not available</ListGroupItem>
      <ListGroupItem color="warning">Fixed delivery info for partially shipped by SverigePost (no T&T)</ListGroupItem>
      <ListGroupItem color="info">PDF download button is disabled if PDF file is not available</ListGroupItem>
      <ListGroupItem color="danger">Christmas campaign promise removed from delivery view</ListGroupItem>
    </ListGroup>
    <h3>2020.01.06</h3>
    <ListGroup>
      <ListGroupItem color="warning">Fixed order status calculation for old orders</ListGroupItem>
    </ListGroup>
    <h3>2020.01.03</h3>
    <ListGroup>
      <ListGroupItem color="success">Backend order status calculation (Experimental)</ListGroupItem>
      <ListGroupItem color="info">Switch to new Widget API</ListGroupItem>
    </ListGroup>
    <h3>2019.12.23</h3>
    <ListGroup>
      <ListGroupItem color="warning">Fixed product overview for Safari browser</ListGroupItem>
    </ListGroup>
    <h3>2019.12.13</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-496 Incorrect size shown on reset (for smaller/bigger copy)</ListGroupItem>
      <ListGroupItem color="warning">Fixed missing country info for UK customers</ListGroupItem>
      <ListGroupItem color="info">Refund UI improvements (reported by Ida Blom)</ListGroupItem>
    </ListGroup>
    <h3>2019.12.11</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-536 Misleading error message when Invoice is missing</ListGroupItem>
      <ListGroupItem color="warning">CBO-657 Error when trying to restart Finalizer for non-albelli order</ListGroupItem>
    </ListGroup>
    <h3>2019.12.04</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-587 Execute finalizer</ListGroupItem>
      <ListGroupItem color="warning">CBO-465 Reason for canceling and reupload is not reported in the notes</ListGroupItem>
      <ListGroupItem color="warning">CBO-565 Fix payment link (shop.* =&gt; checkout.ecom.*)</ListGroupItem>
    </ListGroup>
    <h3>2019.11.28</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-615 Added Christmas promises icons</ListGroupItem>
      <ListGroupItem color="success">Added 'Delayed' order/product status</ListGroupItem>
      <ListGroupItem color="warning">Fixed delivery info for non-shipped orders</ListGroupItem>
    </ListGroup>
    <h3>2019.11.08</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-382 Replace country name instead of country code in Order Details</ListGroupItem>
    </ListGroup>
    <h3>2019.11.06</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-543 Customer lookup feature is incomplete</ListGroupItem>
      <ListGroupItem color="success">Added search by product content ID</ListGroupItem>
      <ListGroupItem color="success">Added custom authentication flow for (non-production environments)</ListGroupItem>
      <ListGroupItem color="success">Introduced "Expert" role</ListGroupItem>
    </ListGroup>
    <h3>2019.11.01</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-457 New refund reason: Pick up at Plant</ListGroupItem>
      <ListGroupItem color="warning">CBO-608 Fix "Scanned for Shipment" date when shipments have no TrackingID</ListGroupItem>
      <ListGroupItem color="warning">Fix front-end error logging</ListGroupItem>
      <ListGroupItem color="success">Added Cloud Order API feature toggle</ListGroupItem>
    </ListGroup>
    <h3>2019.10.16</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-226 Search by address</ListGroupItem>
      <ListGroupItem color="warning">CBO-435 Phone search missing results</ListGroupItem>
    </ListGroup>
    <h3>2019.10.08</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-448 Fix reset on unpaid order doesn't mark the order ready for production</ListGroupItem>
      <ListGroupItem color="warning">CBO-596 Fix typo (wavy pages) in reset reason</ListGroupItem>
    </ListGroup>
    <h3>2019.09.20</h3>
    <ListGroup>
      <ListGroupItem color="info">Change search options order and width</ListGroupItem>
      <ListGroupItem color="success">CBO-300 Search by tracking number</ListGroupItem>
      <ListGroupItem color="success">CBO-225 Search by name</ListGroupItem>
      <ListGroupItem color="info">SBS backend refactoring - now all messages stored on CBO side</ListGroupItem>
    </ListGroup>
    <h3>2019.09.16</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-332 Hide SBS button if SBS is not available or SBS count is 0</ListGroupItem>
      <ListGroupItem color="info">CBO-376 Store SBS on BO side (performance boost)</ListGroupItem>
      <ListGroupItem color="success">CBO-332 Add username to SBS note</ListGroupItem>
      <ListGroupItem color="success">MAPS-50 Allow SBS for NLH</ListGroupItem>
    </ListGroup>
    <h3>2019.09.05</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-423 Shipping address should show up before customer pays</ListGroupItem>
      <ListGroupItem color="success">CBO-329 Show billing address</ListGroupItem>
      <ListGroupItem color="success">CBO-499 Support search by barcode for NLH products</ListGroupItem>
      <ListGroupItem color="info">MAPS-34 Remove NLH specific validation</ListGroupItem>
    </ListGroup>
    <h3>2019.09.03</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-185 Reupload not showing in backoffice and not emailed to customer</ListGroupItem>
    </ListGroup>
    <h3>2019.08.28</h3>
    <ListGroup>
      <ListGroupItem color="success">MAPS-1 Cancel an orderline printed in NLH from Care Back Office</ListGroupItem>
      <ListGroupItem color="warning">CBO-537 Do not allow cancellation if status is 'Scanned for Shipment'</ListGroupItem>
      <ListGroupItem color="warning">CBO-538 Do not allow cancellation if status is Cancelled</ListGroupItem>
    </ListGroup>
    <h3>2019.08.08</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-331 fix Pending payment status</ListGroupItem>
    </ListGroup>
    <h3>2019.08.05</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-368 Show reset flag per product</ListGroupItem>
      <ListGroupItem color="danger">
        Removed old API endpoint (search by order and ticket, cancel and reset per product)
      </ListGroupItem>
    </ListGroup>
    <h3>2019.07.31</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-396 Show order details on Order History page</ListGroupItem>
      <ListGroupItem color="info">CBO-458 Improve back button when switching between one order and OrderHistory</ListGroupItem>
      <ListGroupItem color="warning">CBO-501 Fix reupload (auto) note</ListGroupItem>
      <ListGroupItem color="warning">CBO-374 fixed product highlight</ListGroupItem>
      <ListGroupItem color="info">Added redirect on successful order search result</ListGroupItem>
    </ListGroup>
    <h3>2019.07.21</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-297 New Shipment view (Shipped date + Estimated delivery date)</ListGroupItem>
      <ListGroupItem color="info">CBO-438 Link to Adyen for refunds agents</ListGroupItem>
      <ListGroupItem color="info">CBO-471 Validate Reupload action for NLH plant</ListGroupItem>
      <ListGroupItem color="warning">CBO-333 Fix app init error message</ListGroupItem>
      <ListGroupItem color="info">CBO-333 Added additional logging</ListGroupItem>
      <ListGroupItem color="info">CBO-421 Added icon for all external links</ListGroupItem>
      <ListGroupItem color="info">Changed date and time format (ddd, DD MMM YYYY)</ListGroupItem>
    </ListGroup>
    <h3>2019.07.12</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-426 Problem with the letter "i" (font replacement)</ListGroupItem>
      <ListGroupItem color="info">CBO-388 (NLH only) Disable SBS</ListGroupItem>
      <ListGroupItem color="info">CBO-459 (NLH only) Reset is available for shipped or cancelled orders only</ListGroupItem>
      <ListGroupItem color="info">CBO-460 (NLH only) Improve Cancel Order Views for cancellations</ListGroupItem>
    </ListGroup>
    <h3>2019.07.05</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-366 Show the reset reason code description in the comment about reset</ListGroupItem>
      <ListGroupItem color="warning">CBO-466 Bulk reset should send only one FF API request</ListGroupItem>
    </ListGroup>
    <h3>2019.06.28</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-373 Add a link to MAP factory to BO</ListGroupItem>
      <ListGroupItem color="info">CBO-437 Add Notes button to the header on the Refunds page</ListGroupItem>
    </ListGroup>
    <h3>2019.06.17</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-299 Order refund (MVP)</ListGroupItem>
      <ListGroupItem color="warning">Improved widget and SBS UX</ListGroupItem>
    </ListGroup>
    <h3>2019.06.14</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-301 Widget</ListGroupItem>
      <ListGroupItem color="warning">Fixed ticket search for Resnap orders</ListGroupItem>
    </ListGroup>
    <h3>2019.06.13</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-392 Add automatic notes on SBS changes</ListGroupItem>
      <ListGroupItem color="warning">
        CBO-425 If one product is split into several shipments SBS is not applied to all shipments
      </ListGroupItem>
      <ListGroupItem color="info">Improve SBS view</ListGroupItem>
    </ListGroup>
    <h3>2019.06.07</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-409 Updated product type names</ListGroupItem>
      <ListGroupItem color="warning">CBO-403 Reupload should refer to reuploaded product</ListGroupItem>
    </ListGroup>
    <h3>2019.06.03</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-371 Display products on Order History and Search pages</ListGroupItem>
      <ListGroupItem color="warning">CBO-387 Not all refunds are shown in new BO</ListGroupItem>
    </ListGroup>
    <h3>2019.05.29</h3>
    <ListGroup>
      <ListGroupItem color="warning">CBO-342 Search shouldn't be case sensitive</ListGroupItem>
    </ListGroup>
    <h3>2019.05.24</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-353 Display the production facility per product on Order Details view</ListGroupItem>
      <ListGroupItem color="info">CBO-384 Remove quantity column and add quantity into status as in design</ListGroupItem>
      <ListGroupItem color="info">CBO-375 Support posters in backoffice</ListGroupItem>
    </ListGroup>
    <h3>2019.05.22</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-321 Added SBS/Notes/Reupload flags per product</ListGroupItem>
      <ListGroupItem color="info">CBO-335 Show editor as an icon and a version as a tooltip</ListGroupItem>
      <ListGroupItem color="info">CBO-350 Improved SBS view user experience</ListGroupItem>
    </ListGroup>
    <h3>2019.05.09</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-340 Update Reset and Cancel views with more details</ListGroupItem>
      <ListGroupItem color="info">CBO-349 Update Notes view to the new design, add filtering Notes by product</ListGroupItem>
      <ListGroupItem color="info">CBO-351 Add spinning flower on Invoice creation</ListGroupItem>
      <ListGroupItem color="success">CBO-363 Show price per product</ListGroupItem>
    </ListGroup>
    <h3>2019.04.24</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-302 Resend payment link</ListGroupItem>
      <ListGroupItem color="info">CBO-317 Support generating of invoice PDF</ListGroupItem>
      <ListGroupItem color="info">CBO-330 Show the cover color instead of the code</ListGroupItem>
      <ListGroupItem color="danger">Temp hide expected delivery date (revert CBO-315)</ListGroupItem>
    </ListGroup>
    <h3>2019.04.15</h3>
    <ListGroup>
      <ListGroupItem color="info">CBO-316 Product highlight (Identify product by ticket number)</ListGroupItem>
      <ListGroupItem color="success">CBO-315 Show expected delivery date in order details</ListGroupItem>
      <ListGroupItem color="warning">CBO-343 Fixed pasting product number into browser URL bar</ListGroupItem>
      <ListGroupItem color="warning">CBO-341 Fixed order cancel and reset. Compatibility with old BO</ListGroupItem>
      <ListGroupItem color="warning">CBO-339 Fixed SBS view caching</ListGroupItem>
    </ListGroup>
    <h3>2019.04.05</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-318 Support adding notes per product</ListGroupItem>
      <ListGroupItem color="success">CBO-322 Add product number on Track and Trace view</ListGroupItem>
      <ListGroupItem color="info">CBO-325 Order of columns in Shipping address on Order details page</ListGroupItem>
    </ListGroup>
    <h3>2019.04.02</h3>
    <ListGroup>
      <ListGroupItem color="warning">Fixed Onskefoto orders (SE) search</ListGroupItem>
      <ListGroupItem color="warning">Fixed cancel of entire Order</ListGroupItem>
      <ListGroupItem color="info">Small UI improvements (SBS button, product card layout)</ListGroupItem>
      <ListGroupItem color="info">More detailed payment status (confirmed or not)</ListGroupItem>
      <ListGroupItem color="info">
        Wordings (titles) improved - according to feedback from @lvanderbiezen and @nostrofski
      </ListGroupItem>
    </ListGroup>
    <h3>2019.03.19</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-214 Cancel order (full/partial)</ListGroupItem>
      <ListGroupItem color="success">CBO-215 Reset order (full/partial)</ListGroupItem>
      <ListGroupItem color="success">CBO-217 Reupload order</ListGroupItem>
      <ListGroupItem color="info">UX improvement: added hints and tips</ListGroupItem>
    </ListGroup>
    <h3>2019.03.05</h3>
    <ListGroup>
      <ListGroupItem color="info">
        <b>All pages are re-implemented according to the new design (created by @wthio)</b>
      </ListGroupItem>
      <ListGroupItem color="danger">Temporary removed resnap orders support</ListGroupItem>
    </ListGroup>
    <h2 className="mt-3">Alpha</h2>
    <h3>2019.01.31</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-232 Download zipped pdf</ListGroupItem>
      <ListGroupItem color="success">
        CBO-201 Search orders by ticket ID (T/Z number). Supports products ordered from 1st of Feb 2019
      </ListGroupItem>
      <ListGroupItem color="success">CBO-221 ShowByScan notes management</ListGroupItem>
    </ListGroup>
    <h3>2019.01.23</h3>
    <ListGroup>
      <ListGroupItem color="info">UX improvement: Order and customer search merged</ListGroupItem>
      <ListGroupItem color="info">Added external links to FF UI, old backoffice and RS manager</ListGroupItem>
      <ListGroupItem color="warning">Fixed mix-up search</ListGroupItem>
    </ListGroup>
    <h3>2019.01.17</h3>
    <ListGroup>
      <ListGroupItem color="info">Date and time format unified</ListGroupItem>
      <ListGroupItem color="info">Added discount code for albelli orders</ListGroupItem>
      <ListGroupItem color="warning">Fixed fulfillment view for order with more than 10 copies</ListGroupItem>
    </ListGroup>
    <h3>2019.01.08</h3>
    <ListGroup>
      <ListGroupItem color="info">Changed filter layout</ListGroupItem>
      <ListGroupItem color="info">
        Added <i>Destroyed</i> column to Mix-up list
      </ListGroupItem>
      <ListGroupItem color="info">CBO-206 product preview (resnap) - added go-to-page</ListGroupItem>
      <ListGroupItem color="success">CBO-202 Search by customer email address</ListGroupItem>
      <ListGroupItem color="success">CBO-204 Search by customer phone number</ListGroupItem>
      <ListGroupItem color="success">CBO-223 View order history</ListGroupItem>
    </ListGroup>
    <h3>2018.12.03</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-206 product preview (resnap)</ListGroupItem>
    </ListGroup>
    <h3>2018.11.30</h3>
    <ListGroup>
      <ListGroupItem color="success">CBO-206 product preview (albelli vendors only)</ListGroupItem>
      <ListGroupItem color="success">CCTS-81 Order details page (general overview)</ListGroupItem>
    </ListGroup>
    <h3>2018.11.20</h3>
    <ListGroup>
      <ListGroupItem color="success">New feedback form</ListGroupItem>
    </ListGroup>
    <h3>2018.11.05</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-75 Implemented notes from Manon van Dam</ListGroupItem>
    </ListGroup>
    <h3>2018.11.01</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-75 Mix-up search</ListGroupItem>
    </ListGroup>
    <h3>2018.10.31</h3>
    <ListGroup>
      <ListGroupItem color="warning">CCTS-77 Bug fix</ListGroupItem>
    </ListGroup>
    <h3>2018.10.24</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-75 Simple mix-up registration</ListGroupItem>
    </ListGroup>
    <h3>2018.10.17</h3>
    <ListGroup>
      <ListGroupItem color="success">CCTS-77 Basic search functionality</ListGroupItem>
    </ListGroup>
    <h3>2018.10.12</h3>
    <ListGroup>
      <ListGroupItem color="success">Logging to kinesis</ListGroupItem>
    </ListGroup>
    <h3>2018.10.05</h3>
    <ListGroup>
      <ListGroupItem color="success">Feedback form</ListGroupItem>
    </ListGroup>
    <h3>2018.10.03</h3>
    <ListGroup>
      <ListGroupItem color="success">Authentication</ListGroupItem>
    </ListGroup>
  </Container>
)

function renderHeader() {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <h1>Release notes</h1>
        <button id="ReleaseNotesView_toggler">
          Glossary <FaAngleDoubleDown />
        </button>
      </div>
      <UncontrolledCollapse toggler="#ReleaseNotesView_toggler">
        <ListGroup>
          <ListGroupItem color="success" size="sm">
            <b>Added functionality</b>
          </ListGroupItem>
          <ListGroupItem color="info">
            <b>UI change/enhancement</b>
          </ListGroupItem>
          <ListGroupItem color="warning">
            <b>Bug fix</b>
          </ListGroupItem>
          <ListGroupItem color="danger">
            <b>Removed functionality</b>
          </ListGroupItem>
        </ListGroup>
      </UncontrolledCollapse>
    </React.Fragment>
  )
}

export default ReleaseNotesView
