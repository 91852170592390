import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/redoActions'
import { RedoStore } from './states'
import { RedoProductionOrdersRequest } from '../api/productionOrder'
import {
  Address,
  GetDeliveryOptionsRequest,
  GetDeliveryOptionsResponse,
  GetPickupPointsRequest,
  GetPickupPointsResponse,
} from '../api'

const initialState: RedoStore = {
  isLoading: false,
  deliveryOptions: {} as GetDeliveryOptionsResponse,
  pickupPoints: {} as GetPickupPointsResponse,
  error: '',
}

export const redoPanel = createReducer<RedoStore>(initialState, {
  [actions.requestDeliveryOptions.type]: (state, action: PayloadAction<GetDeliveryOptionsRequest>) => {
    state.isLoading = true
    state.deliveryOptions = undefined
    return state
  },
  [actions.receivedDeliveryOptions.type]: (state, action: PayloadAction<GetDeliveryOptionsResponse>) => {
    state.deliveryOptions = action.payload
    state.isLoading = false
    return state
  },
  [actions.requestPickupPoints.type]: (state, action: PayloadAction<GetPickupPointsRequest>) => {
    state.isLoading = true
    state.pickupPoints = undefined
    return state
  },
  [actions.receivedPickupPoints.type]: (state, action: PayloadAction<GetPickupPointsResponse>) => {
    state.isLoading = false
    state.pickupPoints = action.payload
    return state
  },
  [actions.redoFailure.type]: (state, action: PayloadAction<string>) => {
    state.isLoading = false
    state.error = action.payload
    return state
  },
  [actions.submitProductionOrderRedo.type]: (state, action: PayloadAction<RedoProductionOrdersRequest>) => {
    return state
  },
})
