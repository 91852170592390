import _ from "lodash";
import React, { ChangeEvent, FormEvent } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import * as OrderTypes from "../../api/order";
import {
  AlertInfo,
  OperationType,
  OrderManagementServiceInstance,
  ProductionOrderDetails,
  ProductWithValue,
} from "../../services/OrderManagementService";
import Alerts from "../generic/Alerts";
import Confirmation from "./Confirmation";
import OrderStructure from "./OrderStructure";

import { ResetReason } from "../../api/configuration";
import Select from "./Select";
import { productionOrdersInProgress } from "../../utils";

interface OrderRedoFormProps {
  onSubmit(x: OrderTypes.InternalOperationRequest): void;
  data: OrderTypes.OrderDetails;
  reasons: ResetReason[];
}

interface OrderRedoFormState {
  appVersion: string;
  modal: boolean;
  error?: string;
  comment: string;
  commentError?: string;
  products: ProductWithValue[];
  productionOrders: ProductionOrderDetails[];
  reasonCode?: string;
  reasonPhrase?: string;
}

export default class OrderRedoForm extends React.Component<
  OrderRedoFormProps,
  OrderRedoFormState
> {
  constructor(props: OrderRedoFormProps) {
    super(props);

    this.state = {
      appVersion: process.env.REACT_APP_VERSION || "",
      modal: false,
      error: "",
      comment: "",
      products: [],
      productionOrders: [],
    };

    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.submitState = this.submitState.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
  }

  handleProductsChange(
    products: Array<ProductWithValue>,
    productionOrders: Array<ProductionOrderDetails>
  ) {
    const error = _(products)
      .filter(
        (product: ProductWithValue) =>
          !!product.maxValue &&
          !!product.tooltip &&
          product.value > product.maxValue
      )
      .map((product) => product.tooltip!)
      .uniq()
      .join(" ");

    // So far no tooltips are available for Production Orders

    this.setState({
      error,
      products,
      productionOrders,
    });
  }

  handleCommentChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    this.setState({
      commentError: undefined,
      comment: value,
    });
  }

  handleReasonChange(event: ChangeEvent<HTMLInputElement>) {
    const { reasons } = this.props;

    const target = event.target;
    const code = target.value;

    const [match] = reasons.filter(
      (reason: ResetReason) => reason.errorID === code
    );
    this.setState({
      error: undefined,
      reasonCode: code,
      reasonPhrase: match.description,
    });
  }

  handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { productionOrders, comment, reasonCode } = this.state;

    const invalidProductionOrdersDuringSubmit =
      productionOrders.length === 0 ||
      productionOrders.every((productionOrder: ProductionOrderDetails) =>
        productionOrder.products.every(
          (product: ProductWithValue) => product.value === 0
        )
      );

    if (invalidProductionOrdersDuringSubmit) {
      this.setState({ error: "Check entire order or some part" });
      return;
    }

    if (!comment) {
      this.setState({ commentError: "Please put short reason" });
      return;
    }

    if (comment.trim().length > 254) {
      this.setState({
        commentError: "Please input a shorter reason. (Maximum 254 characters)",
      });
      return;
    }

    if (!reasonCode) {
      this.setState({ error: "Please select a reason code" });
      return;
    }

    this.toggleModal();
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  submitState() {
    const {
      appVersion,
      comment,
      products,
      reasonCode,
      reasonPhrase,
      productionOrders,
    } = this.state;
    const {
      data: { id, vendor, channel, invoiceId, source }, onSubmit
    } = this.props;

    const payload: OrderTypes.InternalOperationRequest = {
      appVersion,
      operation: OrderTypes.InternalOperationType.Reset,
      code: id,
      vendor,
      channel,
      comment: comment.trim(),
      products,
      productionOrders,
      invoiceId,
      source,
      reasonCode,
      reasonPhrase,
    };
    onSubmit(payload);
  }

  renderConfirmationBody() {
    const { productionOrders } = this.state;

    if (!productionOrders) {
      return;
    }

    const allProductionOrderProductsSelected = productionOrders.every(
      (productionOrder) =>
        productionOrder.products.every(
          (product) => product.value === product.quantity
        )
    );

    const showCancelReminder = productionOrdersInProgress(productionOrders)

    if (allProductionOrderProductsSelected) {
      return (
        <div>
          <div>
            Do you really want to <b>Redo</b> entire order?
          </div>
          {showCancelReminder && (
            <div><h2 className="attention">Remember to cancel the original production order!</h2></div>
          )}
        </div>
      );
    }

    return (
      <div>
        <div>
          Do you really want to <b>Redo</b> following part?
        </div>
        <div>
          <ul>
            {productionOrders
              .filter((productionOrder: ProductionOrderDetails) =>
                productionOrder.products.some(
                  (product: ProductWithValue) => product.value > 0
                )
              )
              .map((productionOrder: ProductionOrderDetails) =>
                productionOrder.products.map((product: ProductWithValue) => (
                  <li key={productionOrder.productionOrderId}>
                    {productionOrder.productionOrderId} - {product.id} :{" "}
                    {product.value === product.quantity
                      ? "entire product"
                      : `${product.value} / ${product.quantity} products`}
                  </li>
                ))
              )}
          </ul>
        </div>
        {showCancelReminder && (
          <div><h2 className="attention">Remember to cancel the original production order!</h2></div>
        )}
      </div>
    );
  }

  render() {
    const {
      productionOrders,
      products,
      error,
      commentError,
      modal,
      reasonCode,
      comment,
    } = this.state;
    const { data, reasons } = this.props;
    const { preselectedProductionOrderId } = data || {};

    const allDisabled = productionOrders.every(
      (productionOrder: ProductionOrderDetails) => !!productionOrder.disabled
    );

    const warnings = OrderManagementServiceInstance.getWarnings(products);
    const errors = [error, commentError]
      .filter((x) => x)
      .map(
        (content) =>
        ({
          color: "danger",
          content,
        } as AlertInfo)
      );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Alerts items={errors.concat(warnings)} />
        <Confirmation
          isOpen={modal}
          toggle={this.toggleModal}
          onSubmit={this.submitState}
          cancelText="Back"
        >
          {this.renderConfirmationBody()}
        </Confirmation>
        <OrderStructure
          data={data}
          preselectedProductionOrderId={preselectedProductionOrderId}
          hideFulfillmentProducts={true}
          ignoreCancelledProductionOrders={true}
          handleChange={this.handleProductsChange}
          operation={OperationType.Reset}
        />
        <FormGroup>
          <Label className={allDisabled ? "disabled" : undefined}>
            Redo code
          </Label>
          <Select
            emptyText="-- select a code --"
            onChange={this.handleReasonChange}
            value={reasonCode}
            options={reasons.map((reason: ResetReason) => ({
              value: reason.errorID,
              text: reason.description,
            }))}
            disabled={allDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label
            for="OrderManagementView_ReasonPhrase"
            className={allDisabled ? "disabled" : undefined}
          >
            Redo reason
          </Label>
          <Input
            type="textarea"
            id="OrderManagementView_ReasonPhrase"
            name="comment"
            value={comment}
            onChange={this.handleCommentChange}
            disabled={allDisabled}
          />
        </FormGroup>
        <button
          type="submit"
          disabled={errors.length !== 0 || allDisabled}
          style={{ marginBottom: "10px" }}
        >
          Submit
        </button>
      </Form>
    );
  }
}
