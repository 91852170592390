export enum Severity {
  info = 'Information',
  warn = 'Warning',
  error = 'Error',
}

export enum codes {
  unspecified = 'Unspecified',
  unknownSearchType = 'Unknown search type',
  reactJsError = 'ReactJs error',
  httpCallError = 'Http call error',
  orderDetailsLoaded = 'Order details loaded',
  orderDetailsError = 'Order details error',
  sagaError = 'Saga error',
  pageView = 'Page view',
  badState = 'Bad state',
  statusAssertion = 'Status mapping',
  ambiguousDelayEvent = 'CBO-619 delay communication',
  fileDownloaded = 'Download file',
  timelineLoaded = 'Timeline loaded',
}

export enum Categories {
  clientApp = 'Client App',
}

export class LogMessage<TPayload = any> {
  public logLevel : Severity = Severity.info
  public userName : string = ''
  
  constructor(
    public payload?: TPayload,        
    public code: codes = codes.unspecified,
    public category: Categories = Categories.clientApp,

  ) {}
}
