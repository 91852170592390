import { combineReducers, configureStore, EnhancedStore } from '@reduxjs/toolkit'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { Provider } from 'react-redux'
import { reducer as reduxFormReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import * as global from './actions/globalActions'
import { codes, LogMessage } from './api'
import * as reducers from './reducers'
import { AppStore } from './reducers/states'
import registerServiceWorker from './registerServiceWorker'
import Router from './route/router'
import sagas from './sagas'
import { AuthInstance } from './services/AuthService'
import { Errors } from './services/ErrorMessageService'
import { LocalStorageInstance } from './services/LocalStorageService'
import { LogApiInstance } from './services/LogApiService'
import ReduxThunk from 'redux-thunk'

export default class App extends React.Component<any, any> {
  history: any
  store: EnhancedStore<AppStore>
  globalWindow: any

  constructor(props: any) {
    super(props)

    const baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href')

    this.history = createBrowserHistory({ basename: baseUrl })

    this.store = this.initStore(this.history)

    this.onUpdate = this.onUpdate.bind(this)

    this.globalWindow = window

    registerServiceWorker(this.onUpdate)

    LocalStorageInstance.setGlobalConfiguration()
  }

  fatalError<T>(payload: T, code: codes) {
    this.store.dispatch(Errors.toErrorAction())

    LogApiInstance.error(new LogMessage(payload, code))
  }

  componentDidCatch(error: Error, info: any) {
    const payload = {
      message: error.message,
      stack: error.stack,
      info,
      appState: this.store.getState(),
    }

    this.fatalError(payload, codes.reactJsError)
  }

  initStore(history: any) {
    const sagaMiddleware = createSagaMiddleware({
      onError: (error: Error) => {
        const payload = {
          message: error.message,
          stack: error.stack,
          appState: this.store.getState(),
        }
        console.error(error)
        this.fatalError(payload, codes.sagaError)
      },
    })

    const middleware = [sagaMiddleware, routerMiddleware(history), ReduxThunk]

    const reducer = combineReducers({
      ...reducers,
      form: reduxFormReducer,
      router: connectRouter(history),
    })

    const store = configureStore({
      reducer,
      middleware,
    })

    sagaMiddleware.run(sagas)

    return store
  }

  componentDidMount() {
    this.store.dispatch(global.appInit())
  }

  onUpdate() {
    this.store.dispatch(global.newVersionAvailable())
  }  

  render() {
    return (
      <Provider store={this.store}>
        <ConnectedRouter history={this.history}>
          <Router />
        </ConnectedRouter>
      </Provider>
    )
  }
}
