function orderViewRoute(orderId?: string, selected?: string) {
  const params = selected ? '?selected=' + selected.toUpperCase() : ''

  if (orderId !== ':orderId' && isStudentenOrderNumber(orderId)) {
    //Studenten order
    return routes.STUDENTEN_ORDERS_VIEW({ orderId: orderId })
  } else {
    return `${routes.ORDERS()}/${orderId}${params}`
  }
}

function isStudentenOrderNumber(orderId?: string) {
  return orderId?.match(/^STUD[0-9]*/)
}

const routes = {
  HOME: () => '/',
  NOT_FOUND: () => '/404',
  ERROR: () => '/error',
  SEARCH: () => '/search',
  DISCOUNTS: () => '/discounts',
  CUSTOMERS: () => '/customers',
  CUSTOMERS_VIEW: ({ email = ':email' } = {}) => `${routes.CUSTOMERS()}/${email}`,
  PROJECTS: () => '/projects',
  PROJECTS_VIEW: ({ projectId = ':projectId' } = {}) => `${routes.PROJECTS()}/${projectId}`,
  ACCOUNTS: () => '/accounts',
  ACCOUNTS_VIEW: ({ accountId = ':accountId' } = {}) => `${routes.ACCOUNTS()}/${accountId}`,
  BULK_OPERATIONS: () => '/bulk',
  BULK_OPERATIONS_VIEW: () => `${routes.BULK_OPERATIONS()}/`,
  ORDERS: () => '/orders',
  // eslint-disable-next-line
  ORDERS_VIEW: ({ orderId = ':orderId', selected = <string>(<unknown>undefined) } = {}) => orderViewRoute(orderId, selected),
  STUDENTEN_ORDERS_VIEW: ({ orderId = ':orderId' } = {}) => `${routes.ORDERS()}/studenten/${orderId}`,
  ORDERS_PRODUCT_VIEW: ({ orderId = ':orderId', productId = ':productId', contentId = ':contentId' } = {}) =>
    `${routes.ORDERS_VIEW({ orderId: orderId })}/${productId}/${contentId}`,
  ORDERS_TIMELINE: ({ orderId = ':orderId' } = {}) => `${routes.ORDERS_VIEW({ orderId })}/timeline`,
  PRODUCT_CONTENT_VIEW: ({ contentId = ':contentId' } = {}) => `/product-content/${contentId}`,
  RELEASE_NOTES: () => '/versions',
  PERMISSIONS: () => '/permissions',
  REFUNDS: ({ orderId = ':orderId' } = {}) => `${routes.ORDERS_VIEW({ orderId })}/refunds`,
  MIXUPS: () => '/mix-up',
  MIXUPS_NEW: () => `${routes.MIXUPS()}/new`,
  MIXUPS_DETAILS: ({ id = ':id' } = {}) => `${routes.MIXUPS()}/${id}`,
}

export default routes
