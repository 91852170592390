import { createAction } from '@reduxjs/toolkit'
import { JustSearchResponse, SearchFilter, SearchResponse } from '../api/search'

export const search = createAction<SearchFilter>('SEARCH_REQUEST')

export const receivedAlbelliResults = createAction<SearchResponse>('SEARCH_ALBELLI_RECEIVED')

export const reset = createAction<SearchFilter | undefined>('SEARCH_RESET')

export const toggleOrderDetails = createAction<number>('SEARCH_TOGGLE_ORDER_DETAILS')

export const toggleAllDetails = createAction('SEARCH_TOGGLE_ALL_DETAILS')

export const receivedSearchResults = createAction<JustSearchResponse>('SEARCH_RECEIVED')
