import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { DataSource, OrderListItemModel, Product, OrderEvents, OrderEvent } from '../../api'
import closed from '../../assets/img/closed.svg'
import notesIcon from '../../assets/img/flags/notes.svg'
import resetIcon from '../../assets/img/flags/reset.svg'
import refundIcon from '../../assets/img/flags/refund.svg'
import sbsIcon from '../../assets/img/flags/sbs.svg'
import open from '../../assets/img/open.svg'
import routes from '../../route/routes'
import {
  OrderDetailsServiceInstance as Service,
  studentenEditor,
  studentenFulfillmentProduct,
} from '../../services/OrderDetailsService'
import { toDateTimeNew } from '../../utils'
import ProductLabel from '../forms/ProductLabel'
import Badge from '../generic/Badge'
import CampaignIcon from '../generic/CampaignIcon'
import EditorIcon from '../generic/EditorIcon'
import Flag from '../generic/Flag'
import Grid, { GridColumn } from '../generic/Grid'
import LazyContainer from '../generic/LazyContainer'
import PlantIcon from '../generic/PlantIcon'

interface Props {
  id: string
  createdDateTime: Date
  channelName: string
  data?: OrderListItemModel
  isFolded: boolean
  showCustomerName: boolean
  showPrice: boolean
  loadData(): void
  onToggleRow(): void
}

export default class OrderListItem extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.data) {
      this.props.loadData()
    }
  }

  extraDetails() {
    if (this.props.data && this.props.data.products && this.props.data.products.length) {
      return this.renderProducts(this.props.data)
    }

    return undefined
  }

  renderRightColumn(product: Product) {
    if (!this.props.data) {
      return undefined
    }

    const columns: GridColumn<Product>[] = []
    product.quantity && columns.push({ title: 'Qty', tdClass: 'text-center', value: (p) => p.quantity || <i>Unknown</i> })

    const ffProduct = Service.resolveFulfillmentProduct(product, this.props.data.fulfillment)
    const [plantInfo] =
      this.props.data.source === DataSource.Studenten
        ? Service.getPlants(studentenFulfillmentProduct.lines)
        : Service.getPlantsFromOrderEvents(ffProduct && ffProduct.lines, this.props.data!.orderEvents, ffProduct?.productCode)
    columns.push({
      title: 'Plant',
      tdClass: 'text-center',
      value: (p) => (
        <PlantIcon elementId={`plant_icon_${p.id}`} displayName={plantInfo.displayName} plant={plantInfo.plant} isSmall={true} />
      ),
    })

    const editor =
      this.props.data.source === DataSource.Studenten
        ? Service.resolveProductEditor(studentenFulfillmentProduct, studentenEditor)
        : Service.resolveProductEditor(ffProduct, product.editor)
    columns.push({
      title: 'Editor',
      tdClass: 'text-center',
      value: (p) => (editor ? <EditorIcon elementId={`editor_${p.id}`} description={editor} isSmall={true} /> : <i>Unknown</i>),
    })

    return <Grid data={[product]} columns={columns} className="embedded" />
  }

  renderProducts(data: OrderListItemModel) {
    const products = data.products
    return (
      <div className="order-details">
        {products.map((product) => (
          <Row key={product.id} className="justify-content-between">
            <Col>
              <ProductLabel
                productNumber={
                  data.source === DataSource.Studenten
                    ? Service.resolveStudentenProductDescriptionByType(product.productId)
                    : product.id
                }
                productTitle={product.title}
                productType={Service.resolveProductDescriptionByType(product.productId)}
                previewImageSrc={product.preview && product.preview.url}
              />
            </Col>
            <Col className="col-auto">{this.renderRightColumn(product)}</Col>
          </Row>
        ))}
      </div>
    )
  }

  renderAccountType() {
    const order = this.props.data;
    if (!order) {
      return
    }

    return order.customer?.accountType
  }

  renderNotificationsFlags() {
    const order = this.props.data
    if (!order) {
      return
    }

    const showNotes = order.notes && order.notes.length > 0
    const sbsCollection = Service.extractSbsCollection(order)
    const showSbs = sbsCollection && sbsCollection.length > 0
    const showReset = Service.resolveOrderEventsByType(order.orderEvents || [], OrderEvents.Reprocess).length > 0
    const showRefund = Service.resolveOrderEventsByType(order.orderEvents || [], OrderEvents.Refund).length > 0
    const promisedCampaign = Service.resolvePromisedCampaign(order.orderEvents || [])

    return (
      <div className="d-flex justify-content-between">
        <Flag icon={notesIcon} className={`${showNotes ? 'visible' : 'invisible'}`} title="Notes" />
        <Flag icon={sbsIcon} className={`${showSbs ? 'visible' : 'invisible'}`} title="Show by scan" />
        <Flag icon={resetIcon} className={`${showReset ? 'visible' : 'invisible'}`} title="Reset" />
        <Flag icon={refundIcon} className={`${showRefund ? 'visible' : 'invisible'}`} title="Refunded" />
        {this.renderReuploadIcon()}
        <CampaignIcon
          campaign={promisedCampaign}
          className={`${promisedCampaign ? 'visible' : 'invisible'}`}
          productId={order.id}
        />
      </div>
    )
  }

  renderReuploadIcon() {
    const order = this.props.data
    if (!order) {
      return
    }

    const reuploadState = Service.resolveReuploadState(order.orderEvents)

    return (
      <Flag
        icon={reuploadState?.image || ''}
        className={`${reuploadState ? 'visible' : 'invisible'}`}
        title={reuploadState?.title || ''}
      />
    )
  }

  renderOrderLink() {
    const order = this.props.data

    if (!order) {
      return
    }
    return <Link to={routes.ORDERS_VIEW({ orderId: order.id })}>{order.id}</Link>
  }

  render() {
    const data = this.props.data
    const baseColumnCount = 8
    let columnsCount = baseColumnCount
    if (this.props.showCustomerName) {
      columnsCount += 1
    }
    if (this.props.showPrice) {
      columnsCount += 1
    }
    if (!!this.props.data?.customer?.accountType) {
      columnsCount += 1
    }

    if (!data) {
      return (
        <tr>
          <td colSpan={columnsCount}>
            <LazyContainer isLoading={true} loaderSize="150px" />
          </td>
        </tr>
      )
    }

    if (data.isOldLegacyOrder === true) {
      return (
        <tr>
          <td></td>
          <td>{this.props.id}</td>
          <td>{toDateTimeNew(this.props.createdDateTime)}</td>
          <td colSpan={columnsCount - 3}>{this.props.channelName}</td>
        </tr>
      )
    }
    const extraDetails = this.extraDetails()
    const badgeSettings = Service.resolveOrderStatusBadgeSettings(data)
    return (
      <React.Fragment>
        <tr>
          {extraDetails ? (
            <td className="toggle-details" onClick={() => this.props.onToggleRow()}>
              <img src={this.props.isFolded ? closed : open} width="20" height="20" alt="folded" />
            </td>
          ) : (
            <td />
          )}
          <td>{this.renderOrderLink()}</td>
          <td>{toDateTimeNew(data.createdDateTime)}</td>
          <td>{data.channel}</td>
          <td>{data.products ? data.products.length : '?'}</td>
          {this.props.showCustomerName && <td>{data.customer && data.customer.name}</td>}
          {this.props.showPrice && <td>{data.price && `${data.price.total} ${data.price.currency}`}</td>}
          <td style={{ width: '100%' }} />
          <td>{this.renderAccountType()}</td>
          <td>{this.renderNotificationsFlags()}</td>
          <td>
            <Badge setting={badgeSettings} className="fixed-size" />
          </td>
        </tr>
        {!this.props.isFolded && extraDetails && (
          <tr className="extra-details">
            <td colSpan={columnsCount}>{extraDetails}</td>
          </tr>
        )}
      </React.Fragment>
    )
  }
}
