import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import Button from 'reactstrap/lib/Button'
import Col from 'reactstrap/lib/Col'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Input from 'reactstrap/lib/Input'
import Nav from 'reactstrap/lib/Nav'
import NavLink from 'reactstrap/lib/NavLink'
import searchImg from '../../assets/img/search.svg'
import './FilterBar.scss'

export interface FilterBarItem {
  id: number
  navLinkId: string
  title: string
}

export interface FilterBarValue {
  id: number
  query?: string
}

interface Props {
  initialValue: FilterBarValue
  items: FilterBarItem[]
  onSubmit(filter: FilterBarValue): void
  onSelect?(filter: FilterBarValue): void
  searchOnEmpty?: boolean
  inputId?: string
  formId?: string
}

interface State {
  value: FilterBarValue
  clearButtonVisible: boolean
}

export default class FilterBar extends React.Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)

    this.state = { clearButtonVisible: false, value: props.initialValue || { id: props.items[0].id } }
    this.onInputChange = this.onInputChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onClear = this.onClear.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!_.isMatch(prevProps.initialValue, this.props.initialValue) && this.props.initialValue) {
      this.setState({ value: this.props.initialValue })
    }
  }

  render() {
    const searchEnabled = !!this.props.searchOnEmpty || !!this.state.value.query

    return (
      <div className="search-filter">
        <Nav pills>
          {this.props.items.map((item) => {
            return (
              <NavLink
                key={item.id}
                id={item.navLinkId}
                href="#"
                active={this.state.value.id === item.id}
                onClick={() => this.onSelect(item.id)}>
                {item.title}
              </NavLink>
            )
          })}
        </Nav>
        <Form onSubmit={this.onSubmit} id={this.props.formId || undefined}>
          <FormGroup row>
            <Col md={6} sm={12} className="query">
              <Input
                id={this.props.inputId || undefined}
                type="text"
                value={this.state.value.query}
                autoComplete="off"
                onChange={this.onInputChange}
                innerRef={this.inputRef}
                autoFocus
              />
              {!this.state.clearButtonVisible && (
                <Button color="link" type="submit" disabled={!searchEnabled}>
                  <img src={searchImg} alt="Search" width="24" height="24" />
                </Button>
              )}
              {this.state.clearButtonVisible && <Button color="link" id="search-filter-clear" onClick={this.onClear} close />}
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }

  private onSelect(type: number) {
    const newValue = { ...this.state.value, id: type }
    this.setState({ value: newValue })
    this.props.onSelect && this.props.onSelect(newValue)
    this.inputRef.current && this.inputRef.current.focus()
  }

  private onClear(event: ChangeEvent<any>) {
    event.preventDefault()
    const newValue = { ...this.state.value, query: '' }
    this.setState({ clearButtonVisible: false, value: newValue })
    this.inputRef.current && this.inputRef.current.focus()
  }

  private onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.setState({ clearButtonVisible: true })
    this.props.onSubmit(this.state.value)
  }

  private onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      const newValue = (this.state.value.id + 1) % this.props.items.length
      this.onSelect(newValue)
    }
  }

  private onInputChange(event: ChangeEvent<any>) {
    const newValue = { ...this.state.value, query: event.target.value }
    this.setState({
      clearButtonVisible: false,
      value: newValue,
    })
  }
}
