export const TimelineMapping: { [x: string]: string | undefined } = {
  accepted: 'Plant ticket created',
  'bo-ReUpload': 'Reupload requested',
  'bo-CancelReUpload': 'Reupload cancelled',
  'reuploaded-new': 'Reuploaded',
  'reuploaded-old': 'Reuploaded',
  'bo-PutSbs': 'SBS added',
  'bo-DeleteSbs': 'SBS deleted',
  'bo-FullCancel': 'Cancellation requested',
  'bo-FullReset': 'Reset requested',
  'bo-FullResetBeforeFulfillment': 'Reset requested',
  'bo-PartialCancel': 'Partial cancellation requested',
  'bo-PartialReset': 'Partial reset requested',
  'bo-PartialResetBeforeFulfillment': 'Partial reset requested',
  'bo-RestartFinalizer': 'Finalizer restarted',
  'bo-Refund': 'Refund requested',
  'bo-OrderComment': 'Note posted',
  'bo-MixupComment': 'Note posted',
  'bo-ChangeAddress': 'Change address requested',
  'bo-RefundOrder' : 'Order refunded',
  DeliveryUpdate: 'Change address succeeded',
  DeliveryUpdateFailed: 'Change address failed',
  cancelled: 'Cancellation successful',
  created: 'Order placed',
  delayed: 'Delayed',
  error: 'An error occurred',
  moving: 'Moved to the other plant',
  delivered: 'Delivered',
  delivering: 'In the process of being delivered',
  'payment-AUTHORISATION': 'Payment authorized',
  'payment-AUTHORISATION-Failed': 'Payment failed to authorize',
  'payment-CANCELLATION': 'Payment cancelled',
  'payment-CAPTURE': 'Payment captured',
  'payment-CAPTURE-Failed': 'Payment failed to capture',
  'payment-CHARGEBACK': 'Payment charged back',
  'payment-CHARGEBACK_REVERSED': 'Payment chargeback reversed',
  'payment-NOT_REQUIRED': 'Payment not required',
  'payment-NOTIFICATION_OF_CHARGEBACK': 'Payment notification of chargeback',
  'payment-NOTIFICATION_OF_FRAUD': 'Payment notification of fraud',
  'payment-PENDING': 'Payment pending',
  'payment-PREARBITRATION_LOST': 'Payment prearbitration lost',
  'payment-REFUND': 'Refund successful',
  'payment-REFUND-Failed': 'Refund failed',
  'payment-DEFERRED' : 'Payment deferred',
  'order-api-cancelled': 'Cancelled automatically',
  'ff-received': 'Fulfillment request created',
  producing: 'Started production',
  promisebroken: 'Promise broken',
  promised: 'Promised',
  rejected: 'Rejected',
  reprocess: 'Reset successful',
  shipped: 'Scanned for shipment',
  'plant-reset': 'Plant reset',
  'plant-selected': 'Plant selected',
  'pc-FailedFinalizerJob': 'Finalizer failed',
  'pc-LinkedDescriptionToProductContent': 'PDF created',
  'pc-LinkedProductToProductContent': 'PDF created',
  'delivery-DELIVERED': 'Delivered',
  'virtual-plant-selected': 'Virtual plant selected',
  'production-order-updated': 'Production order updated',
  'production-order-production-notification-updated': 'Production notification updated',
  'production-order-status-updated': 'Production order status updated',
  'production-order-change-address-request-updated': 'Address change request updated',
  'production-order-status-partially-shipped': 'Production order partially shipped',
  'production-order-status-shipped': 'Production order shipped',
  'production-order-redo' : 'Production order reprocessed',
  'production-order-status-delayed' : 'Production order is delayed',
  'production-order-status-producing' : 'Production order is being produced',
  'production-order-status-waiting-for-production' : 'Production order is waiting for production',
  'production-order-status-accepted' : 'Production order is accepted',
  'production-order-status-cancelled' : 'Production order is cancelled',
  'production-order-iways-create' : 'Production order created in iWays',
  'production-order-create': 'Production order is created',
  'production-order-status-packed' : 'Production order is packed',
  'production-order-status-failed' : 'Production order failed'
}
