import React from 'react'
import { ProductionOrder, ProductionOrderDeliveryAddress, Shipment } from '../../api/productionOrder'
import { ActionLinkSettings } from './ActionLink'
import notesIcon from '../../assets/img/flags/notes.svg'
import resetIcon from '../../assets/img/flags/reset.svg'
import Flag from './Flag'
import ActionList from './ActionList'
import Grid, { GridColumn } from './Grid'
import { PlantInfo, OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import Badge from './Badge'
import { toDateTime } from '../../utils'
import { Address, OrderEvent } from '../../api/order'
import sbsIcon from '../../assets/img/flags/sbs.svg'
import PlantIcon from './PlantIcon'

export interface ProductionOrderDetailsCardProps extends React.Props<any> {
  productionOrder: ProductionOrder
  actions: ActionLinkSettings[]
  orderEvents?: OrderEvent[]
  notesCount: number
  hasProductionNotification: boolean
}

export default class ProductionOrderDetailsCard extends React.Component<ProductionOrderDetailsCardProps> {
  constructor(props: ProductionOrderDetailsCardProps) {
    super(props)
    this.renderNotificationsIcons = this.renderNotificationsIcons.bind(this)
  }

  renderNotificationsIcons() {
    return (
      <>
        {!!this.props.notesCount && <Flag icon={notesIcon} title={`Notes (${this.props.notesCount})`} />}
        {this.props.productionOrder.redo && <Flag icon={resetIcon} title={`Production order is created by redo`} />}
        {this.props.hasProductionNotification && <Flag icon={sbsIcon} title="Has SBS / Production Notifications" />}
      </>
    )
  }

  renderAddresses(shipments: Shipment[] | any, maxWidth: number, poId?: string | undefined) {
    let finalLines: string[][] = []
    if (shipments != undefined && shipments.length > 0) {
      finalLines = this.smartBreakAddresses(this.composeAddressesTexts(shipments), maxWidth)
    }
    const rows: JSX.Element[] = []
    for (let r = 0; r < finalLines.length; r++) {
      if (r > 0) {
        rows.push(<hr />) //delimit multiple addresses (shipments)
      }
      rows.push(this.renderSingleAddress(finalLines[r], (poId != undefined && poId?.length ? poId + '_' : '') + r.toString()))
    }
    return finalLines != undefined && finalLines.length > 0 && <div>{rows}</div>
  }

  renderSingleAddress(lines: string[], rowKeyPfx?: string | undefined): JSX.Element {
    const rows: JSX.Element[] = []
    const actualRKPfx = rowKeyPfx != undefined && rowKeyPfx?.length > 0 ? rowKeyPfx : 'addr_rk'
    for (let r = 0; r < lines.length; r++) {
      let rowKey: string = actualRKPfx + '_' + r
      let brRowKey: string = rowKey + 'crlf'
      if (r > 0) {
        rows.push(<br key={brRowKey} />)
      }
      rows.push(<span key={rowKey}>{lines[r]}</span>)
    }
    return <div key={actualRKPfx}>{rows}</div>
  }

  /// Join the address 'words' into text lines
  /// that wouldn't exceed the specified maxWidth,
  /// to eventually form a neat column.
  /// Both inputs and outputs are 2D arrays to allow for multiple shipments
  /// (and hence, addresses) within a single production order.
  smartBreakAddresses(lines: string[][], maxWidth: number): string[][] {
    let result: string[][] = []
    for (let i = 0; i < lines.length; i++) {
      let currentLine: string = ''
      let currentAddressLines: string[] = []
      for (let j = 0; j < lines[i].length; j++) {
        if (currentLine.length >= maxWidth) {
          currentAddressLines.push(currentLine)
          currentLine = ''
        }
        if (currentLine.length > 0) {
          currentLine += ', '
        }
        currentLine += lines[i][j]
      }
      if (currentLine.length > 0) {
        currentAddressLines.push(currentLine)
      }
      if (currentAddressLines != undefined && currentAddressLines.length > 0) {
        result.push(currentAddressLines)
      }
    }
    return result
  }

  composeAddressesTexts(shipments: Shipment[] | undefined): string[][] {
    if (shipments == undefined || shipments.length == 0) {
      return [['']]
    }

    let addresses: string[][] = []
    for (let i = 0; i < shipments.length; i++) {
      let address = this.composeAddressTexts(shipments[i].deliveryAddress)
      if (address.length > 0) {
        addresses.push(address)
      }
    }
    if (addresses.length > 0) {
      return addresses
    }
    return [['']]
  }
  composeAddressTexts(address: Address | undefined): string[] {
    if (address == undefined) {
      return ['']
    }
    let addressLines: string[] = []
    this.pushIfDefinedNotEmpty(address?.companyName, addressLines)
    this.pushIfDefinedNotEmpty(address?.customerPrefix, addressLines)
    this.pushIfDefinedNotEmpty(address?.customerTitle, addressLines)
    this.pushFirstLastNames(address?.customerFirstName, address?.customerLastName, addressLines)
    this.pushFirstLastNames(address?.firstName, address?.lastName, addressLines)
    this.pushIfDefinedNotEmpty(address?.addressLine1, addressLines)
    this.pushIfDefinedNotEmpty(address?.addressLine2, addressLines)
    this.pushIfDefinedNotEmpty(address?.zipCode, addressLines)
    this.pushIfDefinedNotEmpty(address?.city, addressLines)
    this.pushIfDefinedNotEmpty(address?.country, addressLines)
    this.pushIfDefinedNotEmpty(address?.countryId, addressLines)
    this.pushIfDefinedNotEmpty(address?.customerPhone, addressLines)
    this.pushIfDefinedNotEmpty(address?.phone, addressLines)
    if (address?.customerEmail != undefined && address?.customerEmail?.length > 0) {
      addressLines.push(address?.customerEmail)
    } else if (address?.email != undefined && address?.email?.length > 0) {
      addressLines.push(address?.email)
    }
    return addressLines
  }

  pushIfDefinedNotEmpty(needle: string | undefined, target: string[]) {
    if (needle != undefined && needle?.length > 0) {
      target.push(needle)
    }
  }

  pushFirstLastNames(firstName: string | undefined, lastName: string | undefined, target: string[]) {
    if (firstName != undefined && firstName?.length > 0 && lastName != undefined && lastName?.length > 0) {
      target.push(firstName + ' ' + lastName)
    } else {
      if (firstName != undefined && firstName?.length > 0) {
        target.push(firstName)
      }
      if (lastName != undefined && lastName?.length > 0) {
        target.push(lastName)
      }
    }
  }

  render() {
    const infoLeft = {
      orderDate: this.props.productionOrder.createdAt,
      expectedArrivalDate: this.props.productionOrder.shipments[0].customerPromiseDate,
      plant: Service.getPlantInfo(this.props.productionOrder.plant ?? 'TBD'),
      status: Service.resolveProductionOrderStatus(this.props.productionOrder),
      notifications: undefined, // todo: get notifications
      shipments: this.props.productionOrder.shipments,
      plantIconElementId: `plant_tooltip_${this.props.productionOrder.id}`,
    }

    let columnsLeft: GridColumn<any>[] = [
      { title: 'Order date', value: (into) => toDateTime(into.orderDate) },
      { title: 'Expected arrival date', value: (info) => toDateTime(info.expectedArrivalDate) },
      {
        title: 'Plant',
        value: (info) => (
          <PlantIcon elementId={info.plantIconElementId} plant={info.plant.plant} displayName={info.plant.displayName} />
        ),
      },
      {
        title: 'Status',
        value: (info) => <Badge setting={info.status} />,
      },
      {
        title: 'Notifications',
        value: this.renderNotificationsIcons,
      },
      {
        title: 'Address',
        value: (info) => this.renderAddresses(info?.shipments, 25, this.props.productionOrder.id),
      },
    ]

    const infoRight = {
      actions: this.props.actions,
    }

    const columnsRight: GridColumn<any>[] = [
      {
        title: 'Actions',
        value: (info) => {
          return <ActionList actions={info.actions} className="slim" />
        },
      },
    ]

    return (
      <div className="d-flex justify-content-between">
        <div>
          <Grid columns={columnsLeft} data={[infoLeft]} className="embedded" />
        </div>
        <div>
          <Grid
            columns={columnsRight}
            data={[infoRight]}
            className="all-eq last-column-status embedded"
            disableCopyOnClick={true}
          />
        </div>
      </div>
    )
  }
}
