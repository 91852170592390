import { createAction } from '@reduxjs/toolkit'
import { MixupDetails, MixupRecord } from '../api'

export const reset = createAction<string | undefined>('MIXUP_DETAILS_INIT')

export const receivedMixup = createAction<MixupDetails>('MIXUP_DETAILS_RECEIVED')

export const mixupSend = createAction<MixupRecord>('MIXUP_DETAILS_SEND')

export const newMixupOpen = createAction<string | undefined>('MIXUP_DETAILS_CREATE_OPEN')

export const editMixupOpen = createAction<string>('MIXUP_DETAILS_EDIT_OPEN')

export const togglePanel = createAction<string>('MIXUP_DETAILS_TOGGLE_PANEL')
