import _, { Dictionary } from 'lodash'
import React from 'react'
import { Table } from 'reactstrap'
import { OrderListItemModel, OrderSearchResult, SearchFilter } from '../../api'
import OrderListItem from './OrderListItem'

interface Props {
  albelliOrders?: OrderSearchResult[]
  orders: Dictionary<OrderListItemModel>
  filter?: SearchFilter
  enableExtraDetails?: boolean
  foldedRows?: (boolean | undefined)[]
  foldAll?: boolean
  showCustomerName: boolean
  showPrice: boolean
  onToggleRow(index: number): void
  onOrderVisible(id: string): void
}

export default class OrderList extends React.Component<Props> {
  sort() {
    const albelliOrders = this.props.albelliOrders || []

    return _.sortBy(albelliOrders, (x) => -new Date(x.createdDateTime))
  }

  isFolded(index: number) {
    if (this.props.foldedRows && this.props.foldedRows[index] !== undefined) {
      return !!this.props.foldedRows[index]
    }

    return !!this.props.foldAll
  }

  renderRowWithMessage(message: string) {
    return (
      <tr>
        <td colSpan={8}>
          <i>{message}</i>
        </td>
      </tr>
    )
  }

  render() {
    const items = this.sort()

    if (items.length === 0) {
      return <b id="empty-order-list-text">0 result</b>
    }

    return (
      <div className={`bo-grid bo-container order-list foldable`}>
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Order number</th>
              <th>Order date</th>
              <th>Vendor</th>
              <th>Products</th>
              {this.props.showCustomerName && <th>Customer</th>}
              {this.props.showPrice && <th>Total</th>}
              <th style={{ width: '100%' }} />
              <th>Account Type</th>
              <th>Notifications</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length > 0
              ? items.map((o, i) => (
                  <OrderListItem
                    key={o.id}
                    id={o.id}
                    createdDateTime={o.createdDateTime}
                    channelName={o.channel}
                    data={this.props.orders[o.id]}
                    isFolded={this.isFolded(i)}
                    onToggleRow={() => this.props.onToggleRow(i)}
                    loadData={() => {
                      if (o.isOldLegacyOrder === true) {
                        return
                      }

                      this.props.onOrderVisible(o.id)
                    }}
                    showCustomerName={this.props.showCustomerName}
                    showPrice={this.props.showPrice}
                  />
                ))
              : this.renderRowWithMessage('Data unavailable')}
          </tbody>
        </Table>
      </div>
    )
  }
}
