import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { Alert, Container, Row } from 'reactstrap'
import * as global from '../../actions/globalActions'
import { AppStore, GlobalStore } from '../../reducers/states'
import ActionLink from '../generic/ActionLink'
import LazyContainer from '../generic/LazyContainer'
import Footer from './Footer'
import './Layout.scss'
import NavMenu from './NavMenu'

interface LayoutProps extends GlobalStore {
  dispatch?: any
  children: ReactNode
}

class Layout extends React.Component<LayoutProps> {
  constructor(props: LayoutProps) {
    super(props)

    this.onClearError = this.onClearError.bind(this)
    this.onClearWarning = this.onClearWarning.bind(this)
    this.onClearInfo = this.onClearInfo.bind(this)
  }

  onClearError() {
    this.props.dispatch(global.hideError())
  }

  onClearWarning() {
    this.props.dispatch(global.hideWarning())
  }

  onClearInfo() {
    this.props.dispatch(global.hideInfo())
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <NavMenu />
        </Row>
        <Row>
          {this.props.error && this.renderAlert('danger', this.props.error, 'Clear', this.onClearError)}
          {this.props.warning && this.renderAlert('warning', this.props.warning, 'Clear', this.onClearWarning)}
          {this.props.info && this.renderAlert('success', this.props.info, 'Clear', this.onClearInfo)}
          {this.props.isNewVersionAvailable &&
            this.renderAlert(
              'warning',
              <span>
                A <b>new version is available</b>. Hard refresh your page (shortcut for windows: <b>Ctrl+F5</b>)
              </span>
            )}
        </Row>
        <Row>
          <div id="layout-container" className="bo-layout-container">
            <LazyContainer isLoading={this.props.loading}>{this.props.children}</LazyContainer>
          </div>
        </Row>
        <Footer />
      </Container>
    )
  }

  renderAlert(color: string, message: ReactNode, actionText?: string, actionFunc?: () => void): JSX.Element {
    return (
      <Alert color={color} style={{ margin: '10px 20px', width: '100%' }}>
        {message}
        {actionFunc && actionText && <ActionLink text={actionText} onClick={actionFunc} className="float-right mr-3" />}
      </Alert>
    )
  }
}

export default connect((store: AppStore) => store.global)(Layout)
