import * as React from 'react'
import ActionLink, { ActionLinkSettings } from './ActionLink'

interface Props {
  actions: ActionLinkSettings[]
  className?: string
}

export default function ActionList(props: Props) {
  const { actions, className } = props

  return (
    <div className={`bo-container action-list ${className || ''}`}>
      {actions.map((a, i) => (
        <span className="d-inline-block mr-3" key={i}>
          {i > 0 ? <span className="d-inline-block mr-3">|</span> : ''}
          <ActionLink {...a} />
        </span>
      ))}
    </div>
  )
}
