import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as orderActions from '../actions/orderDetailsActions'
import * as actions from '../actions/sbsActions'
import { SbsDeleteRequest, SbsPostRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* postSbsDetails(action: PayloadAction<SbsPostRequest>) {
  const request = action.payload
  try {
    yield call(ApiServiceInstance.post, `fulfillment/sbs-bulk/${request.vendorId}/${request.orderCode}`, request)
    yield put(actions.submittedSbs(request))
    yield put(orderActions.requestOrderDetails(action.payload.orderCode))
  } catch (e) {
    yield put(Errors.toErrorAction(e, 'Cannot put SBS'))
  }
}

function* deleteSbsDetails(action: PayloadAction<SbsDeleteRequest>) {
  const request = action.payload
  try {
    yield call(ApiServiceInstance.del, `fulfillment/sbs-bulk/${request.vendorId}/${request.orderCode}`, request)
    yield put(orderActions.requestOrderDetails(action.payload.orderCode))
  } catch (e) {
    yield put(Errors.toErrorAction(e, 'Cannot delete SBS'))
  }
}

export default function* root() {
  yield all([yield takeEvery(actions.submitSbs, postSbsDetails), yield takeEvery(actions.removeSbs, deleteSbsDetails)])
}
