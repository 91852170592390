import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkCommentRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { CommentType, NoteBulkRequestBase } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import { BulkNoteForm } from '../forms/BulkNotesForm'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  identifiers: string
}

class BulkCommentPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkComment = this.sendBulkComment.bind(this)
  }

  sendBulkComment(x: NoteBulkRequestBase) {
    const identifiersList = x.identifiers
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array
    this.props.dispatch(
      bulkCommentRequest({
        type: CommentType.OrderNote,
        message: x.message,
        identifiers: identifiersList,
      })
    )
  }

  renderForm() {
    return <BulkNoteForm form={`test_notes`} onSubmit={this.sendBulkComment}></BulkNoteForm>
  }

  renderResponse() {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Operation Result</h1>
            {this.props.bulkNoteResponse.response.some((x) => x.statusCode !== 200)
              ? 'There were some errors updating notes, please check report below'
              : 'All notes were successfully updated'}
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        {this.props.bulkNoteResponse.response.map((x, index) => (
          <div key={index} className="response-container">
            <Row key={'state_' + index}>
              <Col md="2" className="column-highlight">
                State:{' '}
              </Col>
              <Col md="10" className={x.statusCode === 200 ? 'status-success' : 'status-error'}>
                {x.statusCode === 200 ? 'Success' : 'Error'}
              </Col>
            </Row>
            {x.errorMessage && (
              <Row key={'error_' + index}>
                <Col md="2" className="column-highlight">
                  Error Message:{' '}
                </Col>
                <Col md="10">{x.errorMessage}</Col>
              </Row>
            )}
            <Row key={'products_' + index}>
              <Col md="2" className="column-highlight">
                Identifiers:{' '}
              </Col>
              <Col md="10">{x.identifiers.replaceAll(',', ', ')}</Col>
            </Row>
          </div>
        ))}
      </Container>
    )
  }

  render() {
    return this.props.bulkNoteResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkCommentPanel
