import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/redoActions'
import * as orderActions from '../actions/orderDetailsActions'
import { GetDeliveryOptionsRequest, GetPickupPointsRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'
import { RedoProductionOrdersRequest } from '../api/productionOrder'
import * as global from '../actions/globalActions'

function* getDeliveryOptions(action: PayloadAction<GetDeliveryOptionsRequest>): any {
  const request = action.payload
  try {
    const response = yield call(ApiServiceInstance.post, `deliveryOptions/${request.orderCode}`, request)
    yield put(actions.receivedDeliveryOptions(response.data))
  } catch (e: any) {
    yield put(actions.redoFailure('Cannot retrieve delivery options'))
  }
}

function* getPickupPoints(action: PayloadAction<GetPickupPointsRequest>): any {
  const request = action.payload
  try {
    const response = yield call(ApiServiceInstance.post, `deliveryOptions/${request.networkId}/pickuppoints`, action.payload)
    yield put(actions.receivedPickupPoints(response.data))
  } catch (e: any) {
    yield put(actions.redoFailure('Cannot retrieve pickup points'))
  }
}

function* submitProductionOrdersRedo(action: PayloadAction<RedoProductionOrdersRequest>): any {
  const request = action.payload
  try {
    yield put(global.showSpinner())
    //TODO: show something when 200
    const response = yield call(ApiServiceInstance.post, `productionOrder/redo/${request.orderCode}`, action.payload)
    if (response.status === 200) {
      yield put(
        global.showInfo(
          'Redo successfully sent, order may take a couple of seconds to appear in the screen. Please try refreshing the browser'
        )
      )
    }
    yield put(orderActions.requestOrderDetails(action.payload.orderCode))
  } catch (e: any) {
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(actions.requestDeliveryOptions, getDeliveryOptions)
  yield takeLatest(actions.requestPickupPoints, getPickupPoints)
  yield takeLatest(actions.submitProductionOrderRedo, submitProductionOrdersRedo)
}
