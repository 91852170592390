import * as React from 'react'
import { Link } from 'react-router-dom'

export interface ActionLinkSettings extends React.HTMLProps<any> {
  id?: string
  text?: string
  route?: string
}

export default function ActionLink(props: ActionLinkSettings) {
  if (props.href) {
    return (
      <a {...props} target="_blank" rel="noopener noreferrer" className={`${props.className || ''} external`}>
        {props.text}
      </a>
    )
  }

  if (props.route) {
    const linkProps = { ...props, ref: undefined }
    return (
      <Link {...linkProps} to={props.route}>
        {props.text}
      </Link>
    )
  }

  return (
    // eslint-disable-next-line
    <a
      {...props}
      href="#"
      onClick={(e) => {
        e.preventDefault()
        props.onClick && props.onClick(e)
      }}>
      {props.text}
    </a>
  )
}
