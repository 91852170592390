import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkCancelRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkCancelBase } from '../../api/cancel'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkCancelForm from '../forms/BulkCancelForm'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
}

class BulkCancelPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkCancel = this.sendBulkCancel.bind(this)
  }

  sendBulkCancel(x: BulkCancelBase) {
    const productionOrderIdList = x.productionOrderIds
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkCancelRequest({
        productionOrderIds: productionOrderIdList
      })
    )
  }

  renderForm() {
    return <BulkCancelForm input=
      {{
        productionOrderIds: ''
      }}
      onSubmit={this.sendBulkCancel}></BulkCancelForm>
  }

  renderResponse() {
    console.log('Bulk Cancel Response:', this.props.bulkCancelResponse);
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Cancel Result</h1>
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        <div className="response-container">
          {this.props.bulkCancelResponse.productionOrdersCancelSuccess && this.props.bulkCancelResponse.productionOrdersCancelSuccess.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-success status-heading'>
                  Cancel Successfully
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="10" className="column-highlight">
                  Production Order Ids
                </Col>
              </Row>
              {this.props.bulkCancelResponse.productionOrdersCancelSuccess.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="success-column">
                    {x.orderCode}
                  </Col>
                  <Col md="10" className="success-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelResponse.productionOrdersCancelFailed && this.props.bulkCancelResponse.productionOrdersCancelFailed.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Cancel Failed
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
                </Col>
                <Col md="5" className="column-highlight">
                  Error Messages
                </Col>
              </Row>
              {this.props.bulkCancelResponse.productionOrdersCancelFailed.map((cancelProductionOrdersFailed, cancelProductionOrdersFailedIndex) => (
                <Row key={cancelProductionOrdersFailedIndex}>
                  <Col md="12">
                    <Row className='response-row'>
                      <Col md="2" className="failed-column">
                        {cancelProductionOrdersFailed.orderCode}
                      </Col>
                      <Col md="10">
                        {cancelProductionOrdersFailed.cancelProductionOrderFailedList.map((cancelProductionOrderFailed, cancelProductionOrderFailedIndex) => (
                          <>
                            <Row key={cancelProductionOrderFailedIndex}>
                              <Col md="6" className="failed-column">
                                {cancelProductionOrderFailed.productionOrderId}
                              </Col>
                              <Col md="6" className="failed-column">
                                {cancelProductionOrderFailed.errorMessage}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelResponse.productionOrdersNotFound && this.props.bulkCancelResponse.productionOrdersNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Production Orders Not Found
                </Col>
              </Row>
              <Row>
                <Col md="12" className="column-highlight">
                  Production Orders
                </Col>
              </Row>
              {this.props.bulkCancelResponse.productionOrdersNotFound.map((x, index) => (
                <Row key={index}>
                  <Col md="12" className="notfound-column">
                    {x}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelResponse.productionOrdersCancelSkipped && this.props.bulkCancelResponse.productionOrdersCancelSkipped.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Non-Cancellable Production Orders
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
                </Col>
                <Col md="5" className="column-highlight">
                  Reasons
                </Col>
              </Row>
              {this.props.bulkCancelResponse.productionOrdersCancelSkipped.map((cancelProductionOrdersSkipped, cancelProductionOrdersSkippedIndex) => (
                <Row key={cancelProductionOrdersSkippedIndex}>
                  <Col md="12">
                    <Row className='response-row'>
                      <Col md="2" className="skipped-column">
                        {cancelProductionOrdersSkipped.orderCode}
                      </Col>
                      <Col md="10">
                        {cancelProductionOrdersSkipped.cancelProductionOrderSkippedList.map((cancelProductionOrderSkipped, cancelProductionOrderSkippedIndex) => (
                          <>
                            <Row key={cancelProductionOrderSkippedIndex}>
                              <Col md="6" className="skipped-column">
                                {cancelProductionOrderSkipped.productionOrderId}
                              </Col>
                              <Col md="6" className="skipped-column">
                                {cancelProductionOrderSkipped.reason}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </Container>
    )
  }

  render() {
    return this.props.bulkCancelResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkCancelPanel
