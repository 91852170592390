import React from 'react'
import { TooltipKey } from '../../models/globalPanels'
import ActionLink from '../generic/ActionLink'

export interface PanelTooltipProps {
  tooltipKey: TooltipKey
}

export default function PanelTooltip({ tooltipKey }: PanelTooltipProps): JSX.Element | null {
  switch (tooltipKey) {
    case 'orderReset':
      return RenderReset()

    case 'orderCancel':
      return RenderCancel()

    case 'showByScan':
      return RenderSbs()

    case 'reupload':
      return RenderReupload()

    //TODO: anything for redo???

    default:
      return null
  }
}

function RenderReset(): JSX.Element {
  return (
    <div>
      Before resetting the order, first check the following:
      <ul>
        <li>Is the order still in production?</li>
        <li>Is it within 14 days of receipt?</li>
      </ul>
      If so, please do not reset. <ActionLink text="Read more..." href="https://wiki.albelli.net/wiki/Reset_procedure" />
    </div>
  )
}

function RenderCancel(): JSX.Element {
  return (
    <div>
      Before cancelling the order and requesting a refund, please first check the following:
      <ul>
        <li>Is the order still in production?</li>
        <li>Is the order payed for?</li>
      </ul>
      If not, please do not cancel the order.
    </div>
  )
}

function RenderSbs(): JSX.Element {
  return (
    <div>
      The SBS is used when production needs to perform an action before the order is shipped.{' '}
      <ActionLink text="Read more..." href="https://wiki.albelli.net/wiki/CC_Show_By_Scan_(SBS)" />
    </div>
  )
}

function RenderReupload(): JSX.Element {
  return (
    <div>
      Before reuploading the order, first check the following:
      <ul>
        <li>Is the status still on NEW?</li>
        <li>Is the product already shipped?</li>
      </ul>
      Only in these cases we can offer a Reupload. Otherwise, please do not reupload.{' '}
      <ActionLink text="Read more..." href="https://wiki.albelli.net/wiki/CC_ReUpload_procedure" />
    </div>
  )
}
