import React from 'react'
import RoundIcon from './RoundIcon'

interface Props extends React.Props<any> {
  elementId: string
  displayName: string
  plant: string
  isSmall?: boolean
}

export default function PlantIcon({ displayName, plant, elementId, isSmall }: Props) {
  return <RoundIcon text={plant} title={displayName} id={elementId} className={extraClass(plant, isSmall)} />
}

const extraClass = (plant: string, isSmall?: boolean) => {
  if (isSmall === true) {
    return plant.length > 3 ? 'small-6' : 'small'
  }
  return plant.length > 3 ? 'size-6' : ''
}
