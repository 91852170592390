import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/customerDetailsActions'
import { CustomerDetailsViewStore } from './states'

const initialState: CustomerDetailsViewStore = {
  historyFolded: false,
  foldedOrders: [],
}

export const customerDetailsView = createReducer<CustomerDetailsViewStore>(initialState, {
  [actions.toggleAllDetails.type]: (state) => {
    state.historyFolded = !state.historyFolded
    state.foldedOrders.forEach((_, rowIndex) => (state.foldedOrders[rowIndex] = state.historyFolded))
  },
  [actions.toggleOrderDetails.type]: (state, action: PayloadAction<number>) => {
    const rowIndex = action.payload
    state.foldedOrders[rowIndex] =
      state.foldedOrders[rowIndex] !== undefined ? !state.foldedOrders[rowIndex] : !state.historyFolded
  },
})
