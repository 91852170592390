import React from 'react'
import BrokenIcon from '../../assets/img/flags/order-promise-broken.svg'
import PromisedIcon from '../../assets/img/flags/order-promised.svg'
import { OrderCampaignInfo } from '../../services/OrderDetailsService'

interface Props extends React.HTMLProps<any> {
  productId?: string
  campaign?: OrderCampaignInfo
}

export default function CampaignIcon({ campaign, productId, className }: Props) {
  const elementId = `promised_tooltip_${productId}`
  const campaignName = campaign?.campaignName || 'N/A'
  const tooltip = campaign?.isBrokenPromise ? `Not in time for ${campaignName}` : `Promised for ${campaignName}`
  const icon = campaign?.isBrokenPromise ? BrokenIcon : PromisedIcon
  const size = 32

  return <img src={icon} height={size} width={size} title={tooltip} id={elementId} alt={tooltip} className={className} />
}
