import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as comments from '../actions/commentActions'
import * as actions from '../actions/mixupDetailsActions'
import { isCommentRelatedToMixUp, MixupDetails, NoteRequest, NoteResponse } from '../api'
import { GlobalPanels } from '../models'
import { MixupDetailsViewStore } from './states'

const initialState: MixupDetailsViewStore = {
  id: '',
  panels: {
    mixupNotes: { ...GlobalPanels.mixupNotes },
  },
}

export const mixupDetailsView = createReducer<MixupDetailsViewStore>(initialState, {
  [actions.reset.type]: () => initialState,
  [actions.receivedMixup.type]: (state, action: PayloadAction<MixupDetails>) => {
    state.id = action.payload.form.id
  },
  [comments.sendComment.type]: (state, action: PayloadAction<NoteRequest>) => {
    if (isCommentRelatedToMixUp(state.id, action)) {
      state.panels.mixupNotes.isLoading = true
    }
  },
  [comments.sentComment.type]: (state, action: PayloadAction<NoteResponse>) => {
    if (isCommentRelatedToMixUp(state.id, action)) {
      state.panels.mixupNotes.isLoading = false
    }
  },
  [actions.togglePanel.type]: (state, action: PayloadAction<string>) => {
    const panel = state.panels[action.payload]
    if (panel) {
      panel.isOpen = !panel.isOpen
    }
  },
})
