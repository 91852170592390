import * as React from 'react'
import { Table } from 'reactstrap'
import closed from '../../assets/img/closed.svg'
import open from '../../assets/img/open.svg'
import { selectOnClick } from '../../utils'
import './Grid.scss'

export interface GridSettings<TItem = any> {
  gridId?: string
  data?: TItem[]
  columns: GridColumn<TItem>[]
  className?: string
  autoScroll?: boolean
  extraDetails?: ((item: TItem, columns: GridColumn<TItem>[]) => React.ReactNode) | false
  rawExtraDetails?: boolean
  foldAll?: boolean
  foldedRows?: (boolean | undefined)[]
  onToggleRow?(index: number): void
  disableCopyOnClick?: boolean
  rowKey?: (item: TItem) => string | number
  showFolderToggleIcon? : boolean
}

export interface GridColumn<TItem = any> {
  title: React.ReactNode
  value?(item: TItem, index: number): React.ReactNode
  thStyle?: React.CSSProperties
  tdClass?: string
  key?: string
}

export default class Grid<TItem = any> extends React.Component<GridSettings<TItem>> {
  private endElement: HTMLTableSectionElement | null = null

  componentDidMount() {
    this.scrollToEnd()
  }

  componentDidUpdate() {
    this.scrollToEnd()
  }

  render() {
    const { gridId, data, columns, className, foldedRows, extraDetails, rowKey } = this.props
    const colSpan = columns.length + (foldedRows && extraDetails ? 1 : 0)
    const showFolderToggleIcon = this.props.showFolderToggleIcon == true

    return (
      <div id={gridId} className={`bo-grid bo-container ${className || ''} ${foldedRows ? 'foldable' : ''}`}>
        <Table responsive>
          <thead>
            <tr>
              {foldedRows && extraDetails && showFolderToggleIcon && <th />}
              {columns.map((item, i) => (
                <th key={item.key || i} style={item.thStyle}>
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((rowItem, index) => (
                <React.Fragment key={rowKey ? rowKey(rowItem) : index}>
                  <tr>
                    {foldedRows && extraDetails && showFolderToggleIcon && (
                      <td className="toggle-details" onClick={() => this.toggleRow(index)}>
                        <img src={this.isFolded(index) ? closed : open} width="20" height="20" alt="folded" />
                      </td>
                    )}
                    {columns.map((column, i) => (
                      <td
                        key={column.key || i}
                        onClick={this.props.disableCopyOnClick === true ? () => {} : selectOnClick}
                        className={column.tdClass}>
                        {column.value && column.value(rowItem, i)}
                      </td>
                    ))}
                  </tr>
                  {!this.isFolded(index) && this.renderExtraDetails(rowItem, colSpan)}
                </React.Fragment>
              ))
            ) : (
              <tr>
                {foldedRows && showFolderToggleIcon && <td />}
                <td colSpan={columns.length}>
                  <i>Data unavailable</i>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot ref={(el) => (this.endElement = el)} />
        </Table>
      </div>
    )
  }

  private isFolded(index: number) {
    if (this.props.foldedRows && this.props.foldedRows[index] !== undefined) {
      return !!this.props.foldedRows[index]
    }

    return !!this.props.foldAll
  }

  private toggleRow(index: number) {
    this.props.onToggleRow && this.props.onToggleRow(index)
  }

  private renderExtraDetails(item: TItem, colSpan: number) {
    const element = this.props.extraDetails && this.props.extraDetails(item, this.props.columns)

    if (!element) {
      return null
    }

    if (this.props.rawExtraDetails) {
      return element
    }

    return (
      <tr className="extra-details">
        <td colSpan={colSpan}>{element}</td>
      </tr>
    )
  }

  private scrollToEnd() {
    if (this.props.autoScroll && this.endElement) {
      this.endElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
