import React from 'react'
import { Alert } from 'reactstrap'
import { FulfillmentProduct, Product, ProductLine } from '../../api'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import ActionLink from '../generic/ActionLink'
import Badge from '../generic/Badge'
import SidePanel, { SidePanelSettings } from './SidePanel'

export interface OrderLinesPanelProps extends SidePanelSettings {
  product: Product
  ffProduct?: FulfillmentProduct
  ffUiUrl: string
  vendor: string
}

export default class OrderLinesPanel extends React.Component<OrderLinesPanelProps> {
  render() {
    const lines = this.props.product.lines
    return (
      <SidePanel {...this.props}>
        <div>
          {!this.props.ffProduct && (
            <Alert color="warning">The links are not available since the product hasn't reached fulfillment/production.</Alert>
          )}
          <h3>
            {Service.resolveProductDescriptionByType(this.props.product.productId)} [{this.props.product.productId}]
          </h3>
          <table className="bo-grid-vertical">
            <tbody>
              {lines.map(o => (
                <tr key={o.id}>
                  <td style={{ verticalAlign: 'middle' }} className="pr-2">
                    {this.props.ffProduct ? (
                      <ActionLink
                        text={o.id}
                        href={`${this.props.ffUiUrl}/FulfillmentItem/Details/${o.id}?vendorCode=${this.props.vendor}`}
                      />
                    ) : (
                      o.id
                    )}
                  </td>
                  <td>{this.renderOrderLineStatus(o)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidePanel>
    )
  }

  renderOrderLineStatus(o: ProductLine): React.ReactNode {
    const badgeSettings = Service.fromOrderLineStatusToBadgeSettings(
      this.props.product.id,
      o.status.toString(),
      this.props.product.productId
    )

    return <Badge setting={badgeSettings} />
  }
}
