import _ from 'lodash'
import React from 'react'
import { Form, FormGroup } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps } from 'redux-form'
import { InternalOperationRequest, ProductReuploadReasons } from '../../api'
import { validators } from '../../utils'
import ReduxSelect, { SelectOption } from './ReduxSelect'
import Textarea from './Textarea'
import { CustomComponent } from './ValidationWrapper'

const allOptions: SelectOption[] = _.keys(ProductReuploadReasons)
  .map((x) => Number(x))
  .map((key) => ({
    key: key,
    value: ProductReuploadReasons[key],
  }))

const ReuploadReasonsOptions: SelectOption[] = _.sortBy(allOptions, [
  function (o) {
    return o.value
  },
])

const ReuploadCodesSelect = (props: WrappedFieldProps & CustomComponent) => {
  return <ReduxSelect options={ReuploadReasonsOptions} emptyText="-- select a code --" {...props} />
}

interface ReuploadFormProps {
  onSubmit(x: InternalOperationRequest): void
}

function ReuploadFormInternal({
  handleSubmit,
  onSubmit,
  submitting,
}: ReuploadFormProps & InjectedFormProps<InternalOperationRequest, ReuploadFormProps>) {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="reasonCode"
        component={ReuploadCodesSelect}
        label="Reupload code"
        validate={[validators.required]}
        disabled={false}
      />
      <Field name="comment" component={Textarea} label="Reupload reason" validate={[validators.required]} disabled={false} />
      <FormGroup>
        <button type="submit" disabled={submitting}>
          Save
        </button>
      </FormGroup>
    </Form>
  )
}

export const ReuploadForm = reduxForm<InternalOperationRequest, ReuploadFormProps>({ enableReinitialize: true })(
  ReuploadFormInternal
)
