import React from 'react'
import { Alert } from 'reactstrap'
import { AlertInfo } from '../../services/OrderManagementService'

interface Props {
  items?: AlertInfo[]
}

export default function Alerts(props: Props) {
  return (
    <React.Fragment>
      {props.items &&
        props.items.map((item, i) => (
          <Alert key={i} color={item.color} fade={false}>
            {item.content}
          </Alert>
        ))}
    </React.Fragment>
  )
}
