import React, { ReactNode } from 'react'
import { Col, Form, FormGroup, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps } from 'redux-form'
import { MixupBarcodeMatchStateNames, MixupRecord, MixupReturnStateNames, MixupTypeHints, MixupTypeNames, MixupCategoryNumber, MixupCategoryHints } from '../../api/mixup'
import routes from '../../route/routes'
import { nameof, validators } from '../../utils'
import ActionLink from '../generic/ActionLink'
import QuestionmarkWithTooltip from '../generic/QuestionmarkWithTooltip'
import CheckboxWithLabel from './CheckboxWithLabel'
import Input from './Input'
import ReduxSelect, { SelectOption } from './ReduxSelect'
import Textarea from './Textarea'
import { CustomComponent } from './ValidationWrapper'

const allTypes = Object.keys(MixupTypeNames).map((x) => Number(x))

const MixupTypesOptions: SelectOption[] = allTypes.map((key) => ({
  key: key,
  value: MixupTypeNames[key],
}))

const MixupTypesSelect = (props: WrappedFieldProps & CustomComponent) => {
  return <ReduxSelect options={MixupTypesOptions} emptyText=" " {...props} />
}

const allCategory = Object.keys(MixupCategoryNumber).map((x) => Number(x))

const MixupCategoryOptions: SelectOption[] = allCategory.map((key) => ({
  key: key,
  value: MixupCategoryNumber[key],
}))

const MixupCategorySelect = (props: WrappedFieldProps & CustomComponent) => {
  return <ReduxSelect options={MixupCategoryOptions} emptyText=" " {...props} />
}

const allStates = Object.keys(MixupReturnStateNames).map((x) => Number(x))

const MixupStatesOptions: SelectOption[] = allStates.map((key) => ({
  key: key,
  value: MixupReturnStateNames[key],
}))

const MixupStatesSelect = (props: WrappedFieldProps & CustomComponent) => {
  return <ReduxSelect options={MixupStatesOptions} {...props} />
}

const allBarcodes = Object.keys(MixupBarcodeMatchStateNames).map((x) => Number(x))

const MixupBarcodesOptions: SelectOption[] = allBarcodes.map((key) => ({
  key: key,
  value: MixupBarcodeMatchStateNames[key],
}))

const MixupBarcodesSelect = (props: WrappedFieldProps & CustomComponent) => {
  return <ReduxSelect options={MixupBarcodesOptions} {...props} />
}

interface MixupFormProps {
  onSubmit(values: MixupRecord): void
}

function MixupFormInternal({
  handleSubmit,
  onSubmit,
  submitting,
}: MixupFormProps & InjectedFormProps<MixupRecord, MixupFormProps>) {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderRow(
        nameof<MixupRecord>('orderRef'),
        'Order number',
        Input,
        true,
        'The order number of the customer who contacts us (customer A).',
        'MixupFormView_orderRef',
        'e.g. SAL123456789, BP987654321, 25671607-52719b2f3edd',
        (value: any) => value && <ActionLink text="Order details page" route={routes.ORDERS_VIEW({ orderId: value })} />
      )}
      {renderRow(
        nameof<MixupRecord>('ticketRef'),
        'Affected ticket(s) number',
        Input,
        false,
        'All T-numbers of products part of this mix-up (customer B), separated by a comma. F.e. Customer A has received the cover of T123456 and content of T654321, fill in “T123456, T654321”.',
        'MixupFormView_ticketRef',
        'T/Z number (e.g. Z21345EL, T38251627); Comma-separated list'
      )}
      {renderRow(
        nameof<MixupRecord>('type'),
        'Mix-up type',
        MixupTypesSelect,
        true,
        <div>
          {allTypes.map((key: number) => (
            <div key={key}>
              <strong>{MixupTypeNames[key]}</strong>
              <br />
              <span>{MixupTypeHints[key]}</span>
              <br />
            </div>
          ))}
        </div>,
        'MixupFormView_type',
        <ActionLink
          text="Read more..."
          href="https://customerservice.bonusprint.co.uk/hc/en-gb/articles/360009109599--Mix-up-procedure"
        />,
        (value: any) => MixupTypeHints[value]
      )}
      {renderRow(
        nameof<MixupRecord>('category'),
        'Risk Category',
        MixupCategorySelect,
        true,
        <div>
          For each mix up we have defined <strong>categories</strong>:
          <ol>
            <li>No significant risk</li>
            <li>Possible/some risk</li>
            <li>High risk</li>
          </ol>

          Each mix up must be <strong>weighed against the possible risk</strong> it causes:
          <ul>
            <li>Risk of personal data breach</li>
            <li>Risk of identification</li>
            <li>Seriousness of the consequences of recognition</li>
            <li>Consequences due to political beliefs, descent (ethnicity), religion, philosophy, trade agreements, genetic data, health, sexual orientation, crime or otherwise</li>
          </ul>

          <strong>A combination of the mentioned risks, increases the category.</strong>
        </div>,
        'MixupFormView_category',
        '',
        (value: any) => MixupCategoryHints[value]
      )}
      {renderRow(
        nameof<MixupRecord>('returnState'),
        'Return state',
        MixupStatesSelect,
        true,
        'As a CC agent, you do not have to fill this in.',
        'MixupFormView_returnState'
      )}
      {renderRow(
        nameof<MixupRecord>('barcodeMatchState'),
        'Barcode match state',
        MixupBarcodesSelect,
        true,
        'As a CC agent, you do not have to fill this in.',
        'MixupFormView_barcodeMatchState'
      )}
      {renderRow(nameof<MixupRecord>('pdfChecked'), 'File Checked', CheckboxWithLabel, false)}
      {renderRow(nameof<MixupRecord>('description'), 'Description', Textarea, false)}
      <FormGroup className="mt-3">
        <button type="submit" disabled={submitting}>
          Submit
        </button>
        <ActionLink className="ml-3" text="Cancel" route={routes.MIXUPS()} />
      </FormGroup>
    </Form>
  )
}

function renderRow(
  name: keyof MixupRecord,
  label: string,
  component: any,
  isRequired: boolean,
  tooltip?: ReactNode | string,
  tooltipId?: string,
  tipText?: ReactNode | string,
  tipTextProcessor?: (value: any) => ReactNode | string
) {
  const validatorsArray = []
  if (isRequired) {
    validatorsArray.push(validators.required)
  }

  return (
    <Row>
      <Col sm="11" className="pr-0">
        <Field
          name={name}
          component={component}
          label={label}
          validate={validatorsArray}
          tooltip={tipText}
          tooltipProcessor={tipTextProcessor}
        />
      </Col>
      <Col sm="1" className="pl-0" style={{ paddingTop: '32px' }}>
        {tooltipId && tooltip && (
          <QuestionmarkWithTooltip tooltipId={tooltipId} placement="right">
            {tooltip}
          </QuestionmarkWithTooltip>
        )}
      </Col>
    </Row>
  )
}

export const MixupForm = reduxForm<MixupRecord, MixupFormProps>({ enableReinitialize: true })(MixupFormInternal)
