import React from 'react'
import { BadgeSetting } from '../../models'

export interface BadgeProps extends React.HTMLAttributes<any> {
  setting: BadgeSetting
}

export default function Badge({ setting, className }: BadgeProps) {
  return (
    <div className={`badge badge-pill d-flex align-items-center ${setting.color} ${className} ${setting.qty && 'with-quantity'}`}>
      <div className="text">{setting.title}</div>
      {setting.qty && <div className={`quantity badge-pill ${setting.color}`}>{setting.qty}</div>}
    </div>
  )
}
