import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { ReactNode } from 'react'
import { GlobalConfiguration, LogMessage } from '../api'

export const newVersionAvailable = createAction('GLOBAL_NEW_VERSION_RELEASED')

export const appInit = createAction('GLOBAL_APP_INIT')

export const showWarning = createAction<string>('GLOBAL_SHOW_WARNING')

export const hideWarning = createAction('GLOBAL_HIDE_WARNING')

export const showError = createAction<ErrorPayload>('GLOBAL_SHOW_ERROR')

export const hideError = createAction('GLOBAL_HIDE_ERROR')

export const showInfo = createAction<string>('GLOBAL_SHOW_INFO')

export const hideInfo = createAction('GLOBAL_HIDE_INFO')

export const receivedConfiguration = createAction<GlobalConfiguration>('GLOBAL_CONFIGURATION_RECEIVED')

export const logError = createAction<LogMessage>('GLOBAL_LOG_ERROR')

export const logWarn = createAction<LogMessage>('GLOBAL_LOG_WARN')

export const logInfo = createAction<LogMessage>('GLOBAL_LOG_INFO')

export const showSpinner = createAction('GLOBAL_SHOW_SPINNER')

// Dirty trick we use to scroll page up
export const showSpinnerForMoment = createAction('GLOBAL_SHOW_SPINNER_200_MS')

export const hideSpinner = createAction('GLOBAL_HIDE_SPINNER')

export interface ErrorPayload {
  httpResponse?: AxiosResponse<any>
  defaultMessage: ReactNode
}
