import { Dictionary } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as orderActions from '../actions/orderDetailsActions'
import * as actions from '../actions/searchActions'
import { CustomerSearchSortingType, OrderListItemModel, SearchFilter, SearchTypes, SearchTypesDisplayNames } from '../api'
import FilterBar, { FilterBarItem, FilterBarValue } from '../components/forms/FilterBar'
import ActionLink from '../components/generic/ActionLink'
import LazyContainer from '../components/generic/LazyContainer'
import CustomerList from '../components/lists/CustomerList'
import CustomerSearchResults from '../components/lists/CustomerSearchResults'
import OrderList from '../components/lists/OrderList'
import OrderSearchResults from '../components/lists/OrderSearchResults'
import { CommonProps } from '../models'
import { AppStore, GlobalStore, SearchStore } from '../reducers/states'

interface Props extends CommonProps, SearchStore, GlobalStore {
  orders: Dictionary<OrderListItemModel>
}

class SearchView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.handleChangeFilterType = this.handleChangeFilterType.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loadData = this.loadData.bind(this)
    this.requestOrderDetails = this.requestOrderDetails.bind(this)
    this.toggleAllDetails = this.toggleAllDetails.bind(this)
    this.toggleOrderDetails = this.toggleOrderDetails.bind(this)
  }

  handleChangeFilterType(value: FilterBarValue) {
    const filter = this.toFilter(value)
    this.props.dispatch(actions.reset(filter))
  }

  handleSubmit(value: FilterBarValue) {
    const filter = this.toFilter(value)
    this.props.dispatch(actions.reset(filter))
    this.props.dispatch(actions.search(filter))
  }

  loadData() {
    this.props.dispatch(actions.search(this.props.filter))
  }

  requestOrderDetails(id: string) {
    this.props.dispatch(orderActions.requestOrderHistoryDetails(id))
  }

  toggleAllDetails() {
    this.props.dispatch(actions.toggleAllDetails())
  }

  toggleOrderDetails(index: number) {
    this.props.dispatch(actions.toggleOrderDetails(index))
  }

  toFilter(value: FilterBarValue): SearchFilter {
    return {
      type: value.id,
      query: value.query || '',
    }
  }

  renderFilter() {
    const types = Object.keys(SearchTypesDisplayNames)
      .map((x) => Number(x))
      .filter(
        (x) =>
          x !== SearchTypes.ByProductContent ||
          (this.props.configuration.permissions && this.props.configuration.permissions.isExpert)
      )
      .map(
        (key) =>
          ({
            id: key,
            navLinkId: `search-filter-option-${key}`,
            title: SearchTypesDisplayNames[key],
          } as FilterBarItem)
      )

    return (
      <FilterBar
        inputId="main-search-input"
        formId="main-search-form"
        initialValue={{ id: this.props.filter.type, query: this.props.filter.query }}
        items={types}
        onSelect={this.handleChangeFilterType}
        onSubmit={this.handleSubmit}
      />
    )
  }

  renderResults() {
    const isLoading = this.props.isAlbelliLoading

    // move me to model or service
    const loadMore =
      this.props.isDirty &&
      this.props.prevAlbelliResponse.hasMore &&
      this.props.prevAlbelliResponse.items &&
      this.props.prevAlbelliResponse.items.length > 0

    return (
      <React.Fragment>
        <LazyContainer isLoading={isLoading} className={isLoading ? 'mt-5' : undefined}>
          {this.renderList()}
          {loadMore && (
            <button className="mt-4 wide-button" onClick={this.loadData} disabled={isLoading}>
              Load more
            </button>
          )}
        </LazyContainer>
      </React.Fragment>
    )
  }

  renderList() {
    if (!this.props.isDirty) {
      return
    }

    switch (this.props.filter.type) {
      case SearchTypes.ByOrderCode:
      case SearchTypes.ByTicketId:
      case SearchTypes.ByTrackingCode:
      case SearchTypes.ByAddress:
      case SearchTypes.ByProductContent:
        return (
          <React.Fragment>
            <div className="d-flex justify-content-between mt-3">
              <h3>Orders</h3>
              <ActionLink
                text={this.props.historyFolded ? 'Show all product details' : 'Close all product details'}
                onClick={this.toggleAllDetails}
              />
            </div>
            <OrderList
              albelliOrders={this.props.prevAlbelliResponse.items}
              orders={this.props.orders}
              filter={this.props.filter}
              enableExtraDetails={true}
              foldAll={this.props.historyFolded}
              foldedRows={this.props.foldedOrders}
              onToggleRow={this.toggleOrderDetails}
              onOrderVisible={this.requestOrderDetails}
              showCustomerName={true}
              showPrice={false}
            />
          </React.Fragment>
        )

      case SearchTypes.ByCustomerEmail:
        return (
          <CustomerList albelliCustomers={this.props.prevAlbelliResponse.items} filter={{ byEmail: this.props.filter.query }} />
        )

      case SearchTypes.ByCustomerPhone:
        return (
          <CustomerList albelliCustomers={this.props.prevAlbelliResponse.items} filter={{ byPhone: this.props.filter.query }} />
        )

      case SearchTypes.ByCustomerName:
        return (
          <CustomerList
            albelliCustomers={this.props.prevAlbelliResponse.items}
            filter={{ byName: this.props.filter.query }}
            orderBy={CustomerSearchSortingType.ByScore}
          />
        )

      case SearchTypes.JustSearch:
        return (this.props.orderSearchResults && this.props.orderSearchResults.length > 0) ||
          (this.props.customerSearchResults && this.props.customerSearchResults.length > 0) 
          ? this.renderJustSearchResults() 
          : (
            <b>Your search did not match any order or customer.</b>
          )

      default:
        throw new Error('Search type not found for rendering!')
    }
  }

  renderJustSearchResults() {
    return (
      <React.Fragment>
        <OrderSearchResults orders={this.props.orderSearchResults} />
        <CustomerSearchResults customers={this.props.customerSearchResults} highlight={this.props.filter.query} />
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="search-container">
        <div className="panel">{this.renderFilter()}</div>
        {this.renderResults()}
      </div>
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.search,
    ...state.global,
    orders: state.orderDetails.orders,
  }
}

export default connect(mapStateToProps)(SearchView)
