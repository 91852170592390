import React from 'react'
import { connect } from 'react-redux'
import { Alert, ListGroup, ListGroupItem } from 'reactstrap'
import * as projectActions from '../actions/projectActions'
import { Project, SearchProjectBy } from '../api'
import FilterBar from '../components/forms/FilterBar'
import ActionLink from '../components/generic/ActionLink'
import Grid, { GridColumn } from '../components/generic/Grid'
import LazyContainer from '../components/generic/LazyContainer'
import { CommonProps } from '../models'
import { AppStore, ProjectsSearchStore } from '../reducers/states'
import { toDateTimeNew } from '../utils'

interface Props extends CommonProps, ProjectsSearchStore { }

const ProjectsSearchResultView = (props: { items: Project[]; onDownload: (id: string) => void }) => {
  const columns: GridColumn<Project>[] = [
    {
      title: 'ID',
      value: (project) => project.id,
    },
    {
      title: 'Title',
      value: (project) => project.title,
    },
    {
      title: 'Editor',
      value: (project) => project.userAgent,
    },
    {
      title: 'Last Changed At',
      value: (project) => toDateTimeNew(new Date(project.modificationDate)),
    },
    {
      title: 'Created At',
      value: (project) => toDateTimeNew(new Date(project.creationDate)),
    },
    {
      title: 'Customer Account',
      value: (project) => project.accountId,
    },
    {
      title: 'ALBX',
      value: (project) => {
        return <ActionLink text="download" onClick={() => props.onDownload(project.id)} />
      },
    },
  ]
  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem>
          <Grid gridId={'project-list'} columns={columns} data={props.items} />
        </ListGroupItem>
      </ListGroup>
    </React.Fragment>
  )
}

class SearchProjectView extends React.Component<Props> {
  render() {
    return (
      <div className="search-container">
        <div className="panel">
          <FilterBar
            inputId="main-search-input"
            formId="main-search-form"
            initialValue={{
              id: this.props.filter.type,
              query: this.props.filter.query,
            }}
            items={[
              { id: SearchProjectBy.User, navLinkId: 'projects-search-by-user', title: 'Email' },
            ]}
            onSubmit={(value) => {
              if (value && value.query) {
                this.props.dispatch(projectActions.search({ type: value.id, query: value.query.trim() }))
              }
            }}
          />
        </div>
        <LazyContainer isLoading={this.props.isLoading}>
          {this.props.error && (
            <Alert color="danger" className="w-100 mb-0">
              {this.props.error}
            </Alert>
          )}
          {this.props.items && (
            <ProjectsSearchResultView
              items={this.props.items}
              onDownload={(id) => this.props.dispatch(projectActions.downloadProjectFile(id))}
            />
          )}
        </LazyContainer>
      </div>
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.projects,
  } as Props
}
export default connect(mapStateToProps)(SearchProjectView)
