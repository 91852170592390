import React from 'react'
import { connect } from 'react-redux'
import NavLink from 'reactstrap/lib/NavLink'
import FilterBar from '../components/forms/FilterBar'
import { DiscountView } from '../components/generic/DiscountView'
import LazyContainer from '../components/generic/LazyContainer'
import { CommonProps } from '../models'
import { AppStore, GlobalStore } from '../reducers/states'
import { DiscountsState, fetchDiscountInfo } from '../slices/discounts'

interface Props extends CommonProps, DiscountsState, GlobalStore {}

const byDiscountCode = 0

class SearchDiscountView extends React.Component<Props> {
  render() {
    const createDiscountUrl = this.props.configuration?.storefrontAdminPanelUrl
      ? new URL('/discounts/new', this.props.configuration.storefrontAdminPanelUrl).toString()
      : ''
    return (
      <div className="search-container">
        <div className="panel">
          <FilterBar
            inputId="main-search-input"
            formId="main-search-form"
            initialValue={{ id: byDiscountCode, query: this.props.query }}
            items={[{ id: byDiscountCode, navLinkId: 'discounts-search-by-code', title: 'Discount Code' }]}
            onSubmit={(value) => {
              const queryRequest = (value.query || '').trim()
              if (queryRequest) {
                this.props.dispatch(fetchDiscountInfo(queryRequest))
              }
            }}
          />
          <div className="buttons">
            <NavLink href={createDiscountUrl} target="_blank">
              + Create new discount code
            </NavLink>
          </div>
        </div>
        <LazyContainer isLoading={this.props.isLoading} className={this.props.isLoading ? 'p-3' : 'p-0'}>
          <DiscountView
            data={this.props.discount}
            discountCode={this.props.query}
            error={this.props.discountLookupError}
            allowCodeRegeneration={true}
          />
        </LazyContainer>
      </div>
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.global,
    ...state.discounts,
  }
}
export default connect(mapStateToProps)(SearchDiscountView)
