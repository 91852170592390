import React, { FormEvent } from 'react'
import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap'
import { BulkReprocessBase, InputTypes } from '../../api/reprocess'
import { validators } from '../../utils'
import Select from './Select'

import './BulkReprocessForm.scss'

interface State {
  data: BulkReprocessBase
  disabled: boolean,
  hasProductNumberError: boolean,
  hasReasonCodeError: boolean;
  hasMessageError: boolean;
}

interface BulkReprocessFormProps {
  input: BulkReprocessBase
  onSubmit(form: BulkReprocessBase): void
}

export default class BulkReprocessForm extends React.Component<BulkReprocessFormProps, State> {
  constructor(props: BulkReprocessFormProps) {
    super(props)

    this.state = {
      data: {
        inputType: props.input.inputType,
        productCodes: props.input.productCodes,
        message: props.input.message,
        resetReasons: props.input.resetReasons,
        reasonCode: props.input.reasonCode
      },
      disabled: false,
      hasProductNumberError: false,
      hasReasonCodeError: false,
      hasMessageError: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGenericChange = this.handleGenericChange.bind(this)
    this.handleInputToggle = this.handleInputToggle.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
  }

  handleReasonChange(event: any) {
    const target = event.target
    const code = target.value
    const [match] = this.props.input.resetReasons.filter((r) => r.errorID === code)

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        reasonCode: match.errorID
      },
      hasReasonCodeError: this.isEmpty(code)
    } as State)
  }

  handleSubmit(event: FormEvent) {
    const hasMessageErrors = this.isEmpty(this.state.data.message)
    const hasReasonCodeError = this.isEmpty(this.state.data.reasonCode)
    const hasIdentifierErrors = this.isEmpty(this.state.data.productCodes)

    this.setState({
      ...this.state,
      hasProductNumberError: hasIdentifierErrors,
      hasReasonCodeError: hasReasonCodeError,
      hasMessageError: hasMessageErrors
    } as State)

    if (hasIdentifierErrors || hasReasonCodeError || hasMessageErrors) {
      event.preventDefault()
      return;
    }
    else {
      this.setState({ disabled: true })
      this.props.onSubmit(this.state.data)
      this.setState({ disabled: false })
    }
  }

  handleInputToggle(event: any) {
    var value = event.target.name === "productNumbers" ? InputTypes.ProductNumbers : InputTypes.Orderlines

    this.setState({
      data: {
        ...this.state.data,
        inputType: value
      },
    } as State)
  }

  handleGenericChange(event: any) {
    const target = event.target
    const value = target.value
    const name = target.name

    const errorProperty = target.name === 'productCodes' ? 'hasProductNumberError' : 'hasMessageError'

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: value
      },
      [errorProperty]: this.isEmpty(value)
    } as State)
  }

  handleBlur(event: any) {
    const target = event.target
    const value = target.value

    const errorProperty = target.name === 'productCodes' ? 'hasProductNumberError' : 'hasMessageError'

    this.setState({
      ...this.state,
      [errorProperty]: this.isEmpty(value)
    } as State)
  }


  isEmpty(data: any) {
    return (data === null || data === undefined || data === '')
  }

  render() {
    const shouldBeChecked = this.state.data.inputType === InputTypes.ProductNumbers

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <label className="field-help-text">The Bulk Reprocess operation supports either Product Numbers or Order Line Ids as input</label>
          <div>
            <FormGroup check inline>
              <Label>
                <Input
                  type="radio"
                  name="productNumbers"
                  onChange={this.handleInputToggle}
                  checked={shouldBeChecked}
                />{' '}
                Product Numbers
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label>
                <Input
                  type="radio"
                  name="orderlines"
                  onChange={this.handleInputToggle}
                  checked={!shouldBeChecked}
                />{' '}
                Order Line Ids
              </Label>
            </FormGroup>
          </div>
          <Input type="textarea" rows={5} name="productCodes" id="bulkReprocessProductCodes" value={this.state.data.productCodes} onChange={this.handleGenericChange} invalid={this.state.hasProductNumberError} onBlur={this.handleBlur} />
          {this.state.hasProductNumberError && (
            <FormFeedback>{validators.required(this.state.data.productCodes)}</FormFeedback>
          )}
          <label className="field-help-text">{shouldBeChecked
            ? "Insert product numbers in comma separated fashion: AL00091, AL00092, AL000087..."
            : "Insert order line ids in comma separated fashion: 10001, 10002..."} </label>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Reason code</Label>
          <Select
            emptyText="-- select a code --"
            onChange={this.handleReasonChange}
            value={this.state.data.reasonCode}
            options={this.props.input.resetReasons.map((x) => ({ value: x.errorID, text: x.description }))}
            invalid={this.state.hasReasonCodeError}
          />
          {this.state.hasReasonCodeError && (
            <FormFeedback>{validators.required(this.state.data.reasonCode)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Comment</Label>
          <Input type="textarea" rows={5} name="message" id="bulkReprocessMessage" value={this.state.data.message} onChange={this.handleGenericChange} invalid={this.state.hasMessageError} onBlur={this.handleBlur} />
          {this.state.hasMessageError && (
            <FormFeedback>{validators.required(this.state.data.message)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <button type="submit" id="bulkReprocessSubmit" disabled={this.state.disabled}>Submit</button>
        </FormGroup>
      </Form>
    )
  }
}
