import React from 'react'
import { Col, Row } from 'reactstrap'
import { FulfillmentProduct, OrderEvent } from '../../api/order'
import { Product } from '../../api/product'
import notesIcon from '../../assets/img/flags/notes.svg'
import resetIcon from '../../assets/img/flags/reset.svg'
import sbsIcon from '../../assets/img/flags/sbs.svg'
import { BadgeSetting, EditorDescription, TogglePanel } from '../../models'
import {
  OrderDetailsServiceInstance as Service,
  PlantInfo,
  studentenEditor,
  studentenFulfillmentProduct,
} from '../../services/OrderDetailsService'
import OrderLinesPanel from '../panels/OrderLinesPanel'
import FulfilmentProductOptionsPanel from '../panels/FulfillmentProductOptionsPanel'
import { ActionLinkSettings } from './ActionLink'
import ActionList from './ActionList'
import CampaignIcon from './CampaignIcon'
import EditorIcon from './EditorIcon'
import Flag from './Flag'
import Grid, { GridColumn } from './Grid'
import PlantIcon from './PlantIcon'

export interface ProductCardProps extends React.Props<any> {
  product: Product
  ffProduct?: FulfillmentProduct
  productEvents: OrderEvent[]
  vendor: string
  actions: ActionLinkSettings[]
  previewSrc: string
  linesPanel?: TogglePanel
  optionsPanel?: TogglePanel
  parentStatus: BadgeSetting
  highlighted: boolean
  notesCount: number
  hasSbs: boolean
  widgetPreviewUrl?: string
  resetCounter: number
  onDelayLinkClick?(): void
  ffUiUrl: string
}

interface State {
  viewLargeImage: boolean
}

export default class StudentenProductCard extends React.Component<ProductCardProps, State> {
  constructor(props: ProductCardProps) {
    super(props)

    this.state = { viewLargeImage: false }
  }

  render() {
    return (
      <React.Fragment key={this.props.product.id}>
        <Row className="mt-2">
          <div className="bo-container d-flex">
            <Col sm="12" md="4" lg="1" className="pl-0">
              <img
                src={this.props.previewSrc}
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ viewLargeImage: !this.state.viewLargeImage })}
                className={this.state.viewLargeImage ? 'image-zoomed' : ''}
                title={this.state.viewLargeImage ? 'Click to hide' : 'Click to zoom'}
                width="100%"
                alt="preview"
              />
            </Col>
            <Col sm="12" md="8" lg="11">
              {this.renderProductInfo()}
            </Col>
          </div>
        </Row>
        <Row className="mb-2">
          <ActionList actions={this.props.actions} />
          {this.props.optionsPanel && (
            <FulfilmentProductOptionsPanel {...this.props.optionsPanel} fulfillmentProduct={this.props.ffProduct} />
          )}
          {this.props.linesPanel && (
            <OrderLinesPanel
              {...this.props.linesPanel}
              product={this.props.product}
              ffProduct={this.props.ffProduct}
              vendor={this.props.vendor}
              ffUiUrl={this.props.ffUiUrl}
            />
          )}
        </Row>
      </React.Fragment>
    )
  }

  renderProductInfo() {
    const infoLeft = {
      name: Service.resolveStudentenProductDescriptionByType(this.props.product.productId),
      isLayflat: false,
      code: this.props.product.id,
    }

    const mockFulfillmentProduct = studentenFulfillmentProduct
    mockFulfillmentProduct.productId = this.props.product.id

    const infoRight = {
      id: this.props.product.id,
      editor: Service.resolveProductEditor(mockFulfillmentProduct, studentenEditor),
      statuses: Service.resolveProductStatuses(this.props.product, this.props.parentStatus),
      plants: Service.getPlants(mockFulfillmentProduct.lines),
      promisedCampaign: Service.resolvePromisedCampaign(this.props.productEvents),
      isDelayed: Service.isDelayed(this.props.productEvents),
    }

    const productNameFunc = (p: any) => {
      if (!p.name) {
        return <i>Unknown product</i>
      }

      return `${p.name} ${p.isLayflat ? ' | layflat' : ''}`
    }

    const columnsLeft: GridColumn<any>[] = [
      { title: this.props.product.title ? `“${this.props.product.title}”` : '', value: productNameFunc },
    ]

    const columnsRight: GridColumn<any>[] = []
    if (infoRight.promisedCampaign) {
      columnsRight.push({
        title: 'Promised',
        tdClass: 'text-center',
        value: (p) => {
          return <CampaignIcon campaign={p.promisedCampaign} productId={p.id} />
        },
      })
    }
    columnsRight.push({
      title: 'Plant',
      tdClass: 'text-center',
      value: (p) => {
        const plants: PlantInfo[] = p.plants
        const [plant] = plants

        const elementId = `plant_tooltip_${p.id}`

        return <PlantIcon elementId={elementId} plant={plant.plant} displayName={plant.displayName} />
      },
    })
    columnsRight.push({
      title: 'Editor',
      tdClass: 'text-center',
      value: (p) => {
        const editor: EditorDescription = p.editor
        const elementId = `editor_tooltip_${p.id}`

        return <EditorIcon description={editor} elementId={elementId} />
      },
    })

    return (
      <React.Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <Grid columns={columnsLeft} data={[infoLeft]} className="embedded" />
          </div>
          <div>
            <Grid
              columns={columnsRight}
              data={[infoRight]}
              className="all-eq last-column-status embedded"
              disableCopyOnClick={true}
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            {!!this.props.notesCount && <Flag icon={notesIcon} title={`Notes (${this.props.notesCount})`} />}
            {this.props.hasSbs && <Flag icon={sbsIcon} title="Has SBS" />}
            {!!this.props.resetCounter && <Flag icon={resetIcon} title={`Reset product (x${this.props.resetCounter})`} />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
