import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import routes from '../../route/routes'
import { AuthInstance } from '../../services/AuthService'
import CopyToClipboard from '../generic/CopyToClipboard'

async function obtainToken(): Promise<string> {
  const token = await AuthInstance.getToken()
  return 'Bearer ' + token
}

export default function Footer() {
  return (
    <div>
      <Container className="bo-footer clearfix" fluid>
        <Row>
          <Col>
            <Link to={routes.RELEASE_NOTES()}>v.{process.env.REACT_APP_VERSION}</Link>
          </Col>
          <Col>
            <span className="float-right">
              <CopyToClipboard title="Copy auth token to clipboard" asyncCallback={obtainToken} />
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
