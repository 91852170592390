import Popper from 'popper.js'
import React from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import questionWhite from '../../assets/img/question-mark-circle-white.svg'
import question from '../../assets/img/question-mark-circle.svg'

type TooltipColor = 'white' | 'black'

interface Props extends React.Props<any> {
  tooltipId: string
  placement: Popper.Placement
  boundariesElement?: Popper.Boundary
  size?: number
  color?: TooltipColor
  onOpen?: any
}

function getIconSource(color: TooltipColor) {
  switch (color) {
    case 'white':
      return questionWhite
    case 'black':
    default:
      return question
  }
}

export default function QuestionmarkWithTooltip({
  tooltipId,
  children,
  placement,
  boundariesElement,
  size = 32,
  color = 'black',
  onOpen,
}: Props) {
  return (
    <div className={`bo-tooltip ${children ? 'clickable' : ''}`}>
      <img
        src={getIconSource(color)}
        height={size}
        width={size}
        title="Click for more info"
        id={tooltipId}
        alt=""
        onClick={onOpen}
      />
      <UncontrolledPopover
        trigger="legacy"
        placement={placement}
        boundariesElement={boundariesElement}
        target={tooltipId}
        container="layout-container">
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </div>
  )
}
