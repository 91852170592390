import React, { FormEvent } from 'react'
import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap'
import { BulkGetStatusBase } from '../../api/status'
import { validators } from '../../utils'

import './BulkStatusForm.scss'

interface State {
  data: BulkGetStatusBase,
  disabled: boolean,
  hasProductionOrderIdsError: boolean
}

interface BulkStatusFormProps {
  input: BulkGetStatusBase
  onSubmit(form: BulkGetStatusBase): void
}

export default class BulkStatusForm extends React.Component<BulkStatusFormProps, State> {
  constructor(props: BulkStatusFormProps) {
    super(props)

    this.state = {
      data: {
        productionOrderIds: props.input.productionOrderIds
      },
      disabled: false,
      hasProductionOrderIdsError: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGenericChange = this.handleGenericChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleSubmit(event: FormEvent) {
    const hasIdentifierErrors = this.isEmpty(this.state.data.productionOrderIds)

    this.setState({
      ...this.state,
      hasProductionOrderIdsError: hasIdentifierErrors,
    } as State)

    if (hasIdentifierErrors) {
      event.preventDefault()
      return;
    }
    else {
      this.setState({ disabled: true })
      this.props.onSubmit(this.state.data)
      this.setState({ disabled: false })
    }
  }

  handleGenericChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
  
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: value
      },
      hasProductionOrderIdsError: name === 'productionOrderIds' ? this.isEmpty(value) : this.state.hasProductionOrderIdsError
    });
  }

  handleBlur(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'productionOrderIds') {
      this.setState({
        ...this.state,
        hasProductionOrderIdsError: this.isEmpty(value)
      });
    }
  }

  isEmpty(data: any) {
    return (data === null || data === undefined || data === '')
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <label className="field-help-text">The Bulk Status operation supports ProductionOrderIds. It will retrieve the latest status along with the timestamp for all the Production Orders.</label>
          <Input type="textarea" rows={5} name="productionOrderIds" id="bulkStatusProductionOrderIds" value={this.state.data.productionOrderIds} onChange={this.handleGenericChange} invalid={this.state.hasProductionOrderIdsError} onBlur={this.handleBlur} />
          {this.state.hasProductionOrderIdsError && (
            <FormFeedback>{validators.required(this.state.data.productionOrderIds)}</FormFeedback>
          )}
          <label className="field-help-text">Insert ProductionOrderIds in comma separated fashion, for instance: ID1, ID2, ID3</label>
        </FormGroup>
        <FormGroup>
          <button type="submit" id="bulkStatusSubmit" disabled={this.state.disabled}>Submit</button>
        </FormGroup>
      </Form>
    )
  }
}
