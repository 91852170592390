import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/accountActions'
import { Account, AccountDeletionRequest, AccountSearchResponse, SearchAccountBy } from '../api'
import { AccountsSearchFilter, AccountsWhitelist, AccountsPasswordResetLinkRequest } from '../reducers/states'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'
import { copyToClipboard } from '../utils/common'

async function getAccountsById(query: string): Promise<AccountSearchResponse> {
  const resource = `accounts/${encodeURIComponent(query)}`
  const response = await ApiServiceInstance.get(resource)
  return response
}

async function getAccountsByEmail(query: string): Promise<AccountSearchResponse> {
  const resource = `accounts/byEmail?query=${encodeURIComponent(query)}&dataSource=1&offset=0&limit=5`
  const response = await ApiServiceInstance.get(resource)
  return response
}

function* search(action: PayloadAction<AccountsSearchFilter>) {
  try {
    const { type, query } = action.payload
    if (query) {
      switch (type) {
        case SearchAccountBy.Id: {
          const result: AccountSearchResponse = yield call(getAccountsById, query)
          yield put(actions.receivedAccounts([result]))
          break
        }
        case SearchAccountBy.Email: {
          const result: AccountSearchResponse = yield call(getAccountsByEmail, query)
          yield put(actions.receivedAccounts([result]))
          break
        }
      }
    }
  } catch (ex) {
    yield put(actions.searchErrorAccounts(`Immediately contact #care-tech-support slack channel ` + ex))
  }
}

function* requestDeleteAccount(action: PayloadAction<AccountDeletionRequest>) {
  try {
    const resource = `accounts/${action.payload.accountId}`
    yield call(ApiServiceInstance.del, resource, action.payload)
    yield put(actions.search({ type: 1, query: action.payload.accountId }))
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* requestWhitelistAccount(action: PayloadAction<AccountsWhitelist>) {
  try {
    const resource = `accounts/${action.payload.operation}/${action.payload.accountId}`
    yield call(ApiServiceInstance.post, resource)
    yield put(actions.search({ type: action.payload.filter.type, query: action.payload.filter.query }))
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* requestGetAccountPasswordResetLink(action: PayloadAction<AccountsPasswordResetLinkRequest>) {
  try {
    const resource = `accounts/password/resetlink/${action.payload.accountId}`
    const response = yield call(ApiServiceInstance.post, resource, action.payload)
    copyToClipboard(response.data)
    alert(`Copied password reset url to clipboard : ${response.data}`)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

export default function* root() {
  yield takeLatest(actions.search, search)
  yield takeLatest(actions.requestDeleteAccount, requestDeleteAccount)
  yield takeLatest(actions.requestWhitelistAccount, requestWhitelistAccount)
  yield takeLatest(actions.requestGetPasswordResetLink, requestGetAccountPasswordResetLink)
}
