import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import * as global from '../actions/globalActions'
import { BulkCancelRequest } from '../api/cancel'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* bulkCancel(action: PayloadAction<BulkCancelRequest>) {
  try {
    yield put(global.showSpinner())
    const response = yield call(ApiServiceInstance.post, 'productionOrder/bulkCancel', action.payload)
console.log(response);
    yield put(
      bulkOperationsActions.bulkCancelResponse({
        ...response.data,
        valid: true,
      })
    )
  } catch (e) {
    let errorMessage = ''
    if (e.response.data) {
      errorMessage = e.response.data
    }
    yield put(Errors.toErrorAction(e, errorMessage))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(bulkOperationsActions.bulkCancelRequest, bulkCancel)
}
