import { createAction } from '@reduxjs/toolkit'
import { SbsDeleteRequest, SbsError, SbsPostRequest, SbsForProductionOrderPostRequest } from '../api'

export const changeSbsFilter = createAction<SbsFilterPayload | undefined>('SBS_FILTER')
export const receivedSbs = createAction<SbsError>('SBS_DETAILS_RECEIVED')
export const submitSbs = createAction<SbsPostRequest>('SBS_DETAILS_SUBMIT')
export const submitSbsForProductionOrder = createAction<SbsForProductionOrderPostRequest>('SBS_FOR_PRODUCTIONORDER_DETAILS_SUBMIT')
export const submittedSbs = createAction<SbsPostRequest>('SBS_DETAILS_SUBMITTED')
export const submittedSbsForProductionOrder = createAction<SbsForProductionOrderPostRequest>('SBS_FOR_PRODUCTIONORDER_DETAILS_SUBMITTED')
export const removeSbs = createAction<SbsDeleteRequest>('SBS_DETAILS_REMOVED')
export const toggleEditMode = createAction<boolean | undefined>('SBS_TOGGLE_EDIT_MODE')

export interface SbsFilterPayload {
  productCode?: string
  productionOrderId?: string
  error?: string
}
