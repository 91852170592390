import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkRedoRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkRedoBase } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkRedoForm from '../forms/BulkRedoForm'
import { ResetReason } from '../../api'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
  resetReasons: ResetReason[]
}

class BulkRedoPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkRedo = this.sendBulkRedo.bind(this)
  }

  sendBulkRedo(x: BulkRedoBase) {
    const productionOrderIdList = x.productionOrderIds
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkRedoRequest({
        productionOrderIds: productionOrderIdList,
        recalculateCustomerPromiseDate: x.recalculateCustomerPromiseDate,
        reasonPhrase: x.reasonCode,
        reasonCode: x.reasonCode,
        comment: x.message
      })
    )
  }

  renderForm(resetReasons: ResetReason[]) {
    return <BulkRedoForm input=
      {{
        productionOrderIds: '',
        recalculateCustomerPromiseDate: true,
        message: '',
        resetReasons: resetReasons,
        reasonCode: ''
      }}
      onSubmit={this.sendBulkRedo}></BulkRedoForm>
  }

  renderResponse() {
    console.log('Bulk Redo Response:', this.props.bulkRedoResponse);
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Redo Result</h1>
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        <div className="response-container">
          {this.props.bulkRedoResponse.productionOrdersRedoSuccess && this.props.bulkRedoResponse.productionOrdersRedoSuccess.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-success status-heading'>
                  Redone Successfully
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
              </Col>
                <Col md="10" className="column-highlight">
                  Production Order Ids
              </Col>
              </Row>
              {this.props.bulkRedoResponse.productionOrdersRedoSuccess.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="success-column">
                    {x.orderCode}
                  </Col>
                  <Col md="10" className="success-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkRedoResponse.productionOrdersRedoFailed && this.props.bulkRedoResponse.productionOrdersRedoFailed.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Redo Failed
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
              </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
              </Col>
                <Col md="5" className="column-highlight">
                  Error Message
              </Col>
              </Row>
              {this.props.bulkRedoResponse.productionOrdersRedoFailed.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="failed-column">
                    {x.orderCode}
                  </Col>
                  <Col md="5" className="failed-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                  <Col md="5" className="failed-column">
                    {x.errorMessage}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkRedoResponse.productionOrdersNotFound && this.props.bulkRedoResponse.productionOrdersNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Production Orders Not Found
              </Col>
              </Row>
              <Row>
                <Col md="12" className="column-highlight">
                  Production Orders
              </Col>
              </Row>
              {this.props.bulkRedoResponse.productionOrdersNotFound.map((x, index) => (
                <Row key={index}>
                  <Col md="12" className="notfound-column">
                    {x}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </Container>
    )
  }

  render() {
    return this.props.bulkRedoResponse.valid ? this.renderResponse() : this.renderForm(this.props.resetReasons)
  }
}

export default BulkRedoPanel
