import React from 'react'
import { Form, FormGroup } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { NoteRequestBase } from '../../api/note'
import { validators } from '../../utils'
import ActionLink from '../generic/ActionLink'
import Textarea from './Textarea'

interface OrderNoteFormProps {
  onSubmit(x: NoteRequestBase): void
  onCancel(): void
}

function OrderNoteFormInternal({
  handleSubmit,
  onSubmit,
  onCancel,
  submitting,
}: OrderNoteFormProps & InjectedFormProps<NoteRequestBase, OrderNoteFormProps>) {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field name="body" component={Textarea} rows={5} label="Message" validate={[validators.required]} />
      <FormGroup>
        <button type="submit" disabled={submitting}>
          Submit
        </button>
        <ActionLink className="ml-3" text="Cancel" onClick={() => onCancel()} />
      </FormGroup>
    </Form>
  )
}

export const OrderNoteForm = reduxForm<NoteRequestBase, OrderNoteFormProps>({})(OrderNoteFormInternal)
