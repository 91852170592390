import React, { ReactNode } from 'react'
import { Button } from 'reactstrap'
import { copyToClipboard } from '../../utils'

export interface CopyToClipboardProps {
  asyncCallback?: () => Promise<string>
  stringToCopy?: string
  title?: string
  icon?: ReactNode
  className?: string
}

export default function CopyToClipboard(props: CopyToClipboardProps) {
  const getValue = async () => {
    if (props.asyncCallback) {
      return await props.asyncCallback()
    } else if (props.stringToCopy) {
      return props.stringToCopy
    } else {
      return ''
    }
  }

  const padding = props.icon ? '0 5px' : '0 10px'

  return (
    <Button
      color="secondary"
      size="sm"
      style={{ fontSize: '.7rem', padding }}
      className={props.className}
      onClick={async () => copyToClipboard(await getValue())}>
      {props.title || 'Copy to clipboard'}
    </Button>
  )
}
