import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/accountActions'
import { AccountSearchResponse, SearchAccountBy } from '../api'
import { notEmpty } from '../utils'
import { AccountsSearchFilter, AccountsSearchStore } from './states'

const initialState: AccountsSearchStore = {
  filter: {
    type: SearchAccountBy.Email,
    query: '',
  },
  items: undefined,
  isLoading: false,
}

export const accounts = createReducer<AccountsSearchStore>(initialState, {
  [actions.search.type]: (state, action: PayloadAction<AccountsSearchFilter>) => {
    state.isLoading = true
    state.filter = action.payload
    state.items = undefined
    state.error = undefined
  },
  [actions.receivedAccounts.type]: (state, action: PayloadAction<AccountSearchResponse[]>) => {
    state.isLoading = false
    state.items = action.payload
      .filter((it) => it.items)
      .map((it) => it.items)
      .flat()
      .filter(notEmpty)
  },
  [actions.searchErrorAccounts.type]: (state, action: PayloadAction<string>) => {
    state.isLoading = false
    state.error = action.payload
  },
})
