import React from 'react'
import { Input } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import ValidationWrapper, { CustomComponent } from './ValidationWrapper'

interface TextareaCustomProps extends CustomComponent {
  id?: string
  rows?: number
}

export default function Textarea(props: WrappedFieldProps & TextareaCustomProps) {
  const hasError = !!props.meta.error && !!props.meta.touched
  return (
    <ValidationWrapper {...props}>
      <Input type="textarea" id={props.id} {...props.input} invalid={hasError} disabled={props.disabled} rows={props.rows || 3} />
    </ValidationWrapper>
  )
}
