import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions/mixupActions'
import { MixupSearchFilter, SearchFilter } from '../api'
import FilterBar, { FilterBarItem, FilterBarValue } from '../components/forms/FilterBar'
import LazyContainer from '../components/generic/LazyContainer'
import MixupList from '../components/lists/MixupList'
import { CommonProps } from '../models'
import { AppStore, GlobalStore, MixupSearchStore } from '../reducers/states'
import routes from '../route/routes'

interface Props extends CommonProps, MixupSearchStore, GlobalStore {}

const byOrder = 0
const byTicket = 1
const SearchTypes: FilterBarItem[] = [
  {
    id: byOrder,
    navLinkId: `search-mix-up-option-${byOrder}`,
    title: 'Order Number',
  },
  {
    id: byTicket,
    navLinkId: `search-mix-up-option-${byTicket}`,
    title: 'T/Z Number',
  },
]

class SearchMixupView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onRegisterNewClick = this.onRegisterNewClick.bind(this)
  }

  render() {
    return (
      <div className="search-container">
        {this.renderFilter()}
        {this.renderResults()}
      </div>
    )
  }

  private renderResults() {
    return (
      <LazyContainer isLoading={this.props.isLoading}>
        {this.props.data.items && <MixupList items={this.props.data.items} filter={this.props.filter} />}
        {this.props.data.hasMore && this.props.data.items && (
          <button className="mt-4 wide-button" disabled={this.props.isLoading} onClick={this.loadData}>
            Load more mix-ups
          </button>
        )}
      </LazyContainer>
    )
  }

  private renderFilter() {
    const initialFilter = this.toSearchFilter(this.props.filter)

    return (
      <div className="panel">
        <FilterBar
          searchOnEmpty={true}
          initialValue={{ id: initialFilter.type, query: initialFilter.query }}
          items={SearchTypes}
          onSelect={this.onFilterChange}
          onSubmit={this.onSubmit}
        />
        {this.props.configuration.permissions.isAgent && (
          <div className="buttons">
            <button onClick={this.onRegisterNewClick}>+ Register mix-up</button>
          </div>
        )}
      </div>
    )
  }

  private toSearchFilter(filter: MixupSearchFilter): SearchFilter {
    const type = filter.byTicket === undefined ? byOrder : byTicket
    const query = {
      [byOrder]: filter.byOrder,
      [byTicket]: filter.byTicket,
    }

    return {
      type,
      query: query[type] || '',
    }
  }

  private toMixupFilter(value: FilterBarValue): MixupSearchFilter {
    return {
      byTicket: value.id === byTicket ? value.query : undefined,
      byOrder: value.id === byOrder ? value.query : undefined,
    }
  }

  private onFilterChange(value: FilterBarValue) {
    const mixupFilter = this.toMixupFilter(value)
    this.props.dispatch(actions.filterChanged(mixupFilter))
  }

  private onSubmit(value: FilterBarValue) {
    const mixupFilter = this.toMixupFilter(value)

    this.search(mixupFilter)
  }

  private search(filter?: MixupSearchFilter) {
    this.props.dispatch(actions.filterChanged(filter))
    this.props.dispatch(actions.requestMixups())
  }

  private loadData() {
    if (this.props.data.hasMore) {
      this.props.dispatch(actions.requestMixups())
    }
  }

  private onRegisterNewClick() {
    this.props.history.push(routes.MIXUPS_NEW())
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.mixups,
    ...state.global,
  }
}
export default connect(mapStateToProps)(SearchMixupView)
