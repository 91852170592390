import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as global from '../actions/globalActions'
import * as actions from '../actions/projectActions'
import { Customer, ProjectSearchResponse, SearchProjectBy } from '../api'
import { ProjectsSearchFilter } from '../reducers/states'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

async function getCustomers(query: string): Promise<Customer[]> {
  const resource = `customers/byEmail?query=${encodeURIComponent(query)}&dataSource=1&offset=0&limit=5`
  const response = await ApiServiceInstance.get(resource)
  const result = response as { items: Customer[] }
  return result.items
}

async function getProjects(type: string, query: string): Promise<ProjectSearchResponse> {
  const resource = `projects/by${type}?query=${encodeURIComponent(query)}`
  const response = await ApiServiceInstance.get(resource)
  return response
}

function* search(action: PayloadAction<ProjectsSearchFilter>) {
  try {
    const { type, query } = action.payload
    if (query) {
      switch (type) {
        case SearchProjectBy.User: {
          const customers: Customer[] = yield call(getCustomers, query)
          const result: ProjectSearchResponse[] = yield all(
            customers.map(function* (customer) {
              const response: ProjectSearchResponse = yield call(getProjects, 'Account', customer.id)
              return response
            })
          )
          yield put(actions.receivedProjects(result))
          break
        }
      }
    }
  } catch (ex) {
    yield put(actions.searchErrorProjects(`Immediately contact #care-tech-support slack channel`))
  }
}

function* downloadProjectFile(action: PayloadAction<string>) {
  try {
    yield put(global.showSpinner())

    const resource = `projects/${encodeURIComponent(action.payload)}/data`
    const url = yield call(ApiServiceInstance.get, resource)

    window.open(url)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(actions.search, search)
  yield takeLatest(actions.downloadProjectFile, downloadProjectFile)
}
