import _ from 'lodash'
import React from 'react'
import { Product } from '../../api'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import SidePanel, { SidePanelSettings } from './SidePanel'

export interface ProductOptionsProps extends SidePanelSettings {
  product?: Product
}

export default class ProductOptionsPanel extends React.Component<ProductOptionsProps> {
  render() {
    const product = this.props.product
    const productOptions = Service.getProductOptions(product)
    const productOptionsSorted = _.sortBy(productOptions, ['name'])

    return (
      <SidePanel {...this.props}>
        {product ? (
          <div>
            <h3>
              {Service.resolveProductDescription(product.productId)}
            </h3>
            <table className="bo-grid-vertical wide">
              <tbody>
                {productOptions &&
                  productOptionsSorted.map((o) => (
                    <tr key={o.name}>
                      <th>{o.name}</th>
                      <td>{o.value ? o.value : <i>Unknown</i>}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h3>
            <i>Options are not available</i>
          </h3>
        )}
      </SidePanel>
    )
  }
}
