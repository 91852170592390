import * as React from 'react'

export default function Checkbox({ name, checked, onChange, isIndeterminate, id, disabled, title }: any) {
  const setCheckboxRef = (checkbox: any) => {
    if (checkbox) {
      checkbox.indeterminate = isIndeterminate
    }
  }

  return (
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      className="form-check-input"
      disabled={disabled}
      title={title}
      ref={setCheckboxRef}
    />
  )
}
