import React from 'react'
import { DataSource, GlobalConfiguration } from '../../api'
import ActionLink from '../../components/generic/ActionLink'

interface Props {
  orderCode: string
  source: DataSource
  configuration: GlobalConfiguration
}

export default function NavigationLinks({ orderCode, configuration, source }: Props) {
  return (
    <React.Fragment>
      <ActionLink
        text="< back"
        className="mr-4"
        onClick={() => {
          window.history.back()
        }}
      />
      {source === DataSource.Studenten && (
        <ActionLink
          text="Studenten slack support channel"
          href={`slack://channel?id=CV9L34FE1&team=T2PF4D4TA`}
          className="mr-4"
        />
      )}

      {source === DataSource.Resnap && (
        <ActionLink text="Open in Resnap Manager" href={`${configuration.resnapManagerUrl}/orders/view/${orderCode}`} />
      )}
    </React.Fragment>
  )
}
