import React, { ReactNode, useState } from 'react'
import closed from '../../assets/img/closed.svg'
import open from '../../assets/img/open.svg'
import copyIcon from '../../assets/img/copy.svg'
import copiedIcon from '../../assets/img/check-mark.svg'
import { copyToClipboard } from '../../utils'

interface CollapsableContainerWithCopyButtonProps {
  title: string | undefined
  valueToCopy: string
  children: ReactNode | undefined
  index: number
  folderCards: boolean[]
  onToggleCard(index: number): void
  foldAll: boolean
}

export const CollapsableContainerWithCopyButton = ({
  title,
  valueToCopy,
  children,
  index,
  folderCards,
  onToggleCard,
  foldAll
}: CollapsableContainerWithCopyButtonProps) => {
  const [copied, setCopied] = useState<boolean>(false)

  const copyValueToClipboard = (message: string) => {
    setCopied(true)
    copyToClipboard(message)
  }

  const isFolded = (index: number) => {
    if (folderCards && folderCards[index] !== undefined) {
      return !!folderCards[index]
    }

    return !!foldAll
  }

  return (
    <React.Fragment>
      <span className="d-flex">
        <img src={isFolded(index) ? closed : open} width="20" height="20" alt="folded" onClick={() => onToggleCard(index)} />
        {title && <h3>{title}</h3>}
        <img
          src={copied ? copiedIcon : copyIcon}
          width="15"
          height="15"
          className="d-flex ml-1 mt-1"
          onClick={() => copyValueToClipboard(valueToCopy)}
        />
      </span>
      {!isFolded(index) && children}
    </React.Fragment>
  )
}
