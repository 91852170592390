import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { CustomerSearchResult, CustomerSearchSortingType } from '../../api/search'
import routes from '../../route/routes'
import { highlight } from '../../utils'
import Grid, { GridColumn } from '../generic/Grid'

interface Props {
  albelliCustomers?: CustomerSearchResult[]
  filter: {
    byEmail?: string
    byPhone?: string
    byName?: string
  }
  orderBy?: CustomerSearchSortingType
}

function sort(props: Props) {
  const albelliCustomers = props.albelliCustomers || []

  const unique: CustomerSearchResult[] = _.uniqWith(albelliCustomers, (a, b) => {
    if (a?.email == null || b?.email == null) {
      return false;
    }
    if (a?.email?.toUpperCase() === b?.email?.toUpperCase()) {
      return true
    }

    return false
  })

  switch (props.orderBy) {
    case CustomerSearchSortingType.ByScore: {
      return _.sortBy(unique, [
        function (o) {
          return -o.score
        },
      ])
    }
    default:
      return _.sortBy(unique, ['email'])
  }
}

export default function CustomerList(props: Props) {
  const items = sort(props)

  if (items.length === 0) {
    return <b id="empty-customer-list-text">Customer account does not exist</b>
  }

  const columns: GridColumn<CustomerSearchResult>[] = [
    {
      title: 'AccountId',
      value: (customer) => customer.id
    },
    {
      title: 'Email',
      value: (customer) => {
        const email = customer.email;
        if (!!email) {
          return (
            <Link to={routes.CUSTOMERS_VIEW({ email: customer.email })}>{highlight(customer.email, props.filter.byEmail)}</Link>
          );
        };

        return "No email found";
      },
    },
    {
      title: 'Name',
      value: (customer) => highlight(customer.name, props.filter.byName),
    },
    {
      title: 'Phone',
      value: (customer) => highlight(customer.phone, props.filter.byPhone),
    },
  ]

  return <Grid gridId={'customer-list'} columns={columns} data={items} autoScroll={true} />
}
