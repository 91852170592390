export const CarrierMapping: { [x: string]: string | undefined } = {
  postnl: 'PostNL',
  'colis-prive': 'Colis Privé',
  colissimo: 'Colissimo',
  'colissimo-api': 'Colissimo',
  bring: 'Bring Parcel',
  gls: 'GLS',
  'royal-mail': 'RoyalMail',
  'dhl-express': 'DHL',
  'ups-i-parcel': 'UPS iParcel',
  directlink: 'DirectLink',
}
