import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { CustomerSearchResult } from '../../api/search'
import routes from '../../route/routes'
import { highlight } from '../../utils'
import Grid, { GridColumn } from '../generic/Grid'

interface Props {
  customers?: CustomerSearchResult[]
  highlight?: string
}

function distinctCustomersUniqueByEmail(customers: CustomerSearchResult[] = []) {
  const unique: CustomerSearchResult[] = _.uniqWith(customers, (a, b) => {
    if (a?.email == null || b?.email == null) {
      return false;
    }
    if (a?.email?.toUpperCase() === b?.email?.toUpperCase()) {
      return true
    }

    return false
  })
  return unique
}

export default class CustomerSearchResults extends React.Component<Props> {
  render() {
    const customers = distinctCustomersUniqueByEmail(this.props.customers)
    const highlightText = this.props.highlight

    if (!customers || customers.length === 0) {
      return null
    }

    const columns: GridColumn<CustomerSearchResult>[] = [
      {
        title: 'AccountId',
        value: (customer) => customer.id
      },
      {
        title: 'Email',
        value: customer => {
          const email = customer.email;
          if (!!email) {
            return (
              (
                <Link to={routes.CUSTOMERS_VIEW({ email: customer.email })}>{highlight(customer.email, highlightText)}</Link>
              )
            );
          };

          return "No email found";
        }
      },
      {
        title: 'Name',
        value: customer => highlight(customer.name, highlightText)
      },
      {
        title: 'Phone',
        value: customer => highlight(customer.phone, highlightText)
      },
      {
        title: 'Match',
        value: customer => customer.matchReason
      }
    ]

    return <Grid gridId={'customer-list'} columns={columns} data={customers} disableCopyOnClick={true} />
  }
}
