import { ReactNode } from 'react'

type BadgeColor = 'success' | 'other' | 'waiting' | 'in-progress' | 'warning' | 'danger'

export enum OrderProductStatus {
  WaitingForPaymentNotConfirmed = 'Waiting for payment (not confirmed)',
  WaitingForPaymentConfirmed = 'Waiting for payment (confirmed)',
  WaitingForPayment = 'Waiting for payment',
  PaymentFailed = 'Payment failed',
  PaymentSucceeded = 'Payment succeeded',
  Accepted = 'Accepted',
  WaitingForProduction = 'Waiting for production',
  InProduction = 'In production',
  ScannedForShipment = 'Scanned for shipment',
  ShippedVoucher = 'Shipped by email',
  Cancelled = 'Cancelled',
  OnHold = 'On hold',
  Error = 'Error',
  Unknown = 'Unknown or complex',
  Delayed = 'Delayed',
  NotAvailable = 'N/A',
  Packed = 'Packed',
  PartiallyShipped = 'Partially shipped',
}

export interface BadgeSetting {
  color: BadgeColor
  title: OrderProductStatus | ReactNode
  qty?: number
}
