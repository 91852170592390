import _ from 'lodash'
import React from 'react'
import { OrderDetails, SbsDeleteRequest, SbsPostRequest, SbsForProductionOrderPostRequest, ShowByScanForm, ShowByScanNames, ShowByScanRecord } from '../../api'
import confirm from '../../assets/img/confirm.svg'
import { ShowByScanNote } from '../../components/forms/ShowByScanNote'
import { TogglePanel } from '../../models'
import { SbsPanelViewStore } from '../../reducers/states'
import { OrderDetailsServiceInstance as service } from '../../services/OrderDetailsService'
import { AlertInfo, OperationType, OrderManagementServiceInstance, ProductionOrderDetails, ProductWithValue } from '../../services/OrderManagementService'
import { nl2br, toDateTime } from '../../utils'
import Confirmation from '../forms/Confirmation'
import OrderStructure from '../forms/OrderStructure'
import ActionList from '../generic/ActionList'
import Alerts from '../generic/Alerts'
import LazyContainer from '../generic/LazyContainer'
import './SbsPanel.scss'
import SidePanel from './SidePanel'

interface Props extends TogglePanel {
  sbsData: SbsPanelViewStore
  readOnly: boolean
  orderDetails: OrderDetails
  onSubmit(x: SbsPostRequest): void
  onSubmitForProductionOrder(x: SbsForProductionOrderPostRequest): void
  onDelete(x: SbsDeleteRequest): void
  onEditModeToggle(): void
}

interface State {
  dirtyProductsSelection: boolean
  productsToSubmit: ProductWithValue[]
  productsToDelete: string[]
  selectedSbs?: ShowByScanRecord
  warnings?: AlertInfo[]
}

const initialState = {
  dirtyProductsSelection: false,
  productsToSubmit: [],
  productsToDelete: [],
  selectedSbs: undefined,
}

export default class SbsPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = initialState

    this.removeHandler = this.removeHandler.bind(this)
    this.onSubmitted = this.onSubmitted.bind(this)
    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)
    this.closeDeleteConfirmation = this.closeDeleteConfirmation.bind(this)
  }

  handleProductsChange(products: ProductWithValue[], productionOrders: Array<ProductionOrderDetails>) {
    this.setState({
      productsToSubmit: products.filter((x) => !x.disabled),
      warnings: OrderManagementServiceInstance.getWarnings(products),
    })
  }

  isOrderStructureValid(products: ProductWithValue[]) {
    return products.some((x) => x.value > 0)
  }

  onSubmitted(sbs: ShowByScanForm): void {
    if (this.props.orderDetails.preselectedProductionOrderId) {
      const request: SbsForProductionOrderPostRequest = {
        appVersion: process.env.REACT_APP_VERSION || '',
        orderCode: this.props.orderDetails.id,
        productionOrderId: this.props.orderDetails.preselectedProductionOrderId,
        text: sbs.text,
        type: sbs.type,
        vendorId: this.props.orderDetails.vendor,
      }

      this.props.onSubmitForProductionOrder(request)
    } else {
      this.setState({ dirtyProductsSelection: true })

      if (!this.isOrderStructureValid(this.state.productsToSubmit)) {
        return
      }

      const request: SbsPostRequest = {
        appVersion: process.env.REACT_APP_VERSION || '',
        orderCode: this.props.orderDetails.id,
        products: this.state.productsToSubmit.filter((p) => p.value > 0).map((p) => p.id),
        text: sbs.text,
        type: sbs.type,
        vendorId: this.props.orderDetails.vendor,
      }

      this.props.onSubmit(request)
    }
  }

  removeHandler() {
    const request: SbsDeleteRequest = {
      appVersion: process.env.REACT_APP_VERSION || '',
      orderCode: this.props.orderDetails.id,
      products: this.state.productsToDelete,
      vendorId: this.props.orderDetails.vendor,
    }

    this.props.onDelete(request)

    this.closeDeleteConfirmation()
  }

  closeDeleteConfirmation() {
    this.setState({ productsToDelete: [] })
  }

  toggleEditMode(record?: ShowByScanRecord, products: string[] = []) {
    let productsToSubmit: ProductWithValue[] = []

    if (this.props.orderDetails.products) {
      productsToSubmit = this.props.orderDetails.products
        .filter((p1) => products.some((p2) => p2 === p1.id))
        .map((p) => ({ ...p, value: 1, isOrderLineHidden: true, isOrderLineDisabled: true, isCheckboxHidden: false }))
    }

    products = products || []
    this.setState({
      ...initialState,
      selectedSbs: record,
      productsToSubmit: productsToSubmit,
    })
    this.props.onEditModeToggle()
  }

  renderEditForm() {
    return (
      <React.Fragment>
        {this.state.selectedSbs ? <h1>Edit Show By Scan</h1> : <h1>Create Show By Scan</h1>}
        <hr />
        <Alerts items={this.state.warnings} />
        {this.state.selectedSbs ? (
          <div className="mb-2 product">
            {this.state.productsToSubmit.map((p) => (
              <div key={p.id}>{`${p.id} | ${service.resolveProductDescriptionByType(p.productId)}`}</div>
            ))}
          </div>
        ) : !this.props.orderDetails.preselectedProductionOrderId ? (
          <React.Fragment>
            <OrderStructure
              preselectedProductionOrderId={this.props.orderDetails.preselectedProductionOrderId}
              data={this.props.orderDetails}
              handleChange={this.handleProductsChange}
              operation={OperationType.Sbs}
            />
            {!this.isOrderStructureValid(this.state.productsToSubmit) && this.state.dirtyProductsSelection && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                Please, select a product.
              </div>
            )}
          </React.Fragment>
        ) : null}
        <ShowByScanNote
          form={'sbs-form'}
          onSubmit={this.onSubmitted}
          onCancel={this.toggleEditMode}
          initialValues={this.state.selectedSbs}
        />
      </React.Fragment>
    )
  }

  renderListItem(products: string[], titles: string[], info: ShowByScanRecord) {
    const actions = info.productionOrderId ? [] : [
      {
        text: 'Edit',
        onClick: () => {
          this.toggleEditMode(info, products)
        },
      },
      {
        text: 'Delete',
        onClick: () => this.setState({ productsToDelete: products }),
      },
    ]

    return (
      <div className="list-item">
        <div className="mb-2 product">
          {titles.map((title, i) => (
            <div key={i}>{title}</div>
          ))}
        </div>
        <div className="mb-2" title="Creation time">
          <span className="date-time">{toDateTime(info.createdAt)}</span>
          &nbsp;|&nbsp;{info.username}
        </div>
        <div className="mb-2 sbs-type">Type: {ShowByScanNames[info.type]}</div>
        <div className="sbs-text">{nl2br(info.text)}</div>
        <ActionList actions={actions} className="slim" />
      </div>
    )
  }

  renderList(sbsData: SbsPanelViewStore) {
    const filteredCollection = service.filterSbsCollection(sbsData.collection, sbsData.productFilter, sbsData.productionOrderFilter)
    const grouped = _.chain(filteredCollection)
      .groupBy((sbs) => `${sbs.text}|${sbs.type}`)
      .map((group) => ({
        productIds: group.map((sbs) => sbs.productId),
        titles: group.map(
          (sbs) => sbs.productId
            ? `${sbs.productId} | ${service.resolveProductDescriptionByCode(this.props.orderDetails, sbs.productId) || ''}`
            : `Production order ${sbs.productionOrderId}`
        ),
        info: _.orderBy(group, (g) => g.createdAt, 'desc')[0],
      }))
      .orderBy((g) => g.info.createdAt, 'desc')
      .value()

    return grouped.map((group, i) => <div key={i}>{this.renderListItem(group.productIds, group.titles, group.info)}</div>)
  }

  renderTopLine() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between">
          <span
            className={`${this.props.sbsData.showUpdateNotification ? 'visible fade-out' : 'invisible'}`}
            id="sbs-updated-notification">
            <img src={confirm} width="32" height="32" className="mr-2" alt="confirmed" />
            <b>SBS updated</b>
          </span>
          <button className="medium" id="sbs-panel-add-sbs" onClick={() => this.toggleEditMode()}>
            Add SBS
          </button>
        </div>
        <hr />
      </React.Fragment>
    )
  }

  render() {
    const { sbsData, readOnly } = this.props
    if (sbsData.error) {
      return (
        <SidePanel {...this.props}>
          <b>{sbsData.error}</b>
        </SidePanel>
      )
    }

    return (
      <SidePanel {...this.props}>
        <div className="sbs-panel">
          <LazyContainer isLoading={sbsData.isLoading}>
            {!sbsData.editMode && !readOnly && this.renderTopLine()}
            {!sbsData.editMode && this.renderList(sbsData)}
            {sbsData.editMode && this.renderEditForm()}
          </LazyContainer>
          <Confirmation
            isOpen={this.state.productsToDelete.length > 0}
            cancelText="No, Cancel"
            submitText="Yes"
            title="Confirm SBS deletion"
            toggle={this.closeDeleteConfirmation}
            onSubmit={this.removeHandler}>
            Are you sure you want to delete this SBS?
          </Confirmation>
        </div>
      </SidePanel>
    )
  }
}
