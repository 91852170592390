import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/customerDetailsActions'
import { Customer, SearchResponse } from '../api'
import { concatArrays } from '../utils'
import { CustomerDetailsStore } from './states'

const initialState: CustomerDetailsStore = {
  prevAlbelliResponse: defaultEmptyResponse(),
}

function defaultEmptyResponse(): SearchResponse {
  return {
    offset: 0,
    limit: 5,
    hasMore: true,
    items: [],
  }
}

function mergeResponses(current: SearchResponse, response: SearchResponse): SearchResponse {
  return {
    offset: response.offset + response.limit,
    limit: response.limit,
    hasMore: response.hasMore,
    items: concatArrays(current.items, response.items),
  }
}

export const customerDetails = createReducer<CustomerDetailsStore>(initialState, {
  [actions.receivedDetails.type]: (_state, action: PayloadAction<Customer>) => {
    return { ...initialState, data: action.payload }
  },
  [actions.receivedAlbelliHistory.type]: (state, action: PayloadAction<SearchResponse>) => {
    state.prevAlbelliResponse = mergeResponses(state.prevAlbelliResponse, action.payload)
  },
})
