import moment from 'moment'

export function toDate(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('ddd, DD MMM YYYY')
}

export function toShortDateMonth(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('ddd, DD MMM')
}

export function toDateTimeNew(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('ddd, DD MMM YYYY, HH:mm')
}
export function toDateTimeNewWithSeconds(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('ddd, DD MMM YYYY, HH:mm:ss')
}

export function toDateTime(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('DD MMM YYYY, HH:mm')
}

export function toDateTimeDetailed(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).format('DD MMM YYYY, HH:mm:ss.SSS')
}

// 14:01 becomes 15:00
export function toDateTimeHour(dt: Date | string | null | undefined): string | undefined {
  if (!dt) {
    return undefined
  }
  return moment(dt).add(1, 'hour').format('ddd, DD MMM YYYY, HH:00')
}

export function isNowInRange(start: Date | string | null | undefined, end: Date | string | null | undefined): boolean {
  if (!start || !end) {
    return false
  }

  return moment(moment()).isBetween(start, end)
}
