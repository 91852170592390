import _ from 'lodash'
import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { DataSource, OrderDetails } from '../../api/order'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import {
  OperationType,
  OrderManagementServiceInstance as orderManagement,
  ProductionOrderDetails,
  ProductWithValue,
} from '../../services/OrderManagementService'
import Checkbox from './Checkbox'
import ProductLabel from './ProductLabel'

interface OrderStructureProps {
  data: OrderDetails
  operation: OperationType
  hideFulfillmentProducts?: boolean
  hideProductionOrders?: boolean
  ignoreCancelledProductionOrders?: boolean
  handleChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>): void
  preselectedProductionOrderId?: string
  preselectedProductId?: string
}

interface OrderStructureState {
  products: ProductWithValue[]
  productionOrders: ProductionOrderDetails[]
}

export default class OrderStructure extends React.Component<OrderStructureProps, OrderStructureState> {
  constructor(props: OrderStructureProps) {
    super(props)

    this.state = { products: [], productionOrders: [] }

    this.handleOrderChange = this.handleOrderChange.bind(this)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleLineChange = this.handleLineChange.bind(this)
    this.handleProductChangeInternal = this.handleProductChangeInternal.bind(this)
    this.handleProductionOrderChange = this.handleProductionOrderChange.bind(this)
    this.handleProductionOrderChangeInternal = this.handleProductionOrderChangeInternal.bind(this)
  }

  componentDidMount() {
    this.populateStateWithProductsIfNecessary()
  }

  componentDidUpdate(_prevProps: OrderStructureProps, prevState: OrderStructureState) {
    this.populateStateWithProductsIfNecessary(prevState)
  }

  populateStateWithProductsIfNecessary(prevState?: OrderStructureState) {
    const { data, operation } = this.props
    if (!data || !data.products || data.products.length === 0) {
      return
    }

    const noFulfillmentProducts = this.state.products.length === 0
    const noProductionOrders = this.state.productionOrders.length === 0

    if (noFulfillmentProducts && noProductionOrders) {
      const products = orderManagement.getProductsWithValue(data, operation)
      var productionOrders = orderManagement.getProductionOrders(data, products, operation)

      if (this.props.ignoreCancelledProductionOrders) {
        productionOrders = productionOrders.filter(productionorder => productionorder.status !== 'Cancelled')
      }

      const productionOrderProductCodes = this.getProductionOrderProductCodes(productionOrders)

      const fulfillmentProducts = products.filter(p => !productionOrderProductCodes.includes(p.id))

      const preselectedProductionOrderId = this.props.preselectedProductionOrderId
      // Select production order, if present
      if (preselectedProductionOrderId !== undefined) {
        const productionOrder = productionOrders.find(po => po.productionOrderId === preselectedProductionOrderId)
        if (productionOrder !== undefined) {
          productionOrder.products.forEach(p => p.value = p.quantity)
        }
      }

      const preselectedProductId = this.props.preselectedProductId
      // Select products, if present
      if (preselectedProductId !== undefined) {
        productionOrders.forEach(po => {
          po.products.forEach(p => {
            if (p.id === preselectedProductId) {
              p.value = p.quantity
            }
          })
        })
      }
      if (this.props.operation === OperationType.RefundPercentage || this.props.operation === OperationType.RefundOrder) {
        this.setState({
          products: products,
          productionOrders: []
        })
      } else {
        this.setState({
          products: this.props.hideFulfillmentProducts ? [] : fulfillmentProducts,
          productionOrders: this.props.hideProductionOrders ? [] : productionOrders
        })
      }
      return
    }

    if (prevState && (prevState.products !== this.state.products || prevState.productionOrders !== this.state.productionOrders)) {
      this.props.handleChange(_.cloneDeep(this.state.products), _.cloneDeep(this.state.productionOrders))
      return
    }
  }

  handleOrderChange(event: any) {
    const value = event.target.checked ? Number.MAX_SAFE_INTEGER : 0

    this.state.products.filter(p => !p.disabled).forEach(p => this.handleProductChangeInternal(p.id, value))
    this.state.productionOrders.forEach(po => this.handleProductionOrderChangeInternal(po.productionOrderId, value))
  }

  // 'productionOrderId' is needed when we have multiple same products (with the same id)
  // if we compare only by 'productId' we will update value only of the first one
  handleProductChange(event: any, productId: string, productionOrderId: string | undefined = undefined) {
    this.handleProductChangeInternal(productId, event.target.checked ? Number.MAX_SAFE_INTEGER : 0, productionOrderId)
  }

  // 'productionOrderId' is needed when we have multiple same products (with the same id)
  // if we compare only by 'productId' we will update value only of the first one
  handleLineChange(event: any, productId: string, productionOrderId: string | undefined = undefined) {
    const target = event.target
    const value = isNaN(target.value) ? 0 : Number(target.value)

    this.handleProductChangeInternal(productId, value, productionOrderId)
  }

  // 'productionOrderId' is needed when we have multiple same products (with the same id)
  // if we compare only by 'productId' we will update value only of the first one
  handleProductChangeInternal(productId: string, value: number, productionOrderId: string | undefined = undefined) {
    var fulfillmentProducts: ProductWithValue[]

    fulfillmentProducts = this.state.products
    const fulfillmentProduct = fulfillmentProducts.find(p => p.id === productId)

    const productionOrder = this.state.productionOrders.find(po => po.productionOrderId === productionOrderId)
    const productionOrderProduct = (productionOrder) ? productionOrder.products.find(p => p.id === productId) : undefined

    if (!fulfillmentProduct && !productionOrderProduct) {
      return
    }

    if (fulfillmentProduct) {
      if (fulfillmentProduct.groupId) {
        // mark entire group (all or nothing)
        for (let j = 0; j < fulfillmentProducts.length; j++) {
          const p = fulfillmentProducts[j]
          if (p.groupId === fulfillmentProduct.groupId) {
            fulfillmentProducts[j].value = Math.min(value, fulfillmentProducts[j].quantity)
          }
        }
      } else {
        fulfillmentProduct.value = Math.min(value, fulfillmentProduct.quantity)
      }
    }

    if (productionOrderProduct) {
      productionOrderProduct.value = Math.min(value, productionOrderProduct.quantity)
    }

    this.setState({ products: fulfillmentProducts })
    this.props.handleChange(_.cloneDeep(this.state.products), _.cloneDeep(this.state.productionOrders))
  }

  handleProductionOrderChange(event: any, productionOrderId: string) {
    this.handleProductionOrderChangeInternal(productionOrderId, event.target.checked ? Number.MAX_SAFE_INTEGER : 0)
  }

  handleProductionOrderChangeInternal(productionOrderId: string, value: number) {
    const productionOrders = this.state.productionOrders
    const productionOrder = productionOrders.find(po => po.productionOrderId === productionOrderId)
    if (productionOrder === undefined) {
      return
    }

    if (this.props.operation === OperationType.Cancel) {
      if (productionOrder.status === 'Cancelled' || productionOrder.status === 'Shipped') {
        return
      }
    }

    productionOrder.products.forEach(p =>
      this.handleProductChangeInternal(p.id, Math.min(value, p.quantity), productionOrder.productionOrderId))

    this.setState({ productionOrders })
  }

  render() {
    const allFulfillmentEnabledChecked =
      this.state.products.filter(p => !p.disabled).length === 0 ||
      this.state.products.filter(p => !p.disabled).every(p => p.value === p.quantity)

    const allProductionOrdersEnabledChecked =
      this.state.productionOrders.length === 0 ||
      this.state.productionOrders.every(po => po.products.every(p => p.value === p.quantity))

    const allDisabled = this.state.products.every(p => !!p.disabled) && this.state.productionOrders.every(po => !!po.disabled)

    return (
      <div className="order-structure">
        <FormGroup className="mb-3 big-font" check>
          <Label check>
            <Checkbox
              checked={allFulfillmentEnabledChecked && allProductionOrdersEnabledChecked && !allDisabled}
              disabled={allDisabled}
              isIndeterminate={this.state.products.some(p => p.value > 0) && !allFulfillmentEnabledChecked}
              onChange={this.handleOrderChange}
              id="full-order-checkbox"
            />
            <span className={`ml-3 ${allDisabled && 'disabled'}`}>All products</span>
          </Label>
        </FormGroup>
        {
          this.showProductionOrders() &&
          this.props.data.source !== DataSource.Studenten &&
          this.state.productionOrders.map(po => this.renderProductionOrderWithProductItems(po))
        }
        {
          this.props.data.source !== DataSource.Studenten &&
          this.state.products.map(p => this.renderProductItem(p))
        }
      </div>
    )
  }

  renderProductItem(product: ProductWithValue, productionOrderId: string | undefined = undefined) {
    const checkboxId = `${product.id}_checkbox`
    const previewImage = Service.resolveProductPreviewUrl(product)
    const productType = Service.resolveProductDescriptionByType(product.productId)

    return (
      <div className="ml-4 mb-3" key={product.id}>
        <FormGroup check>
          {
            !product.isCheckboxHidden && (
              <Checkbox
                id={checkboxId}
                checked={product.value === product.quantity}
                isIndeterminate={product.value !== product.quantity && product.value !== 0}
                onChange={(e: any) => this.handleProductChange(e, product.id, productionOrderId)}
                disabled={product.disabled}
                title={product.tooltip}
              />
            )}
          <ProductLabel
            productNumber={product.id}
            productTitle={product.title}
            productType={productType}
            quantity={product.isOrderLineHidden ? undefined : product.quantity}
            value={product.value}
            checkboxId={checkboxId}
            handleLineChange={(e: any) => this.handleLineChange(e, product.id, productionOrderId)}
            previewImageSrc={previewImage}
            disabled={product.disabled}
            tooltip={product.tooltip}
            isLineDisabled={product.isOrderLineDisabled}
          />
        </FormGroup>
      </div>
    )
  }

  renderProductionOrderWithProductItems(productionOrder: ProductionOrderDetails) {
    const productionOrderId = productionOrder.productionOrderId
    const products = productionOrder.products

    if (productionOrderId === undefined) {
      return
    }
    const checkboxId = `${productionOrderId}_checkbox`

    const quantity = productionOrder.products.map(p => p.quantity).reduce((result, nextQuantity) => result + nextQuantity, 0)
    const value = productionOrder.products.map(p => p.value).reduce((result, nextValue) => result + nextValue, 0)

    return (
      <div className="ml-4 mb-3" key={productionOrderId}>
        <FormGroup check>
          <Checkbox
            id={checkboxId}
            checked={value === quantity}
            isIndeterminate={value !== quantity && value !== 0}
            disabled={productionOrder.disabled}
            onChange={(e: any) => this.handleProductionOrderChange(e, productionOrderId)}
          />
          <span className="ml-3">{productionOrderId}</span>
          {products.map(p => this.renderProductItem(p, productionOrderId))}
        </FormGroup>
      </div>
    )
  }

  private getProductionOrderProductCodes(productionOrders: ProductionOrderDetails[]): string[] {
    return this.getProductionOrderProducts(productionOrders).map(p => p.id)
  }

  private getProductionOrderProducts(productionOrders: ProductionOrderDetails[]): ProductWithValue[] {
    return productionOrders.map(po => po.products).reduce((result, nextProducts) => result.concat(nextProducts), [])
  }

  private showProductionOrders() {
    return this.props.operation === OperationType.Cancel ||
      this.props.operation === OperationType.Reset ||
      this.props.operation === OperationType.Note
  }
}
