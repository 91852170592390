import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkReprocessRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkReprocessBase, InputTypes } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkReprocessForm from '../forms/BulkReprocessForm'
import { ResetReason } from '../../api'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
  resetReasons: ResetReason[]
}

class BulkReprocessPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkReprocess = this.sendBulkReprocess.bind(this)
  }

  sendBulkReprocess(x: BulkReprocessBase) {
    const productCodesList = x.productCodes
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkReprocessRequest({
        productNumbers: (x.inputType === InputTypes.ProductNumbers) ? productCodesList : undefined,
        orderLineIds: (x.inputType === InputTypes.Orderlines) ? productCodesList : undefined,
        reasonCode: x.reasonCode,
        comment: x.message
      })
    )
  }

  renderForm(resetReasons: ResetReason[]) {
    return <BulkReprocessForm input=
      {{
        inputType: InputTypes.ProductNumbers,
        productCodes: '',
        message: '',
        resetReasons: resetReasons,
        reasonCode: ''
      }}
      onSubmit={this.sendBulkReprocess}></BulkReprocessForm>
  }

  renderResponse() {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Reprocess Result</h1>
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        <div className="response-container">
          {this.props.bulkReprocessResponse.productsResetSuccess && this.props.bulkReprocessResponse.productsResetSuccess.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-success status-heading'>
                  Reprocessed Successfully
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Product Number
              </Col>
                <Col md="10" className="column-highlight">
                  Order Line Ids
              </Col>
              </Row>
              {this.props.bulkReprocessResponse.productsResetSuccess.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="success-column">
                    {x.productNumber}
                  </Col>
                  <Col md="10" className="success-column">
                    {x.orderLineIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkReprocessResponse.productsResetFailed && this.props.bulkReprocessResponse.productsResetFailed.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Reprocess Failed
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Product Number
              </Col>
                <Col md="10" className="column-highlight">
                  Order Line Ids
              </Col>
              </Row>
              {this.props.bulkReprocessResponse.productsResetFailed.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="failed-column">
                    {x.productNumber}
                  </Col>
                  <Col md="10" className="failed-column">
                    {x.orderLineIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkReprocessResponse.productsNotFound && this.props.bulkReprocessResponse.productsNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Products Not Found
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Product Number
              </Col>
                <Col md="10" className="column-highlight">
                  Reason
              </Col>
              </Row>
              {this.props.bulkReprocessResponse.productsNotFound.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="notfound-column">
                    {x.productNumber}
                  </Col>
                  <Col md="10" className="notfound-column">
                    {x.reason}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkReprocessResponse.orderLinesNotFound && this.props.bulkReprocessResponse.orderLinesNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Order Lines Not Found
              </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Order Line Ids
              </Col>
                <Col md="10" className="column-highlight">
                  Reason
              </Col>
              </Row>
              {this.props.bulkReprocessResponse.orderLinesNotFound.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="notfound-column">
                    {x.orderLineId}
                  </Col>
                  <Col md="10" className="notfound-column">
                    {x.reason}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </Container>
    )
  }

  render() {
    return this.props.bulkReprocessResponse.valid ? this.renderResponse() : this.renderForm(this.props.resetReasons)
  }
}

export default BulkReprocessPanel
