import _, { Dictionary, NumericDictionary } from 'lodash'
import { ProductionOrderDetails } from '../services/OrderManagementService'

const currencyRegex = /^\d+(\.\d{1,2})?$/

const currencyFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export function formatCurrency(value?: number) {
  return value !== undefined ? currencyFormatter.format(value) : ''
}

export const validators = {
  required: (value: string | number | null | undefined) => {
    if (value === null || value === undefined || value === '') {
      return 'This field is required'
    }
    return undefined
  },
  maxLength9: (value: any) => {
    if (value && typeof value === 'string' && value.length > 9) {
      return 'Max length is 9'
    }
    return undefined
  },
  maxLength10: (value: any) => {
    if (value && typeof value === 'string' && value.length > 10) {
      return 'Max length is 10'
    }
    return undefined
  },
  maxLength20: (value: any) => {
    if (value && typeof value === 'string' && value.length > 20) {
      return 'Max length is 20'
    }
    return undefined
  },
  maxLength24: (value: any) => {
    if (value && typeof value === 'string' && value.length > 24) {
      return 'Max length is 24'
    }
    return undefined
  },
  maxLength25: (value: any) => {
    if (value && typeof value === 'string' && value.length > 25) {
      return 'Max length is 25'
    }
    return undefined
  },
  maxLength30: (value: any) => {
    if (value && typeof value === 'string' && value.length > 30) {
      return 'Max length is 30'
    }
    return undefined
  },
  maxLength50: (value: any) => {
    if (value && typeof value === 'string' && value.length > 50) {
      return 'Max length is 50'
    }
    return undefined
  },
  maxLength100: (value: any) => {
    if (value && typeof value === 'string' && value.length > 100) {
      return 'Max length is 100'
    }
    return undefined
  },
  maxLength200: (value: any) => {
    if (value && typeof value === 'string' && value.length > 200) {
      return 'Max length is 200'
    }
    return undefined
  },
  maxLengthUnsupported: (value: any) => {
    return 'Max length validation requested but not implemented'
  },
  minLength2: (value: any) => {
    if (value && typeof value === 'string' && value.length < 2) {
      return 'Min length is 2'
    }
    return undefined
  },
  minLengthUnsupported: (value: any) => {
    return 'Min length validation requested but not implemented'
  },
  currency: (value: any) => {
    if (currencyRegex.test(value)) {
      return undefined
    }

    return 'Invalid money value (example: 123.45)'
  },
  percentage: (value: number | undefined) => {
    if (value !== undefined && (value < 1 || value > 99)) {
      return 'Value should be between 1-99 %'
    }
    return undefined
  },
}

export function arrayToDictionary<TItem>(
  array: TItem[] | undefined | null,
  keySelector: { (item: TItem): string | number },
  valueSelector?: { (item: TItem): any }
): Dictionary<TItem> | NumericDictionary<TItem> {
  const getValue = valueSelector || (valueSelector = (item) => item)

  return (
    (array &&
      array.reduce((acc, item) => {
        return { ...acc, [keySelector(item)]: getValue(item) }
      }, {})) ||
    {}
  )
}

export function concatArrays<T>(array1: Array<T> | undefined, array2: Array<T> | undefined): Array<T> | undefined {
  if (!array2 || array2.length === 0) return array1
  if (!array1 || array1.length === 0) return array2
  return _.concat(array1, array2)
}

export const nameof = <T>(name: keyof T) => name

export function threeValuedLogic(value: boolean | undefined | null, ifTrue: any, ifFalse: any, ifUnknown: any): any {
  if (value === true) {
    return ifTrue
  }

  if (value === false) {
    return ifFalse
  }

  return ifUnknown
}

export function fromCamelCase(str: string) {
  return str.replace(/([A-Z])/g, ' $1')
}

export function copyToClipboard(value: string) {
  const el = document.createElement('textarea')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function isNullOrWhitespace(input: string) {
  if (typeof input === 'undefined' || input === null) return true

  return input.replace(/\s/g, '').length < 1
}

export function productionOrdersInProgress(productionOrders: ProductionOrderDetails[]) {
  const specialStatuses = ['Shipped', 'PartiallyShipped', 'Cancelled']
  return productionOrders.some(
    (productionOrder) =>
      productionOrder.products.some((product) => product.value > 0) && specialStatuses.includes(productionOrder.status) === false
  )
}
