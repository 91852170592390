import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { sendComment } from '../actions/commentActions'
import * as actions from '../actions/mixupDetailsActions'
import { CommentType, MixupRecord, NoteRequestBase } from '../api'
import { MixupForm } from '../components/forms/MixupForm'
import MixupNotesPanel from '../components/panels/MixupNotesPanel'
import { CommonProps, GlobalPanels } from '../models'
import { AppStore, MixupDetailsStore, MixupDetailsViewStore } from '../reducers/states'

interface Props extends CommonProps, MixupDetailsStore, MixupDetailsViewStore {}

class MixupFormView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.sendComment = this.sendComment.bind(this)
    this.toggleNotes = this.toggleNotes.bind(this)
  }

  componentDidMount() {
    if (this.props.id !== this.props.match.params.id) {
      this.fillState()
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fillState()
    }
  }

  fillState() {
    const id = this.props.match.params.id
    const isNew = id === 'new'

    if (isNew) {
      const qs = queryString.parse(this.props.history.location.search)

      this.props.dispatch(actions.newMixupOpen(qs.orderRef as string))
    } else {
      this.props.dispatch(actions.editMixupOpen(id))
    }
  }

  handleSubmit(state: MixupRecord) {
    this.props.dispatch(actions.mixupSend(state))
  }

  sendComment(msg: NoteRequestBase) {
    this.props.dispatch(
      sendComment({
        type: CommentType.MixUpNote,
        parentId: this.props.form.id,
        body: msg.body,
        secondaryKey: undefined,
      })
    )
  }

  isNew() {
    return !this.props.form.id
  }

  toggleNotes() {
    this.props.dispatch(actions.togglePanel(GlobalPanels.mixupNotes.accessKey))
  }

  render() {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col sm="12" md="6">
            <h1 className="mb-4">{this.isNew() ? 'Register new mix-up' : 'Update registered mix-up'}</h1>
            <MixupForm form="mix-up-form" initialValues={this.props.form} onSubmit={this.handleSubmit} />
          </Col>
          <Col>
            {!this.isNew() && (
              <button className="medium" onClick={this.toggleNotes}>
                Notes
              </button>
            )}
          </Col>
          {this.renderNotes()}
        </Row>
      </Container>
    )
  }

  renderNotes() {
    if (this.isNew()) {
      return
    }

    const notesPanel: any = {
      ...this.props.panels[GlobalPanels.mixupNotes.accessKey],
      toggle: this.toggleNotes,
    }

    return (
      <MixupNotesPanel {...notesPanel} parentId={this.props.form.id} notes={this.props.notes} onNoteSubmit={this.sendComment} />
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.mixupDetails,
    ...state.mixupDetailsView,
  }
}

export default connect(mapStateToProps)(MixupFormView)
