export type TooltipKey = 'orderReset' | 'orderCancel' | 'showByScan' | 'reupload' | 'orderRedo'

export interface Panel {
  accessKey: string
  tooltipKey?: TooltipKey
  title: string
  isOpen: boolean
  isLoading: boolean
}

export interface TogglePanel extends Panel {
  toggle(key?: React.Key): void
}

export interface WidgetNotification {
  productCode: string
  title: string
  message: string
}

export interface WidgetSidePanel extends Panel {
  notification?: WidgetNotification
}

export type PanelsCollection = { [panel: string]: Panel | undefined }

export type StaticPanelsCollection = { [panel: string]: Panel }

export const GlobalPanels: StaticPanelsCollection = {
  orderNotes: {
    accessKey: 'orderNotes',
    title: 'Notes',
    isOpen: false,
    isLoading: false,
  },
  orderTraces: {
    accessKey: 'orderTraces',
    title: 'Delivery',
    isOpen: false,
    isLoading: false,
  },
  showByScan: {
    accessKey: 'showByScan',
    tooltipKey: 'showByScan',
    title: 'Show by scan',
    isOpen: false,
    isLoading: false,
  },
  orderCancel: {
    accessKey: 'orderCancel',
    tooltipKey: 'orderCancel',
    title: 'Cancel order',
    isOpen: false,
    isLoading: false,
  },
  orderReset: {
    accessKey: 'orderReset',
    tooltipKey: 'orderReset',
    title: 'Reset order',
    isOpen: false,
    isLoading: false,
  },
  orderRedo: {
    accessKey: 'orderRedo',
    tooltipKey: 'orderRedo',
    title: 'Redo order',
    isOpen: false,
    isLoading: false,
  },
  orderPrice: {
    accessKey: 'orderPrice',
    title: 'Price details',
    isOpen: false,
    isLoading: false,
  },
  mixupNotes: {
    accessKey: 'mixupNotes',
    title: 'Notes',
    isOpen: false,
    isLoading: false,
  },
  changeAddress: {
    accessKey: 'changeAddress',
    title: 'Change address',
    isOpen: false,
    isLoading: false,
  }
}
