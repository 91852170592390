import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'reactstrap/lib/Spinner'
import { AppStore } from '../../reducers/states'
import { generateNewCode } from '../../slices/discounts'
import SidePanel from '../panels/SidePanel'
import styles from './GenerateCodePanel.module.css'

export interface ReplacementCodePanelProps {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  discountId: string
}

export const GenerateCodePanel = (props: ReplacementCodePanelProps) => {
  const dispatch = useDispatch()
  const discounts = useSelector((state: AppStore) => state.discounts)

  const regenerateCode = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    await dispatch(generateNewCode(props.discountId))
  }

  const copyToClipboard = (e: React.MouseEvent<HTMLElement>, text: string) => {
    e.preventDefault()
    navigator.clipboard.writeText(text)
  }

  return (
    <SidePanel
      toggle={() => props.setIsOpen(!props.isOpen)}
      accessKey={''}
      title={'Generate replacement code'}
      isOpen={props.isOpen}
      isLoading={discounts.isGeneratingCode}>
      <button className="medium" onClick={regenerateCode}>
        Generate replacement code
      </button>
      {discounts.isGeneratingCode && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {discounts.newGeneratedCode && (
        <div>
          <hr />
          <div className={styles.newCode}>
            <b>{discounts.newGeneratedCode}</b>{' '}
            <a href="" onClick={(e) => copyToClipboard(e, discounts.newGeneratedCode)}>
              Copy to clipboard
            </a>
          </div>

          <hr />
        </div>
      )}
    </SidePanel>
  )
}
