import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/searchActions'
import { JustSearchResponse, SearchFilter, SearchResponse, SearchTypes } from '../api'
import { concatArrays } from '../utils'
import { SearchStore } from './states'

const initialFilter = { type: SearchTypes.ByOrderCode, query: '' }

const initialState: SearchStore = {
  isDirty: false,
  isAlbelliLoading: false,
  prevAlbelliResponse: getEmptyResponse(),
  filter: initialFilter,
  historyFolded: false,
  foldedOrders: [],
  customerSearchResults: undefined,
  orderSearchResults: undefined,
}

function getEmptyResponse(): SearchResponse {
  return {
    offset: 0,
    limit: 5,
    hasMore: true,
    items: [],
  }
}

function getResponse(current: SearchResponse, response: SearchResponse): SearchResponse {
  return {
    offset: response.offset + response.limit,
    limit: response.limit,
    hasMore: response.hasMore,
    items: concatArrays(response.items, current.items),
  }
}

export const search = createReducer<SearchStore>(initialState, {
  [actions.search.type]: (state, action: PayloadAction<SearchFilter>) => {
    state.isDirty = true
    state.isAlbelliLoading = true
    state.filter = {
      ...action.payload,
      query: action.payload.query ? action.payload.query.trim() : '',
    }
  },
  [actions.reset.type]: (_state, action: PayloadAction<SearchFilter | undefined>) => ({
    ...initialState,
    filter: action.payload || initialFilter,
  }),
  [actions.receivedAlbelliResults.type]: (state, action: PayloadAction<SearchResponse>) => {
    state.isAlbelliLoading = false
    state.prevAlbelliResponse = getResponse(state.prevAlbelliResponse, action.payload)
  },
  [actions.toggleAllDetails.type]: (state) => {
    state.historyFolded = !state.historyFolded
    state.foldedOrders.forEach((_, rowIndex) => (state.foldedOrders[rowIndex] = state.historyFolded))
  },
  [actions.toggleOrderDetails.type]: (state, action: PayloadAction<number>) => {
    const rowIndex = action.payload
    state.foldedOrders[rowIndex] =
      state.foldedOrders[rowIndex] !== undefined ? !state.foldedOrders[rowIndex] : !state.historyFolded
  },
  [actions.receivedSearchResults.type]: (state, action: PayloadAction<JustSearchResponse>) => {
    state.isAlbelliLoading = false
    state.customerSearchResults = action.payload.customers
    state.orderSearchResults = action.payload.orders
  },
})
