import axios, { AxiosInstance } from 'axios'
import { AuthInstance } from './AuthService'

export default async function createHttpClient(baseURL: string): Promise<AxiosInstance> {
  const token = await AuthInstance.getToken()

  const instance = axios.create({
    baseURL,
    timeout: 1000 * 60, // one minute
    headers: {
      common: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  })

  instance.interceptors.request.use(
    function(config) {
      console.debug(config)
      return config
    },
    function(error) {
      if (error.request) {
        console.error(error.request)
      } else {
        console.error(error)
      }
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function(response) {
      console.debug(response)
      return response
    },
    function(error) {
      if (error.response) {
        console.error(error.response)
      } else {
        console.error(error)
      }
      return Promise.reject(error)
    }
  )

  return instance
}
