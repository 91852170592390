import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Layout from '../components/global/Layout'
import BulkOperationsView from '../views/BulkOperationsView'
import CustomerDetailsView from '../views/CustomerDetailsView'
import ErrorView from '../views/ErrorView'
import MixupFormView from '../views/MixupFormView'
import NotFoundView from '../views/NotFoundView'
import OrderDetailsView from '../views/OrderDetailsView'
import OrderTimelineView from '../views/OrderTimelineView'
import PermissionsView from '../views/PermissionsView'
import ProductView from '../views/ProductView'
import RefundsView from '../views/RefundsView'
import ReleaseNotesView from '../views/ReleaseNotesView'
import SearchAccountView from '../views/SearchAccountView'
import SearchDiscountView from '../views/SearchDiscountView'
import SearchMixupView from '../views/SearchMixupView'
import SearchProjectView from '../views/SearchProjectView'
import SearchView from '../views/SearchView'
import StudentenOrderDetailsView from '../views/StudentenOrderDetailsView'
import routes from './routes'

export default () => (
  <Layout>
    <Switch>
      <Redirect exact from={routes.HOME()} to={routes.SEARCH()} />
      <Route exact path={routes.SEARCH()} component={SearchView} />
      <Route exact path={routes.ORDERS_VIEW()} component={OrderDetailsView} />
      <Route exact path={routes.STUDENTEN_ORDERS_VIEW()} component={StudentenOrderDetailsView} />
      <Route exact path={routes.ORDERS_PRODUCT_VIEW()} component={ProductView} />
      <Route exact path={routes.ORDERS_TIMELINE()} component={OrderTimelineView} />
      <Route exact path={routes.PRODUCT_CONTENT_VIEW()} component={ProductView} />
      <Route exact path={routes.RELEASE_NOTES()} component={ReleaseNotesView} />
      <Route exact path={routes.CUSTOMERS_VIEW()} component={CustomerDetailsView} />
      <Route exact path={routes.MIXUPS()} component={SearchMixupView} />
      <Route exact path={routes.DISCOUNTS()} component={SearchDiscountView} />
      <Route exact path={routes.MIXUPS_DETAILS()} component={MixupFormView} />
      <Route exact path={routes.PROJECTS()} component={SearchProjectView} />
      <Route exact path={routes.ACCOUNTS()} component={SearchAccountView} />
      <Route exact path={routes.ACCOUNTS_VIEW()} component={SearchAccountView} />
      <Route exact path={routes.BULK_OPERATIONS()} component={BulkOperationsView} />
      <Route exact path={routes.PERMISSIONS()} component={PermissionsView} />
      <Route exact path={routes.REFUNDS()} component={RefundsView} />
      <Route path={routes.NOT_FOUND()} component={NotFoundView} />
      <Route path={routes.ERROR()} component={ErrorView} />
      <Route component={NotFoundView} />
    </Switch>
  </Layout>
)
