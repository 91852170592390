import { PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import * as global from '../actions/globalActions'
import * as actions from '../actions/searchActions'
import {
  codes,
  DataSource,
  JustSearchResponse,
  LogMessage,
  OrderSearchResult,
  ProductContentSearchResult,
  SearchFilter,
  SearchResponse,
  SearchTypes,
} from '../api'
import { AppStore, SearchStore } from '../reducers/states'
import routes from '../route/routes'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

const emptyResponse = {
  offset: 0,
  limit: 1,
  hasMore: false,
  items: [],
}

function* search(action: PayloadAction<SearchFilter>) {
  const state = yield select((store: AppStore) => store.search)

  const query = action.payload.query.trim()

  switch (action.payload.type) {
    case SearchTypes.ByOrderCode:
      yield fork(lookUpOrder, query, 'orders/lookupByCode')
      break

    case SearchTypes.ByTicketId:
      yield fork(lookUpOrder, query, 'orders/lookupByTicket')
      break

    case SearchTypes.ByTrackingCode:
      yield fork(lookUpOrder, query, 'orders/lookupByTrackingNumber')
      break

    case SearchTypes.ByCustomerEmail:
      yield fork(fetchAlbelliData, query, state, 'customers/byEmail')
      break

    case SearchTypes.ByCustomerPhone:
      yield fork(fetchAlbelliData, query, state, 'customers/byPhone')
      break

    case SearchTypes.ByCustomerName:
      yield fork(fetchAlbelliData, query, state, 'customers/byName')
      break

    case SearchTypes.ByAddress:
      yield fork(fetchAlbelliData, query, state, 'orders/byAddress')
      break

    case SearchTypes.ByProductContent:
      yield fork(lookUpContent, query, 'product/lookupByContentId')
      break

    case SearchTypes.JustSearch:
      yield fork(justSearch, query)
      break

    default:
      yield put(Errors.toErrorAction(undefined, 'Search type not found!'))
      yield put(global.logError(new LogMessage(action.payload, codes.unknownSearchType)))
      break
  }
}

function* lookUpOrder(query: string, prefix: string) {
  try {
    const response: OrderSearchResult = yield call(ApiServiceInstance.get, `${prefix}/${query}`)

    if (response.isOldLegacyOrder === true) {
      // TODO: maybe not redirect but show in the search results
      yield put(push(routes.NOT_FOUND()))
      // REVIEW: move this logic/message to somewhere centralized
      yield put(global.showWarning('Orders placed before 1st March 2012 cannot be displayed.'))
      return
    }

    yield put(push(routes.ORDERS_VIEW({ orderId: response.id, selected: response.matchedProductCode })))
  } catch (e) {
    // TODO: fix copy paste
    yield put(actions.receivedAlbelliResults(emptyResponse))
    if (!e.response || e.response.status !== 404) {
      yield put(Errors.toErrorAction(e))
    }
  }
}

function* lookUpContent(query: string, prefix: string) {
  try {
    const response: ProductContentSearchResult = yield call(ApiServiceInstance.get, `${prefix}/${query}`)

    if (response.isOrderSupported === true) {
      yield put(
        push(
          routes.ORDERS_PRODUCT_VIEW({
            orderId: response.orderCode,
            productId: response.productCode,
            contentId: response.contentId,
          })
        )
      )
    } else {
      yield put(
        push(
          routes.PRODUCT_CONTENT_VIEW({
            contentId: response.contentId,
          })
        )
      )
    }
  } catch (e) {
    yield put(actions.receivedAlbelliResults(emptyResponse))
    if (!e.response || e.response.status !== 404) {
      yield put(Errors.toErrorAction(e))
    }
  }
}

function* fetchAlbelliData(queryString: string, state: SearchStore, prefix: string) {
  if (!state.prevAlbelliResponse.hasMore) {
    yield put(actions.receivedAlbelliResults(emptyResponse))
    return
  }

  const query = yield getQueryString(
    DataSource.Albelli,
    queryString,
    state.prevAlbelliResponse.limit,
    state.prevAlbelliResponse.offset
  )

  yield fetchSearchData(prefix, query, actions.receivedAlbelliResults)
}

function getQueryString(dataSource: DataSource, query: string, limit: number, offset: number) {
  const queryObj = { dataSource, query, limit, offset }

  return queryString.stringify(queryObj)
}

function* fetchSearchData(prefix: string, query: string, action: PayloadActionCreator<SearchResponse, string>) {
  let response: SearchResponse = emptyResponse

  try {
    response = yield call(ApiServiceInstance.get, `${prefix}?${query}`)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(action(response))
  }
}

function* justSearch(query: string) {
  try {
    const qs = queryString.stringify({ query: query })
    const response: JustSearchResponse = yield call(ApiServiceInstance.get, `search?${qs}`)

    yield put(actions.receivedSearchResults(response))

    // Redirect
    if (
      response.orders &&
      response.orders.length === 1 &&
      response.orders[0].source === DataSource.Albelli &&
      response.orders[0].isOldLegacyOrder === false
    ) {
      yield put(push(routes.ORDERS_VIEW({ orderId: response.orders[0].id, selected: response.orders[0].matchedProductCode })))
    }
  } catch (e) {
    if (!e.response || e.response.status !== 404) {
      yield put(Errors.toErrorAction(e))
    }
    yield put(actions.receivedSearchResults({}))
  }
}

export default function* root() {
  yield takeLatest(actions.search, search)
}
