import { AuthInstance } from '../services/AuthService'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { LogMessage, Severity } from '../api'
import createHttpClient from './HttpService'
import { LocalStorageInstance } from './LocalStorageService'

class LogApiService {
  private client?: AxiosInstance

  async error(logMessage: LogMessage) {
    return this.log(logMessage, Severity.error)
  }

  async info(logMessage: LogMessage) {
    return this.log(logMessage, Severity.info)
  }

  async warn(logMessage: LogMessage) {
    return this.log(logMessage, Severity.warn)
  }

  private async getClient() {
    if (this.client) {
      return this.client
    }

    const config = LocalStorageInstance.getGlobalConfiguration()
    if (!config) {
      return (config: AxiosRequestConfig) => console.error('Untracked log message:', config.data)
    }

    this.client = await createHttpClient(`${config.userEventsEndpoint}`)

    return this.client
  }

  private getCorsHeaders(){
    const headers = {
      'Access-Control-Allow-Origin': window.ALBELLI_FRONTEND_URL,
    }

    return headers
  }

  private log(logMessage: LogMessage, severity: Severity) {
    const headers = this.getCorsHeaders()  

    this.getClient()
      .then((client) => {
        logMessage.logLevel = severity
        logMessage.userName = AuthInstance.getUser().userName

        return client({ url:'logs', method: 'post', data: logMessage, headers: headers})
      })
      .catch((e) => {
        console.error('Failed to send log message', e)
      })
  }
}

export const LogApiInstance = new LogApiService()
