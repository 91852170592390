import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/mixupActions'
import { MixupSearchFilter, MixupSearchResponse } from '../api'
import { concatArrays } from '../utils'
import { MixupSearchStore } from './states'

const initialState: MixupSearchStore = {
  isLoading: false,
  data: getEmptyResponse(),
  filter: {},
}

function getEmptyResponse(): MixupSearchResponse {
  return {
    paginationToken: '{}',
    limit: 10,
    hasMore: true,
  }
}

function getResponse(current: MixupSearchResponse, response: MixupSearchResponse): MixupSearchResponse {
  if (!response) {
    return current
  }

  return {
    paginationToken: response.paginationToken,
    limit: response.limit,
    hasMore: response.hasMore,
    items: concatArrays(current.items, response.items) || [],
  }
}

export const mixups = createReducer<MixupSearchStore>(initialState, {
  [actions.loadStarted.type]: (state) => {
    state.isLoading = true
  },
  [actions.receivedMixups.type]: (state, action: PayloadAction<MixupSearchResponse>) => {
    state.isLoading = false
    state.data = getResponse(state.data, action.payload)
  },
  [actions.filterChanged.type]: (state, action: PayloadAction<MixupSearchFilter | undefined>) => {
    state.isLoading = false
    state.data = getEmptyResponse()
    state.filter = action.payload || {}
  },
})
