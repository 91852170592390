import React, { ChangeEvent, MouseEvent } from 'react'
import { Input, Label } from 'reactstrap'
import defaultImage from '../../assets/img/placeholder-image.png'
import { highlightText, selectOnClick } from '../../utils'

interface Props {
  productNumber?: string
  highlighted?: boolean
  checkboxId?: string
  productType?: string
  productTitle?: string
  value?: number
  quantity?: number
  hidePreviewImage?: boolean
  previewImageSrc?: string
  disabled?: boolean
  tooltip?: string
  isLineDisabled?: boolean
  handleLineChange?(event: ChangeEvent): void
}

export default function ProductLabel(props: Props) {
  const selectTextInternal = (e: MouseEvent) => {
    !props.handleLineChange && selectOnClick(e)
  }

  return (
    <div className="d-flex flex-row">
      {!props.hidePreviewImage && (
        <div className="product-preview">
          <img src={props.previewImageSrc || defaultImage} alt="preview" />
        </div>
      )}
      <div className={`d-inline-flex flex-column ${props.value === props.quantity ? 'checked' : ''}`}>
        <Label
          check
          className={`d-inline-flex flex-column ${props.disabled && 'disabled'}`}
          for={props.checkboxId}
          title={props.tooltip}>
          {props.productTitle && <span onClick={selectTextInternal}>{`“${props.productTitle}”`}</span>}
          {props.productType && (
            <span className="normal-weight" onClick={selectTextInternal}>
              {props.productType}
            </span>
          )}
          {props.productNumber && (
            <span onClick={selectTextInternal} className="normal-weight">
              {highlightText(props.productNumber, !!props.highlighted)}
            </span>
          )}
        </Label>
        {props.handleLineChange && !props.disabled && (
          <span className={`normal-weight ${props.quantity === 1 || props.quantity === undefined ? 'd-none' : 'd-inline'}`}>
            <Input
              type="text"
              name={props.productNumber}
              value={props.value}
              onChange={props.handleLineChange}
              disabled={props.isLineDisabled === undefined ? false : props.isLineDisabled}
              bsSize="sm"
              className="mr-2 mt-1 d-inline"
              style={{ maxWidth: '50px' }}
            />
            Qty
          </span>
        )}
      </div>
    </div>
  )
}
