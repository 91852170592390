import React from 'react'
import apple from '../../assets/img/editors/apple.svg'
import win from '../../assets/img/editors/win.svg'
import { EditorDescription } from '../../models'
import RoundIcon from './RoundIcon'

const editorImages: { [type: string]: string | undefined } = {
  apple,
  win,
}

interface Props extends React.Props<any> {
  elementId: string
  description: EditorDescription
  isSmall?: boolean
}

export default function EditorIcon({ description: editor, elementId, isSmall }: Props) {
  const content = editor.name + (editor.version ? ` [${editor.version}]` : '')
  const type = editor.type
  const image = editorImages[type]
  const size = isSmall === true ? 24 : 32

  if (image) {
    return <img src={image} height={size} width={size} title={content} id={elementId} alt={content} />
  }

  return <RoundIcon text={type.toUpperCase()} title={content} id={elementId} className={`${isSmall === true ? 'small' : ''}`} />
}
