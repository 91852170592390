import { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/customerDetailsActions'
import * as global from '../actions/globalActions'
import { DataSource } from '../api'
import { AppStore } from '../reducers/states'
import routes from '../route/routes'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* fetchDetails(action: PayloadAction<string>) {
  try {
    yield put(global.showSpinner())
    const details = yield call(ApiServiceInstance.get, `customers/${action.payload}`)
    yield put(actions.receivedDetails(details))

    if (details) {
      const state = yield select((store: AppStore) => store.customerDetails)
      yield requestAlbelliOrders(details.email, state.prevAlbelliResponse.limit, state.prevAlbelliResponse.offset)
    }
  } catch (e) {
    yield put(Errors.toErrorAction(e))
    if (e.response && e.response.status === 404) {
      yield put(push(routes.NOT_FOUND()))
    } else {
      yield put(push(routes.ERROR()))
    }
  } finally {
    yield put(global.hideSpinner())
  }
}

function* fetchAlbelliHistory() {
  try {
    const state = yield select((store: AppStore) => store.customerDetails)

    yield requestAlbelliOrders(state.data.email, state.prevAlbelliResponse.limit, state.prevAlbelliResponse.offset)
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* requestAlbelliOrders(id: string, limit: number, offset: number) {
  const query = yield getQueryString(DataSource.Albelli, id, limit, offset)
  const orders = yield call(ApiServiceInstance.get, `orders/byCustomerEmail?${query}`)

  yield put(actions.receivedAlbelliHistory(orders))
}

function getQueryString(dataSource: DataSource, id: string, limit: number, offset: number) {
  const queryObj = {
    dataSource: dataSource,
    query: id,
    limit: limit,
    offset: offset,
  }

  return queryString.stringify(queryObj)
}

export default function* root() {
  yield all([
    yield takeLatest(actions.requestDetails, fetchDetails),
    yield takeLatest(actions.requestAlbelliOrders, fetchAlbelliHistory),
  ])
}
