import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Input, Label, FormGroup, Col, ButtonGroup, Button } from 'reactstrap'
import Select from '../components/forms/Select'
import * as orderDetailsActions from '../actions/orderDetailsActions'
import {
  codes,
  FulfillmentPlant,
  GlobalConfiguration,
  LogMessage,
  OrderEvent,
  OrderEventLevel,
  OrderEvents,
  OrderEventSourceType,
} from '../api'
import closed from '../assets/img/closed.svg'
import notes from '../assets/img/flags/notes.svg'
import reset from '../assets/img/flags/reset.svg'
import reuploadCancelledIcon from '../assets/img/flags/reupload-cancel.svg'
import reuploadPermittedIcon from '../assets/img/flags/reupload-permitted.svg'
import reuploadedIcon from '../assets/img/flags/reupload-used.svg'
import sbs from '../assets/img/flags/sbs.svg'
import refundIcon from '../assets/img/flags/refund.svg'
import open from '../assets/img/open.svg'
import sort from '../assets/img/sort.svg'
import ActionLink from '../components/generic/ActionLink'
import Flag from '../components/generic/Flag'
import Grid, { GridColumn } from '../components/generic/Grid'
import NavigationLinks from '../components/generic/NavigationLinks'
import OrderHeader from '../components/generic/OrderHeader'
import OrderOverview from '../components/generic/OrderOverview'
import PlantIcon from '../components/generic/PlantIcon'
import TimelinePrediction from '../components/generic/TimelinePrediction'
import { CarrierMapping, CommonProps, TimelineMapping } from '../models'
import { AppStore, OrderDetailsStore, OrderDetailsViewStore } from '../reducers/states'
import routes from '../route/routes'
import { LogApiInstance } from '../services/LogApiService'
import { OrderDetailsServiceInstance } from '../services/OrderDetailsService'
import { toDateTimeNew } from '../utils'
import './OrderTimelineView.scss'

const iconDictionary: { [key: string]: string | undefined } = {
  'bo-FullReset': reset,
  'bo-FullResetBeforeFulfillment': reset,
  'bo-PartialReset': reset,
  'bo-PartialResetBeforeFulfillment': reset,
  'bo-PutSbs': sbs,
  'bo-DeleteSbs': sbs,
  'bo-OrderComment': notes,
  'bo-ReUpload': reuploadPermittedIcon,
  'bo-CancelReUpload': reuploadCancelledIcon,
  'reuploaded-new': reuploadedIcon,
  'reuploaded-old': reuploadedIcon,
  'payment-REFUND': refundIcon,
}

interface Props extends CommonProps, OrderDetailsStore, OrderDetailsViewStore {
  configuration: GlobalConfiguration
}

interface State {
  ascending: boolean
  showAllEvents: boolean
  filterLevelState: number
  orderFilter: string
  productFilter: string
  eventFilter: string
  openGroups: string[]
  itemsToShow: number
  levelsToShow: OrderEventLevel[]
}

class OrderTimelineView extends React.Component<Props, State> {
  private static loadSize = 100

  constructor(props: Props) {
    super(props)

    this.state = {
      ascending: false,
      showAllEvents: false,
      filterLevelState: 0,
      orderFilter: '',
      productFilter: '',
      eventFilter: '',
      openGroups: [],
      itemsToShow: OrderTimelineView.loadSize,
      levelsToShow: [OrderEventLevel.Order, OrderEventLevel.Product, OrderEventLevel.Ticket],
    }

    this.toggleSorting = this.toggleSorting.bind(this)
    this.toggleShowAllEvents = this.toggleShowAllEvents.bind(this)
    this.toggleGroupVisibility = this.toggleGroupVisibility.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.renderIdColumn = this.renderIdColumn.bind(this)
    this.renderExtraDetails = this.renderExtraDetails.bind(this)
    this.renderEventName = this.renderEventName.bind(this)
  }

  componentDidMount() {
    if (!this.props.data || this.props.data.id !== this.props.match.params.orderId) {
      this.props.dispatch(orderDetailsActions.requestOrderDetails(this.props.match.params.orderId))
    }

    LogApiInstance.info(new LogMessage(this.props.match.params.orderId, codes.timelineLoaded))
  }

  private toggleSorting() {
    this.setState((prevState) => ({
      ascending: !prevState.ascending,
    }))
  }

  private toggleShowAllEvents() {
    this.setState((prevState) => ({
      showAllEvents: !prevState.showAllEvents,
    }))
  }

  private toggleFilterLevelState(filerIndex: number) {
    this.setState((prevState) => ({
      filterLevelState: filerIndex,
    }))
  }

  private toggleEventFilter = (event: any) => {
    const filterValue = event.target.value

    if (filterValue) {
      this.setState((prevState) => ({
        eventFilter: filterValue,
      }))
    } else {
      this.setState((prevState) => ({
        eventFilter: '',
      }))
    }
  }

  private toggleOrderFilter = (event: any) => {
    const filterValue = event.target.value

    if (filterValue) {
      this.setState((prevState) => ({
        orderFilter: filterValue,
      }))
    } else {
      this.setState((prevState) => ({
        orderFilter: '',
      }))
    }
  }

  private toggleProductFilter = (event: any) => {
    const filterValue = event.target.value

    if (filterValue) {
      this.setState((prevState) => ({
        productFilter: filterValue,
      }))
    } else {
      this.setState((prevState) => ({
        productFilter: '',
      }))
    }
  }

  private toggleGroupVisibility(groupId: string | undefined) {
    if (groupId === undefined) {
      return
    }

    this.setState((prevState) => ({
      openGroups: _.xor(prevState.openGroups, [groupId]),
    }))
  }

  private static levelsToGroup = [OrderEventLevel.Ticket, OrderEventLevel.Product]
  private static eventSourcesToExclude = [
    OrderEventSourceType.ProductContentApi,
    OrderEventSourceType.PdfRetrievalApi,
    OrderEventSourceType.DocumentRetrievalApi,
  ]

  private static eventTypesToExcludeForSimplifiedTimeline = [
    'production-order-status-accepted',
    'production-order-status-waiting-for-production',
    'created',
    'payment-AUTHORISATION',
  ]

  private static commentSubstringToExcludeForUpdatedEvent = ['is ready.', 'Resource file was updated for']

  private filterOrderEvents(orderEvents: OrderEvent[]) {
    return orderEvents.filter((orderEvent) => {
      return (
        this.state.levelsToShow.includes(orderEvent.level) &&
        !OrderTimelineView.eventSourcesToExclude.includes(orderEvent.sourceType) &&
        (this.isForSimplifiedTimeline(orderEvent) || this.state.showAllEvents) &&
        (orderEvent.level == this.state.filterLevelState || this.state.filterLevelState == 0) &&
        (orderEvent.productionOrderId?.toLowerCase().includes(this.state.orderFilter.toLowerCase()) ||
          this.state.orderFilter == '') &&
        (orderEvent.productCode?.toLowerCase().includes(this.state.productFilter.toLowerCase()) ||
          this.state.productFilter == '') &&
        (orderEvent.event.toLowerCase().includes(this.state.eventFilter.toLowerCase()) || this.state.eventFilter == '')
      )
    })
  }

  private isForSimplifiedTimeline(orderEvent: OrderEvent) {
    return (
      !OrderTimelineView.eventTypesToExcludeForSimplifiedTimeline.includes(orderEvent.event) &&
      !(
        orderEvent.event == 'production-order-updated' &&
        OrderTimelineView.commentSubstringToExcludeForUpdatedEvent.some((x) => orderEvent.comment?.includes(x))
      )
    )
  }

  private groupOrderEvents(orderEvents: OrderEvent[]) {
    const grouped = _.groupBy(orderEvents, (orderEvent) =>
      [
        orderEvent.level,
        orderEvent.event,
        orderEvent.correlationId,
        // only group events that are meant to be grouped
        OrderTimelineView.levelsToGroup.includes(orderEvent.level) ? '' : _.uniqueId('id-'),
      ].join('-')
    )

    return Object.values(grouped)
  }

  private loadMore() {
    this.setState(({ itemsToShow }) => ({
      itemsToShow: itemsToShow + OrderTimelineView.loadSize,
    }))
  }

  render() {
    const { ascending, openGroups, itemsToShow } = this.state
    const { data, configuration } = this.props
    if (!data) {
      return <div />
    }

    const { orderEvents = [] } = data
    const filteredOrderEvents = this.filterOrderEvents(orderEvents)
    const groupedOrderEvents = this.groupOrderEvents(filteredOrderEvents)
    if (ascending) {
      groupedOrderEvents.reverse()
    }
    const groupedOrderEventsVisible = groupedOrderEvents.slice(0, itemsToShow)

    const columns: GridColumn<OrderEvent[]>[] = [
      {
        title: '',
        value: ([orderEvent]) => this.renderColumnIcon(orderEvent),
        key: 'icon',
      },
      {
        title: (
          <div className="clickable" onClick={this.toggleSorting}>
            <img
              src={sort}
              style={{
                transform: `scaleY(${ascending ? -1 : 1})`,
              }}
              alt="sort"
            />
            <span className="ml-2">Date</span>
          </div>
        ),
        value: ([orderEvent]) => toDateTimeNew(orderEvent.timestamp),
        key: 'date',
      },
      {
        title: 'Event',
        value: ([orderEvent]) => this.renderEventName(orderEvent),
        key: 'event',
      },
      {
        title: 'Level',
        value: ([orderEvent]) => OrderEventLevel[orderEvent.level] || `Unknown (${orderEvent.level})`,
        key: 'level',
      },
      {
        title: 'ID',
        value: (orderEvents) => this.renderIdColumn(orderEvents, !openGroups.includes(orderEvents[0].id)),
        key: 'id',
      },
      {
        title: 'Created by',
        value: ([orderEvents]) => this.renderCreatedBy(orderEvents),
        key: 'created-by',
      },
      {
        title: 'Comment',
        value: (orderEvents) => this.renderCommentColumn(orderEvents),
        key: 'comment',
      },
    ]

    let predictionError: string | undefined = `Prediction is not available for orders that haven't reached plant yet.`
    let predictionNote: string | undefined = undefined
    if (data.fulfillment) {
      predictionError = undefined
      if (
        data.fulfillment.products.some(
          (p) => p.lines[0].plant !== FulfillmentPlant.YPB && p.lines[0].plant !== FulfillmentPlant.NLH
        )
      ) {
        predictionNote = 'Prediction is only available for products printed in YPB or NLH plants.'
      }
    } else {
      // Vouchers
      if (data.products.every((p) => p.productId === 'PAP_050')) {
        predictionError = undefined
      }
    }

    // Show timeline prediction if there're no Production Orders or some Fulfilment Order
    const showTimelinePrediction = data.productionOrders.length === 0 || data.fulfillment !== undefined

    return (
      <Container fluid>
        <Row>
          <NavigationLinks orderCode={data.id} source={data.source} configuration={configuration} />
        </Row>
        <Row>
          <OrderHeader prefix="Order Timeline:" order={data} />
        </Row>
        <Row className="mb-2">
          <OrderOverview data={data} />
        </Row>
        {this.props.configuration?.enableTimelinePrediction &&
          this.props.configuration.permissions.isAdmin &&
          showTimelinePrediction && (
            <TimelinePrediction
              loading={this.props.waitingPredictionResponse}
              data={this.props.ffPrediction}
              error={predictionError}
              note={predictionNote}
            />
          )}
        <Row className="mt-2">
          <h2 className="mb-2">Order Timeline</h2>
        </Row>
        <Row>
          <div className="bo-container header-row justify-content-between">
            <Row className="col-xs-12">
              <Col>
                <ButtonGroup>
                  <Button
                    id="showAllEventsButton"
                    color="secondary"
                    outline
                    onClick={this.toggleShowAllEvents}
                    active={this.state.showAllEvents}>
                    Show all events
                  </Button>
                </ButtonGroup>
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => this.toggleFilterLevelState(0)}
                    active={this.state.filterLevelState === 0}>
                    All levels
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => this.toggleFilterLevelState(1)}
                    active={this.state.filterLevelState === 1}>
                    Order level
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => this.toggleFilterLevelState(2)}
                    active={this.state.filterLevelState === 2}>
                    Product level
                  </Button>
                </ButtonGroup>
              </Col>
              <Col></Col>
            </Row>
            <Row className="col-xs-12">
              <Col>
                <FormGroup>
                  <Label>Filter by production order</Label>
                  <Input
                    name="orderFilter"
                    placeholder="7fc70997-65eb-49fd-8528-04319d27da11"
                    onChange={this.toggleOrderFilter}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Filter by product</Label>
                  <Select
                    emptyText="-- select a product --"
                    alwaysShowEmptyText
                    onChange={this.toggleProductFilter}
                    value={this.state.productFilter}
                    options={Array.from(
                      new Set(
                        orderEvents
                          .filter((x) => !OrderTimelineView.eventSourcesToExclude.includes(x.sourceType))
                          .filter((x) => x.productCode)
                          .map((item: any) => item.productCode)
                      )
                    ).map((productCode: any) => ({
                      value: productCode,
                      text: productCode,
                    }))}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Filter by event</Label>
                  <Select
                    emptyText="-- select an event --"
                    alwaysShowEmptyText
                    onChange={this.toggleEventFilter}
                    value={this.state.eventFilter}
                    options={Array.from(
                      new Set(
                        orderEvents
                          .filter((x) => !OrderTimelineView.eventSourcesToExclude.includes(x.sourceType))
                          .map((item: any) => item.event)
                      )
                    ).map((orderEvent: any) => ({
                      value: orderEvent,
                      text: TimelineMapping[orderEvent],
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Row>
        <Row>
          <Grid
            className="timeline"
            columns={columns}
            data={groupedOrderEventsVisible}
            rawExtraDetails
            rowKey={(i) => i[0].id}
            extraDetails={this.renderExtraDetails}
            disableCopyOnClick={true}
          />
          {groupedOrderEventsVisible.length < groupedOrderEvents.length ? (
            <button className="mt-4 wide-button" onClick={this.loadMore}>
              Load More
            </button>
          ) : null}
        </Row>
      </Container>
    )
  }

  renderCommentColumn(orderEvents: OrderEvent[]): React.ReactNode {
    if (orderEvents.length > 1) {
      return
    }

    const any = orderEvents[0]

    if (any.event === OrderEvents.ReuploadedOriginal) {
      return <ActionLink route={routes.ORDERS_VIEW({ orderId: any.comment })} text={`Reuploaded as ${any.comment}`} />
    }

    if (any.event === OrderEvents.ReuploadedNew) {
      return <ActionLink route={routes.ORDERS_VIEW({ orderId: any.comment })} text={`Reupload of ${any.comment}`} />
    }

    return any.comment
  }

  renderEventName(orderEvent: OrderEvent) {
    if (!orderEvent.event) {
      LogApiInstance.error(
        new LogMessage(
          {
            message: `Order event ${orderEvent.id} has no event defined`,
            orderEvent: orderEvent,
          },
          codes.badState
        )
      )
      return
    }

    const mappedEvent = TimelineMapping[orderEvent.event]

    if (!mappedEvent) {
      LogApiInstance.error(
        new LogMessage(
          {
            message: `Order event ${orderEvent.id} has no mapping defined`,
            orderEvent: orderEvent,
          },
          codes.badState
        )
      )
    }

    return mappedEvent || orderEvent.event
  }

  renderExtraDetails(orderEvents: OrderEvent[], columns: GridColumn<OrderEvent[]>[]) {
    if (!this.state.openGroups.includes(orderEvents[0].id)) {
      return null
    }

    return orderEvents.map((event) => (
      <tr className="ticket-row" key={event.id}>
        {columns.map((column) => (
          <td key={event.id + column.key}>
            {column.key === 'id' && this.renderId(event)}
            {column.key === 'comment' && event.comment}
          </td>
        ))}
      </tr>
    ))
  }

  renderCreatedBy(orderEvent: OrderEvent) {
    const { sourceType, operator, sourceId, id } = orderEvent
    const source = OrderEventSourceType[sourceType] || `Unknown (${sourceType})`

    if (operator) {
      return operator
    }

    if (source === 'Plant' && sourceId) {
      const plantInfo = OrderDetailsServiceInstance.getPlantInfo(sourceId)
      return (
        <div className="d-flex">
          <span className="pr-2">{source}</span>
          <PlantIcon
            elementId={`plant_tooltip_${id}`}
            plant={plantInfo.plant}
            displayName={plantInfo.displayName}
            isSmall={true}
          />
        </div>
      )
    }

    if (source === 'SAPI' && sourceId) {
      return CarrierMapping[sourceId] || sourceId
    }

    return source
  }

  renderId(orderEvent: OrderEvent) {
    switch (orderEvent.level) {
      case OrderEventLevel.Order:
        return orderEvent.orderCode
      case OrderEventLevel.Product:
        return orderEvent.productCode
      case OrderEventLevel.Ticket: {
        if (!orderEvent.ticketId) {
          return undefined
        }
        if (!orderEvent.ticketType) {
          return orderEvent.ticketId
        }
        return `${orderEvent.ticketId} (${orderEvent.ticketType})`
      }
      case OrderEventLevel.Debug:
        return undefined
      case OrderEventLevel.Unknown:
      default:
        LogApiInstance.error(
          new LogMessage(
            {
              message: `Order event ${orderEvent.id} has no level defined`,
              orderEvent: orderEvent,
            },
            codes.badState
          )
        )
        return undefined
    }
  }

  renderIdColumn(orderEvents: OrderEvent[], isOpen: boolean) {
    const [orderEvent] = orderEvents

    if (orderEvents.length > 1) {
      return (
        <span
          className="text-primary font-weight-bold d-flex align-items-center clickable"
          onClick={() => this.toggleGroupVisibility(orderEvent.id)}>
          <img className="transform-black-blue" src={isOpen ? closed : open} alt="expand" />
          {orderEvents.length} {OrderEventLevel[orderEvent.level].toLowerCase()}s
        </span>
      )
    }

    return this.renderId(orderEvent)
  }

  renderColumnIcon(orderEvent: OrderEvent) {
    const icon = iconDictionary[orderEvent.event]

    return icon && <Flag icon={icon} size={24} title="" />
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.orderDetails,
    ...state.orderDetailsView,
    configuration: state.global.configuration,
  } as Props
}

export default connect(mapStateToProps)(OrderTimelineView)
