import React, { useState } from 'react'
import { PudoOptions } from './PudoOptions'
import { useSelector } from 'react-redux'
import { AppStore } from '../../reducers/states'
import './DeliveryOptions.scss'
import { RedoAddressChangeForm } from './RedoAddressChange'
import { ProductionOrderDeliveryAddress, RedoDeliveryAddress } from '../../api/productionOrder'
import { DeliveryOptionsTab } from './DeliveryOptionTab'
import { DeliveryOption, PickupPoint } from '../../api'
import * as DeliveryOptionsService from '../../services/DeliveryOptionsService'

interface DeliveryOptionsProps {
  shippingAddress: ProductionOrderDeliveryAddress
  originalDeliveryType: string
  originalDeliveryMethod: string
  originalPickupPoint?: PickupPoint
  onAddressSelected(newAddress: RedoDeliveryAddress, deliveryType: string): void
  onPudoSelected(pickupPoint: PickupPoint): void
}

const [STANDARD_OPTION, EXPRESS_OPTION, PUDO_OPTION] = ['standard', 'express', 'pudo']

const resolveSelectedTab = (
  deliveryMethod: string,
  deliveryType: string,
  standardDeliveryDate: string | undefined,
  expressDeliveryDate: string | undefined,
  pudoDeliveryDate: string | undefined
): string => {
  if (deliveryMethod === 'PUDO' && pudoDeliveryDate) {
    return PUDO_OPTION
  }

  if (deliveryMethod === 'Home' && deliveryType === 'Express' && expressDeliveryDate) {
    return EXPRESS_OPTION
  }

  if (deliveryMethod === 'Home' && deliveryType === 'Standard' && standardDeliveryDate) {
    return STANDARD_OPTION
  }

  // Return first option available
  if (standardDeliveryDate) {
    return STANDARD_OPTION
  }

  if (expressDeliveryDate) {
    return EXPRESS_OPTION
  }

  if (pudoDeliveryDate) {
    return PUDO_OPTION
  }

  return ''
}

export const DeliveryOptionsComponent = ({
  shippingAddress,
  originalDeliveryType,
  originalDeliveryMethod,
  originalPickupPoint,
  onAddressSelected,
  onPudoSelected,
}: DeliveryOptionsProps) => {
  const deliveryOptions = useSelector((state: AppStore) => state.redoPanel.deliveryOptions)
  const pickupPoints = useSelector((state: AppStore) => state.redoPanel.pickupPoints)

  const standardDeliveryDate = DeliveryOptionsService.resolveEstimatedDeliveryDate('Home', 'Standard', deliveryOptions)
  const expressDeliveryDate = DeliveryOptionsService.resolveEstimatedDeliveryDate('Home', 'Express', deliveryOptions)
  const pudoDeliveryDate = DeliveryOptionsService.resolveEstimatedDeliveryDate('PUDO', 'Standard', deliveryOptions)

  const selectedTabName = resolveSelectedTab(
    originalDeliveryMethod,
    originalDeliveryType,
    standardDeliveryDate,
    expressDeliveryDate,
    pudoDeliveryDate
  )

  const [selectedTab, setSelectedTab] = useState<string>(selectedTabName)

  const onTabSelected = (name: string) => {
    setSelectedTab(name)
  }

  const onChangeAddressStandard = (newAddress: RedoDeliveryAddress) => {
    onAddressSelected(newAddress, 'Standard')
  }

  const onChangeAddressExpress = (newAddress: RedoDeliveryAddress) => {
    onAddressSelected(newAddress, 'Express')
  }

  return (
    <>
      <div className="tab-block">
        <DeliveryOptionsTab
          tabName={STANDARD_OPTION}
          tabTitle={'Home Standard'}
          isSelected={selectedTab === STANDARD_OPTION}
          isLast={false}
          estimatedDeliveryDate={standardDeliveryDate}
          onTabSelected={onTabSelected}></DeliveryOptionsTab>
        <DeliveryOptionsTab
          tabName={EXPRESS_OPTION}
          tabTitle={'Home Express'}
          isSelected={selectedTab === EXPRESS_OPTION}
          isLast={false}
          estimatedDeliveryDate={expressDeliveryDate}
          onTabSelected={onTabSelected}></DeliveryOptionsTab>
        <DeliveryOptionsTab
          tabName={PUDO_OPTION}
          tabTitle={'PUDO'}
          isSelected={selectedTab === PUDO_OPTION}
          isLast={true}
          estimatedDeliveryDate={pudoDeliveryDate}
          onTabSelected={onTabSelected}></DeliveryOptionsTab>
      </div>
      <div className="tab-content" style={selectedTab === STANDARD_OPTION ? { display: 'block' } : { display: 'none' }}>
        <h2>Standard Shipping</h2>
        <p>Standard shipping info</p>
        <RedoAddressChangeForm
          onSubmit={onChangeAddressStandard}
          disableSubmit={false}
          form="redo-address-change"
          initialValues={shippingAddress}></RedoAddressChangeForm>
      </div>
      <div className="tab-content" style={selectedTab === EXPRESS_OPTION ? { display: 'block' } : { display: 'none' }}>
        <h2>Express Shipping</h2>
        <p>Express shipping info</p>
        <RedoAddressChangeForm
          onSubmit={onChangeAddressExpress}
          disableSubmit={false}
          form="redo-address-change"
          initialValues={shippingAddress}></RedoAddressChangeForm>
      </div>
      <div className="tab-content" style={selectedTab === PUDO_OPTION ? { display: 'block' } : { display: 'none' }}>
        <h2>PUDO Shipping</h2>
        <PudoOptions
          initialPickupPoint={originalPickupPoint}
          pickupPoints={pickupPoints}
          onPickupPointSelection={onPudoSelected}></PudoOptions>
      </div>
    </>
  )
}
