import React, { ReactNode } from 'react'
import { NoteRequest, OrderDetails, OrderEvents } from '../../api'
import notesIcon from '../../assets/img/flags/notes.svg'
import Badge from '../../components/generic/Badge'
import { BadgeSetting, TogglePanel } from '../../models'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { selectOnClick } from '../../utils'
import OrderNotesPanel from '../panels/OrderNotesPanel'
import Flag from './Flag'

interface Props {
  order: OrderDetails
  // TODO: refactor me
  notesPanelDetails?: {
    notesFilter?: string
    notesPanel: TogglePanel
    showNotesCreatedNotification: boolean
    isNotesInEditMode: boolean
    sendNote(request: NoteRequest): void
    toggleNotesMode(): void
    toggleNotes(): void
  }
  prefix?: string
  children?: ReactNode
}

export default function OrderHeader(props: Props) {
  const badgeSettings = Service.resolveOrderStatusBadgeSettings(props.order)

  const showRefundBadge = Service.resolveOrderEventsByType(props.order.orderEvents || [], OrderEvents.Refund).length > 0
  const refundBadgeSetting: BadgeSetting = {
    color: 'other',
    title: 'Refunded',
  }

  const reuploadCount = props.order.products && props.order.products.filter((p) => p.reUploadPermitted).length
  const showReuploadBadge = reuploadCount > 0
  const reuploadBadgeSetting: BadgeSetting = {
    color: 'other',
    qty: reuploadCount,
    title: 'Reupload requested',
  }

  return (
    <div className="bo-container header-row d-flex justify-content-between">
      <div className="d-inline-flex">
        <h2 className="mb-0 mr-2 pt-1 pb-1">
          {props.prefix && props.prefix + ' '}
          <span onClick={selectOnClick}>{props.order.id}</span>
        </h2>
        <Badge setting={badgeSettings} />
        {showRefundBadge && <Badge setting={refundBadgeSetting} className="ml-2" />}
        {showReuploadBadge && <Badge setting={reuploadBadgeSetting} className="ml-2" />}
      </div>
      <div className="d-inline-flex">
        {props.notesPanelDetails && (
          <>
            {props.order.notes && props.order.notes.length > 0 && (
              <button
                className="orange medium d-flex align-items-center"
                onClick={props.notesPanelDetails.toggleNotes}
                id="header-notes-button">
                <Flag icon={notesIcon} title="Notes" size={24} /> Notes ({props.order.notes.length})
              </button>
            )}
            <OrderNotesPanel
              {...props.notesPanelDetails.notesPanel}
              onNoteSubmit={props.notesPanelDetails.sendNote}
              lookupProductKeyDisplayName={(key) =>
                key ? `${key} | ${Service.resolveProductDescriptionByCode(props.order, getProductId(key)) || ''}` : 'All products'
              }
              editMode={props.notesPanelDetails.isNotesInEditMode}
              toggleEditMode={props.notesPanelDetails.toggleNotesMode}
              orderData={props.order}
              filter={props.notesPanelDetails.notesFilter}
              showCreatedNotification={props.notesPanelDetails.showNotesCreatedNotification}
            />
            {props.children}
          </>
        )}
      </div>
    </div>
  )
}

function getProductId(key: string): string {
  if (key.includes(':')) {
    // If ':' is present, then key is formatted as 'ProductionOrderId:ProductId'
    return key.split(':')[1]
  }

  return key
}