import React, { ReactNode } from 'react'
import { FormFeedback, FormGroup, FormText, Label } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

export interface CustomComponent {
  disabled?: boolean
  tooltip?: ReactNode | string
  tooltipProcessor?: (value: any) => ReactNode | string
  children?: ReactNode
  label: string
}

export default function ValidationWrapper(props: WrappedFieldProps & CustomComponent) {
  return (
    <FormGroup>
      <Label>{props.label}</Label>
      {props.children}
      <FormFeedback>{props.meta.error}</FormFeedback>
      {props.tooltip && <FormText>{props.tooltip}</FormText>}
      {props.tooltipProcessor && <FormText>{props.tooltipProcessor(props.input.value)}</FormText>}
    </FormGroup>
  )
}
