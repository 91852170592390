import React from 'react'
import { MigrationDetails } from '../../api/migrationDetails'
import { toDateTimeNew } from '../../utils'

interface Props {
  details: MigrationDetails
}

function BooleanToDisplay(field: boolean) {
  return field ? "Yes" : "No";
}

export default function MigrationInfo(props: Props) {
  const migrationRows = [
    { text: 'Phone number', value: props.details.phoneNumber },
    { text: 'Last order date', value: toDateTimeNew(props.details.lastOrderDate) },
    { text: 'Old account Id', value: props.details.everestAccountId },
    { text: 'Channel', value: props.details.channel },
    { text: 'Is subscribed', value: BooleanToDisplay(props.details.isSubscribed) },
    { text: 'Last subscription change date', value: toDateTimeNew(props.details.lastSubscriptionDate) },
    { text: 'Last logged in date', value: toDateTimeNew(props.details.lastSeen) },
    { text: 'Registration date', value: toDateTimeNew(props.details.registrationDate) },
    { text: 'Migration message sent date', value: toDateTimeNew(props.details.messageSentDateTime) },
    { text: 'Sent to CRM', value: BooleanToDisplay(props.details.isAccountIdSentToCRM) },
    { text: 'Meged with Albelli', value: BooleanToDisplay(props.details.isMergedAccount) },
    { text: 'Merged Pbx & Hoff', value: BooleanToDisplay(props.details.isMergedAccountPbxHfm) },
    { text: 'Previous emails', value: props.details.emailHistory.join(',') },
    { text: 'Last updated in migration database', value: toDateTimeNew(props.details.lastUpdatedMigrationDbAt) },
    { text: 'Last updated in migration system', value: toDateTimeNew(props.details.everestLastUpdatedAt) },
  ]

  return <table>
    {migrationRows.map(element =>
      <tr>
        <td><b>{element.text}</b></td>
        <td>{element.value}</td>
      </tr>
    )}
  </table>
}
