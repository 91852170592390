import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import * as actions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import { BulkNoteRequest, CommentType, NoteBulkRequest, NoteRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* send(action: PayloadAction<NoteRequest>) {
  try {
    const response = yield call(ApiServiceInstance.post, 'comment', action.payload)

    yield put(
      actions.sentComment({
        type: action.payload.type,
        parentId: action.payload.parentId,
        response: response.data,
      })
    )
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

function* sendBulk(action: PayloadAction<NoteBulkRequest>) {
  try {
    yield put(global.showSpinner())
    const response = yield call(ApiServiceInstance.post, 'comment/collection', action.payload)

    yield put(
      bulkOperationsActions.bulkCommentReceived({
        response: response.data,
        valid: true,
      })
    )
  } catch (e) {
    let errorMessage = ''
    if (e.response.data) {
      errorMessage = e.response.data
    }
    yield put(Errors.toErrorAction(e, errorMessage))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* requestBulk(action: PayloadAction<BulkNoteRequest>) {
  try {
    // TODO: extend for other entities
    const entity = action.payload.type === CommentType.OrderNote ? 'order' : '[undefined]'

    const response = yield call(ApiServiceInstance.get, `comment/${entity}/${action.payload.parentId}`)

    yield put(
      actions.receivedBulk({
        type: action.payload.type,
        parentId: action.payload.parentId,
        notes: response,
      })
    )
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  }
}

export default function* root() {
  yield takeLatest(actions.sendComment, send)
  yield takeLatest(bulkOperationsActions.bulkCommentRequest, sendBulk)
  yield takeLatest(actions.requestBulk, requestBulk)
}
