import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/main.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { AuthInstance } from './services/AuthService'
import App from './App'

AuthInstance.runWithAdal(() => {
  ReactDOM.render(<App />, document.getElementById('root'))
})
