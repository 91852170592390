import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as orderActions from '../actions/orderDetailsActions'
import * as actions from '../actions/sbsActions'
import { SbsForProductionOrderPostRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* postSbsForProductionOrderDetails(action: PayloadAction<SbsForProductionOrderPostRequest>) {
  const request = action.payload
  try {
    yield call(ApiServiceInstance.post, `productionorder/sbs/${request.orderCode}`, request)
    yield put(actions.submittedSbsForProductionOrder(request))
    yield put(orderActions.requestOrderDetails(action.payload.orderCode))
  } catch (e) {
    yield put(Errors.toErrorAction(e, 'Cannot put SBS'))
  }
}

export default function* root() {
  yield all([yield takeEvery(actions.submitSbsForProductionOrder, postSbsForProductionOrderDetails)])
}
