import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ActionLink from '../generic/ActionLink'

export interface ModalPopupProps extends React.Props<any> {
  isOpen: boolean
  toggle(): void
  title?: string
}

export default function ModalPopup({ children, isOpen, toggle, title }: ModalPopupProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="bo-modal">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ActionLink onClick={toggle} text="Back" />
      </ModalFooter>
    </Modal>
  )
}
