import { createAction } from '@reduxjs/toolkit'
import {
  FileInfoResponse,
  ProductContentLog,
  ProductContentView,
  ProductsRequest,
  RestartFinalizerRequest,
  RestoreFromColdStorageRequest,
} from '../api/product'

export const requestProductView = createAction<ProductsRequest>('PRODUCT_VIEW_REQUEST')

export const requestProductViewPartial = createAction<string>('PARTIAL_PRODUCT_VIEW_REQUEST')

export const receivedProductView = createAction<ProductContentView>('PRODUCT_VIEW_RECEIVED')

export const receivedProductLogs = createAction<ProductContentLog[]>('PRODUCT_LOGS_RECEIVED')

export const requestRestartFinalizer = createAction<RestartFinalizerRequest>('PRODUCT_VIEW_REQUEST_RESTART_FINALIZER')

export const requestRestoreFromColdStorage = createAction<RestoreFromColdStorageRequest>('PRODUCT_VIEW_RESTORE_FILE')

export const requestRestoringFiles = createAction<any>('PRODUCT_VIEW_RESTORING_FILES_INFO_REQUEST')

export const receivedRestoringFile = createAction<FileInfoResponse>('PRODUCT_VIEW_RESTORING_FILE_INFO_RECEIVED')
