import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ActionLink from '../generic/ActionLink'

export interface ConfirmationProps extends React.Props<any> {
  isOpen?: boolean
  toggle(): void
  onSubmit(): void
  title?: string
  submitText?: string
  cancelText?: string
}

export default function Confirmation({
  children,
  isOpen,
  toggle,
  onSubmit,
  cancelText,
  title = 'Confirmation',
  submitText = 'Submit'
}: ConfirmationProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="bo-modal">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <button onClick={onSubmit} id='confirmation-submit-button'>{submitText}</button>
        {cancelText && <ActionLink className="ml-4" onClick={toggle} text={cancelText} id='confirmation-cancel-link' />}
      </ModalFooter>
    </Modal>
  )
}
