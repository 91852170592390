import React, { FormEvent } from 'react'
import { Alert, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  RestartFinalizerEnhancementType,
  RestartFinalizerReason,
  RestartFinalizerReasonNames,
  RestartFinalizerRequest,
  RestartFinalizerRequestBase,
} from '../../api/product'
import Confirmation from './Confirmation'
import './RestartFinalizerForm.scss'
import Select from './Select'

const restartReasons = Object.keys(RestartFinalizerReasonNames)
  .map((x) => Number(x))
  .map((x) => ({ value: x, text: RestartFinalizerReasonNames[x] }))

const xsWidth = 12
const mdWidth = 6

const blackListedEditors: string[] = [
  'Canvas Editor',
  'NeXt Editor Web',
  'Greeting Cards Editor',
  'Easy Flow Calendar',
  'Mug Editor',
  'HTML5 Photobook Editor',
  'Prints Editor',
]

interface State {
  data: RestartFinalizerRequest
  showConfirmation: boolean
  error?: string
  disabled: boolean
}

interface Props {
  input: RestartFinalizerRequestBase
  onSubmit(form: RestartFinalizerRequest): void
}

export default class RestartFinalizerForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: {
        appVersion: props.input.appVersion,
        orderCode: props.input.orderCode,
        productCode: props.input.productCode,
        contentId: props.input.contentId,
        vendor: props.input.vendor,
        enhancement: RestartFinalizerEnhancementType.On,
        reasonCode: 0,
      },
      showConfirmation: false,
      disabled: false,
    }
    this.handleGenericChange = this.handleGenericChange.bind(this)
    this.handleEnhancementChange = this.handleEnhancementChange.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.input.vendor !== this.props.input.vendor || prevProps.input.contentId !== this.props.input.contentId) {
      this.setState({
        data: {
          ...this.state.data,
          appVersion: this.props.input.appVersion,
          orderCode: this.props.input.orderCode,
          productCode: this.props.input.productCode,
          contentId: this.props.input.contentId,
          vendor: this.props.input.vendor,
        },
      })
    }
  }

  handleGenericChange(event: any) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      error: undefined,
      data: {
        ...this.state.data,
        [name]: value,
      },
    } as State)
  }

  handleEnhancementChange(value: RestartFinalizerEnhancementType) {
    this.setState({
      error: undefined,
      data: {
        ...this.state.data,
        enhancement: value,
      },
    } as State)
  }

  handleReasonChange(event: any) {
    const target = event.target
    const value = target.value
    const reasonId = Number(value)
    const [match] = restartReasons.filter((r) => r.value === reasonId)
    let enhancement = this.state.data.enhancement

    if (
      reasonId === RestartFinalizerReason.RemoveEnhancementAfterDelivery ||
      reasonId === RestartFinalizerReason.RemoveEnhancementBeforeDelivery
    ) {
      enhancement = RestartFinalizerEnhancementType.Off
    }

    this.setState({
      error: undefined,
      data: {
        ...this.state.data,
        reasonCode: match.value,
        reasonPhrase: match.text,
        enhancement: enhancement,
      },
    } as State)
  }

  handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!this.state.data.reasonCode) {
      this.setState({ error: 'Please select a reason' })
      return
    }

    if (this.state.data.reasonCode === RestartFinalizerReason.Other && !this.state.data.reasonComment) {
      this.setState({ error: 'Please add a comment' })
      return
    }

    this.toggleConfirmation()
    this.props.onSubmit(this.state.data)

    this.setState({ disabled: true })
  }

  toggleConfirmation() {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  render() {
    const { data, showConfirmation } = this.state

    const enhancementDisabled = this.props.input.editor !== undefined && blackListedEditors.includes(this.props.input.editor)

    return (
      <Form onSubmit={this.handleSubmit} disabled={this.state.disabled}>
        <Container fluid>
          <Row>
            <Col className="pl-0 pr-0" xs={xsWidth} md={mdWidth}>
              <Confirmation
                isOpen={showConfirmation}
                toggle={this.toggleConfirmation}
                onSubmit={this.toggleConfirmation}
                submitText="Ok"
                title="Restarting">
                Finalizer is restarting. It might take a while before the document is created.
              </Confirmation>
              {enhancementDisabled && (
                <FormGroup row>
                  <Col>
                    <Alert color="warning" className="mb-0">
                      Image enhancement cannot be overridden for online editor products when restarting the finalizer. Users can
                      control this themselves using the filter feature in the editor. Please advise the customer to re-upload.
                    </Alert>
                  </Col>
                </FormGroup>
              )}
              <FormGroup row className="mb-0">
                <Label xs={xsWidth} className="col-form-label">
                  Image Enhancement
                </Label>
                <Col className="col-form-label">
                  <FormGroup check inline className="mr-4">
                    <Label className={`${enhancementDisabled && 'disabled'}`}>
                      <Input
                        type="radio"
                        name="enhancement"
                        onChange={(x) => this.handleEnhancementChange(RestartFinalizerEnhancementType.On)}
                        checked={data.enhancement === RestartFinalizerEnhancementType.On}
                        disabled={this.state.disabled || enhancementDisabled}
                      />{' '}
                      ON
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label className={`${enhancementDisabled && 'disabled'}`}>
                      <Input
                        type="radio"
                        name="enhancement"
                        onChange={(x) => this.handleEnhancementChange(RestartFinalizerEnhancementType.Off)}
                        checked={data.enhancement === RestartFinalizerEnhancementType.Off}
                        disabled={this.state.disabled || enhancementDisabled}
                      />{' '}
                      OFF
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={xsWidth} className="col-form-label">
                  Reason
                </Label>
                <Col>
                  <Select
                    emptyText="-- select a reason --"
                    value={data.reasonCode ? data.reasonCode.toString() : undefined}
                    onChange={this.handleReasonChange}
                    options={restartReasons.filter(
                      (x) =>
                        !(
                          enhancementDisabled &&
                          (x.value === Number(RestartFinalizerReason.RemoveEnhancementAfterDelivery) ||
                            x.value === Number(RestartFinalizerReason.RemoveEnhancementBeforeDelivery))
                        )
                    )}
                    disabled={this.state.disabled}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={xsWidth} className="col-form-label">
                  Comment
                </Label>
                <Col>
                  <Input
                    type="textarea"
                    name="reasonComment"
                    value={data.reasonComment}
                    onChange={this.handleGenericChange}
                    disabled={this.state.disabled}
                  />
                </Col>
              </FormGroup>
              {this.state.error && (
                <FormGroup row>
                  <Col>
                    <Alert color="danger" className="mb-0">
                      {this.state.error}
                    </Alert>
                  </Col>
                </FormGroup>
              )}
              <FormGroup row className="mb-0">
                <Col>
                  <button type="submit" className="medium pl-4 pr-4" disabled={this.state.disabled}>
                    Restart finalizer
                  </button>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}
