import React, { MouseEvent } from 'react'

interface Props extends React.HTMLProps<any> {
  text: string
}

export default function RoundIcon(props: Props) {
  const onClick = (e: MouseEvent) => {
    e.stopPropagation()
    props.onClick && props.onClick(e)
  }

  return (
    <div {...props} className={`round-icon ${props.className}`} onClick={onClick}>
      {props.text}
    </div>
  )
}
