import React, { FormEvent } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { RestartFinalizerReason, RestartFinalizerReasonNames } from '../../api'
import { BulkRefinalizeBase } from '../../api/refinalize'
import { validators } from '../../utils'
import './BulkRefinalizeForm.scss'
import Select from './Select'

interface BulkRefinalizeFormProps {
  onSubmit(x: BulkRefinalizeBase): void
}

interface State {
  data: BulkRefinalizeBase
  disabled: boolean
  hasBulkDocumentTokensError: boolean
  hasReasonCodeError: boolean
  hasReasonCommentError: boolean
}

const initialState: State = {
  data: {
    bulkDocumentTokens: '',
    reasonCode: '',
    reasonPhrase: '',
    reasonComment: '',
  },
  disabled: false,
  hasBulkDocumentTokensError: false,
  hasReasonCodeError: false,
  hasReasonCommentError: false,
} as State

const restartReasons = Object.keys(RestartFinalizerReasonNames)
  .map((x) => Number(x))
  .map((x) => ({ value: x, text: RestartFinalizerReasonNames[x] }))

export default class BulkRefinalizeForm extends React.Component<BulkRefinalizeFormProps, State> {
  constructor(props: BulkRefinalizeFormProps) {
    super(props)
    this.state = initialState
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGenericChange = this.handleGenericChange.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
  }

  handleReasonChange(event: any) {
    const code = event.target.value

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        reasonCode: code,
        reasonPhrase: RestartFinalizerReasonNames[code],
      },
      hasReasonCodeError: this.isEmpty(code),
    } as State)
  }

  handleSubmit(event: FormEvent) {
    const hasBulkDocumentTokensError = this.isEmpty(this.state.data.bulkDocumentTokens)
    const hasReasonCodeError = this.isEmpty(this.state.data.reasonCode)
    const hasReasonCommentError = this.isEmpty(this.state.data.reasonComment)

    this.setState({
      ...this.state,
      hasBulkDocumentTokensError: hasBulkDocumentTokensError,
      hasReasonCodeError: hasReasonCodeError,
      hasReasonCommentError: hasReasonCommentError,
    } as State)

    if (hasBulkDocumentTokensError || hasReasonCodeError || hasReasonCommentError) {
      event.preventDefault()
      return
    } else {
      this.setState({ disabled: true })
      this.props.onSubmit(this.state.data)
      this.setState({ disabled: false })
    }
  }

  handleGenericChange(event: any) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    } as State)
  }

  isEmpty(data: any) {
    return data === null || data === undefined || data === ''
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label className="col-form-label">Document token Ids</Label>
          <Input
            name="bulkDocumentTokens"
            id="bulkDocumentTokens"
            rows={5}
            type="textarea"
            label="Document tokens"
            invalid={this.state.hasBulkDocumentTokensError}
            onChange={this.handleGenericChange}
          />
          {this.state.hasBulkDocumentTokensError && (
            <FormFeedback>{validators.required(this.state.data.bulkDocumentTokens)}</FormFeedback>
          )}
          <Label className="field-help-text">
            Insert external content document tokens in comma separated fashion: 386f138b-c417-42a9-9, 49cd8e10-25cf-4716-b,
            becba693-5581-4bc9-8...
          </Label>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Reason code</Label>
          <Select
            emptyText="-- select a reason code --"
            onChange={this.handleReasonChange}
            value={this.state.data.reasonCode.toString()}
            options={restartReasons.filter(
              (x) =>
                x.value !== Number(RestartFinalizerReason.RemoveEnhancementAfterDelivery) &&
                x.value !== Number(RestartFinalizerReason.RemoveEnhancementBeforeDelivery)
            )}
            invalid={this.state.hasReasonCodeError}
          />
          {this.state.hasReasonCodeError && <FormFeedback>{validators.required(this.state.data.reasonCode)}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Comment</Label>
          <Input
            name="reasonComment"
            id="reasonComment"
            rows={5}
            type="textarea"
            label="Comment"
            invalid={this.state.hasReasonCommentError}
            onChange={this.handleGenericChange}
          />
          {this.state.hasReasonCommentError && <FormFeedback>{validators.required(this.state.data.reasonComment)}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <button type="submit" id="bulkRefinalizeSubmit" disabled={this.state.disabled}>
            Submit
          </button>
        </FormGroup>
      </Form>
    )
  }
}
