import React from 'react'
import { Col, Container, Form, FormGroup, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { nameof, validators } from '../../utils'
import ReadOnlyInput from '../forms/ReadOnlyInput'
import Input from '../forms/Input'
import { RedoDeliveryAddress } from '../../api/productionOrder'

interface RedoAddressChangeFormProps {
  onSubmit(newAddress: RedoDeliveryAddress): void
  disableSubmit: boolean
}

function RedoAddressChangeFormInternal({
  handleSubmit,
  onSubmit,
  submitting,
  invalid,
  disableSubmit,
}: RedoAddressChangeFormProps & InjectedFormProps<RedoDeliveryAddress, RedoAddressChangeFormProps>) {
  return (
    <Container className="pl-0">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {renderRow(nameof<RedoDeliveryAddress>('customerTitle'), 'Customer Title', Input, false, true)}
        {renderRow(nameof<RedoDeliveryAddress>('customerFirstName'), 'Customer First Name', Input, false, false)}
        {renderRow(nameof<RedoDeliveryAddress>('customerPrefix'), 'Customer Prefix', Input, false, true)}
        {renderRow(nameof<RedoDeliveryAddress>('customerLastName'), 'Customer Last Name', Input, false, false)}
        {renderRow(nameof<RedoDeliveryAddress>('companyName'), 'Company', Input, false, true)}
        {renderRow(nameof<RedoDeliveryAddress>('addressLine1'), 'Address Line 1', Input, true, true)}
        {renderRow(nameof<RedoDeliveryAddress>('addressLine2'), 'Address Line 2', Input, false, true)}
        {renderRow(nameof<RedoDeliveryAddress>('zipCode'), 'Zip', Input, true, true)}
        {renderRow(nameof<RedoDeliveryAddress>('city'), 'City', Input, true, true)}
        {renderRow(nameof<RedoDeliveryAddress>('countryId'), 'Country', ReadOnlyInput, true, true)}
        {renderRow(nameof<RedoDeliveryAddress>('customerPhone'), 'Phone Number', Input, false, true)}
        {renderRow(nameof<RedoDeliveryAddress>('customerEmail'), 'Email', ReadOnlyInput, true, true)}
        <FormGroup className="mt-3">
          <button type="submit" disabled={submitting || invalid || disableSubmit}>
            Next
          </button>
        </FormGroup>
      </Form>
    </Container>
  )
}

function renderRow(name: keyof RedoDeliveryAddress, label: string, component: any, isRequired: boolean, maxLength50: boolean) {
  const validatorsArray = []
  if (isRequired) {
    validatorsArray.push(validators.required)
  }
  if (maxLength50) {
    validatorsArray.push(validators.maxLength50)
  }

  return (
    <Row>
      <Col sm="12" className="pr-0">
        <Field name={name} component={component} label={label} validate={validatorsArray} />
      </Col>
    </Row>
  )
}

export const RedoAddressChangeForm = reduxForm<RedoDeliveryAddress, RedoAddressChangeFormProps>({ enableReinitialize: true })(
  RedoAddressChangeFormInternal
)
