import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as commentActions from '../actions/commentActions'
import * as customerDetailsActions from '../actions/customerDetailsActions'
import * as actions from '../actions/orderDetailsActions'
import * as productActions from '../actions/productViewActions'
import * as productionOrderActions from '../actions/productionOrderActions'

import {
  BulkNoteResponse,
  Discount,
  FileInfoResponse,
  FulfillmentPredictionResults,
  isCommentRelatedToOrder,
  NoteResponse,
  OrderDetails,
  OrderListItemModel,
  OrderStatus,
  SearchResponse,
} from '../api'
import { PreviewResponseWrapper } from '../models'
import { OrderDetailsStore } from './states'

const initialState: OrderDetailsStore = {
  orders: {},
}

function processPreviews(data: OrderDetails | undefined, response: { [key: string]: string }) {
  if (!data || !data.products) {
    return data
  }

  const newProducts = data.products.concat()
  for (let i = 0; i < newProducts.length; i++) {
    const preview = newProducts[i].preview
    if (preview && response.hasOwnProperty(preview.id)) {
      preview.url = response[preview.id]
    }
  }

  return {
    ...data,
    products: newProducts,
  }
}

export const orderDetails = createReducer<OrderDetailsStore>(initialState, {
  [actions.receivedOrderDetails.type]: (state, action: PayloadAction<OrderDetails>) => {
    if (state.data?.id !== action.payload.id) {
      state.discount = undefined
    }
    state.data = action.payload
  },
  [actions.receivedOrderDetailsPreview.type]: (state, action: PayloadAction<{ [key: string]: string }>) => {
    if (action.payload) {
      state.data = processPreviews(state.data, action.payload)
    }
  },
  [commentActions.sentComment.type]: (state, action: PayloadAction<NoteResponse>) => {
    if (state.data && isCommentRelatedToOrder(state.data.id, action)) {
      state.data.notes = (state.data.notes || []).concat(action.payload.response)
    }
  },
  [commentActions.receivedBulk.type]: (state, action: PayloadAction<BulkNoteResponse>) => {
    if (state.data && isCommentRelatedToOrder(state.data.id, action)) {
      state.data.notes = action.payload.notes
    }
  },
  [actions.receivedOrderHistoryDetails.type]: (state, action: PayloadAction<OrderListItemModel>) => {
    state.orders[action.payload.id] = action.payload
  },
  [actions.receivedOrderHistoryDetailsPreviews.type]: (state, action: PayloadAction<PreviewResponseWrapper>) => {
    const order = state.orders[action.payload.orderCode]
    if (order) {
      for (let i = 0; i < order.products.length; i++) {
        const product = order.products[i]
        if (product.preview) {
          product.preview.url = action.payload.previews[product.preview.id || '']
        }
      }
    }
  },
  [productionOrderActions.setPreselectedProductionOrderId.type]: (state, action: PayloadAction<string>) => {
    if (state.data !== undefined) {
      state.data.preselectedProductionOrderId = action.payload
    }
  },
  [productionOrderActions.setPreselectedProductId.type]: (state, action: PayloadAction<string>) => {
    if (state.data !== undefined) {
      state.data.preselectedProductId = action.payload
    }
  },
  [customerDetailsActions.receivedAlbelliHistory.type]: (state, action: PayloadAction<SearchResponse>) => {
    if (action.payload && action.payload.items) {
      for (let index = 0; index < action.payload.items.length; index++) {
        const order = action.payload.items[index]
        if (order.isOldLegacyOrder === true) {
          state.orders[order.id] = {
            id: order.id,
            source: order.source,
            vendor: order.vendor,
            channel: order.channel,
            createdDateTime: order.createdDateTime,
            products: [],
            productionOrders: [],
            status: OrderStatus.Unknown,
            isOldLegacyOrder: order.isOldLegacyOrder,
          }
        }
      }
    }
  },
  [actions.receivedFulfillmentPrediction.type]: (state, action: PayloadAction<FulfillmentPredictionResults>) => {
    state.ffPrediction = action.payload
  },
  [productActions.receivedRestoringFile.type]: (state, action: PayloadAction<FileInfoResponse>) => {
    if (state.data) {
      const storageId = action.payload.storageId
      state.data.products = state.data.products.map((product) => {
        if (product.pdfStorageId === storageId) {
          product.pdfIsInColdStorage = action.payload.coldStorage
        }
        if (product.albxStorageId === storageId) {
          product.albxIsInColdStorage = action.payload.coldStorage
        }
        if (product.imageArchiveStorageId === storageId) {
          product.imageArchiveIsInColdStorage = action.payload.coldStorage
        }
        return product
      })
    }
  },
  [actions.receivedDiscountView.type]: (state, action: PayloadAction<Discount>) => {
    state.discount = action.payload
  },
})
