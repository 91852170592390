import React from 'react'
import { Input as BootstrapInput } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import ValidationWrapper, { CustomComponent } from './ValidationWrapper'

export default function Input(props: WrappedFieldProps & CustomComponent) {
  const hasError = !!props.meta.error && !!props.meta.touched
  return (
    <ValidationWrapper {...props}>
      <BootstrapInput type="text" {...props.input} invalid={hasError} />
    </ValidationWrapper>
  )
}
