import _ from 'lodash'
import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkPromiseBrokenRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkPromiseBrokenBase } from '../../api/promiseBroken'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkPromiseBrokenForm from '../forms/BulkPromiseBrokenForm'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
}

class BulkPromiseBrokenPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkPromiseBroken = this.sendBulkPromiseBroken.bind(this)
  }

  sendBulkPromiseBroken(formParams: BulkPromiseBrokenBase) {
    const productCodeList = formParams.bulkProductCodes
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkPromiseBrokenRequest({
        productCodes: productCodeList,
      })
    )
  }

  renderForm() {
    return <BulkPromiseBrokenForm onSubmit={this.sendBulkPromiseBroken}></BulkPromiseBrokenForm>
  }

  renderResponse() {
    const groupedResponse = _(this.props.bulkPromiseBrokenResponse.response)
      .groupBy((x) => Math.floor(x.statusCode / 100) * 100)
      .map((value, key) => ({ statusCode: key, responses: value }))
      .value()

    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="8">
            <h1>Bulk Operation Result</h1>
            {this.props.bulkPromiseBrokenResponse.response.some((x) => x.statusCode >= 400)
              ? 'There were some errors setting promise broken for some products, please check report below'
              : 'Promise broken was triggered successfully for all products'}
          </Col>
          <Col md="4" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        {groupedResponse.map((x, index) => (
          <div key={index} className="response-container">
            <Row key={'state_' + index}>
              <Col md="2" className="column-highlight">
                State:{' '}
              </Col>
              <Col md="10" className={x.statusCode < '400' ? 'status-success' : 'status-error'}>
                {x.statusCode < '400' ? 'Success' : 'Error'}
              </Col>
            </Row>
            {x.responses[0].message && (
              <Row key={'error_' + index}>
                <Col md="2" className="column-highlight">
                  Message:{' '}
                </Col>
                <Col md="10">{x.responses[0].message}</Col>
              </Row>
            )}
            <Row key={'products_' + index}>
              <Col md="2" className="column-highlight">
                ProductCodes:{' '}
              </Col>
              <Col md="10">{x.responses.map((y) => y.productCode).join(', ')}</Col>
            </Row>
          </div>
        ))}
      </Container>
    )
  }

  render() {
    return this.props.bulkPromiseBrokenResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkPromiseBrokenPanel
