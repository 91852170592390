import { Customer } from './customer'
import { Note } from './note'
import { Product } from './product'
import { ProductionOrder } from './productionOrder'

export interface OrderListItemModel {
  source: DataSource
  id: string
  createdDateTime?: Date
  vendor: string
  brand?: string
  locale?: string
  channel: string
  channelType?: string
  products: Product[]
  productionOrders: ProductionOrder[]
  fulfillment?: Fulfillment
  paymentHistory?: PaymentHistory[]
  notes?: Note[]
  orderEvents?: OrderEvent[]
  internalOperations?: InternalOperation[]
  shippingAddress?: Address
  billingAddress?: Address
  orderDeliveryAddress?: OrderDeliveryAddress
  customer?: Customer
  status: OrderStatus
  isOldLegacyOrder?: boolean
  price?: Price
}

export interface OrderDetails extends OrderListItemModel {
  internalId: string
  invoiceId: string
  customerId?: string
  deliveryInfo?: TraceWithProducts[]
  preselectedProductionOrderId?: string
  preselectedProductId?: string
}

export enum DataSource {
  Unknown = 0,
  Albelli = 1,
  Resnap = 2,
  Studenten = 3,
}

export interface TraceWithProducts {
  distributor?: string
  trackingId?: string
  trackingUrl?: string
  scannedDate?: Date
  estimatedDate?: Date
  products: TraceProduct[]
  deliveryType?: string
  deliveryMethod?: string
  productionOrderId?: string
}

export interface TraceProduct {
  productCode: string
  qty: number
}

export const VendorShopUrls: { [vendorId: string]: string } = {
  '1200': 'https://checkout.ecom.albelli.nl',
  '1201': 'https://checkout.ecom.albelli.de',
  '1203': 'https://checkout.ecom.albelli.fr',
  '1205': 'https://checkout.ecom.albelli.com',
  '1207': 'https://checkout.ecom.albelli.be',
  '1210': 'https://checkout.ecom.albelli.com',
  '1212': 'https://checkout.ecom.albelli.com',
  '1213': 'https://checkout.ecom.onskefoto.se',
  '1214': 'https://checkout.ecom.albelli.com',
  '1215': 'https://checkout.ecom.fotoknudsen.no',
  '1217': 'https://checkout.ecom.monalbumphoto.fr',
  '1221': 'https://checkout.ecom.bonusprint.co.uk',
  '1222': 'https://checkout.ecom.posterxxl.de',
  '1223': 'https://checkout.ecom.posterxxl.at',
  '1224': 'https://checkout.ecom.b2b.posterxxl.de',
  '1225': 'https://checkout.ecom.b2b.posterxxl.de',
  '1226': 'https://checkout.ecom.photobox.co.uk',
  '1227': 'https://checkout.ecom.photobox.fr',
  '1228': 'https://checkout.ecom.photobox.ie',
  '1229': 'https://checkout.ecom.photobox.it',
  '1230': 'https://checkout.ecom.photobox.dk',
  '1231': 'https://checkout.ecom.hofmann.es',
  '1232': 'https://checkout.ecom.hofmann.pt',
}

export interface PaymentHistory {
  received: string
  providerReference?: string
  event?: string
  success?: boolean
  method?: string
  currency?: string
  paidAmount?: number
  reason?: string
}

export interface Price {
  currency?: string
  subTotal?: string
  shipment?: string
  discount?: string
  discountCode?: string
  total?: string
  tax?: string
}

export interface Fulfillment {
  products: FulfillmentProduct[]
  deliveryType: string
  deliveryMethod: string
  pickupPointName: string
}

export interface FulfillmentProduct {
  contentRef?: string
  productId: string
  productCode?: string // absent for some rare cases
  options?: FulfillmentProductOption[]
  lines: FulfillmentLine[]
  barcodes?: string[]
}

export interface FulfillmentLine {
  plant?: FulfillmentPlant
  stage?: FulfillmentStage
  state?: FulfillmentState
  orderLineRef?: string
  deliveryType?: string
  carrierName?: string
}

export enum FulfillmentPlant {
  UNKNOWN = '?',
  YPB = 'YPB',
  BHI = 'BHI',
  KHS = 'KHS',
  KIS = 'KIS',
  NLH = 'NLH',
  FRZ = 'FRZ',
  RRD = 'RRD',
  ELA = 'ELA',
  SYM = 'SYM',
  BDK = 'BDK',
  CCC = 'CCC',
  COOVZ = 'COOVZ',
  CTM = 'CTM',
  EKT = 'EKT',
  HAR = 'HAR',
  HOF_ES = 'HOF_ES',
  JONDO = 'JONDO',
  MM = 'MM',
  ORWO = 'ORWO',
  PMI = 'PMI',
  PP = 'PP',
  RAV = 'RAV',
  SART = 'SART',
  WFR = 'WFR',
  TBD = 'TBD',
}

// OBSOLETE
export enum FulfillmentStage {
  Unknown = 0,
  Waiting = 1,
  Producing = 2,
  Shipped = 3,
  Cancelled = 4,
  Error = 5,
  Delayed = 6,
}

// OBSOLETE
export enum FulfillmentState {
  Unknown = 0,
  Done = 1,
  Failed = 2,
}

export interface FulfillmentItem {
  id: number
  range?: FulfillmentItemRange
}

export interface FulfillmentItemRange {
  from: number
  to: number
}

export interface FulfillmentProductOption {
  key: string
  value: string
}

export const FulfillmentProductOptionNames: { [key: string]: string } = {
  '1': 'Binding',
  '2': 'Cover',
  '3': 'Cover Text',
  '4': 'Extra Pages',
  '5': 'Paper',
  '6': 'Photo Cover',
  '7': 'Print Surface',
  //'8': 'Base Pages',
  '9': 'Editor',
  '10': 'Spine',
  //'11': 'End User Price',
  //'12': 'End User Price Currency',
  //'13':'WaybillItemPrice',
  //'14':'WaybillItemPriceCurrency',
  '15': 'Editor Version',
  '16': 'Pages',
  '17': 'Wood Finishing Type',
  '18': 'Planks Orientation Type',
  '19': 'Print Size 10x10',
  '20': 'Print Size 10x12',
  '21': 'Print Size 10x14',
  '22': 'Print Size 10x15',
  '23': 'Print Size 13x17',
  '24': 'Print Size 13x18',
  '25': 'Print Size 13x19',
  '26': 'Print Size 20x30',
  '27': 'Print Size 13x13',
  '28': 'Print Size 15x20',
  '29': 'Product Type',
  '30': 'Frame',
  //'31': 'Wrappers'
}

export const ProductOptionNames: { [key: string]: string } = {
  ArticleType: 'Product Type',
  Binding: 'Binding',
  Border: 'Border',
  CoverType: 'Cover',
  CoverText: 'Cover Text',
  Spine: 'Spine',
  PrintSize: 'Print Size',
  PrintSurface: 'Print Surface',
  Glossy: 'Glossy',
  Color: 'Color',
  PhotoSide: 'PhotoSide',
  Material: 'Material',
  PrebuiltPosterFrame: 'PrebuiltPosterFrame',
  PaperType: 'Paper',
  PosterFrame: 'Poster Frame',
  Passepartout: 'Passepartout',
  HangingKit: 'HangingKit',
  Editor: 'Editor',
  EditorVersion: 'Editor Version',
  BoxFrame: 'BoxFrame',
  FloatingFrame: 'Frame',
  WoodFinishingType: 'Wood Finishing Type',
  PlanksOrientationType: 'Planks Orientation Type',
  WrapperType: 'Wrappers',
  ExtraPages: 'Extra Pages',
  Pages: 'Pages',
  PremiumLayFlat: 'PremiumLayFlat',
  VoucherDiscount: 'Voucher Discount',
  PrintSize10x10: 'Print Size 10x10',
  PrintSize10x12: 'Print Size 10x12',
  PrintSize10x13: 'Print Size 10x13',
  PrintSize10x15: 'Print Size 10x15',
  PrintSize13x17: 'Print Size 13x17',
  PrintSize13x18: 'Print Size 13x18',
  PrintSize13x19: 'Print Size 13x19',
  PrintSize20x30: 'Print Size 20x30',
  PrintSize13x13: 'Print Size 13x13',
  PrintSize15x20: 'Print Size 15x20',
}

export interface OrderDeliveryAddress {
  customerName?: string
  customerPhone?: string
  companyName?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  zipCode?: string
  country?: string
  customerTitle?: string
  customerFirstName?: string
  customerPrefix?: string
  customerLastName?: string
  customerEmail?: string
  street?: string
  houseNumber?: string
  houseNumberExtension?: string
  county?: string
}

export interface Address {
  type: FulfillmentShipmentDeliveryType
  customerName?: string
  customerPhone?: string
  companyName?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  zipCode?: string
  country?: string
  countryId?: string
  customerFirstName?: string
  customerLastName?: string
  firstName?: string
  lastName?: string
  phone?: string
  customerPrefix?: string
  customerTitle?: string
  customerEmail?: string
  email?: string
  plants?: FulfillmentPlant[]
}

export interface FulfillmentShipment extends Address {
  id: number
}

export type FulfillmentShipmentDeliveryType = 0 | 1 | 2

export const FulfillmentShipmentDeliveryTypeNames: { [id: number]: string } = {
  0: 'Unknown',
  1: 'Home',
  2: 'Pickup Point',
}

export interface InternalOperationRequest {
  appVersion: string
  operation: InternalOperationType
  code: string
  vendor: string
  channel: string
  reasonCode?: string
  reasonPhrase?: string
  comment: string
  invoiceId?: string
  source?: DataSource
  products: InternalOperationProduct[]
  productionOrders: InternalOperationProductionOrder[]
}

export interface InternalOperationProduct {
  id: string
  quantity: number
  value: number
}

export interface InternalOperationProductionOrder {
  productionOrderId: string
  products: InternalOperationProduct[]
}

export enum InternalOperationType {
  Unknown = 0,
  Reset = 1,
  Cancel = 2,
  Reupload = 3,
  CancelReupload = 4,
}

export interface InvoiceDownloadRequest {
  superOrderId: string
  vendor: string
  orderNumber: string
  isPaid: boolean
}

export interface RefundAmountRequest {
  orderCode: string
  amount: number
  reason: string
  refundType: string
}

export enum RefundCalculationType {
  Amount = 'Amount to refund',
  Percentage = '% of product price',
  Order = 'Entire or partial order',
  ShippingCosts = 'Shipping costs',
}

export interface OrderEvent {
  id: string
  correlationId?: string
  orderCode: string
  plant: string
  channelType: string
  productCode?: string
  quantity?: number
  status?: string
  productionOrderId?: string
  timestamp: string
  event: string
  comment?: string
  ticketId?: string
  ticketType?: string
  level: OrderEventLevel
  sourceType: OrderEventSourceType
  sourceId?: string
  operator?: string
}

export interface InternalOperation {
  productCode: string
  productionOrderId: string
  reasonCode: string
  comment: string
  timestamp: Date
  operation: string
  causedBy: string
}

export enum OrderStatus {
  Unknown = 0,
  WaitingForPayment = 10,
  WaitingConfirmation = 11,
  WaitingForPaymentConfirmed = 12,
  WaitingForPaymentPending = 13,
  PaymentFailed = 20,
  PaymentSucceeded = 30,
  WaitingForProduction = 40,
  InProduction = 50,
  ScannedForShipment = 60,
  Cancelled = 70,
  OnHold = 80,
  Delivering = 90,
  Delayed = 100,
}

export enum OrderEvents {
  Delayed = 'delayed',
  Promised = 'promised',
  Unpromised = 'unpromised',
  PromiseBroken = 'promisebroken',
  Reprocess = 'reprocess',
  FullReset = 'bo-FullReset',
  ReuploadPermitted = 'bo-ReUpload',
  CancelReupload = 'bo-CancelReUpload',
  PartialReset = 'bo-PartialReset',
  ReuploadedNew = 'reuploaded-new',
  ReuploadedOriginal = 'reuploaded-old',
  Refund = 'payment-REFUND',
}

export enum OrderEventLevel {
  Unknown,
  Order,
  Product,
  Ticket,
  Debug,
}

export enum OrderEventSourceType {
  Unknown,
  Backoffice,
  Plant,
  StatusProcessor,
  NlhLambdas,
  IntakeProcessor,
  Adyen,
  OrderApi,
  Fritzell, // 3rd party that creates the Studenten products
  FulfillmentIntakeApi,
  ProductContentApi,
  SAPI,
  FulfillmentRequestCreator,
  PdfRetrievalApi,
  Symphony,
  ProductionOrder,
  Mcp,
  DocumentRetrievalApi,
}

export interface Discount {
  id?: string
  definition?: string
  validTo?: string
  validFrom?: string
  description?: string
  usageType?: string
  layer?: string
  status?: string
  tags?: string[]
  createdDate?: string
  lastChangedDate?: string
  singleUseOrderCode?: string
  isOldSystemDiscount: boolean
  removed: boolean
}

export interface ChangeAddressRequest {
  appVersion: string
  orderCode: string
  newAddress: OrderDeliveryAddress
  addressAsString: string
  vendorId: string
}
