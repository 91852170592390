import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row } from 'reactstrap'
import { Alert } from 'reactstrap'
import * as commentActions from '../actions/commentActions'
import * as globalActions from '../actions/globalActions'
import * as actions from '../actions/orderDetailsActions'
import * as productionOrderActions from '../actions/productionOrderActions'
import * as productActions from '../actions/productViewActions'
import * as sbsActions from '../actions/sbsActions'
import * as widgetActions from '../actions/widgetActions'
import { getWidgetPanelKey } from '../actions/widgetActions'
import {
  OrderDeliveryAddress,
  codes,
  GlobalConfiguration,
  LogMessage,
  NoteRequest,
  OrderEvents,
  Product,
  ProductRequest,
  ProductReuploadReasons,
  RestoreFromColdStorageRequest,
  SbsDeleteRequest,
  SbsPostRequest,
  SbsForProductionOrderPostRequest,
  WidgetRequest,
} from '../api'
import { ProductionOrder, ProductionOrderProduct, Shipment, ProductionOrderDeliveryAddress } from '../api/productionOrder'
import * as OrderTypes from '../api/order'
import sbsIcon from '../assets/img/flags/sbs.svg'
import { ChangeAddressForm } from '../components/forms/ChangeAddressForm'
import ModalPopup from '../components/forms/ModalPopup'
import OrderCancelForm from '../components/forms/OrderCancelForm'
import OrderResetForm from '../components/forms/OrderResetForm'
import ActionLink, { ActionLinkSettings } from '../components/generic/ActionLink'
import ActionList from '../components/generic/ActionList'
import CollapsableContainer from '../components/generic/CollapsableContainer'
import CollapsableContainerWithLink from '../components/generic/CollapsableContainerWithLink'
import CopyToClipboard from '../components/generic/CopyToClipboard'
import DiscountViewWithTooltip from '../components/generic/DiscountViewWithTooltip'
import Flag from '../components/generic/Flag'
import Grid, { GridColumn } from '../components/generic/Grid'
import LazyContainer from '../components/generic/LazyContainer'
import NavigationLinks from '../components/generic/NavigationLinks'
import OrderHeader from '../components/generic/OrderHeader'
import OrderOverview from '../components/generic/OrderOverview'
import FulfillmentProductCard from '../components/generic/FulfillmentProductCard'
import QuestionmarkWithTooltip from '../components/generic/QuestionmarkWithTooltip'
import DeliveryInfoPanel from '../components/panels/DeliveryInfoPanel'
import OrderPricePanel from '../components/panels/OrderPricePanel'
import SbsPanel from '../components/panels/SbsPanel'
import SidePanel from '../components/panels/SidePanel'
import { BadgeSetting, CommonProps, CountryNames, GlobalPanels, TogglePanel } from '../models'
import { AppStore, OrderDetailsStore, OrderDetailsViewStore, RedoStore, SbsPanelViewStore } from '../reducers/states'
import routes from '../route/routes'
import { LocalStorageInstance } from '../services/LocalStorageService'
import { LogApiInstance as LogService } from '../services/LogApiService'
import { OrderDetailsServiceInstance as Service, ReuploadState } from '../services/OrderDetailsService'
import { copyToClipboard, threeValuedLogic, toDateTime } from '../utils'
import ProductionOrderProductCard from '../components/generic/ProductionOrderProductCard'
import ProductionOrderDetailsCard from '../components/generic/ProductionOrderDetailsCard'
import OrderRedoForm from '../components/forms/OrderRedoForm'
import { CollapsableContainerWithCopyButton } from '../components/generic/CollapsableContainerWithCopyButton'
import { OrderRedoFormWithDeliveryOptions } from '../components/redo/OrderRedoFormWithDeliveryOptions'

interface Props extends CommonProps, OrderDetailsViewStore, OrderDetailsStore {
  configuration: GlobalConfiguration
  data: OrderTypes.OrderDetails
  sbsPanelData: SbsPanelViewStore
  redoPanelData: RedoStore
}

class OrderDetailsView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.panelToggle = this.panelToggle.bind(this)
    this.sendNote = this.sendNote.bind(this)
    this.toggleNotes = this.toggleNotes.bind(this)
    this.toggleProductNotes = this.toggleProductNotes.bind(this)
    this.toggleNotesMode = this.toggleNotesMode.bind(this)
    this.toggleTraces = this.toggleTraces.bind(this)
    this.toggleSbs = this.toggleSbs.bind(this)
    this.restorePdf = this.restorePdf.bind(this)
    this.downloadPdf = this.downloadPdf.bind(this)
    this.downloadImageArchive = this.downloadImageArchive.bind(this)
    this.restoreImageArchive = this.restoreImageArchive.bind(this)
    this.toggleReset = this.toggleReset.bind(this)
    this.toggleRedo = this.toggleRedo.bind(this)
    this.toggleCancel = this.toggleCancel.bind(this)
    this.togglePrice = this.togglePrice.bind(this)
    this.toggleChangeAddress = this.toggleChangeAddress.bind(this)
    this.submitRedo = this.submitRedo.bind(this)
    this.submitOrderUpdate = this.submitOrderUpdate.bind(this)
    this.requestReupload = this.requestReupload.bind(this)
    this.cancelReupload = this.cancelReupload.bind(this)
    this.downloadInvoice = this.downloadInvoice.bind(this)
    this.togglePaymentLink = this.togglePaymentLink.bind(this)
    this.submitSbs = this.submitSbs.bind(this)
    this.submitSbsForProductionOrder = this.submitSbsForProductionOrder.bind(this)
    this.deleteSbs = this.deleteSbs.bind(this)
    this.toggleSbsEditMode = this.toggleSbsEditMode.bind(this)
    this.changeAddress = this.changeAddress.bind(this)
    this.toggleCard = this.toggleCard.bind(this)
    this.foldAllCards = this.foldAllCards.bind(this)
  }

  componentDidMount() {
    if (!this.props.data || this.props.data.id !== this.props.match.params.orderId) {
      this.props.dispatch(actions.requestOrderDetails(this.props.match.params.orderId))
    }

    LogService.info(new LogMessage(this.props.match.params.orderId, codes.orderDetailsLoaded))
  }

  componentDidUpdate() {
    if (!this.props.data || this.props.data.id !== this.props.match.params.orderId) {
      this.props.dispatch(actions.requestOrderDetails(this.props.match.params.orderId))
    }
  }

  panelToggle(key: string) {
    this.props.dispatch(actions.togglePanel(key))
  }

  setPreselectedProductionOrderId(productionOrderId?: string) {
    this.props.dispatch(productionOrderActions.setPreselectedProductionOrderId(productionOrderId))
  }

  setPreselectedProductId(productId?: string) {
    this.props.dispatch(productionOrderActions.setPreselectedProductId(productId))
  }

  sendNote(request: NoteRequest) {
    this.props.dispatch(commentActions.sendComment(request))
  }

  toggleNotes() {
    this.props.dispatch(actions.changeNotesFilter())
    this.panelToggle(GlobalPanels.orderNotes.accessKey)
  }

  toggleProductNotes(id: string) {
    this.props.dispatch(actions.changeNotesFilter(id))
    this.panelToggle(GlobalPanels.orderNotes.accessKey)
  }

  toggleNotesMode(isEditMode?: boolean) {
    this.props.dispatch(actions.toggleNotesPanelMode(isEditMode))
  }

  toggleTraces() {
    this.panelToggle(GlobalPanels.orderTraces.accessKey)
  }

  toggleSbs(productCode?: string, ffProduct?: OrderTypes.FulfillmentProduct, productionOrderId?: string) {
    if (productionOrderId) {
      this.props.dispatch(sbsActions.changeSbsFilter({ productionOrderId: productionOrderId }))
    } else if (!productCode) {
      this.props.dispatch(sbsActions.changeSbsFilter())
    } else if (ffProduct) {
      this.props.dispatch(sbsActions.changeSbsFilter({ productCode }))
    } else {
      this.props.dispatch(sbsActions.changeSbsFilter({ error: 'SBS temporarily unavailable' }))
    }

    this.setPreselectedProductionOrderId(productionOrderId)

    this.panelToggle(GlobalPanels.showByScan.accessKey)
  }

  submitSbs(x: SbsPostRequest) {
    this.props.dispatch(sbsActions.submitSbs(x))
  }

  submitSbsForProductionOrder(x: SbsForProductionOrderPostRequest) {
    this.props.dispatch(sbsActions.submitSbsForProductionOrder(x))
  }

  deleteSbs(x: SbsDeleteRequest) {
    this.props.dispatch(sbsActions.removeSbs(x))
  }

  changeAddress(address: OrderDeliveryAddress) {
    const addressAsString = this.orderDeliveryAddressToText(address)
    this.props.dispatch(
      actions.changeAddress({
        appVersion: process.env.REACT_APP_VERSION || '',
        orderCode: this.props.data.id,
        newAddress: address,
        addressAsString: addressAsString,
        vendorId: this.props.data.vendor,
      })
    )
  }

  toggleSbsEditMode() {
    this.props.dispatch(sbsActions.toggleEditMode())
  }

  downloadPdf(product: Product) {
    if (product.pdfUrl) {
      LogService.info(new LogMessage({ message: 'PDF downloaded', order: this.props.data.id }, codes.fileDownloaded))
      window.open(product.pdfUrl, '_blank')
    } else {
      this.props.dispatch(globalActions.showWarning('PDF not available'))
    }
  }

  downloadImageArchive(product: Product) {
    if (product.imageArchiveUrl) {
      LogService.info(new LogMessage({ message: 'Image Archive downloaded', order: this.props.data.id }, codes.fileDownloaded))
      window.open(product.imageArchiveUrl, '_blank')
    } else {
      this.props.dispatch(globalActions.showWarning('Image Archive not available'))
    }
  }

  restorePdf(product: Product) {
    const request: RestoreFromColdStorageRequest = {
      storageId: product.pdfStorageId!,
      orderCode: this.props.data.id,
      productCode: product.id,
      productContentId: product.productContentId!,
    }
    this.props.dispatch(productActions.requestRestoreFromColdStorage(request))
  }

  restoreImageArchive(product: Product) {
    const request: RestoreFromColdStorageRequest = {
      storageId: product.imageArchiveUrl!,
      orderCode: this.props.data.id,
      productCode: product.id,
      productContentId: product.productContentId!,
    }
    this.props.dispatch(productActions.requestRestoreFromColdStorage(request))
  }

  downloadInvoice() {
    const request: OrderTypes.InvoiceDownloadRequest = {
      orderNumber: this.props.data.id,
      superOrderId: this.props.data.internalId,
      vendor: this.props.data.vendor,
      isPaid: Service.isPaid(this.props.data),
    }

    this.props.dispatch(actions.requestDownloadInvoice(request))
  }

  toggleReset() {
    this.panelToggle(GlobalPanels.orderReset.accessKey)
  }

  toggleRedo() {
    this.panelToggle(GlobalPanels.orderRedo.accessKey)
  }

  toggleCancel() {
    this.panelToggle(GlobalPanels.orderCancel.accessKey)
  }

  togglePrice() {
    this.panelToggle(GlobalPanels.orderPrice.accessKey)
  }

  toggleChangeAddress() {
    this.panelToggle(GlobalPanels.changeAddress.accessKey)
  }

  togglePaymentLink() {
    this.props.dispatch(actions.togglePaymentLink())
  }

  submitRedo(payload: OrderTypes.InternalOperationRequest) {
    this.props.dispatch(
      actions.submitOperation({
        ...payload,
        operation: OrderTypes.InternalOperationType.Reset,
      })
    )
  }

  submitOrderUpdate(payload: OrderTypes.InternalOperationRequest) {
    this.props.dispatch(actions.submitOperation(payload))
  }

  requestReupload(payload: OrderTypes.InternalOperationRequest) {
    this.props.dispatch(
      actions.submitOperation({
        ...payload,
        code: this.props.data.id,
        vendor: this.props.data.vendor,
        channel: this.props.data.channel,
        appVersion: process.env.REACT_APP_VERSION || '',
        reasonPhrase: ProductReuploadReasons[Number(payload.reasonCode)],
        operation: OrderTypes.InternalOperationType.Reupload,
      })
    )
  }

  cancelReupload(payload: ProductRequest) {
    this.props.dispatch(
      actions.submitOperation({
        code: this.props.data.id,
        vendor: this.props.data.vendor,
        channel: this.props.data.channel,
        appVersion: process.env.REACT_APP_VERSION || '',
        operation: OrderTypes.InternalOperationType.CancelReupload,
        products: [
          {
            id: payload.productCode,
            quantity: 1,
            value: 1,
          },
        ],
        productionOrders: [],
        comment: '',
      })
    )
  }

  foldAllCards() {
    this.props.dispatch(actions.toggleAllDetails())
  }

  toggleCard(index: number) {
    this.props.dispatch(actions.toggleCardDetails(index))
  }

  getProductionOrdersDetails(
    orderDetails: OrderTypes.OrderDetails
  ): {
    productionOrder: ProductionOrder
    products: Product[]
  }[] {
    let productionOrdersDetails = orderDetails.productionOrders.map((productionOrder: ProductionOrder) => {
      let productsIds = productionOrder.shipments.flatMap((shipment: Shipment) => shipment.products)
      let products = orderDetails.products.filter((product: Product) => productsIds.includes(product.id))
      return {
        productionOrder: productionOrder,
        products: products,
      }
    })
    return productionOrdersDetails
  }

  getFulfillmentOrderDetails(
    orderDetails: OrderTypes.OrderDetails
  ): {
    fulfillmentManagementUrl: string | undefined
    products: Product[]
  } {
    let fulfillmentManagementUrl =
      orderDetails.source !== OrderTypes.DataSource.Studenten
        ? `${this.props.configuration.fulfillmentManagementUrl}?OrderRefCode=${this.props.data.id}`
        : undefined
    let ffProducts: Product[] = []
    if (orderDetails.fulfillment !== undefined) {
      let ffProductIds = orderDetails.fulfillment?.products.map((p: OrderTypes.FulfillmentProduct) => {
        return p.productCode
      })
      ffProducts = orderDetails.products.filter((p: Product) => ffProductIds?.includes(p.id))
    }
    return {
      fulfillmentManagementUrl: fulfillmentManagementUrl,
      products: ffProducts,
    }
  }

  getUnassignedOrderDetails(
    orderDetails: OrderTypes.OrderDetails
  ): {
    products: Product[]
  } {
    let unassignedProducts: Product[] = []
    try {
      if (
        orderDetails.fulfillment != undefined ||
        (orderDetails.productionOrders != undefined && orderDetails.productionOrders.length > 0)
      ) {
        let assignedProductIds: (string | undefined)[] = []
        let poProductIdsMatrix = orderDetails.productionOrders.map((productionOrder: ProductionOrder) => {
          let productsIds = productionOrder.shipments.flatMap((shipment: Shipment) => shipment.products)
          {
            return productsIds
          }
        })

        for (let i = 0; i < poProductIdsMatrix.length; i++) {
          assignedProductIds.push(...poProductIdsMatrix[i])
        }

        if (orderDetails.fulfillment != undefined) {
          assignedProductIds.push(
            ...orderDetails.fulfillment?.products.map((p: OrderTypes.FulfillmentProduct) => {
              return p.productCode
            })
          )
        }
        unassignedProducts = orderDetails.products.filter((product: Product) => !assignedProductIds.includes(product.id))
      } else {
        unassignedProducts = orderDetails.products
      }
    } catch (ex) {
      console.error('getUnassignedOrderDetails::Error:' + ex)
      LogService.error(
        new LogMessage(
          {
            message: 'Error composing unassigned products for the SALesOrder',
            salesOrderId: orderDetails.id,
            jsException: ex,
          },
          codes.reactJsError
        )
      )
    }

    return {
      products: unassignedProducts,
    }
  }

  render() {
    const data = this.props.data
    if (!data) {
      return <div />
    }

    const parentBadgeSettings = Service.resolveOrderStatusBadgeSettings(data, false)

    const cancelPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderCancel.accessKey],
      toggle: this.toggleCancel,
    }

    const resetPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderReset.accessKey],
      toggle: this.toggleReset,
    }

    const redoPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderRedo.accessKey],
      toggle: this.toggleRedo,
    }

    const pricePanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderPrice.accessKey],
      toggle: this.togglePrice,
    }

    const changeAddressPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.changeAddress.accessKey],
      toggle: this.toggleChangeAddress,
    }

    const shopUrl = OrderTypes.VendorShopUrls[data.vendor]

    const paymentLink = shopUrl ? `${shopUrl}/Payment?superOrderCode=${data.id}` : '[Unknown link. Contact CCTS]'

    const actions = []

    // Mixed order that is spread among FF and PO
    const isHybridOrder =
      data.fulfillment !== undefined && data.productionOrders !== undefined && data.productionOrders.length > 0

    const resetText = data.fulfillment ? 'Reset' : 'Send to Fulfilment'
    if (this.props.configuration.permissions.isAgent) {
      if (!isHybridOrder) {
        if (data.productionOrders?.length === 0) {
          actions.push({ text: resetText, onClick: this.toggleReset })
        } else {
          if (data.status === OrderTypes.OrderStatus.Cancelled) {
            actions.push({ text: 'Redo', className: 'disabled', title: 'Redo is not allowed for cancelled order' })
          } else {
            actions.push({ text: 'Redo', onClick: () => this.onRedoWithPreselectedProductionOrder(undefined) })
          }
        }
      }

      actions.push({ text: 'Cancel', onClick: () => this.onCancelWithSelectedProductionOrder(undefined) })
    }

    actions.push({ text: 'Add note', onClick: () => this.onNoteWithSelectedProductAndProductionOrder(undefined, undefined) })
    actions.push({ text: 'Timeline', route: routes.ORDERS_TIMELINE({ orderId: data.id }) })

    const isGuest = data.customer?.accountType == 'GUEST'
    if (isGuest) {
      actions.push({ text: 'Copy order status page Url', onClick: () => this.onCopyStatusPageUrl(data) })
    }

    const paymentAction: ActionLinkSettings[] = [
      { text: 'Invoice', onClick: this.downloadInvoice },
      { text: 'Payment link', onClick: this.togglePaymentLink },
    ]

    const isRefundAgent = this.props.configuration.permissions.isRefundAgent
    if (isRefundAgent) {
      paymentAction.push({ text: 'Refund', route: routes.REFUNDS({ orderId: data.id }) })
    }

    const tracesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderTraces.accessKey],
      toggle: this.toggleTraces,
    }

    const shippableFulfillmentLines = Service.getShippableFulfillmentLines(data.products, data.fulfillment)
    const shippableProductionOrders = Service.getShippableProductionOrders(data.productionOrders)
    const plantsWithOutstandingDeliveries = _.union(
      Service.getPlants(shippableFulfillmentLines),
      Service.getPlantsFromProductionOrders(shippableProductionOrders)
    )

    const addressActions: ActionLinkSettings[] = [
      { text: 'Delivery info', onClick: this.toggleTraces },
      {
        text: 'Copy to clipboard',
        onClick: () => (data.shippingAddress ? this.copyAddressToClipboard(data.shippingAddress) : {}),
      },
    ]

    const config = LocalStorageInstance.getGlobalConfiguration()

    let productionOrdersDetails = this.getProductionOrdersDetails(data)
    let fulfillmentOrderDetails = this.getFulfillmentOrderDetails(data)
    let unassignedOrderDetails = this.getUnassignedOrderDetails(data)

    if (plantsWithOutstandingDeliveries.some((p) => config?.plantFeatures.addressChangeEnabled.includes(p.plant))
      || (unassignedOrderDetails.products && unassignedOrderDetails.products.length > 0)) {
      addressActions.push({ id: 'change-address-link', text: 'Change address', onClick: this.toggleChangeAddress })
    }

    return (
      <Container fluid>
        <Row>
          <NavigationLinks orderCode={data.id} source={data.source} configuration={this.props.configuration} />
        </Row>
        <Row>{this.renderOrderHeader()}</Row>
        <Row>
          <OrderOverview data={data} />
        </Row>
        <Row>
          <ActionList actions={actions} />
          <SidePanel {...cancelPanel}>
            <LazyContainer isLoading={cancelPanel.isLoading}>
              <OrderCancelForm onSubmit={this.submitOrderUpdate} data={data} />
            </LazyContainer>
          </SidePanel>
          <SidePanel {...resetPanel} title={resetText}>
            <LazyContainer isLoading={resetPanel.isLoading}>
              <OrderResetForm
                onSubmit={this.submitOrderUpdate}
                reasons={this.props.configuration.resetReasons}
                data={data}
                operationName={resetText}
              />
            </LazyContainer>
          </SidePanel>
          <SidePanel {...redoPanel} title="Redo">
            <LazyContainer isLoading={redoPanel.isLoading}>
              {this.props.configuration?.redoWithDeliveryOptions ? (
                <OrderRedoFormWithDeliveryOptions toggleSidePanel={this.toggleRedo} />
              ) : (
                <OrderRedoForm onSubmit={this.submitRedo} reasons={this.props.configuration.resetReasons} data={data} />
              )}
            </LazyContainer>
          </SidePanel>
        </Row>
        <Row id="shipping-address-details" className="mt-3">
          <h3>Shipping address</h3>
          {this.renderAddress(data.shippingAddress, data.deliveryInfo, data.fulfillment)}
          <DeliveryInfoPanel {...tracesPanel} data={data} />
          <ActionList actions={addressActions} />
          <SidePanel {...changeAddressPanel}>
            <LazyContainer isLoading={changeAddressPanel.isLoading}>
              {this.isPUDO(data.fulfillment?.deliveryMethod) && (
                <Alert color="warning">Change address is unavailable for Pickup Point deliveries.</Alert>
              )}
              <ChangeAddressForm
                onSubmit={this.changeAddress}
                form="change-address-form"
                initialValues={data.orderDeliveryAddress}
                disableSubmit={this.isPUDO(data.fulfillment?.deliveryMethod)}
                showExtendedAddress={this.showExtendedAddress(data.orderDeliveryAddress?.country)}
                showCounty={this.showCounty(data.orderDeliveryAddress?.country)}
                showComplement={this.showComplement(data.orderDeliveryAddress?.country)}
              />
            </LazyContainer>
          </SidePanel>
        </Row>
        <Row className="mt-3">
          <CollapsableContainer title="Billing address" folded={true}>
            {this.renderAddress(data.billingAddress)}
            <ActionList
              actions={[
                {
                  text: 'Copy to clipboard',
                  onClick: () => (data.billingAddress ? this.copyAddressToClipboard(data.billingAddress) : {}),
                },
              ]}
            />
          </CollapsableContainer>
        </Row>
        <div className="d-flex justify-content-between mt-1">
          <h3></h3>
          <ActionLink
            text={this.props.allCardsFolded ? 'Show all product details' : 'Close all product details'}
            onClick={this.foldAllCards}
          />
        </div>
        {productionOrdersDetails &&
          productionOrdersDetails.length > 0 &&
          productionOrdersDetails
            .sort(sortByProductionOrderDate())
            .map((productionOrderDetails: { productionOrder: ProductionOrder; products: Product[] }) => {
              let containerTitle = `Production order ${productionOrderDetails.productionOrder.id}`
              return (
                <Row className="mt-3" key={productionOrderDetails.productionOrder.id}>
                  <div className="bo-container d-flex flex-column pb-1">
                    <CollapsableContainerWithCopyButton
                      title={containerTitle}
                      valueToCopy={productionOrderDetails.productionOrder.id}
                      onToggleCard={this.toggleCard}
                      folderCards={this.props.foldedCards}
                      foldAll={this.props.allCardsFolded}
                      index={productionOrdersDetails.findIndex(
                        (pod) => pod.productionOrder.id === productionOrderDetails.productionOrder.id
                      )}>
                      <div className="d-flex flex-column">
                        {this.renderProductionOrderHeader(productionOrderDetails.productionOrder)}
                        <Row>
                          {productionOrderDetails.products &&
                            productionOrderDetails.products.map((product: Product) =>
                              this.renderProductionOrderProductCard(
                                productionOrderDetails.productionOrder,
                                product,
                                this.getProductionOrderProduct(productionOrderDetails.productionOrder, product.id),
                                data.vendor,
                                parentBadgeSettings
                              )
                            )}
                        </Row>
                      </div>
                    </CollapsableContainerWithCopyButton>
                  </div>
                </Row>
              )
            })}
        {fulfillmentOrderDetails.products && fulfillmentOrderDetails.products.length > 0 && (
          <Row className="mt-3">
            <div className="bo-container d-flex flex-column pb-1">
              <CollapsableContainerWithLink
                // If there's any virtual plant selected event, then order split is already done and it's fine to show FF text
                title={data.orderEvents?.find((e) => e.event.includes('virtual-plant-selected')) ? 'Fulfillment order' : 'Order'}
                link={fulfillmentOrderDetails.fulfillmentManagementUrl}
                index={productionOrdersDetails.length}
                folderCards={this.props.foldedCards}
                onToggleCard={this.toggleCard}
                foldAll={this.props.allCardsFolded}>
                <Row>
                  {fulfillmentOrderDetails.products.map((p: Product) =>
                    this.renderFulfillmentProductCard(p, data.fulfillment, data.vendor, parentBadgeSettings, isHybridOrder)
                  )}
                </Row>
              </CollapsableContainerWithLink>
            </div>
          </Row>
        )}
        {unassignedOrderDetails.products && unassignedOrderDetails.products.length > 0 && (
          <Row className="mt-3">
            <div className="bo-container d-flex flex-column pb-1">
              <CollapsableContainerWithLink
                title="Pending products"
                index={unassignedOrderDetails.products.length}
                folderCards={this.props.foldedCards}
                onToggleCard={this.toggleCard}
                foldAll={this.props.allCardsFolded}
                toolTip="Not assigned to iWays or Fulfillment yet">
                <Row>
                  {unassignedOrderDetails.products.map((p: Product) =>
                    this.renderFulfillmentProductCard(p, data.fulfillment, data.vendor, parentBadgeSettings, isHybridOrder)
                  )}
                </Row>
              </CollapsableContainerWithLink>
            </div>
          </Row>
        )}
        <Row className="mt-3">
          <h3>Price</h3>
          {this.renderPriceGrid()}
        </Row>
        <Row>
          <OrderPricePanel {...pricePanel} data={data} showRefundButton={isRefundAgent} />
          <ActionList actions={[{ text: 'Price details', onClick: this.togglePrice }]} />
        </Row>
        <Row className="mt-3">
          <h3>Payment</h3>
          {this.renderPayments(data.paymentHistory)}
          <ModalPopup title="Payment link" isOpen={this.props.paymentLinkIsOpen} toggle={this.togglePaymentLink}>
            {this.renderPaymentLinkWarningIfNeeded(data)}
            <div className="d-flex align-items-center">
              <span>{paymentLink}</span>
              <CopyToClipboard stringToCopy={paymentLink} className="ml-3" />
            </div>
          </ModalPopup>
        </Row>
        <Row>
          <ActionList actions={paymentAction} />
        </Row>
      </Container>
    )
  }

  renderPaymentLinkWarningIfNeeded(data: OrderTypes.OrderDetails) {
    const warning = <Alert color="warning">Product file is not available anymore. Please advise the customer to re-order.</Alert>

    if (data.createdDateTime && moment().diff(moment(data.createdDateTime), 'months') >= 3) {
      return warning
    }

    if (data.products.some((p) => !p.albxUrl && !p.pdfUrl)) {
      return warning
    }

    return undefined
  }

  renderOrderHeader() {
    const sbsPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.showByScan.accessKey],
      toggle: this.toggleSbs,
    }
    const notesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderNotes.accessKey],
      toggle: this.toggleNotes,
    }
    const sbsCollection = Service.filterSbsCollection(this.props.sbsPanelData.collection)

    return (
      <OrderHeader
        order={this.props.data}
        notesPanelDetails={{
          notesPanel: notesPanel,
          isNotesInEditMode: this.props.isNotesInEditMode,
          notesFilter: this.props.notesFilter,
          sendNote: this.sendNote,
          showNotesCreatedNotification: this.props.showNotesCreatedNotification,
          toggleNotes: () => this.onNoteWithSelectedProductAndProductionOrder(undefined, undefined),
          toggleNotesMode: this.toggleNotesMode,
        }}>
        <React.Fragment>
          {sbsCollection && sbsCollection.length > 0 && (
            <button
              className="orange medium d-flex align-items-center ml-2"
              onClick={() => this.toggleSbs()}
              id="header-sbs-button">
              <Flag icon={sbsIcon} title="SBS" size={24} /> SBS ({sbsCollection.length})
            </button>
          )}
          <SbsPanel
            {...sbsPanel}
            sbsData={this.props.sbsPanelData}
            orderDetails={this.props.data}
            readOnly={!this.props.configuration.permissions.isAgent}
            onSubmit={this.submitSbs}
            onSubmitForProductionOrder={this.submitSbsForProductionOrder}
            onDelete={this.deleteSbs}
            onEditModeToggle={this.toggleSbsEditMode}
          />
        </React.Fragment>
      </OrderHeader>
    )
  }

  renderPriceGrid() {
    const data = this.props.data

    const columns: GridColumn<OrderTypes.Price>[] = [
      { title: 'Currency', value: (price) => price.currency },
      { title: 'Sub Total', value: (price) => price.subTotal },
      { title: 'Shipping cost', value: (price) => price.shipment },
      { title: 'Discount', value: (price) => price.discount },
      { title: 'Total', value: (price) => price.total },
      {
        title: 'Discount code',
        value: (price) =>
          price.discountCode && (
            <DiscountViewWithTooltip
              discountCode={price.discountCode}
              loading={this.props.discountLoading}
              data={this.props.discount}
              error={this.props.discountLookupError}
              showTooltip={!price.discountCode.toUpperCase().startsWith('REUPLOAD')}
              onLoad={() => this.props.dispatch(actions.requestDiscountView(price.discountCode))}
            />
          ),
      },
      { title: 'Tax', value: (price) => price.tax },
    ]

    return <Grid columns={columns} data={[data.price]} disableCopyOnClick={true} />
  }

  renderPayments(payments?: OrderTypes.PaymentHistory[]) {
    const columns: GridColumn<OrderTypes.PaymentHistory>[] = [
      { title: 'Date', value: (p) => toDateTime(p.received) },
      { title: 'Method', value: (p) => p.method },
      { title: 'Status', value: (p) => p.event },
      { title: 'Success', value: (p) => threeValuedLogic(p.success, 'Yes', 'No', '') },
      { title: 'Reference', value: (p) => p.providerReference, tdClass: 'pr-1' },
      { title: '', value: (p) => this.renderAdyenLink(p.providerReference) },
      { title: 'Amount', value: (p) => p.paidAmount },
      { title: 'Currency', value: (p) => p.currency },
      { title: 'Reason', value: (p) => p.reason },
    ]

    return <Grid columns={columns} data={payments} />
  }

  renderAdyenLink(reference?: string) {
    if (!reference) {
      return
    }

    return <ActionLink href={`${this.props.configuration.adyenUrl}${reference}`} />
  }

  renderAddress(
    address?: OrderTypes.Address,
    deliveryInfo?: OrderTypes.TraceWithProducts[],
    fulfillment?: OrderTypes.Fulfillment
  ) {
    const secondLinePresent = address && !!address.addressLine2

    const columns: GridColumn<OrderTypes.Address>[] = [{ title: 'Name', value: (s) => this.addressToCustomerName(s) }]

    if (this.isPUDO(fulfillment?.deliveryMethod)) {
      columns.push({ title: 'Pickup Point Name', value: () => fulfillment?.pickupPointName })
    } else {
      columns.push({ title: 'Company', value: (s) => s.companyName })
    }

    if (secondLinePresent) {
      columns.push({ title: 'Line 1', value: (s) => s.addressLine1 })
      columns.push({ title: 'Line 2', value: (s) => s.addressLine2 })
    } else {
      columns.push({ title: 'Address', value: (s) => s.addressLine1 })
    }

    const countryCode = (address && address.country) || ''
    const countryName = CountryNames[countryCode]
    if (address && !countryName) {
      LogService.error(
        new LogMessage(
          {
            message: 'Failed to resolve the country.',
            country: countryCode,
          },
          codes.orderDetailsError
        )
      )
    }

    const additionalColumns: GridColumn<OrderTypes.Address>[] = [
      { title: 'Zip', value: (s) => s.zipCode },
      { title: 'City', value: (s) => s.city },
      { title: this.renderCountryColumnTitle(), value: (s) => countryName },
      { title: 'Phone', value: (s) => s.customerPhone },
    ]

    if (address && address.type !== 0) {
      additionalColumns.push({ title: 'Type', value: (s) => OrderTypes.FulfillmentShipmentDeliveryTypeNames[s.type] })
    }

    if (deliveryInfo) {
      additionalColumns.push({
        title: 'Scanned for shipment',
        value: () => {
          const scannedLinkText = Service.resolveShipmentsText(deliveryInfo)
          if (scannedLinkText) {
            return <ActionLink className="attention" onClick={this.toggleTraces} text={scannedLinkText} />
          }

          return <span className="secondary-font">N/A</span>
        },
      })
    }

    return <Grid columns={columns.concat(additionalColumns)} data={address ? [address] : undefined} disableCopyOnClick={true} />
  }

  renderCountryColumnTitle() {
    return (
      <span>
        Country{' '}
        <QuestionmarkWithTooltip tooltipId="countryCodes_tooltip" placement="right" size={20}>
          <div>
            <ActionLink text="Check country info" href="https://wiki.albelli.net/wiki/CC_Vendor_differences" />
          </div>
        </QuestionmarkWithTooltip>
      </span>
    )
  }

  addressToText(address: OrderTypes.Address): string {
    const append = function (result: string, add?: string): string {
      if (!add) {
        return result
      }

      return result + '\n' + add
    }

    let result: string = ''

    result = append(result, this.addressToCustomerName(address))
    result = append(result, address.companyName)
    result = append(result, address.addressLine1)
    result = append(result, address.addressLine2)
    result = append(result, address.zipCode + ' ' + address.city)
    result = append(result, address.country ? CountryNames[address.country] : '')

    return result.trim()
  }

  addressToCustomerName(address: OrderTypes.Address): string {
    let customerName: string = ''

    if (address.customerFirstName) {
      customerName += address.customerFirstName
    }

    if (address.customerPrefix) {
      customerName += ' ' + address.customerPrefix
    }

    if (address.customerLastName) {
      customerName += ' ' + address.customerLastName
    }

    return customerName || address.customerName || ''
  }

  copyAddressToClipboard(address: OrderTypes.Address): void {
    copyToClipboard(this.addressToText(address))
  }

  orderDeliveryAddressToText(address: OrderTypes.OrderDeliveryAddress): string {
    const append = function (result: string, add?: string): string {
      if (!add) {
        return result
      }

      return result + '\n' + add
    }

    let result: string = ''

    result = append(result, this.orderDeliveryAddressToCustomerName(address))
    result = append(result, address.companyName)
    if (this.showExtendedAddress(address.country)) {
      result = append(result, address.street + ' ' + address.houseNumber + '-' + address.houseNumberExtension)
    } else {
      result = append(result, address.addressLine1)
      result = append(result, address.addressLine2)
    }
    result = append(result, address.zipCode + ' ' + address.city)
    if (this.showCounty(address.country)) {
      result = append(result, address.county)
    }
    result = append(result, address.country ? CountryNames[address.country] : '')

    return result.trim()
  }

  orderDeliveryAddressToCustomerName(address: OrderTypes.OrderDeliveryAddress): string {
    let customerName: string = ''

    if (address.customerFirstName) {
      customerName += address.customerFirstName
    }

    if (address.customerPrefix) {
      customerName += ' ' + address.customerPrefix
    }

    if (address.customerLastName) {
      customerName += ' ' + address.customerLastName
    }

    return customerName || address.customerName || ''
  }

  extractPanel(accessKey: string): TogglePanel | undefined {
    const panel = this.props.productPanels[accessKey]
    return (
      panel && {
        ...panel,
        toggle: () => panel && this.panelToggle(panel.accessKey),
      }
    )
  }

  isHighlighted(product: Product) {
    const search = queryString.parse(this.props.location.search)

    return product.id === search.selected
  }

  getProductionOrderProduct(productionOrder: ProductionOrder, productCode: string): ProductionOrderProduct | undefined {
    const shipment = productionOrder.shipments.find(
      (s) => s.productsData !== undefined && s.productsData.some((p) => p.id === productCode)
    )

    return shipment?.productsData.find((p) => p.id === productCode)
  }

  renderProductionOrderProductCard(
    productionOrder: ProductionOrder,
    product: Product,
    productionOrderProduct: ProductionOrderProduct | undefined,
    vendor: string,
    parentStatus: BadgeSetting
  ) {
    const widgetPanelKey = getWidgetPanelKey(product.id)
    const previewSrc = Service.resolveProductPreviewUrl(product)
    const linesTogglePanel = this.extractPanel(`${product.id}_lines`)
    const optionsTogglePanel = this.extractPanel(`${product.id}_options`)
    const reuploadTogglePanel = this.extractPanel(`${product.id}_reupload`)
    const cancelReuploadTogglePanel = this.extractPanel(`${product.id}_cancel_reupload`)
    const widgetTogglePanel = this.extractPanel(widgetPanelKey)
    const downloadPdfFunc = () => this.downloadPdf(product)
    const downloadImageArchiveFunc = () => this.downloadImageArchive(product)
    const restorePdfFunc = () => this.restorePdf(product)
    const restoreImageArchiveFunc = () => this.restoreImageArchive(product)
    const events = Service.resolveProductEvents(this.props.data.orderEvents, product.id)
    const reuploadState = Service.resolveReuploadState(events)
    const requestReupload = (request: OrderTypes.InternalOperationRequest) => this.requestReupload(request)
    const cancelReupload = (request: ProductRequest) => this.cancelReupload(request)
    const toggleProductNotes = () => this.toggleProductNotes(product.id)
    const widgetPreviewBaseUrl = this.props.configuration.widgetPreviewUrls[vendor]
    const widgetPreviewUrl = widgetPreviewBaseUrl && `${widgetPreviewBaseUrl}${product.widgetId}&securityId=${product.securityId}`;

    const actions: ActionLinkSettings[] = []

    if (product.pdfIsInColdStorage) {
      if (this.props.restoringFiles.some((x) => x === product.pdfStorageId)) {
        actions.push({
          text: 'Restoring PDF',
          className: 'disabled',
          title: 'PDF is not available yet',
        })
      } else {
        actions.push({
          text: 'Restore PDF',
          onClick: restorePdfFunc,
          title: 'Restore PDF',
        })
      }
    } else if (product.pdfUrl) {
      actions.push({
        text: 'PDF',
        onClick: downloadPdfFunc,
        title: 'Download PDF',
      })
    } else {
      actions.push({
        text: 'PDF',
        className: 'disabled',
        title: 'PDF is not available',
      })
    }

    if (product.imageArchiveIsInColdStorage) {
      if (this.props.restoringFiles.some((x) => x === product.imageArchiveStorageId)) {
        actions.push({
          text: 'Restoring Image Archive',
          className: 'disabled',
          title: 'Image Archive is not available yet',
        })
      } else {
        actions.push({
          text: 'Restore Image Archive',
          onClick: restoreImageArchiveFunc,
          title: 'Restore Image Archive',
        })
      }
    } else if (product.imageArchiveUrl) {
      actions.push({
        text: 'Image Archive',
        onClick: downloadImageArchiveFunc,
        title: 'Download Image Archive',
      })
    } else {
      actions.push({
        text: 'Image Archive',
        className: 'disabled',
        title: 'Image Archive is not available',
      })
    }

    actions.push({ text: 'Widget', onClick: () => this.panelToggle(widgetPanelKey) })

    if (optionsTogglePanel) {
      actions.push({ text: 'Product Options', onClick: () => optionsTogglePanel.toggle() })
    }

    if (this.props.configuration.permissions.isExpert) {
      actions.push({
        text: 'Product Content',
        route: routes.ORDERS_PRODUCT_VIEW({
          orderId: this.props.data.id,
          productId: productionOrderProduct?.id,
          contentId: productionOrderProduct?.resourceFiles[0]?.resourceFileId,
        }),
      })
    }

    if (this.props.configuration.permissions.isAgent) {
      if (reuploadState?.state === ReuploadState.Requested && cancelReuploadTogglePanel) {
        actions.push({ text: 'Cancel Reupload', onClick: () => cancelReuploadTogglePanel.toggle() })
      } else if (reuploadState === undefined && reuploadTogglePanel) {
        actions.push({ text: 'Reupload', onClick: () => reuploadTogglePanel.toggle() })
      }
      actions.push({
        text: 'Note',
        onClick: () => this.onNoteWithSelectedProductAndProductionOrder(productionOrder.id, product.id),
      })
    }

    const relatedNotes = Service.filterNotesByProductionOrderAndProductId(this.props.data.notes, productionOrder.id, product.id)
    const resetCounter = Service.resolveResetOrderEvents(events || [], productionOrder.id).length

    const deleteWidget = () => {
      if (product.widgetId) {
        const payload = {
          orderCode: this.props.data.id,
          productCode: product.id,
          vendorId: vendor,
          widgetId: product.widgetId,
        }
        this.props.dispatch(widgetActions.deleteRequest(payload))
      }
    }

    const recreateWidget = () => {
      if (product.widgetId) {
        const payload = {
          orderCode: this.props.data.id,
          productCode: product.id,
          vendorId: vendor,
          widgetId: product.widgetId,
        }
        this.props.dispatch(widgetActions.recreateRequest(payload))
      }
    }

    const createWidget = () => {
      const payload: WidgetRequest = {
        orderCode: this.props.data.id,
        productCode: product.id,
      }
      this.props.dispatch(widgetActions.createRequest(payload))
    }

    return (
      <ProductionOrderProductCard
        key={product.id}
        product={product}
        productionOrder={productionOrder}
        productionOrderProduct={productionOrderProduct}
        productEvents={events}
        vendor={vendor}
        actions={actions}
        previewSrc={previewSrc}
        optionsPanel={optionsTogglePanel}
        reuploadPanel={reuploadTogglePanel}
        cancelReuploadPanel={cancelReuploadTogglePanel}
        onReuploadSubmit={requestReupload}
        onReuploadCancel={cancelReupload}
        widgetPanel={widgetTogglePanel}
        parentStatus={parentStatus}
        highlighted={this.isHighlighted(product)}
        notesCount={relatedNotes.length}
        resetCounter={resetCounter}
        widgetPreviewUrl={widgetPreviewUrl}
        onCreateWidgetClick={createWidget}
        onDeleteWidgetClick={deleteWidget}
        onRecreateWidgetClick={recreateWidget}
        onDelayLinkClick={this.toggleTraces}
      />
    )
  }

  renderFulfillmentProductCard(
    product: Product,
    fulfillment: OrderTypes.Fulfillment | undefined,
    vendor: string,
    parentStatus: BadgeSetting,
    isHybridOrder: boolean
  ) {
    const widgetPanelKey = getWidgetPanelKey(product.id)
    const previewSrc = Service.resolveProductPreviewUrl(product)
    const ffProduct = Service.resolveFulfillmentProduct(product, fulfillment)
    const linesTogglePanel = this.extractPanel(`${product.id}_lines`)
    const optionsTogglePanel = this.extractPanel(`${product.id}_options`)
    const reuploadTogglePanel = this.extractPanel(`${product.id}_reupload`)
    const cancelReuploadTogglePanel = this.extractPanel(`${product.id}_cancel_reupload`)
    const widgetTogglePanel = this.extractPanel(widgetPanelKey)
    const downloadPdfFunc = () => this.downloadPdf(product)
    const downloadImageArchiveFunc = () => this.downloadImageArchive(product)
    const restorePdfFunc = () => this.restorePdf(product)
    const restoreImageArchiveFunc = () => this.restoreImageArchive(product)
    const events = Service.resolveProductEvents(this.props.data.orderEvents, product.id)
    const reuploadState = Service.resolveReuploadState(events)
    const requestReupload = (request: OrderTypes.InternalOperationRequest) => this.requestReupload(request)
    const cancelReupload = (request: ProductRequest) => this.cancelReupload(request)
    const toggleProductNotes = () => this.toggleProductNotes(product.id)
    const widgetPreviewBaseUrl = this.props.configuration.widgetPreviewUrls[vendor]
    const widgetPreviewUrl = widgetPreviewBaseUrl && `${widgetPreviewBaseUrl}${product.widgetId}&securityId=${product.securityId}`;

    const actions: ActionLinkSettings[] = []

    if (product.pdfIsInColdStorage) {
      if (this.props.restoringFiles.some((x) => x === product.pdfStorageId)) {
        actions.push({
          text: 'Restoring PDF',
          className: 'disabled',
          title: 'PDF is not available yet',
        })
      } else {
        actions.push({
          text: 'Restore PDF',
          onClick: restorePdfFunc,
          title: 'Restore PDF',
        })
      }
    } else if (product.pdfUrl) {
      actions.push({
        text: 'PDF',
        onClick: downloadPdfFunc,
        title: 'Download PDF',
      })
    } else {
      actions.push({
        text: 'PDF',
        className: 'disabled',
        title: 'PDF is not available',
      })
    }

    if (product.imageArchiveIsInColdStorage) {
      if (this.props.restoringFiles.some((x) => x === product.imageArchiveStorageId)) {
        actions.push({
          text: 'Restoring Image Archive',
          className: 'disabled',
          title: 'Image Archive is not available yet',
        })
      } else {
        actions.push({
          text: 'Restore Image Archive',
          onClick: restoreImageArchiveFunc,
          title: 'Restore Image Archive',
        })
      }
    } else if (product.imageArchiveUrl) {
      actions.push({
        text: 'Image Archive',
        onClick: downloadImageArchiveFunc,
        title: 'Download Image Archive',
      })
    } else {
      actions.push({
        text: 'Image Archive',
        className: 'disabled',
        title: 'Image Archive is not available',
      })
    }

    if (isHybridOrder) {
      actions.push({ text: 'Reset', onClick: this.toggleReset })
    }

    actions.push({ text: 'SBS', onClick: () => this.toggleSbs(product.id, ffProduct, undefined) })
    actions.push({ text: 'Notes', onClick: toggleProductNotes })
    actions.push({ text: 'Widget', onClick: () => this.panelToggle(widgetPanelKey) })

    if (this.props.configuration.permissions.isAgent) {
      if (reuploadState?.state === ReuploadState.Requested && cancelReuploadTogglePanel) {
        actions.push({ text: 'Cancel Reupload', onClick: () => cancelReuploadTogglePanel.toggle() })
      } else if (reuploadState === undefined && reuploadTogglePanel) {
        actions.push({ text: 'Reupload', onClick: () => reuploadTogglePanel.toggle() })
      }
    }

    if (optionsTogglePanel) {
      actions.push({ text: 'Product Options', onClick: () => optionsTogglePanel.toggle() })
    }

    if (this.props.configuration.permissions.isExpert) {
      actions.push({
        text: 'Product Content',
        route: routes.ORDERS_PRODUCT_VIEW({
          orderId: this.props.data.id,
          productId: product.id,
          contentId: fulfillment
            ? fulfillment.products.find((x) => x.productCode === product.id)?.contentRef
            : product.productContentId,
        }),
      })
    }

    if (linesTogglePanel) {
      actions.push({ text: 'Lines', onClick: () => linesTogglePanel.toggle() })
    }

    const relatedNotes = Service.filterNotes(this.props.data.notes, product.id)
    const sbsCollection = Service.filterSbsCollection(this.props.sbsPanelData.collection, product.id)
    const resetCounter = Service.resolveOrderEventsByType(events || [], OrderEvents.Reprocess).length

    const deleteWidget = () => {
      if (product.widgetId) {
        const payload = {
          orderCode: this.props.data.id,
          productCode: product.id,
          vendorId: vendor,
          widgetId: product.widgetId,
        }
        this.props.dispatch(widgetActions.deleteRequest(payload))
      }
    }

    const recreateWidget = () => {
      if (product.widgetId) {
        const payload = {
          orderCode: this.props.data.id,
          productCode: product.id,
          vendorId: vendor,
          widgetId: product.widgetId,
        }
        this.props.dispatch(widgetActions.recreateRequest(payload))
      }
    }

    const createWidget = () => {
      const payload: WidgetRequest = {
        orderCode: this.props.data.id,
        productCode: product.id,
      }
      this.props.dispatch(widgetActions.createRequest(payload))
    }

    return (
      <FulfillmentProductCard
        key={product.id}
        product={product}
        ffProduct={ffProduct}
        productEvents={events}
        vendor={vendor}
        actions={actions}
        previewSrc={previewSrc}
        linesPanel={linesTogglePanel}
        optionsPanel={optionsTogglePanel}
        reuploadPanel={reuploadTogglePanel}
        cancelReuploadPanel={cancelReuploadTogglePanel}
        onReuploadSubmit={requestReupload}
        onReuploadCancel={cancelReupload}
        widgetPanel={widgetTogglePanel}
        parentStatus={parentStatus}
        highlighted={this.isHighlighted(product)}
        notesCount={relatedNotes.length}
        hasSbs={sbsCollection ? sbsCollection.length > 0 : false}
        resetCounter={resetCounter}
        widgetPreviewUrl={widgetPreviewUrl}
        onCreateWidgetClick={createWidget}
        onDeleteWidgetClick={deleteWidget}
        onRecreateWidgetClick={recreateWidget}
        onDelayLinkClick={this.toggleTraces}
        ffUiUrl={this.props.configuration.fulfillmentManagementUrl}
      />
    )
  }

  renderProductionOrderHeader(productionOrder: ProductionOrder) {
    const actions: ActionLinkSettings[] = []

    if (productionOrder.status === 'Cancelled') {
      actions.push({
        text: 'Redo',
        className: 'disabled',
        title: 'Redo is not allowed for cancelled production order',
      })
    } else {
      actions.push({
        text: 'Redo',
        onClick: () => this.onRedoWithPreselectedProductionOrder(productionOrder.id),
        title: 'Redo',
      })
    }

    const config = LocalStorageInstance.getGlobalConfiguration()
    const plantInfo = Service.getPlantInfo(productionOrder.plant ?? 'TBD')
    if (config?.plantFeatures.sbsDisabled.includes(plantInfo.plant)) {
      actions.push({
        text: 'SBS',
        className: 'disabled',
        title: 'SBS not available for plant',
      })
    } else {
      actions.push({ text: 'SBS', title: 'SBS', onClick: () => this.toggleSbs(undefined, undefined, productionOrder.id) })
    }

    if (productionOrder.status === 'Cancelled') {
      actions.push({
        text: 'Cancel',
        className: 'disabled',
        title: 'Production order is already cancelled',
      })
    } else if (productionOrder.status === 'Shipped') {
      actions.push({
        text: 'Cancel',
        className: 'disabled',
        title: 'Production order is already shipped',
      })
    } else {
      actions.push({
        text: 'Cancel',
        title: 'Cancel production order',
        onClick: () => this.onCancelWithSelectedProductionOrder(productionOrder.id),
      })
    }

    const relatedNotes = Service.filterNotesByProductionOrderId(this.props.data.notes, productionOrder.id)
    const relatedProductionNotifications = Service.filterSbsCollection(
      this.props.sbsPanelData.collection,
      undefined,
      productionOrder.id
    )

    return (
      <ProductionOrderDetailsCard
        productionOrder={productionOrder}
        actions={actions}
        orderEvents={this.props.data.orderEvents}
        notesCount={relatedNotes.length}
        hasProductionNotification={relatedProductionNotifications ? relatedProductionNotifications.length > 0 : false}
      />
    )
  }

  onCancelWithSelectedProductionOrder(productionOrderId?: string) {
    this.setPreselectedProductionOrderId(productionOrderId)
    this.toggleCancel()
  }

  onNoteWithSelectedProductAndProductionOrder(productionOrderId?: string, productId?: string) {
    this.setPreselectedProductionOrderId(productionOrderId)
    this.setPreselectedProductId(productId)
    this.toggleNotes()
  }

  onRedoWithPreselectedProductionOrder = (productionOrderId?: string) => {
    this.setPreselectedProductionOrderId(productionOrderId)
    this.toggleRedo()
  }

  onCopyStatusPageUrl = (orderDetail: OrderTypes.OrderDetails) => {
    var channel = orderDetail.channel
    var environmentPrefix = ''

    var currentPageUrl = window.location.href

    if (currentPageUrl.includes('t-dtap')) {
      environmentPrefix = 't-dtap.'
    } else if (currentPageUrl.includes('a-tdap')) {
      environmentPrefix = 'a-tdap.'
    }

    var token = btoa(`${orderDetail.customer?.id}:${orderDetail.id}`).replace(/=+$/, '')
    var link = `https://${environmentPrefix}guest.${channel}/order?token=${token}`

    navigator.clipboard.writeText(link).catch((error) => {
      console.error('Failed to copy StatusPageUrl to clipboard:', error)
    })
  }

  isPUDO(deliveryMethod?: string) {
    return deliveryMethod === 'PUDO'
  }

  showExtendedAddress(country?: string) {
    return country === 'NL' || country === 'BE'
  }

  showCounty(country?: string) {
    return country == 'GB'
  }

  showComplement(country?: string) {
    return country == 'FR'
  }
}

function sortByProductionOrderDate():
  | ((
    productionOrderDetailsA: { productionOrder: ProductionOrder; products: Product[] },
    productionOrderDetailsB: { productionOrder: ProductionOrder; products: Product[] }
  ) => number)
  | undefined {
  return (x, y) => {
    const dateProductionOrderA = x.productionOrder.approvedForProductionDate
    const dateProductionOrderB = y.productionOrder.approvedForProductionDate
    if (dateProductionOrderA > dateProductionOrderB) {
      return -1
    }
    if (dateProductionOrderA < dateProductionOrderB) {
      return 1
    }
    return 0
  }
}

function mapStateToProps(state: AppStore) {
  return {
    sbsPanelData: state.sbsPanel,
    redoPanelData: state.redoPanel,
    ...state.orderDetails,
    ...state.orderDetailsView,
    configuration: state.global.configuration,
  } as Props
}

export default connect(mapStateToProps)(OrderDetailsView)
