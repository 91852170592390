import React from 'react'
import { Form, FormGroup } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { NoteBulkRequestBase } from '../../api/note'
import { validators } from '../../utils'
import './BulkNotesForm.scss'
import Textarea from './Textarea'

interface BulkNoteFormProps {
  onSubmit(x: NoteBulkRequestBase): void
}

function BulkNoteFormInternal({
  handleSubmit,
  onSubmit,
  submitting,
}: BulkNoteFormProps & InjectedFormProps<NoteBulkRequestBase, BulkNoteFormProps>) {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field name="identifiers" id="bulkNoteIdentifiers" component={Textarea} rows={5} label="Production Order IDs and/or Product Numbers" validate={[validators.required]} />
      <label className="field-help-text">Insert production order ids and/or product numbers in comma separated fashion: a3f49eee-bb1e-48a4-8376-6413495afb82, AL020049567583...</label>
      <Field name="message" id="bulkNoteMessage" component={Textarea} rows={5} label="Comment" validate={[validators.required]} />
      <FormGroup>
        <button type="submit" id="bulkNoteSubmit" disabled={submitting}>
          Submit
        </button>
      </FormGroup>
    </Form>
  )
}

export const BulkNoteForm = reduxForm<NoteBulkRequestBase, BulkNoteFormProps>({})(BulkNoteFormInternal)
