import React from 'react'
import { ProductWithValue, ProductionOrderDetails } from '../../services/OrderManagementService'
import { RedoDeliveryAddress, RedoPickupPointData } from '../../api/productionOrder'
import { Container } from 'reactstrap'
import { toDate } from '../../utils'
import './RedoSummary.scss'
import ActionLink from '../generic/ActionLink'
import { productionOrdersInProgress } from '../../utils'

interface RedoSummaryProps {
  products: ProductWithValue[]
  productionOrders: ProductionOrderDetails[]
  pickUpPointData?: RedoPickupPointData
  deliveryAddress?: RedoDeliveryAddress
  deliveryMethod: string
  deliveryType: string
  deliveryDate: string
  handleSubmit(): void
  handleCancel(): void
}

export const RedoSummary = ({
  products,
  productionOrders,
  pickUpPointData,
  deliveryAddress,
  deliveryMethod,
  deliveryType,
  deliveryDate,
  handleSubmit,
  handleCancel,
}: RedoSummaryProps) => {

  const showCancelReminder = productionOrdersInProgress(productionOrders)

  return (
    <Container className="pl-0">
      <div className="redo-confirmation-container">
        <h1>
          <b>Redo</b> production order summary
        </h1>
        <div>
          <div className="redo-confirmation-product">
            <h2>Production orders:</h2>
            <ul>
              {productionOrders
                .filter((po) => po.products.some((p) => p.value > 0))
                .map((po) => (
                  <li key={po.productionOrderId}>
                    {po.productionOrderId}
                    <ul>
                      {po.products
                        .filter((p) => p.value > 0)
                        .map((p) => (
                          <li key={p.id}>
                            {p.id} : {p.value === p.quantity ? 'entire product' : `${p.value} / ${p.quantity} products`}
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </div>
          <div className="redo-confirmation-delivery">
            <h2>Delivery info:</h2>
            <div>
              <b>Delivery method:</b> {deliveryMethod} - {deliveryType}
            </div>
            {deliveryMethod === 'PUDO' && (
              <div className="redo-confirmation-delivery-pudo">
                <div>
                  <b>Pickup Point:</b> {pickUpPointData?.pickupPointName}
                </div>
              </div>
            )}
            <div className="redo-confirmation-delivery-address">
              <div>
                <b>Address:</b>{' '}
                {' ' +
                  deliveryAddress?.addressLine1 +
                  ', ' +
                  deliveryAddress?.addressLine2 +
                  ', ' +
                  deliveryAddress?.city +
                  ', ' +
                  deliveryAddress?.countryId +
                  ', ' +
                  deliveryAddress?.zipCode}
              </div>
            </div>
            <div>
              <b>Expected delivery date:</b> {toDate(deliveryDate)}
            </div>
          </div>
          {showCancelReminder && (
              <div><h2 className="attention">Remember to cancel the original production order!</h2></div>
            )}
        </div>
        <br/>
        <div className="submit-button-container">
          <ActionLink
            className="ml-4 redo-confirmation-cancel"
            onClick={handleCancel}
            text={'Cancel'}
            id="confirmation-cancel-link"
          />
          <button className="submit-redo" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </Container>
  )
}
