import React, { FormEvent } from 'react'
import { Col, Form, FormGroup, Row, Label } from 'reactstrap'
import { formatCurrency } from '../../utils'
import Confirmation from './Confirmation'
import OrderStructure from './OrderStructure'
import { OperationType, ProductionOrderDetails, ProductWithValue } from '../../services/OrderManagementService'
import { RefundCalculatorServiceInstance as refundCalculatorService } from '../../services/RefundCalculatorService'
import * as OrderTypes from '../../api/order'
import Checkbox from './Checkbox'

export interface FormData {
  amount?: number
  operationType?: OperationType
}

interface OrderRefundFormState {
  data: FormData
  showConfirmation: boolean
  error: string | null
  products: ProductWithValue[]
  includeShippingCosts: boolean
}

interface Props {
  onSubmit(form: FormData): void
  valid: boolean
  orderDetails: OrderTypes.OrderDetails
}

export default class OrderRefundForm extends React.Component<Props, OrderRefundFormState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: {},
      showConfirmation: false,
      error: '',
      products: [],
      includeShippingCosts: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.handleShippingCosts = this.handleShippingCosts.bind(this)
  }

  componentDidMount() {
    this.setState(state => (state.data.operationType = OperationType.RefundOrder, state))
  }

  render() {
    const { data, showConfirmation, includeShippingCosts, products } = this.state
    const selectedProducts = products.filter((p) => p.value > 0)
    const submitDisabled = !this.props.valid || selectedProducts.length === 0

    return (
      <Form onSubmit={this.handleSubmit}>
        <Confirmation
          isOpen={showConfirmation}
          toggle={this.toggleConfirmation}
          onSubmit={this.onSubmit}
          submitText="Yes"
          cancelText="No">
          Are you sure that you want to refund <b>{formatCurrency(data.amount)}</b>?
        </Confirmation>
        <OrderStructure
          data={this.props.orderDetails}
          handleChange={this.handleProductsChange}
          operation={OperationType.RefundOrder}
        />
        <FormGroup>
          <Checkbox id="includeShippingCosts" checked={includeShippingCosts} onChange={this.handleShippingCosts} />
          <Label for="includeShippingCosts" className="ml-4">
            Include shipping costs
          </Label>
        </FormGroup>
        <FormGroup className="summary">
          {this.renderSummary(data.amount || 0)}
          <button type="submit" disabled={submitDisabled} id="refund-order-submit-button">
            Submit
          </button>
        </FormGroup>
      </Form>
    )
  }

  private handleSubmit(event: FormEvent) {
    event.preventDefault()
    this.toggleConfirmation()
  }

  private toggleConfirmation() {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  private handleProductsChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>) {
    this.setState(
      {
        products: products,
      },
      () => this.calculateRefund()
    )
  }

  private handleShippingCosts(event: any) {
    this.setState(
      {
        includeShippingCosts: event.target.checked,
      },
      () => this.calculateRefund()
    )
  }

  private calculateRefund() {
    let refundAmount = refundCalculatorService.calculateRefund(
      100,
      this.state.includeShippingCosts,
      this.state.products,
      this.props.orderDetails
    )
    this.setState(state => (state.data.amount = refundAmount, state))
  }

  private onSubmit() {
    this.props.onSubmit(this.state.data)
  }

  private renderSummary(amount: number) {
    return (
      <Row className="total">
        <Col sm="4">Total refund</Col>
        <Col id="refund-order-total">{formatCurrency(amount)}</Col>
      </Row>
    )
  }
}
