import React from 'react'
import { Product } from '../../api'
import confirmIcon from '../../assets/img/confirm.svg'
import { TogglePanel, WidgetNotification, WidgetSidePanel } from '../../models'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { selectOnClick } from '../../utils'
import Confirmation from '../forms/Confirmation'
import ProductLabel from '../forms/ProductLabel'
import ActionLink, { ActionLinkSettings } from '../generic/ActionLink'
import ActionList from '../generic/ActionList'
import LazyContainer from '../generic/LazyContainer'
import SidePanel from '../panels/SidePanel'

interface State {
  showDeleteConfirmation: boolean
}

interface Props extends WidgetSidePanel, TogglePanel {
  product: Product
  widgetPreviewUrl?: string
  onCreateClick(): void
  onDeleteClick(): void
  onRecreateClick(): void
}

export default class WidgetPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { showDeleteConfirmation: false }

    this.toggleDeleteConfirmation = this.toggleDeleteConfirmation.bind(this)
  }

  render() {
    return (
      <SidePanel {...this.props}>
        <LazyContainer isLoading={this.props.isLoading}>
          <div className="bo-widget">
            {this.props.notification && this.renderNotification(this.props.notification)}
            {this.props.product.widgetId ? this.renderWidgetInfo() : this.renderCreateWidget()}
          </div>
        </LazyContainer>
      </SidePanel>
    )
  }

  private renderLabel(product: Product) {
    const productType = Service.resolveProductDescriptionByType(product.productId)

    return <ProductLabel productTitle={product.title} productType={productType} hidePreviewImage={true} />
  }

  private renderCreateWidget() {
    return (
      <div className="create">
        <button onClick={this.props.onCreateClick} className="float-right medium">
          Create widget
        </button>
        {this.renderLabel(this.props.product)}
      </div>
    )
  }

  private renderWidgetInfo() {
    const actions: ActionLinkSettings[] = [
      {
        text: 'Create again',
        onClick: this.props.onRecreateClick
      },
      {
        text: 'Delete',
        onClick: this.toggleDeleteConfirmation
      }
    ]

    return (
      <React.Fragment>
        <table className="bo-grid-vertical">
          <tbody>
            <tr>
              <th>Product</th>
              <td>{this.renderLabel(this.props.product)}</td>
            </tr>
            <tr>
              <th>Widget link</th>
              <td>
                {this.props.widgetPreviewUrl ? (
                  <ActionLink href={this.props.widgetPreviewUrl} text="View widget" />
                ) : (
                  <i>Link is unavailable</i>
                )}
              </td>
            </tr>
            <tr>
              <th>Widget id</th>
              <td onClick={selectOnClick}>{this.props.product.widgetId}</td>
            </tr>
          </tbody>
        </table>
        {<ActionList actions={actions} className="slim" />}
        {this.renderDeleteConfirmation()}
      </React.Fragment>
    )
  }

  private toggleDeleteConfirmation() {
    this.setState({ showDeleteConfirmation: !this.state.showDeleteConfirmation })
  }

  private renderDeleteConfirmation() {
    return (
      <Confirmation
        isOpen={this.state.showDeleteConfirmation}
        cancelText="No, Cancel"
        submitText="Yes"
        title="Delete widget"
        toggle={this.toggleDeleteConfirmation}
        onSubmit={this.props.onDeleteClick}>
        Are you sure you want to delete this widget?
      </Confirmation>
    )
  }

  private renderNotification(notification: WidgetNotification) {
    return (
      <div className="notification fade-out">
        <img src={confirmIcon} width="32" height="32" alt="confirmed" />
        <div>
          <h2>{notification.title}</h2>
          {notification.message}
        </div>
      </div>
    )
  }
}
