import * as React from 'react'
import close from '../../assets/img/close-white.svg'
import { TogglePanel } from '../../models'
import QuestionmarkWithTooltip from '../generic/QuestionmarkWithTooltip'
import PanelTooltip from './PanelTooltip'
import './SidePanel.scss'

export interface SidePanelSettings extends React.Props<any>, TogglePanel {}

export default function SidePanel(props: SidePanelSettings): JSX.Element | null {
  const localToggle = () => props.toggle(props.accessKey)

  if (!props.isOpen) {
    return null
  }

  const tooltip = props.tooltipKey && <PanelTooltip tooltipKey={props.tooltipKey} />

  return (
    <div className="bo-side-panel">
      <div className="overlay" onClick={localToggle} />
      <div className="bo-side-panel-container">
        <div className="title">
          {props.title}{' '}
          {tooltip && (
            <QuestionmarkWithTooltip tooltipId={`${props.accessKey}_title`} placement="bottom" color="white" size={24}>
              {tooltip}
            </QuestionmarkWithTooltip>
          )}
          <span className="close">
            <img src={close} height="24" width="24" onClick={localToggle} alt="toggle" />
          </span>
        </div>
        <div className="container-payload">{props.children}</div>
      </div>
    </div>
  )
}
