import _ from 'lodash'
import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkRefinalizeRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkRefinalizeBase } from '../../api/refinalize'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkRefinalizeForm from '../forms/BulkRefinalizeForm'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
}

class BulkRefinalizePanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkRefinalize = this.sendBulkRefinalize.bind(this)
  }

  sendBulkRefinalize(formParams: BulkRefinalizeBase) {
    const documentTokensList = formParams.bulkDocumentTokens
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkRefinalizeRequest({
        reasonCode: Number(formParams.reasonCode),
        reasonPhrase: formParams.reasonPhrase,
        reasonComment: formParams.reasonComment,
        documentTokens: documentTokensList,
      })
    )
  }

  renderForm() {
    return <BulkRefinalizeForm onSubmit={this.sendBulkRefinalize}></BulkRefinalizeForm>
  }

  renderResponse() {
    const groupedResponse = _(this.props.bulkRefinalizeResponse.response)
      .groupBy((x) => Math.floor(x.statusCode / 100) * 100)
      .map((value, key) => ({ statusCode: key, responses: value }))
      .value()

    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="8">
            <h1>Bulk Operation Result</h1>
            {this.props.bulkRefinalizeResponse.response.some((x) => x.statusCode >= 400)
              ? 'There were some errors refinalizing some products, please check report below'
              : 'Refinalization was triggered successfully for all products'}
          </Col>
          <Col md="4" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        {groupedResponse.map((x, index) => (
          <div key={index} className="response-container">
            <Row key={'state_' + index}>
              <Col md="2" className="column-highlight">
                State:{' '}
              </Col>
              <Col md="10" className={x.statusCode < '400' ? 'status-success' : 'status-error'}>
                {x.statusCode < '400' ? 'Success' : 'Error'}
              </Col>
            </Row>
            {x.responses[0].message && (
              <Row key={'error_' + index}>
                <Col md="2" className="column-highlight">
                  Message:{' '}
                </Col>
                <Col md="10">{x.responses[0].message}</Col>
              </Row>
            )}
            <Row key={'products_' + index}>
              <Col md="2" className="column-highlight">
                DocumentTokens:{' '}
              </Col>
              <Col md="10">{x.responses.map((y) => y.documentToken).join(', ')}</Col>
            </Row>
          </div>
        ))}
      </Container>
    )
  }

  render() {
    return this.props.bulkRefinalizeResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkRefinalizePanel
