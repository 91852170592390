import _ from 'lodash'
import React from 'react'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import * as OrderTypes from '../../api/order'
import {
  AlertInfo,
  OperationType,
  OrderManagementServiceInstance,
  ProductionOrderDetails,
  ProductWithValue,
} from '../../services/OrderManagementService'
import Alerts from '../generic/Alerts'
import Confirmation from './Confirmation'
import OrderStructure from './OrderStructure'

interface OrderCancelFormProps {
  onSubmit(x: OrderTypes.InternalOperationRequest): void
  data: OrderTypes.OrderDetails
}

interface OrderCancelFormState {
  appVersion: string
  modal: boolean
  error?: string
  comment: string
  commentError?: string
  products: ProductWithValue[]
  productionOrders: ProductionOrderDetails[]
}

export default class OrderManagementForm extends React.Component<OrderCancelFormProps, OrderCancelFormState> {
  constructor(props: OrderCancelFormProps) {
    super(props)

    this.state = {
      appVersion: process.env.REACT_APP_VERSION || '',
      modal: false,
      error: '',
      comment: '',
      products: [],
      productionOrders: []
    }

    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.submitState = this.submitState.bind(this)
  }

  handleProductsChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>) {
    const error = _(products)
      .filter(x => !!x.maxValue && !!x.tooltip && x.value > x.maxValue)
      .map(x => x.tooltip!)
      .uniq()
      .join(' ')

    // So far no tooltips are available for Production Orders

    this.setState({
      error,
      products,
      productionOrders
    })
  }

  handleCommentChange(event: any) {
    const value = event.target.value

    this.setState({
      commentError: undefined,
      comment: value,
    })
  }

  handleSubmit(event: any) {
    event.preventDefault()

    const invalidProductsDuringSubmit = this.state.products.length === 0 ||
      this.state.products.every(p => p.value === 0)
    const invalidProductionOrdersDuringSubmit =
      this.state.productionOrders.length === 0 ||
      this.state.productionOrders.every(po => po.products.every(p => p.value === 0))

    if (invalidProductsDuringSubmit && invalidProductionOrdersDuringSubmit) {
      this.setState({ error: 'Check entire order or some part' })
      return
    }

    if (!this.state.comment) {
      this.setState({ commentError: 'Please put short reason' })
      return
    }

    this.toggleModal()
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  submitState() {
    const payload: OrderTypes.InternalOperationRequest = {
      appVersion: this.state.appVersion,
      operation: OrderTypes.InternalOperationType.Cancel,
      code: this.props.data.id,
      vendor: this.props.data.vendor,
      channel: this.props.data.channel,
      comment: this.state.comment,
      products: this.state.products,
      productionOrders: this.state.productionOrders,
      invoiceId: this.props.data.invoiceId,
      source: this.props.data.source
    }
    this.props.onSubmit(payload)
  }

  renderConfirmationBody() {
    if (!this.state.products && !this.state.productionOrders) {
      return
    }

    const allFulfillmentProductsSelected = this.state.products.every(p => p.value === p.quantity)
    const allProductionOrderProductsSelected = this.state.productionOrders.every(po =>
      po.products.every(p => p.value === p.quantity)
    )

    if (allFulfillmentProductsSelected && allProductionOrderProductsSelected) {
      return (
        <div>
          <div>
            Do you really want to <b>Cancel</b> entire order?
          </div>
        </div>
      )
    }

    return (
      <div>
        <div>
          Do you really want to <b>Cancel</b> following part?
        </div>
        <div>
          <ul>
            {
              this.state.products
                .filter(p => p.value > 0)
                .map(p => (
                  <li key={p.id}>
                    {p.id} : {p.value === p.quantity ? 'entire product' : `${p.value} / ${p.quantity} order lines`}
                  </li>
                )
                )
            }
            {
              this.state.productionOrders
                .filter(po => po.products.every(p => p.value === p.quantity))
                .map(po => (
                  <li key={po.productionOrderId}>
                    {po.productionOrderId} : {'entire production order'}
                  </li>
                )
                )
            }
          </ul>
        </div>
      </div>
    )
  }

  render() {
    const allProductionOrdersDisabled = this.state.productionOrders.every((p) => !!p.disabled)
    const allProductsDisabled = this.state.products.every((p) => !!p.disabled)

    const allDisabled = allProductionOrdersDisabled && allProductsDisabled

    const warnings = OrderManagementServiceInstance.getWarnings(this.state.products)
    const errors = [this.state.error, this.state.commentError]
      .filter(x => x)
      .map(
        (content) =>
        ({
          color: 'danger',
          content,
        } as AlertInfo)
      )

    return (
      <Form onSubmit={this.handleSubmit}>
        <Alerts items={errors.concat(warnings)} />
        <Confirmation isOpen={this.state.modal} toggle={this.toggleModal} onSubmit={this.submitState} cancelText="Back">
          {this.renderConfirmationBody()}
        </Confirmation>
        <OrderStructure
          data={this.props.data}
          preselectedProductionOrderId={this.props.data.preselectedProductionOrderId}
          handleChange={this.handleProductsChange}
          operation={OperationType.Cancel}
        />
        <FormGroup>
          <Label for="OrderManagementView_ReasonPhrase" className={allDisabled ? 'disabled' : undefined}>
            Cancel reason
          </Label>
          <Input
            type="textarea"
            id="OrderManagementView_ReasonPhrase"
            name="comment"
            value={this.state.comment}
            onChange={this.handleCommentChange}
            disabled={allDisabled}
          />
        </FormGroup>
        <button type="submit" disabled={errors.length !== 0 || allDisabled} style={{ marginBottom: '10px' }}>
          Submit
        </button>
      </Form>
    )
  }
}
