import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import * as global from '../actions/globalActions'
import { BulkPromiseBrokenRequest } from '../api/promiseBroken'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* bulkPromiseBroken(action: PayloadAction<BulkPromiseBrokenRequest>) {
  try {
    yield put(global.showSpinner())
    const response = yield call(ApiServiceInstance.post, 'orders/bulkPromiseBroken', action.payload)

    yield put(
      bulkOperationsActions.bulkPromiseBrokenReceived({
        response: response.data,
        valid: true,
      })
    )
  } catch (e) {
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(bulkOperationsActions.bulkPromiseBrokenRequest, bulkPromiseBroken)
}
