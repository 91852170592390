import React from 'react'
import { Link } from 'react-router-dom'
import * as OrderTypes from '../../api/order'
import routes from '../../route/routes'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { toDateTime } from '../../utils'
import Grid, { GridColumn } from '../generic/Grid'

interface Props {
  data: OrderTypes.OrderDetails
}

export default function OrderOverview({ data }: Props) {
  const columns: GridColumn<OrderTypes.OrderDetails>[] = [
    {
      title: 'Order date',
      value: (order) => toDateTime(order.createdDateTime),
    },
    {
      title: 'Vendor',
      value: (order) => order.channel,
    },
    {
      title: 'Brand',
      value: (order) => order.brand,
    },
    {
      title: 'Locale',
      value: (order) => order.locale,
    },
    {
      title: 'ChannelType',
      value: (order) => order.channelType,
    },
    {
      title: 'Shipments #',
      value: (order) => Service.getShipmentsCount(order) || '?',
    },
    {
      title: 'Products #',
      value: (order) => (order.products && order.products.length) || '?',
    },
    {
      title: 'Orderlines #',
      value: (order) => Service.getOrderlinesCount(order) || '?',
    },
    { title: 'Name', value: (order) => order.customer && order.customer.name },
    {
      title: "Email",
      value: (order) => {
        const email = order.orderDeliveryAddress?.customerEmail ?? order.customer?.email;
        return (
          order.customer && (
            <Link to={routes.CUSTOMERS_VIEW({ email })}>
              {email}
            </Link>
          )
        );
      },
    },
    {
      title: "Order Account Id",
      value: (order) => order.customerId
    },
    {
      title: "Account Type",
      value: (order) => order.customer?.accountType
    },
  ]

  return <Grid columns={columns} data={[data]} disableCopyOnClick={true} />
}
