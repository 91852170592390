import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as orderActions from '../actions/orderDetailsActions'
import * as actions from '../actions/productViewActions'
import { FileInfoResponse, OrderDetails, RestoreFromColdStorageRequest } from '../api'
import { ProductViewStore } from './states'

const initialState: ProductViewStore = { restoringFiles: [] }

export const productView = createReducer<ProductViewStore>(initialState, {
  [actions.receivedProductView.type]: (state, action) => {
    state.data = action.payload
  },
  [actions.receivedProductLogs.type]: (state, action) => {
    state.logs = action.payload
  },
  [actions.requestRestoreFromColdStorage.type]: (state, action: PayloadAction<RestoreFromColdStorageRequest>) => {
    state.restoringFiles.push(action.payload.storageId)
  },
  [actions.receivedRestoringFile.type]: (state, action: PayloadAction<FileInfoResponse>) => {
    if (state.data && state.data.pdfStorageId === action.payload.storageId) {
      state.data.pdfIsInColdStorage = action.payload.coldStorage
    }

    if (state.data && state.data.albxStorageId === action.payload.storageId) {
      state.data.albxIsInColdStorage = action.payload.coldStorage
    }

    if (state.data && state.data.imageArchiveStorageId === action.payload.storageId) {
      state.data.imageArchiveIsInColdStorage = action.payload.coldStorage
    }

    if (action.payload.coldStorage === false) {
      state.restoringFiles = state.restoringFiles.filter((x) => x !== action.payload.storageId)
    }
  },
  [orderActions.receivedOrderDetails.type]: (state, action: PayloadAction<OrderDetails>) => {
    if (state.orderCode !== action.payload.id) {
      state.orderCode = action.payload.id
      state.restoringFiles = []
    }
  },
})
