import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import { Discount } from '../api'
import { ApiServiceInstance } from '../services/ApiService'

export interface GenerateCodeResponse {
  code: string
}

export const fetchDiscountInfo = createAsyncThunk('discounts/search', async (searchQuery: string) => {
  const response = await ApiServiceInstance.get(`discounts/${encodeURIComponent(searchQuery)}`)
  return response
})

export interface DeleteDiscountRequest {
  discountId:string,
  discountCode: string
}

export const deleteDiscountCode = createAsyncThunk('discounts/delete', async (options:DeleteDiscountRequest) => {
  var { discountId, discountCode } = options;
  await ApiServiceInstance.del(`discounts/${discountId}/${discountCode}`)
  return await ApiServiceInstance.get(`discounts/${encodeURIComponent(discountCode)}`)
})

export const generateNewCode = createAsyncThunk('discounts/generateNewCode', async (discountId: string) => {
  const response = await ApiServiceInstance.post(`discounts/${discountId}/generateCode`)
  if (!response) throw new Error('Failed to generate new code')
  return response.data.code
})

export interface DiscountsState {
  isLoading: boolean
  query?: string
  discount?: Discount
  discountLookupError?: string

  isGeneratingCode: boolean
  newGeneratedCode: string

  codeBeingDeleted: boolean
  codeBeingDeletedError: boolean
}

const initialState: DiscountsState = {
  isLoading: false,
  query: '',
  isGeneratingCode: false,
  newGeneratedCode: '',
  codeBeingDeleted: false,
  codeBeingDeletedError: false
}

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    resetCodeGeneration(state, action) {
      state.newGeneratedCode = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscountInfo.pending, (state, action) => {
      state.isLoading = true
      state.query = action.meta.arg
    })
    builder.addCase(fetchDiscountInfo.fulfilled, (state, action) => {
      state.isLoading = false
      state.discount = action.payload
      state.discountLookupError = undefined
    })
    builder.addCase(fetchDiscountInfo.rejected, (state, action) => {
      state.isLoading = false
      state.discountLookupError = action.error?.message
    })

    builder.addCase(generateNewCode.pending, (state, action) => {
      state.isGeneratingCode = true
    })
    builder.addCase(generateNewCode.fulfilled, (state, action) => {
      state.isGeneratingCode = false
      state.newGeneratedCode = action.payload
    })
    builder.addCase(generateNewCode.rejected, (state, action) => {
      state.isGeneratingCode = false
    })

    builder.addCase(deleteDiscountCode.pending, (state, action) => {
      state.codeBeingDeleted = true
    })
    builder.addCase(deleteDiscountCode.fulfilled, (state, action) => {
      state.codeBeingDeleted = false
      state.discount = action.payload
    })
    builder.addCase(deleteDiscountCode.rejected, (state, action) => {
      state.codeBeingDeleted = false
      state.codeBeingDeletedError = true
    })
  },
})
