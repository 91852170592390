import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as commentActions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import * as actions from '../actions/refundsActions'
import { CommentType, RefundAmountRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function* submitRefund(action: PayloadAction<RefundAmountRequest>) {
  try {
    yield put(global.showSpinner())
    yield call(ApiServiceInstance.post, 'orders/refund-amount', action.payload)
    yield put(
      global.showInfo(
        `Refund was sent successfully (${action.payload.orderCode}). It can take a few minutes until the payment history is updated.`
      )
    )
    yield put(commentActions.requestBulk({ parentId: action.payload.orderCode, type: CommentType.OrderNote }))
  } catch (e) {
    yield put(Errors.toErrorAction(e, `Immediately contact #care-tech-support slack channel`))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield all([yield takeLatest(actions.submitAmountRefund, submitRefund)])
}
