export interface ProjectSearchResponse {
  items?: Project[]
  hasMore: boolean
  offset: number
  limit: number
}

export interface Project {
  id: string
  accountId: string
  title: string
  userAgent: string
  creationDate: string
  modificationDate: string
}

export enum SearchProjectBy {
  User = 1
}
