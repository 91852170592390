import React from 'react'
import { toShortDateMonth } from '../../utils'
import './DeliveryOptionsTabs.scss'

interface DeliveryOptionTabProps {
  tabName: string
  tabTitle: string
  isSelected: boolean
  isLast: boolean
  estimatedDeliveryDate: string | undefined
  onTabSelected(tabName: string): void
}

export const DeliveryOptionsTab = ({
  tabName,
  tabTitle,
  isSelected,
  isLast,
  estimatedDeliveryDate,
  onTabSelected,
}: DeliveryOptionTabProps) => {
  const disabled = estimatedDeliveryDate === undefined
  let classValue = 'tab-bar-item tab-button'
  classValue = isLast ? classValue + ' tab-button-last' : classValue
  classValue = isSelected ? classValue + ' selected' : classValue

  return (
    <button className={classValue} onClick={() => onTabSelected(tabName)} disabled={disabled}>
      <span className="tab-button-title">{tabTitle}</span>
      <span className="tab-button-date">{disabled ? 'Not available' : toShortDateMonth(estimatedDeliveryDate)}</span>
    </button>
  )
}
