import React from 'react'

interface Props extends React.HTMLProps<any> {
  icon: string
  size?: number
  title: string
}

export default function Flag(props: Props) {
  return (
    <img
      src={props.icon}
      width={props.size || '32'}
      height={props.size || '32'}
      alt={props.title}
      title={props.title}
      className={`mr-1 ${(props.className || '').trim()}`}
    />
  )
}
