import { createAction } from '@reduxjs/toolkit'
import { RedoProductionOrdersRequest } from '../api/productionOrder'
import { GetDeliveryOptionsResponse, GetDeliveryOptionsRequest, GetPickupPointsRequest, GetPickupPointsResponse } from '../api'

export const requestDeliveryOptions = createAction<GetDeliveryOptionsRequest>('REQUEST_DELIVERY_OPTIONS')

export const receivedDeliveryOptions = createAction<GetDeliveryOptionsResponse>('RECEIVED_DELIVERY_OPTIONS')

export const requestPickupPoints = createAction<GetPickupPointsRequest>('REQUEST_PICKUP_POINTS')

export const receivedPickupPoints = createAction<GetPickupPointsResponse>('RECEIVED_PICKUP_POINTS')

export const submitProductionOrderRedo = createAction<RedoProductionOrdersRequest>('SUBMIT_PRODUCTION_ORDERS_REDO')

export const redoFailure = createAction<string>('REDO_FAILURE')
