import React, { FormEvent } from 'react'
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { BulkPromiseBrokenBase } from '../../api/promiseBroken'
import { validators } from '../../utils'
import './BulkPromiseBrokenForm.scss'

interface BulkPromiseBrokenFormProps {
  onSubmit(x: BulkPromiseBrokenBase): void
}

interface State {
  data: BulkPromiseBrokenBase
  disabled: boolean
  hasBulkProductCodesError: boolean
}

const initialState: State = {
  data: {
    bulkProductCodes: '',
  },
  disabled: false,
  hasBulkProductCodesError: false,
} as State

export default class BulkPromiseBrokenForm extends React.Component<BulkPromiseBrokenFormProps, State> {
  constructor(props: BulkPromiseBrokenFormProps) {
    super(props)
    this.state = initialState
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGenericChange = this.handleGenericChange.bind(this)
  }

  handleSubmit(event: FormEvent) {
    const hasBulkProductCodesError = this.isEmpty(this.state.data.bulkProductCodes)

    this.setState({
      ...this.state,
      hasBulkProductCodesError: hasBulkProductCodesError,
    } as State)

    if (hasBulkProductCodesError) {
      event.preventDefault()
      return
    } else {
      this.setState({ disabled: true })
      this.props.onSubmit(this.state.data)
      this.setState({ disabled: false })
    }
  }

  handleGenericChange(event: any) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    } as State)
  }

  isEmpty(data: any) {
    return data === null || data === undefined || data === ''
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label className="col-form-label">Product Codes</Label>
          <Input
            name="bulkProductCodes"
            id="bulkProductCodes"
            rows={5}
            type="textarea"
            label="Product Codes"
            invalid={this.state.hasBulkProductCodesError}
            onChange={this.handleGenericChange}
          />
          {this.state.hasBulkProductCodesError && (
            <FormFeedback>{validators.required(this.state.data.bulkProductCodes)}</FormFeedback>
          )}
          <Label className="field-help-text">
            Insert product codes in comma separated fashion: AL020009895869, AL020009892344, etc
          </Label>
        </FormGroup>
        <FormGroup>
          <button type="submit" id="bulkPromiseBrokenSubmit" disabled={this.state.disabled}>
            Submit
          </button>
        </FormGroup>
      </Form>
    )
  }
}
