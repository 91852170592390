import React, { HTMLProps } from 'react'
import { Spinner } from 'reactstrap'

export interface LazyContainerProps extends HTMLProps<any> {
  isLoading: boolean
  message?: string
  loaderSize?: string
}

export default function LazyContainer({ className, loaderSize, isLoading, children, message }: LazyContainerProps) {
  const newSize = loaderSize || '8em'
  if (isLoading) {
    return (
      <div className="lazy-container">
        <div className={className}>
          <Spinner color="secondary" style={{ width: newSize, height: newSize }} />
        </div>
        {message && <i className="message">{message}</i>}
      </div>
    )
  }

  return <div className={className}>{children}</div>
}
