import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { receivedOrderDetails } from '../actions/orderDetailsActions'
import * as actions from '../actions/sbsActions'
import { OrderDetails } from '../api'
import { OrderDetailsServiceInstance as Service } from '../services/OrderDetailsService'
import { SbsPanelViewStore } from './states'

const initialState: SbsPanelViewStore = {
  isLoading: false,
  editMode: false,
  showUpdateNotification: false,
  error: undefined,
}

export const sbsPanel = createReducer<SbsPanelViewStore>(initialState, {
  [receivedOrderDetails.type]: (state, action: PayloadAction<OrderDetails>) => {
    state.collection = Service.extractSbsCollection(action.payload)
    state.isLoading = false
  },
  [actions.changeSbsFilter.type]: (state, action: PayloadAction<actions.SbsFilterPayload | undefined>) => {
    state.productFilter = action.payload && action.payload.productCode ? action.payload.productCode : undefined
    state.productionOrderFilter = action.payload && action.payload.productionOrderId ? action.payload.productionOrderId : undefined
    state.error = action.payload && action.payload.error ? action.payload.error : undefined
    state.editMode = false
    state.showUpdateNotification = false
  },
  [actions.receivedSbs.type]: (state, action) => {
    state.error = action.payload.error
  },
  [actions.removeSbs.type]: (state) => {
    state.isLoading = true
  },
  [actions.submitSbs.type]: (state) => {
    state.isLoading = true
  },
  [actions.submitSbsForProductionOrder.type]: (state) => {
    state.isLoading = true
  },
  [actions.submittedSbs.type]: (state) => {
    state.editMode = false
    state.showUpdateNotification = true
  },
  [actions.submittedSbsForProductionOrder.type]: (state) => {
    state.editMode = false
    state.showUpdateNotification = true
  },
  [actions.toggleEditMode.type]: (state, action: PayloadAction<boolean | undefined>) => {
    state.editMode = action.payload === undefined ? !state.editMode : action.payload
    state.showUpdateNotification = false
  },
})
