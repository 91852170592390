import { PayloadAction } from '@reduxjs/toolkit'
import queryString from 'query-string'
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as global from '../actions/globalActions'
import * as search from '../actions/searchActions'
import { GlobalConfiguration, SearchTypes } from '../api'
import * as log from '../api/log'
import { AppStore } from '../reducers/states'
import { ApiServiceInstance } from '../services/ApiService'
import { AuthInstance } from '../services/AuthService'
import { Errors } from '../services/ErrorMessageService'
import { LocalStorageInstance } from '../services/LocalStorageService'
import { LogApiInstance } from '../services/LogApiService'

function checkStartupParams(location: any) {
  const searchParams = location.search
  const appParams = queryString.parse(searchParams)

  if (appParams.logout) {
    AuthInstance.logOut()
    return true
  }

  return false
}

function* appInit() {
  try {
    yield put(global.showSpinner())
    const location = yield select((store: AppStore) => store.router.location)
    const processingBlocked = yield call(checkStartupParams, location)

    if (processingBlocked === true) {
      return
    }

    if (!LocalStorageInstance.getGlobalConfiguration()) {
      const config: GlobalConfiguration = yield call(ApiServiceInstance.get, `configuration/global`)
      LocalStorageInstance.setGlobalConfiguration(config)
      yield put(global.receivedConfiguration(config))
      if (config.permissions.isViewer === false) {
        yield put(Errors.noViewerRights())
      }
      if (config.permissions.isAdmin === true) {
        yield put(search.reset({ type: SearchTypes.JustSearch, query: '' }))
      }
    }

    const user = AuthInstance.getUser()
    const pageViewLog = new log.LogMessage(
      {
        version: process.env.REACT_APP_VERSION,
        location: window.location.href,
        userName: user.profile.name,
        email: user.userName,
        browser: window.navigator.userAgent,
      },
      log.codes.pageView
    )

    yield put(global.logInfo(pageViewLog))
  } catch (e) {
    const message = window.origin.includes('localhost')
      ? 'Do not forget to start Visual Studio with Backoffice backend'
      : 'Oh no! Customer Care Back Office is temporarily unavailable. ' +
        'Please check the #care-tech-support Slack channel for more information. ' +
        'If no information is available please report this problem.'
    yield put(Errors.toErrorAction(e, message))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* logInfo(action: PayloadAction<log.LogMessage>) {
  yield call([LogApiInstance, LogApiInstance.info], action.payload)
}

function* logWarn(action: PayloadAction<log.LogMessage>) {
  action.payload.payload.location = window.location.href
  yield call([LogApiInstance, LogApiInstance.warn], action.payload)
}

function* logError(action: PayloadAction<log.LogMessage>) {
  action.payload.payload.location = window.location.href
  yield call([LogApiInstance, LogApiInstance.error], action.payload)
}

function* showSpinnerForMoment() {
  yield put(global.showSpinner())
  yield delay(200)
  yield put(global.hideSpinner())
}

export default function* root() {
  yield takeLatest(global.appInit, appInit)
  yield takeEvery(global.logError, logError)
  yield takeEvery(global.logInfo, logInfo)
  yield takeEvery(global.logWarn, logWarn)
  yield takeLatest(global.showSpinnerForMoment, showSpinnerForMoment)
}
