import { createAction } from '@reduxjs/toolkit'
import { Account, AccountDeletionRequest, AccountSearchResponse } from '../api'
import { AccountsSearchFilter, AccountsWhitelist, AccountsPasswordResetLinkRequest } from '../reducers/states'

export const search = createAction<AccountsSearchFilter>('ACCOUNTS_SEARCH_REQUEST')
export const receivedAccounts = createAction<AccountSearchResponse[]>('ACCOUNTS_SEARCH_RECEIVED')
export const searchErrorAccounts = createAction<string>('ACCOUNTS_SEARCH_ERROR')
export const requestDeleteAccount = createAction<AccountDeletionRequest>('DELETE_ACCOUNT_REQUEST')
export const requestWhitelistAccount = createAction<AccountsWhitelist>('WHITELIST_ACCOUNT_REQUEST')
export const requestGetPasswordResetLink = createAction<AccountsPasswordResetLinkRequest>('ACCOUNT_PASSWORD_RESET_LINKREQUEST')
