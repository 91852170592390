import React from 'react'
import { FaBan, FaCheckCircle } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { GlobalConfiguration } from '../api/configuration'
import QuestionmarkWithTooltip from '../components/generic/QuestionmarkWithTooltip'
import { AppStore } from '../reducers/states'

const PermissionsView = (props: GlobalConfiguration) => (
  <Container>
    <h1>Permissions</h1>
    {props.permissions ? (
      <div className="d-flex">
        <table>
          <thead>
            <tr>
              <th>You have access to:</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                View order details
                <QuestionmarkWithTooltip tooltipId="view-order-details" placement="auto">
                  <ul>
                    <li>production status</li>
                    <li>product(s) info</li>
                    <li>shipping address</li>
                    <li>notes</li>
                    <li>show-by-scan</li>
                    <li>etc</li>
                  </ul>
                </QuestionmarkWithTooltip>
              </td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>Put new notes</td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>View thumbnails</td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>Download PDF</td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>Download Image Archive</td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>Download ALBX</td>
              <td>{props.permissions.isExpert ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Download invoice</td>
              <td>{Yes()}</td>
            </tr>
            <tr>
              <td>Cancel or Reset order</td>
              <td>{props.permissions.isAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Create/Update SBS message</td>
              <td>{props.permissions.isAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Create/Update mix-ups</td>
              <td>{props.permissions.isAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Request reupload</td>
              <td>{props.permissions.isAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Widget management</td>
              <td>{props.permissions.isAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Refunds</td>
              <td>{props.permissions.isRefundAgent ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Search by product content ID</td>
              <td>{props.permissions.isExpert ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Restart finalizer</td>
              <td>{props.permissions.isExpert ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>Bulk Operations</td>
              <td>{props.permissions.isSupportEngineer ? Yes() : No()}</td>
            </tr>
            <tr>
              <td>WhiteList account</td>
              <td>{props.permissions.isExpert ? Yes() : No()}</td>
            </tr> 
            <tr>
              <td>Delete account</td>
              <td>{props.permissions.isExpert ? Yes() : No()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      <h2>
        <i>Information is unavailable. Refresh the page.</i>
      </h2>
    )}
  </Container>
)

const Yes = () => <FaCheckCircle className="success-color" size="24" />
const No = () => <FaBan className="danger-color" size="24" />

export default connect((state: AppStore) => state.global.configuration)(PermissionsView)
