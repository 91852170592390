import { Dictionary } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions/customerDetailsActions'
import * as orderActions from '../actions/orderDetailsActions'
import { Customer, GlobalConfiguration, OrderListItemModel, PhotoOrganizerInactivityWarning, PhotoOrganizerStats } from '../api'
import ActionLink from '../components/generic/ActionLink'
import Grid, { GridColumn } from '../components/generic/Grid'
import OrderList from '../components/lists/OrderList'
import { CommonProps } from '../models'
import { AppStore, CustomerDetailsStore, CustomerDetailsViewStore } from '../reducers/states'
import { selectOnClick, toDateTimeDetailed, toDateTimeNew } from '../utils'
import MigrationInfo from '../components/lists/MigrationInfo'

interface Props extends CommonProps, CustomerDetailsStore, CustomerDetailsViewStore {
  configuration: GlobalConfiguration
  orders: Dictionary<OrderListItemModel>
}

class CustomerDetailsView extends React.Component<Props> {
  private foldedCustomers: Array<boolean>;

  constructor(props: Props) {
    super(props)

    this.loadMore = this.loadMore.bind(this)
    this.toggleAllDetails = this.toggleAllDetails.bind(this)
    this.toggleOrderDetails = this.toggleOrderDetails.bind(this)
    this.toggleCustomerMigrationDetails = this.toggleCustomerMigrationDetails.bind(this)
    this.requestOrderDetails = this.requestOrderDetails.bind(this)

    this.foldedCustomers = new Array<boolean>(true);
  }

  componentDidMount() {
    if (!this.props.data || this.props.data.email.toLowerCase() !== this.props.match.params.email.toLowerCase()) {
      this.props.dispatch(actions.requestDetails(this.props.match.params.email))
    }
  }

  toggleAllDetails() {
    this.props.dispatch(actions.toggleAllDetails())
  }

  toggleOrderDetails(index: number) {
    this.props.dispatch(actions.toggleOrderDetails(index))
  }

  toggleCustomerMigrationDetails() {
    this.foldedCustomers[0] = !this.foldedCustomers[0];
    this.forceUpdate();
  }

  requestOrderDetails(id: string) {
    this.props.dispatch(orderActions.requestOrderHistoryDetails(id))
  }

  loadMore() {
    if (this.props.prevAlbelliResponse.hasMore) {
      this.props.dispatch(actions.requestAlbelliOrders())
    }
  }

  renderLinks() {
    return (
      <React.Fragment>
        <ActionLink
          text="< back"
          className="mr-4"
          onClick={() => {
            this.props.history.goBack()
          }}
        />
      </React.Fragment>
    )
  }

  renderDetails(data: Customer) {
    const columns: GridColumn<Customer>[] = [
      { title: 'Salutation', value: (item) => item.salutation },
      { title: 'Name', value: (item) => item.name },
      { title: 'Created at', value: (item) => toDateTimeNew(item.createdAt) },
      { title: '', value: (_) => 
        <React.Fragment>
          {data.migrationDetails &&
          (<ActionLink
            text={this.foldedCustomers[0] == true ? 'Show migration info' : 'Hide migration info'}
            onClick={this.toggleCustomerMigrationDetails}/>
          )}
        </React.Fragment>}
    ]
  
    return (
      <React.Fragment>
        <div className="bo-container mt-3">
          <h2 className="m-0" onClick={selectOnClick}>
            {data.email}
          </h2>
        </div>
        <Grid columns={columns}
              data={[data]}
              className="mt-3 pl-3"
              disableCopyOnClick={true}
              extraDetails={data.migrationDetails ? this.renderExtraDetails : false}
              showFolderToggleIcon={false}
              foldedRows={this.foldedCustomers}/>
      </React.Fragment>
    )
  }

  renderExtraDetails(customer: Customer, _: GridColumn<Customer>[]) {
     return (
      <React.Fragment>
        {customer.migrationDetails &&
        (
          <MigrationInfo details={customer.migrationDetails}></MigrationInfo>
        )}
      </React.Fragment>
      )
  }

  renderOrders() {
    const hasMore = this.props.prevAlbelliResponse.hasMore
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between mt-3">
          <h3>Orders</h3>
          <ActionLink
            text={this.props.historyFolded ? 'Show all product details' : 'Close all product details'}
            onClick={this.toggleAllDetails}
          />
        </div>
        <OrderList
          albelliOrders={this.props.prevAlbelliResponse.items}
          enableExtraDetails={true}
          foldAll={this.props.historyFolded}
          foldedRows={this.props.foldedOrders}
          onToggleRow={this.toggleOrderDetails}
          orders={this.props.orders}
          onOrderVisible={this.requestOrderDetails}
          showCustomerName={false}
          showPrice={true}
        />
        {hasMore && (
          <button className="mt-4 wide-button" onClick={this.loadMore}>
            Load more orders
          </button>
        )}
      </React.Fragment>
    )
  }

  //Users with inactive photoorganiser accounts gets deleted, and CC needs to
  //have some information on warning emails sent, deletion date etc.
  renderPhotoorganiserDeletionData(data: PhotoOrganizerInactivityWarning[]) {
    const notificationTypes: Map<string, string> = new Map([
      ['PhotoOrganizerFirstInactivityWarning', 'First reminder'],
      ['PhotoOrganizerSecondInactivityWarning', 'Second reminder'],
      ['PhotoOrganizerFinalInactivityWarning', 'Final deletion warning'],
      ['PhotoOrganizerOrderPerformed', 'Order placed, deletion halted'],
      ['PhotoOrganizerAccountDeleted', 'Account deleted'],
      ['PhotoOrganizerSmsSent', 'Sent SMS'],
      ['PhotoOrganizerUserHasNoContact', 'No Contact'],
      ['PhotoOrganizerEmailBounced', 'Email bounced'],
      ['PhotoOrganizerEmailInvalid', 'Email invalid'],
      ['PhotoOrganizerSmsFailure', 'SMS failed'],
    ])

    const getStateMessage = (item: any) => {
      // If the account has no photos, we instantly delete the account without bothering with emails or texts
      // Thus we should show a different copy to indicate this is intended behaviour and not just the copy 'Account was deleted'
      if (item.state == 'Deleted' && !item.nrPicturesStored && !!data && data.length == 1) {
        return 'This account was deleted instantly as it did not have any photos. For this reason there are no emails or texts sent to the customer.'
      }

      return item.stateMessage
    }

    const columns: GridColumn[] = [
      { title: 'Channel', value: (item) => item.channel },
      { title: 'Type', value: (item) => notificationTypes.get(item.emailType) },
      { title: 'Sent to', value: (item) => item.email },
      { title: 'Sent at', value: (item) => toDateTimeDetailed(item.emailSentAtUtc) },
      { title: 'Order before date', value: (item) => toDateTimeDetailed(item.orderBeforeDateTime) },
      { title: 'Album count', value: (item) => item.amountAlbums },
      { title: 'Photo count', value: (item) => item.nrPicturesStored },
      { title: '', value: (item) => getStateMessage(item), tdClass: "wrap-normal" },
    ]

    return (
      <div className="bo-container mt-3">
        <h2>Photo Organiser Delete Account Warnings</h2>
        <Grid columns={columns} data={data} className="mt-3" disableCopyOnClick={true} />
      </div>
    )
  }

  renderPhotoorganiserStats(data: PhotoOrganizerStats) {
    if (data.errorMessage == null || data.errorMessage.length === 0) {
      const columns: GridColumn[] = [
        { title: 'Photos', value: (item) => item.photoCount },
        { title: 'Events', value: (item) => item.eventCount },
        { title: 'Collections', value: (item) => item.ownCollections },
        { title: 'Favourites', value: (item) => item.favouriteCount },
        { title: 'Collections shared by customer', value: (item) => item.collectionsSharedByMe },
        { title: 'Collections shared with customer', value: (item) => item.collectionsSharedWithMe },
      ]

      return (
        <div className="bo-container mt-3">
          <h2>Photo Organizer Stats</h2>
          <Grid columns={columns} data={[data]} className="mt-3" disableCopyOnClick={true} />
        </div>
      )
    } else {
      return (
        <div className="bo-container mt-3">
          <h2>Photo Organizer Stats</h2>
          <h3 style={{ color: 'red' }}>{data.errorMessage}</h3>
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderLinks()}
        {this.props.data && this.renderDetails(this.props.data)}
        {this.props.data?.poInactivityWarnings && this.renderPhotoorganiserDeletionData(this.props.data.poInactivityWarnings)}
        {this.props.data?.poStats && this.renderPhotoorganiserStats(this.props.data.poStats)}
        {this.renderOrders()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.customerDetails,
    ...state.customerDetailsView,
    orders: state.orderDetails.orders,
    configuration: state.global.configuration,
  } as Props
}

export default connect(mapStateToProps)(CustomerDetailsView)
