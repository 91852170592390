import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import { OrderSearchResult } from '../../api'
import routes from '../../route/routes'
import { toDateTimeNew } from '../../utils'

const columnsCount = 4

interface Props {
  orders?: OrderSearchResult[]
}

export default class OrderSearchResults extends React.Component<Props> {
  renderRowWithMessage(message: string) {
    return (
      <tr>
        <td colSpan={columnsCount}>
          <i>{message}</i>
        </td>
      </tr>
    )
  }

  renderOrderLink(item: OrderSearchResult) {
    if (item.isOldLegacyOrder) {
      return <span>{item.id}</span>
    }

    return <Link to={routes.ORDERS_VIEW({ orderId: item.id, selected: item.matchedProductCode })}>{item.id}</Link>
  }

  render() {
    const items = this.props.orders

    if (!items || items.length === 0) {
      return null
    }

    return (
      <div className={`bo-grid bo-container order-list`}>
        <Table responsive>
          <thead>
            <tr>
              <th>Order number</th>
              <th>Order date</th>
              <th>Vendor</th>
              <th>Match</th>
              <th style={{ width: '100%' }} />
              {/* TODO: <th>Notifications</th> */}
              {/* TODO: <th>Status</th> */}
            </tr>
          </thead>
          <tbody>
            {items.map((o, i) => (
              <tr key={o.id} id={`order-search-result-${o.id}-${i}`}>
                <td>{this.renderOrderLink(o)}</td>
                <td>{toDateTimeNew(o.createdDateTime)}</td>
                <td>{o.channel}</td>
                <td>{o.matchReason}</td>
                <td style={{ width: '100%' }} />
                {/* TODO: <td>{this.renderNotificationsFlags()}</td> */}
                {/* TODO: <td><Badge setting={badgeSettings} className="fixed-size" /></td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
