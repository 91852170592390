import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Discount } from '../../api'
import { AppStore } from '../../reducers/states'
import { deleteDiscountCode, discountsSlice } from '../../slices/discounts'
import routes from './../../route/routes'
import { GenerateCodePanel } from './GenerateCodePanel'
import ModalPopup from './../forms/ModalPopup';
import Confirmation from '../forms/Confirmation'
import Spinner from 'reactstrap/lib/Spinner'

interface DiscountUsageProps {
  discount: Discount
  discountCode?: string
  allowCodeRegeneration?: boolean
}

interface DiscountCodeRemoveProps {
  discount: Discount
  discountCode?: string
}

export const DiscountCodeRemove = ({discount, discountCode}: DiscountCodeRemoveProps) =>{
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false)
  const dispatch = useDispatch()
  const configuration = useSelector((state: AppStore) => state.global.configuration)
  const discounts = useSelector((state: AppStore) => state.discounts)
  useEffect(()=> {
    if (discounts.codeBeingDeleted)
      setConfirmingDelete(false);
  }, [discounts])

  if (!discount || !discount.id) return null

  const oldDiscountUrl = configuration?.platformBackofficeUrl
  ? `${configuration.platformBackofficeUrl.replace(/\/$/g, '')}/Payment/DiscountCodeView.aspx?code=${discountCode}`
  : ''

  const confirmDelete = () => {
    dispatch(deleteDiscountCode({
      discountCode: discountCode!,
      discountId: discount.id!
    }))
  }
  const confirmRemoveCode = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setConfirmingDelete(true);
  }
  const remove = discount.isOldSystemDiscount ? (<a href={oldDiscountUrl} target="_blank">Remove from Discount</a>) : (<a href="" onClick={confirmRemoveCode}>Remove from Discount</a>);
  const error = (<span className="danger-color">Error occurred</span>);
  return <>
    {discounts.codeBeingDeleted ? (<Spinner/>) : discounts.codeBeingDeletedError ? error : remove}
    <Confirmation
      isOpen={confirmingDelete}
      toggle={()=> setConfirmingDelete(!confirmingDelete)}
      onSubmit={confirmDelete}
      submitText="Yes"
      cancelText="No">
      Are you sure that you want to remove code <b>{discountCode}</b> from the discount <b>{discount.description}</b>?
    </Confirmation>
  </>;
}

export const DiscountUsage = ({ discount, discountCode, allowCodeRegeneration }: DiscountUsageProps) => {
  const [isSidePanelOpen, setSidePanelOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const configuration = useSelector((state: AppStore) => state.global.configuration)
  const showNewCodeLink = allowCodeRegeneration && configuration.permissions.isAgent

  if (!discount || !discount.id) return null

  const removed = discount.removed && (<span className="danger-color">Removed from Discount</span>) || "";
  if (!discount.singleUseOrderCode) {
    return <><span className="warning-color">Not used</span>{" | "}
      {removed || (<DiscountCodeRemove discount={discount} discountCode={discountCode}/>)}
    </>
  }

  const oldDiscountUrl = configuration?.platformBackofficeUrl ? `${configuration.platformBackofficeUrl.replace(/\/$/g, '')}/Payment/DiscountCodeView.aspx?code=${discountCode}` : ''
  const openCodeGenerationPanel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    dispatch(discountsSlice.actions.resetCodeGeneration(null))
    setSidePanelOpen(true)
  }

  return (
    <>
      <GenerateCodePanel discountId={discount.id || ''} isOpen={isSidePanelOpen} setIsOpen={setSidePanelOpen} />
      <a href={`${routes.ORDERS_VIEW({ orderId: discount.singleUseOrderCode })}`}>{discount.singleUseOrderCode}</a>
      {removed && (<>{" | "} {removed}</>)}
      {showNewCodeLink && (
        <>
          <span> | </span>
          {discount.isOldSystemDiscount ? (
            <a href={oldDiscountUrl} target="_blank">
              Generate replacement code
            </a>
          ) : (
            <a href="" onClick={openCodeGenerationPanel}>
              Generate replacement code
            </a>
          )}
        </>
      )}
    </>
  )
}
