import { push } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap'
import { reset } from '../../actions/searchActions'
import { PermissionsView, SearchTypes } from '../../api'
import logo from '../../assets/img/logo.svg'
import { AppStore } from '../../reducers/states'
import routes from '../../route/routes'
import { AuthInstance } from '../../services/AuthService'
import ActionLink from '../generic/ActionLink'

interface Props {
  location?: any
  dispatch?: any
  permissions?: PermissionsView
}

class NavMenu extends React.Component<Props, { isOpen: boolean }> {
  constructor(props: Props) {
    super(props)

    this.state = { isOpen: false }

    this.toggle = this.toggle.bind(this)
    this.goToDefault = this.goToDefault.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  title() {
    if (window.origin.includes('localhost:')) {
      return 'Customer care backoffice (LOCAL)'
    }

    if (window.origin.includes('t-dtap')) {
      return 'Customer care backoffice (TEST)'
    }

    if (window.origin.includes('a-dtap')) {
      return 'Customer care backoffice (ACC)'
    }

    return 'Customer care backoffice'
  }

  goToDefault() {
    let defaultSearch = SearchTypes.ByOrderCode
    if (this.props.permissions && this.props.permissions.isAdmin) {
      defaultSearch = SearchTypes.JustSearch
    }
    this.props.dispatch(reset({ type: defaultSearch, query: '' }))
    this.props.dispatch(push(routes.HOME()))
  }

  render() {
    const isActive = (route: string) => this.props.location.pathname === route

    return (
      <Navbar dark expand="lg" className="bo-main-menu">
        <NavbarBrand className="nav-link logo" onClick={this.goToDefault}>
          <img src={logo} width="32" height="32" alt="logo" />
        </NavbarBrand>
        <ActionLink className="nav-link title d-lg-none" onClick={this.goToDefault} text={this.title()} />
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-0" navbar>
            <NavItem active={isActive(routes.SEARCH())}>
              <ActionLink onClick={this.goToDefault} className="nav-link" id="nav-link-search" text="Search" />
            </NavItem>
            <NavItem active={isActive(routes.MIXUPS())}>
              <ActionLink route={routes.MIXUPS()} className="nav-link" id="nav-link-mix-up" text="Mix-ups" />
            </NavItem>
            <NavItem active={isActive(routes.DISCOUNTS())}>
              <ActionLink route={routes.DISCOUNTS()} className="nav-link" id="nav-link-discount" text="Discounts" />
            </NavItem>
            {this.props.permissions && this.props.permissions.isExpert && (
              <NavItem active={isActive(routes.PROJECTS())}>
                <ActionLink route={routes.PROJECTS()} className="nav-link" id="nav-link-discount" text="Projects" />
              </NavItem>
            )}
            {this.props.permissions && this.props.permissions.isExpert && (
              <NavItem active={isActive(routes.ACCOUNTS())}>
                <ActionLink route={routes.ACCOUNTS()} className="nav-link" id="nav-link-discount" text="Accounts" />
              </NavItem>
            )}
            {this.props.permissions && this.props.permissions?.isSupportEngineer && (
              <NavItem active={isActive(routes.BULK_OPERATIONS())}>
                <ActionLink route={routes.BULK_OPERATIONS()} className="nav-link" id="nav-link-discount" text="Bulk-Operations" />
              </NavItem>
            )}
          </Nav>
        </Collapse>
        <Nav className="ml-0 right-menu" navbar>
          <NavItem className="d-none d-lg-block">
            <ActionLink className="nav-link" onClick={this.goToDefault} text={this.title()} />
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Welcome, {AuthInstance.getUser().profile.name}
            </DropdownToggle>
            <DropdownMenu right className="mt-0">
              <DropdownItem>
                <ActionLink route={routes.PERMISSIONS()} text="Permissions" />
              </DropdownItem>
              <DropdownItem>
                <ActionLink onClick={() => AuthInstance.logOut()} text="Log out" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    )
  }
}

export default connect((store: AppStore) => {
  return {
    ...store.router,
    ...store.global?.configuration,
  }
})(NavMenu)
