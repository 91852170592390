import { GlobalConfiguration } from '../api/configuration'

export class LocalStorageService {
  globalConfigKey: string = 'cc.bo.global.configuration'

  getGlobalConfiguration(): GlobalConfiguration | undefined {
    try {
      const json: string | null = window.localStorage.getItem(this.globalConfigKey)
      if (!json) {
        return undefined
      }
      return JSON.parse(json)
    } catch (e) {
      console.error('Global config read error:')
      console.error(e)
      try {
        window.localStorage.removeItem(this.globalConfigKey)
      } catch {}
      return undefined
    }
  }

  setGlobalConfiguration(config?: GlobalConfiguration): void {
    const json = config ? JSON.stringify(config) : ''
    window.localStorage.setItem(this.globalConfigKey, json)
  }

  clearAll(): void {
    window.localStorage.clear()
  }
}

export const LocalStorageInstance: LocalStorageService = new LocalStorageService()
